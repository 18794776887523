import { SxProps, TextField } from '@mui/material'
import { ChangeEventHandler } from 'react'
import { FormGridFieldCustomRenderProps } from '../../../../../../models/form'

export type ControllerTextFieldProps<InputProps, OutputValueType> =
  FormGridFieldCustomRenderProps<InputProps, string, OutputValueType> &
    Partial<{
      sx: SxProps
    }>

function ControllerTextField<InputProps, OutputValueType>(
  props: ControllerTextFieldProps<InputProps, OutputValueType>
) {
  const {
    controller,
    label,
    sx,
    onChange: onChangeIntercept,
    // eslint-disable-next-line
    translatePrefix, // used to destructure this property from 'rest'
    inputProps
  } = props

  const {
    field: { value, onChange: controllerOnChange, onBlur },
    fieldState: { error }
  } = controller

  const onChange:
    | ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | undefined = (evt) => {
    const value = evt.target.value

    controllerOnChange(onChangeIntercept ? onChangeIntercept(value) : value)
  }

  return (
    <TextField
      label={label}
      variant="outlined"
      error={!!error}
      sx={{ flexGrow: 1, ...sx }}
      value={value}
      fullWidth
      onChange={onChange}
      onBlur={onBlur}
      {...inputProps}
    />
  )
}

export default ControllerTextField
