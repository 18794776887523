const apiaryInfoTab = {
  info: {
    deleteConfirmation: 'Ta bort bigård',
    deleteInformation:
      'Är du säker på att du vill ta bort bigården? Om bigården innehåller några bikupor kommer även desssa raderas, detta går inte att ångra.',
    deleteSucces: 'Bigården har tagits bort'
  },
  button: {
    deleteApiary: 'Ta bort bigård',
    cancel: 'Avbryt'
  },
  apiary: {
    owner: 'Ägare',
    coordinates: 'Koordinater',
    town: 'Kommun',
    propertyDesignation: 'Fastighetsbeteckning',
    areaOfUse: 'Användningsområde',
    other: 'Övrigt'
  },
  error: {
    loadingApiary: 'Ett fel inträffade när bigården skulle laddas',
    loadingOrganization: 'Ett fel inträffade när organisationen skulle laddas'
  },
  settings: 'Inställningar'
}

export default apiaryInfoTab
