import { useMutation } from '@tanstack/react-query'
import { useApiClient } from './useApiClient'

export const UPDATE_MEMBER_ROLE = 'updateMemberRole'

export function useUpdateMemberRoleMutation(onSuccess?: () => void) {
  const apiClient = useApiClient()

  return useMutation({
    mutationFn: apiClient.organizationMember.updateMemberRole,
    onSuccess: onSuccess
  })
}

export function useDeleteMemberMutation(onSuccess?: () => void) {
  const apiClient = useApiClient()
  return useMutation({
    mutationFn: apiClient.organizationMember.deleteMember,
    onSuccess: onSuccess
  })
}
