import { components } from '../utils/api/schema'

export type Organization = components['schemas']['OrganizationDetailsDto']

export type OrganizationApiariesAmountOverview =
  components['schemas']['OrganizationOverviewDto']

export type OrganizationMemberOverview =
  components['schemas']['OrganizationMemberOverviewDto']

export type OrganizationMemberRoleOverview = OrganizationMemberOverview['role']

export type OrganizationMemberRoleInput =
  components['schemas']['OrgMemberRoleEditInputDto']['role']

export type OrganizationBasicOverview =
  components['schemas']['OrganizationBasicOverviewDto']

export enum OrganizationMemberRoles {
  ADMIN = 'ADMIN',
  MAINTAINER = 'MAINTAINER',
  READER = 'READER'
}
