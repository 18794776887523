import {
  Button,
  Grid,
  Stack,
  Box,
  Paper,
  Typography,
  Theme
} from '@mui/material'
import StaticBackground from '../../components/common/StaticBackground'
import { useTranslation } from 'react-i18next'
import LoginIcon from '../../components/common/Icon/Login'
import PlusIcon from '../../components/common/Icon/Plus'
import { Link } from 'react-router-dom'
import { AppRoutePaths } from '../../utils/routes'
import PageBodyContainer from '../../components/common/PageBodyContainer'

const COMPONENT_TRANSLATE_PREFIX = 'pages.auth.landingPage'

const gridRootStyle = (theme: Theme) => ({
  alignItems: 'center',
  justifyContent: 'center',
  p: 2,
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row-reverse'
  }
})

const imageCardStyle = (theme: Theme) => ({
  width: '100%',
  height: 'auto',
  [theme.breakpoints.down('md')]: {
    mb: 2,
    maxWidth: 150
  },
  [theme.breakpoints.up('md')]: {
    ml: 4
  }
})

const AuthLandingPage = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: COMPONENT_TRANSLATE_PREFIX
  })

  return (
    <Stack className="pageRoot" justifyContent="center">
      <StaticBackground src="/images/generic-background.png" />
      <Grid container spacing={2} sx={gridRootStyle}>
        <Grid item container justifyContent="center" xs={12} md={6}>
          <Paper sx={imageCardStyle} className="rounded">
            <Box
              sx={{ p: '25%' }}
              component="img"
              src="/images/bee-title-icon.svg"
            />
          </Paper>
        </Grid>

        <Grid item xs={12} sm={8} md={6}>
          <Stack spacing={2} sx={{ color: 'white.main' }}>
            <PageBodyContainer>
              <Typography variant="h2">{t('title')}</Typography>

              <Typography>{t('description')}</Typography>
            </PageBodyContainer>

            <Button
              variant="contained"
              component={Link}
              to={AppRoutePaths.LOGIN_PATH}
              startIcon={<LoginIcon />}
            >
              {t('signIn')}
            </Button>

            <Button
              variant="outlined"
              color="white"
              component={Link}
              to={AppRoutePaths.REGISTER_PATH}
              startIcon={<PlusIcon />}
            >
              {t('register')}
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  )
}

export default AuthLandingPage
