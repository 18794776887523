import AutocompleteField from './AutocompleteField'

const ApiarySelectField = props => {
  const { apiaries, ...rest } = props

  return (
    <AutocompleteField
      name="apiary"
      translateKey="apiary"
      translatePrefix="form"
      options={apiaries}
      optionKeyById
      customNoOptionsText
      getOptionLabel={o => o?.name || ''}
      {...rest}
    />
  )
}

export default ApiarySelectField
