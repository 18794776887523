import { Stack, Typography, Box } from '@mui/material'
import { useCallback, useMemo } from 'react'

import CircleIcon from '@mui/icons-material/Circle'
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined'
import { THEME_CONSTANTS } from '../../styles/themeConstants'

const containerStyle = theme => ({
  // height: theme.spacing(20),
  paddingBottom: 2,
  position: 'relative',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.up('md')]: {
    justifyContent: 'end'
  }
})

const imageStyle = theme => ({
  backgroundColor: theme.palette.imagePlaceholder.main,
  filter: THEME_CONSTANTS.darkenImageBrightness,
  position: 'absolute',
  top: 0,
  height: '100%',
  width: '100%',
  display: 'block',
  objectFit: 'cover',
  zIndex: -1,

  [theme.breakpoints.up('md')]: {
    position: 'fixed',
    left: 0,
    top: THEME_CONSTANTS.toolbarHeight,
    width: '100vw',
    height: `calc(100% - ${THEME_CONSTANTS.toolbarHeight})`
  }
})

const titleStyle = theme => ({
  pt: theme.spacing(4),
  fontWeight: 'bold',
  overflow: 'hidden',
  WebkitLineClamp: '2',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  color: theme.palette.darkContrast.main,

  [theme.breakpoints.down('md')]: {
    textAlign: 'center'
  },

  [theme.breakpoints.up('md')]: {
    width: '100%'
  }
})

const StepDot = props => {
  const { index, activeStep } = props

  const color = index === activeStep
    ? 'primary'
    : 'white'

  const iconProps = {
    color,
    sx: {
      fontSize: 16
    }
  }

  return index > activeStep
    ? <CircleOutlinedIcon {...iconProps}/>
    : <CircleIcon {...iconProps}/>
}

const TitleImageStepDots = ({ stepsAmount, activeStep }) => {
  const dots = useMemo(
    () => Array.from(
      new Array(stepsAmount),
      (...[, index]) =>
        <StepDot
          key={index}
          index={index}
          activeStep={activeStep}
        />
    ),
    [stepsAmount, activeStep]
  )

  return (
    <Stack
      direction="row"
      spacing={2}
    >
      {dots}
    </Stack>
  )
}

/**
 * Component for displaying title images
 * @param {string} props.title title
 * @param {string} props.src image source
 * @param {object=} props.sx additional styles
 * @param {number=} props.stepsAmount amount of steps for stepper page
 * @param {number=} props.activeStep active step index for stepper page
 * @returns
 */
const TitleImage = props => {
  const {
    title,
    src = '/images/generic-background.png',
    sx,
    stepsAmount,
    activeStep,
    ...rest
  } = props

  const containerSxFn = useCallback(
    theme => ({
      ...containerStyle(theme),
      ...sx
    }),
    [sx]
  )

  return (
    <Stack spacing={1} sx={containerSxFn} {...rest}>
      <Box component="img" sx={imageStyle} src={src}/>

      <Typography variant='h1' sx={titleStyle}>
        {title}
      </Typography>

      {stepsAmount &&
        <TitleImageStepDots
          stepsAmount={stepsAmount}
          activeStep={activeStep}
        />
      }
    </Stack>
  )
}

export default TitleImage
