import S from '../../enums/Path/StaticPathSegment'
import V from '../../enums/Path/VariablePathSegment'
import * as PathSegment from './pathSegment'

const {
  createPath: p,
  variableSegment: vSeg
} = PathSegment

export const DISPLAY_NOTE_PATH = '/Note'
export const DISPLAY_APIARY_PATH = '/apiaries/display/:id'
// export const EDIT_NOTE_PATH = '/notes/edit/:id'

export const ALARMS_PATH = '/alarms'
export const NEW_APIARY_PATH = '/apiaries/new'
export const LIST_APIARIES_PATH = '/apiaries'
export const LIST_BEEHIVES_PATH = '/beehives'
export const ADMIN_CREATE_STATIC_ORGS_PATH = '/static-org/create'

// ---------------- UPDATED PATHS ----------------
export const LANDING_PATH = '/'

export const LOGIN_PATH = p(S.LOGIN)
export const PASSWORD_RESET_PATH = p(S.PASSWORD_RESET)
export const REGISTER_PATH = p(S.REGISTER)
export const VERIFY_USER_EMAIL_PATH = p(S.VERIFY_USER_EMAIL)

export const HOME_PATH = p(S.HOME)
export const ABOUT_PATH = p(S.HOME, S.ABOUT)
export const GETTING_STARTED_PATH = p(S.GUIDE)
export const ORGANIZATION = p(S.ORGANIZATION)

export const LIST_ORGANIZATIONS_PATH = p(HOME_PATH, S.ORGANIZATION)
export const NEW_ORGANIZATION_PATH = p(LIST_ORGANIZATIONS_PATH, S.NEW)
export const SEARCH_ORGANIZATION_PATH = p(LIST_ORGANIZATIONS_PATH, S.SEARCH)
export const DISPLAY_ORGANIZATION_PATH = p(LIST_ORGANIZATIONS_PATH, vSeg(V.ORGANIZATION_ID))
export const EDIT_ORGANIZATION_PATH = p(DISPLAY_ORGANIZATION_PATH, S.EDIT)

export const EDIT_ORGANIZATION_MEMBER_PATH = p(DISPLAY_ORGANIZATION_PATH, S.ORGANIZATION_MEMBER, vSeg(V.ORGANIZATION_MEMBER_ID), S.EDIT)

export const ADD_APIARIES_PATH = p(DISPLAY_ORGANIZATION_PATH, S.NEW_APIARIES)
export const APIARY_VIEW_PATH = p(DISPLAY_ORGANIZATION_PATH, vSeg(V.APIARY_ID))
export const EDIT_APIARY_PATH = p(APIARY_VIEW_PATH, S.EDIT)

export const PROFILE_PATH = p(HOME_PATH, S.PROFILE, vSeg(V.PROFILE_ID))
export const MY_PROFILE_PATH = p(HOME_PATH, S.MY_PROFILE)
export const EDIT_PROFILE_PATH = p(MY_PROFILE_PATH, S.EDIT)

export const NOTES_PATH = p(HOME_PATH, S.NOTE)
export const VIEW_OTHER_NOTE_PATH = p(NOTES_PATH, S.OTHER_NOTE, vSeg(V.OTHER_NOTE_ID))
export const NEW_NOTE_PATH = p(HOME_PATH, S.NOTE, S.NEW)
export const LIST_NOTES_PATH = p(NOTES_PATH, S.ALL_NOTES)
export const EDIT_NOTE_PATH = p(LIST_NOTES_PATH, vSeg(V.NOTE_ID), S.EDIT)

export const BEEHIVE_INSPECTION_NOTE_PATH = p(NOTES_PATH, S.BEEHIVE_INSPECTION_NOTE)
export const VIEW_BEEHIVE_INSPECTION_NOTE_PATH = p(BEEHIVE_INSPECTION_NOTE_PATH, vSeg(V.BEEHIVE_INSPECTION_NOTE_ID))
export const EDIT_BEEHIVE_INSPECTION_NOTE_PATH = p(BEEHIVE_INSPECTION_NOTE_PATH, vSeg(V.BEEHIVE_INSPECTION_NOTE_ID), S.EDIT)

export const NEW_BEEHIVE_PATH = p(APIARY_VIEW_PATH, S.NEW)
export const DISPLAY_BEEHIVE_PATH = p(APIARY_VIEW_PATH, vSeg(V.BEEHIVE_ID))
export const EDIT_BEEHIVE_PATH = p(DISPLAY_BEEHIVE_PATH, S.EDIT)
