import TextField, { DefaultTranslatedTextFieldProps } from './TextField'

function AddressField<InputProps> (
  props: DefaultTranslatedTextFieldProps<InputProps>
) {
  return (
    <TextField
      name="street"
      translateKey="street"
      translatePrefix="form"
      {...props}
    />
  )
}

export default AddressField
