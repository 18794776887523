const createStaticOrganizations = {
  title: 'Skapa organisationer',
  fileSelect: {
    inputLabel: 'Välj fil',
    valuePrefix: 'Vald fil: ',
    required: 'Fil krävs!'
  },
  parentOrgSelectLabel: 'Överliggande organisation',
  staticOrgsMissing: 'Det finns inga existerande statiska organisationer',

  staticOrgTypeSelectLabel: 'Typ av organisation',
  staticOrganization: {
    nation: 'Nation',
    district: 'Distrikt',
    association: 'Förening'
  },

  createOrganizations: {
    success: 'Organisationerna har skapats',
    error: 'Fel inträffade vid skapning av vissa organisationer'
  }
}

export default createStaticOrganizations
