import { Box } from '@mui/material'
import { LatLngExpression } from 'leaflet'
import { MapContainer, Marker, TileLayer, Tooltip, Circle } from 'react-leaflet'
import * as L from 'leaflet'
import {
  useGetAllIoTGeneralCoOrdsQuery,
  useGetIoTCoOrdsQuery
} from '../../../../../utils/api/hooks/beehive'
import { Beehive } from '../../../../../models/beehive'

const MAP_TILE_LAYER_PROPS = {
  attribution:
    '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
  url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
}

type IoTMapProps = {
  beehive: Beehive
}
const DEFAULT_LOCATION = { lat: 63.545688, lng: 16.45271 }
const MARKER_ICONS_PATH = '/images/map/icons'

const LeafIcon = L.Icon.extend({
  options: {
    iconUrl: `${MARKER_ICONS_PATH}/marker-icon-${'orange'}.png`,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [0, -41]
  }
})

const IoTMap = (props: IoTMapProps) => {
  const allIotGeneralCoOrds = useGetAllIoTGeneralCoOrdsQuery(props.beehive.id)
    .data?.data
  const myIotCoOrdsQuery = useGetIoTCoOrdsQuery(props.beehive.id)
  const myIotCoOrds = myIotCoOrdsQuery.data?.data
  let myMarker: LatLngExpression = [51.505, -0.09]
  if (myIotCoOrds && myIotCoOrdsQuery.isFetched) {
    myMarker = [myIotCoOrds.locationLat, myIotCoOrds.locationLng]
  }

  let filtered:
    | {
        locationLat: number
        locationLng: number
      }[]
    | undefined

  if (myIotCoOrds !== undefined) {
    filtered = allIotGeneralCoOrds?.reduce<typeof allIotGeneralCoOrds>(
      (temp, curr) => {
        const hasDuplicate = temp.some((item) => {
          return (
            item.locationLat === curr.locationLat &&
            item.locationLng === curr.locationLng
          )
        })
        if (!hasDuplicate) {
          temp.push(curr)
        }
        return temp
      },
      []
    )
  }
  if (filtered !== undefined && myIotCoOrds !== undefined) {
    const index = filtered.findIndex(
      (location) =>
        location.locationLat === myIotCoOrds.locationLat &&
        location.locationLng === myIotCoOrds.locationLng
    )

    if (index >= 0) {
      filtered.splice(index, 1)
    }
  }

  return (
    <Box sx={{ height: '80vh' }}>
      <MapContainer
        style={{ width: '100%', height: '100%', borderRadius: '15px' }}
        center={DEFAULT_LOCATION}
        zoom={5}
      >
        <TileLayer {...MAP_TILE_LAYER_PROPS} />
        {myMarker !== undefined && (
          <Marker icon={new LeafIcon()} position={[myMarker[0], myMarker[1]]}>
            <Tooltip>{props.beehive.name}</Tooltip>
          </Marker>
        )}
        {filtered?.map((location, index) => (
          <Circle
            key={index}
            center={[location.locationLat, location.locationLng]}
            color="#EDA839FF"
            fillColor="#EDA839FF"
            radius={5000}
          ></Circle>
        ))}
      </MapContainer>
    </Box>
  )
}

export default IoTMap

/* <ApiaryMarker
    key={0}
    index={0}
    // apiary={apiary}
    location={location}
    // select={selectApiary}
    draggable={true}
    markersAmount={1}
    setLocation={
      (location: any) => {
        return location
      } // updateApiaryLocation((apiary.sortId || apiary.id), location)
    }
  /> */
