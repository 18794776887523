/**
 *
 * DEPRECATED
 *
 */
import { useFormContext } from 'react-hook-form'
import ORGANIZATION_RULES from '../../../../../utils/form/rules/organization'
import FormGrid from '../../../Form/FormGrid'
import AddressField from '../../../Form/FormGrid/CommonRows/AddressField'
import CountryField from '../../../Form/FormGrid/CommonRows/CountryField'
import NameField from '../../../Form/FormGrid/CommonRows/NameField'
import TextField from '../../../Form/FormGrid/CommonRows/TextField'
import TownRow from '../../../Form/FormGrid/CommonRows/TownRow'
import { ORGANIZATION_FORM_NAME } from '../types'

export type OrganizationOptionsFields = {
  name: string
  description: string
  organizationNumber: string
  town: string
  country: string
}

const COMPONENT_TRANSLATE_PREFIX =
  'components.forms.organizationGeneration.organizationOptions'

const OrganizationOptions = () => {
  const methods = useFormContext()

  return (
    <FormGrid
      name={ORGANIZATION_FORM_NAME}
      methods={methods}
      translatePrefix={COMPONENT_TRANSLATE_PREFIX}
      /* rules={{ required: true }} */
      sx={{ px: 2 }}
    >
      <NameField rules={ORGANIZATION_RULES.name} />

      <TextField name="description" rules={ORGANIZATION_RULES.description} />

      <TextField name="organizationNumber" />
      <AddressField />
      <TownRow />
      <CountryField />
    </FormGrid>
  )
}

export default OrganizationOptions
