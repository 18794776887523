const newOrganization = {
  title: 'Create organization',

  stepTitle: {
    organization: 'About the organization',
    apiaries: 'Add apiaries',
    beehives: 'Adjust beehives',
    review: 'Overview'
  },

  actions: {
    createOrganization: {
      pending: 'Creating organization...',
      error: 'An error occurred while creating the organization',
      fulfilled: 'Organization created'
    }
  }
}

export default newOrganization
