import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

/**
 * Custom hook for automatically navigating to a specific path when a condition is met
 * @param {*} controlVal condition for navigating
 * @param {*} navPath the path to navigate to when the condition is met
 * @param {*} cb callback called on navigation
 */
export const useStateNavigateEffect = (
  controlVal: any,
  navPath: string,
  cb?: () => void
) => {
  const navigate = useNavigate()

  useEffect(() => {
    if (controlVal) {
      navigate(navPath)
      if (cb) {
        cb()
      }
    }
  }, [controlVal, navPath])
}
