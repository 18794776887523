const displayBeehiveInspectionNote = {
  actions: {
    updateInspectionNote: {
      error: 'An error occurred while saving the changes.',
      success: 'Changes have been saved.'
    },
    loadInspectionNote: {
      error: 'An error occurred while loading the note.',
      success: 'Note has been loaded.'
    }
  }
}

export default displayBeehiveInspectionNote
