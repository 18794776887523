import { GlobalStyles } from '@mui/material'
import theme, { APP_CONTENT_MARGIN } from './theme'

const AppGlobalStyles = (
  <GlobalStyles
    styles={{
      '.appRoot': {
        paddingTop: APP_CONTENT_MARGIN,
        minHeight: '100vh',
        boxSizing: 'border-box',

        [theme.breakpoints.up('md')]: {
          paddingBottom: theme.spacing(2)
        }
      },
      '.appRootMobile': {
        paddingTop: APP_CONTENT_MARGIN,
        minHeight: '100vh',
        boxSizing: 'border-box',

        [theme.breakpoints.down('md')]: {
          paddingBottom: '56px'
        }
      },
      '.link': {
        color: '#000000',
        transition: 'all 0.2s ease-in-out'
      },
      '.link:hover': {
        color: theme.palette.primary.main
      },
      '.link:active': {
        color: theme.palette.primary.main
      },
      '.pagination': {
        justifyContent: 'center',
        width: '100%'
      },
      '.viewNotePage': {
        overflow: 'clip',
        'fieldset.MuiOutlinedInput-notchedOutline': {
          opacity: 0
        },
        '.Mui-disabled': {
          color: `${theme.palette.text.primary} !important`,
          WebkitTextFillColor: `${theme.palette.text.primary} !important`
        },
        '.main-checkbox': {
          opacity: 0.5
        }
      },
      '.pageRoot': {
        maxWidth: '100%',
        overflowX: 'hidden',
        position: 'relative',
        flexGrow: 1
      },
      '.pageContent': {
        flexGrow: 1,

        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),

        backgroundColor: theme.palette.background.paper,

        [theme.breakpoints.up('md')]: {
          borderRadius: theme.spacing(2)
        },
        '&.noBG': {
          background: 'none'
        },

        '.overflowPageContentPadding': {
          marginLeft: theme.spacing(-2),
          marginRight: theme.spacing(-2),
          width: `calc(100% + ${theme.spacing(4)})`,

          '.insidePageContentPadding': {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2)
          }
        },

        '.TabPanel-root': {
          padding: theme.spacing(2)
          // paddingTop: theme.spacing(4)
        }
      },
      '.staticBackground': {
        position: 'absolute',
        height: '100%'
      },
      '.itemListSubList': {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
      },
      '.contained': {
        maxWidth: '100%',
        maxHeight: '100%'
      },
      '.checkRow': {
        padding: theme.spacing(2),

        '&.after-other-fields': {
          marginTop: theme.spacing(2)
        },

        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.white.main
        },
        '&:nth-of-type(even)': {
          backgroundColor: theme.palette.paper.main
        },
        '.main-checkbox': {
          padding: 0
        },
        '.check-row-section-title': {
          marginTop: theme.spacing(2),

          '&.no-gutter': {
            marginTop: 0
          }
        },
        '.content-checkrow-content': {
          width: '100%'
        },
        '.checkRowContent': {
          marginTop: 0
        }
      },
      '.radioGroupField': {
        '.MuiFormControlLabel-root': {
          marginLeft: 0,
          marginRight: 0,
          justifyContent: 'center'
        }
      },
      '.itemList': {
        th: {
          fontWeight: 'bold'
        },
        'th, td': {
          borderBottom: 'none'
        },
        'tbody tr': {
          position: 'relative'
        },

        'tbody .MuiTableRow-root': {
          position: 'relative'
        },

        td: {
          overflowWrap: 'anywhere'
        },

        '&.non-expandable': {
          'tbody .MuiTableRow-root': {
            '&:nth-of-type(even)': {
              '&, & .itemListActionButtonsCollapse': {
                backgroundColor: theme.palette.white.main
              }
            },
            '&:nth-of-type(odd)': {
              '&, & .itemListActionButtonsCollapse': {
                backgroundColor: theme.palette.paper.main
              }
            }
          }
        },

        '&.expandable': {
          'tbody .MuiTableRow-root': {
            '&:nth-of-type(4n+3), &:nth-of-type(4n+4)': {
              '&, .itemListActionButtonsCollapse': {
                backgroundColor: theme.palette.white.main
              }
            },
            '&:nth-of-type(4n+1), &:nth-of-type(4n+2)': {
              '&, .itemListActionButtonsCollapse': {
                backgroundColor: theme.palette.paper.main
              }
            }
          },

          '.expandable-row td': {
            padding: 0,
            '.expandable-row-content': {
              padding: theme.spacing(2)
            }
          }
        },
        '.itemListActionButtons': {
          position: 'relative',
          padding: 0,
          width: theme.spacing(8.375),
          height: '100%',
          zIndex: 2,
          '.itemListActionButtonsCollapse': {
            position: 'absolute',
            right: 0,
            top: 0,
            height: '100%',

            '.itemListActionButtonsStack': {
              paddingLeft: theme.spacing(2),
              paddingRight: theme.spacing(2),
              height: '100%',
              alignItems: 'center'
            }
          }
        },
        '.itemListContentOverlayTableCell': {
          padding: 0,
          width: 0,
          '.itemListContentOverlay': {
            cursor: 'pointer',
            position: 'absolute',
            zIndex: 1,
            padding: 0,
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: '#0000007a'
          }
        },
        '.itemListLinkCell': {
          padding: 0
        },
        '.rounded': {
          borderRadius: theme.spacing(2)
        }
      }
    }}
  />
)

export default AppGlobalStyles
