import { Stack } from '@mui/material'
import { useParams } from 'react-router-dom'
import TitleImage from '../../components/common/TitleImage'
import { AppRoutePaths } from '../../utils/routes'
import { useStateNavigateEffect } from '../../utils/hooks/stateNavigateEffect'

import LoadingPage from '../../components/common/LoadingPage'
import { useTranslation } from 'react-i18next'

import {
  useErrorStateSnackEffect,
  useSuccessStateSnackEffect
} from '../../utils/hooks/stateSnackEffect'
import V from '../../enums/Path/VariablePathSegment'

import BeehiveInspectionNoteFormStateWrapper from '../../components/Note/BeehiveInspectionNoteFormStateWrapper'
import {
  useGetBeehiveInspectionNoteById,
  usePutBeehiveInspectionNote
} from '../../utils/api/hooks/inspectionNote'
import { replaceContentCheckRowValues } from '../../components/common/Form/FormGrid/CommonRows/CheckRow/ContentCheckRow'
import { BeehiveInspectionNoteEditDto } from '../../models/notes'

const EditBeehiveInspectionNote = () => {
  const { [V.BEEHIVE_INSPECTION_NOTE_ID]: noteId } = useParams()
  const { [V.ORGANIZATION_ID]: organizationId } = useParams()
  const { t } = useTranslation()

  const getBeehiveInspectionNoteById = useGetBeehiveInspectionNoteById(noteId!)
  const getNoteData = getBeehiveInspectionNoteById.data?.data
  const getError = getBeehiveInspectionNoteById.error
  const getPending = getBeehiveInspectionNoteById.isLoading

  useErrorStateSnackEffect(getError, t('error.loadingNote'))

  const putBeehiveInspectionNote = usePutBeehiveInspectionNote()
  const putError = putBeehiveInspectionNote.error
  const putPending = putBeehiveInspectionNote.isLoading
  const putSuccess = putBeehiveInspectionNote.isSuccess

  useErrorStateSnackEffect(putError, t('error.updateNote'))

  useErrorStateSnackEffect(putError, t('error.update'))
  useSuccessStateSnackEffect(putSuccess, t('success.noteSaved'))

  useStateNavigateEffect(putSuccess, AppRoutePaths.LIST_NOTES_PATH)

  const onSubmit = async (data: any) => {
    Object.keys(data).forEach((key) => {
      if (data[key] === null) {
        data[key] = undefined
      }
    })
    const updatedInspectionNote = {
      ...replaceContentCheckRowValues(data),
      beehiveId: getNoteData!.beehiveId!,
      organizationId: organizationId!
    } as BeehiveInspectionNoteEditDto

    putBeehiveInspectionNote.mutate({ id: noteId!, ...updatedInspectionNote })
  }

  return getPending ? (
    <LoadingPage />
  ) : (
    <Stack className="Pageroot">
      <TitleImage
        src="/images/generic-background.png"
        title={getNoteData?.title}
      />

      <Stack className="pageContent">
        <BeehiveInspectionNoteFormStateWrapper
          initialState={getNoteData!}
          onSubmit={onSubmit}
          pending={putPending}
          isForDisplayOnly={false}
        />
      </Stack>
    </Stack>
  )
}

export default EditBeehiveInspectionNote
