import { Stack, StackProps } from '@mui/material'

export type ButtonListContainerProps = {
  fullwidth?: boolean
} & StackProps

function ButtonListContainer (props: ButtonListContainerProps) {
  const { children, fullwidth, ...rest } = props

  return (
    <Stack
      direction="row"
      justifyContent="center"
      spacing={2}
      style={{ width: fullwidth ? '100%' : undefined }}
      {...rest}
    >
      {children}
    </Stack>
  )
}

export default ButtonListContainer
