import { useSnackbar } from 'notistack'
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import { formHelpers } from '../../../../utils/form'
import useIsMounted from '../../../../utils/hooks/isMounted'
import FormGrid from '../../../common/Form/FormGrid'
import FreeTextField from '../../../common/Form/FormGrid/CommonRows/FreeTextField'
import TextField from '../../../common/Form/FormGrid/CommonRows/TextField'
import { useNavigate } from 'react-router-dom'
import { useCreateOtherNoteMutation } from '../../../../utils/api/hooks/otherNote'
import { useTranslation } from 'react-i18next'
import { AppRoutePaths } from '../../../../utils/routes'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import { Button, Grid, Card, CardMedia } from '@mui/material'
import { Box } from '@mui/system'
import axios from 'axios'
import NOTE_RULES from '../../../../utils/form/rules/note'

const COMPONENT_TRANSLATE_PREFIX =
  'components.note.newNote.newNoteDetails.beehiveEventDetails'

const CREATE_NOTE_TRANSLATE_PREFIX = 'pages.note.newNotePage'

type OtherDetailsProps = {
  entitySelectName: string
  categorySelectName: string
  name: string
  byId: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmitRef: MutableRefObject<any>
}

const COMPONENT_CREATE_FUNCTION =
  (
    name: string,
    entitySelectName: string,
    categorySelectName: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    mutation: any,
    image: File
  ) =>
  async (form: object) => {
    const noteValues = formHelpers.getFormValue(form, name)
    const category = formHelpers.getFormValue(form, categorySelectName)
    const entitySelectValues = formHelpers.getFormValue(form, entitySelectName)

    const organizationId = entitySelectValues.organization?.id
    const apiaryId = entitySelectValues.apiary?.id
    const beehiveId = entitySelectValues.beehive?.id

    const mutationResult = await mutation.mutate({
      title: noteValues.title,
      text: noteValues.text ? noteValues.text : '',
      category: category,
      organizationId: organizationId,
      apiaryId: apiaryId,
      beehiveId: beehiveId
    })

    if (image.size > 0) {
      const token = localStorage.getItem('api-jwt-token')
      const formData = new FormData()
      const fileName =
        '!!OrgID:' +
        mutationResult.data.organizationId +
        '!!otherNoteID:' +
        mutationResult.data.id
      formData.append('file', image, fileName)
      const options = {
        headers: {
          'Content-Type':
            'multipart/form-data; boundary=---011000010111000001101001',
          Authorization: 'Bearer ' + token
        }
      }
      try {
        await axios.post('/api/aws/postImageToS3', formData, options)
        console.log('File uploaded successfully')
      } catch (error) {
        console.error('Error uploading file:', error)
      }
    }

    return mutationResult
  }

const OtherDetails = (props: OtherDetailsProps) => {
  const [selectedImage, setSelectedImage] = useState<File>(new File([], ''))
  const [imageData, setImageData] = useState<string | null>('')
  const handleImageSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0]
      setImageData(file ? URL.createObjectURL(file) : '')
      setSelectedImage(file)
    }
  }

  const { entitySelectName, categorySelectName, name, byId, onSubmitRef } =
    props

  const { t } = useTranslation(undefined, {
    keyPrefix: CREATE_NOTE_TRANSLATE_PREFIX
  })

  const { enqueueSnackbar } = useSnackbar()
  const isMounted = useIsMounted()
  const navigate = useNavigate()

  const onCreateOtherNoteSuccess = useCallback(() => {
    enqueueSnackbar(t('actions.submit.fulfilled'), { variant: 'success' })

    navigate(AppRoutePaths.NOTES_PATH)
  }, [t, enqueueSnackbar, isMounted, navigate])

  const createOtherNoteMutation = useCreateOtherNoteMutation(
    onCreateOtherNoteSuccess
  )

  useEffect(() => {
    // if (!onSubmitRef) return

    /*   if (byId) {
      /* onSubmitRef.current = COMPONENT_UPDATE_FUNCTION(name) */

    onSubmitRef.current = COMPONENT_CREATE_FUNCTION(
      name,
      entitySelectName,
      categorySelectName,
      createOtherNoteMutation,
      selectedImage
    )
  }, [name, byId, onSubmitRef, selectedImage])

  useEffect(() => undefined, [onSubmitRef.current])

  const inputRef = useRef<HTMLInputElement>(null)

  const handleClick = () => {
    if (inputRef.current !== null) {
      inputRef.current.click()
    }
  }

  return (
    <FormGrid name={name} translatePrefix={COMPONENT_TRANSLATE_PREFIX}>
      <TextField name="title" rules={NOTE_RULES.title} />

      <FreeTextField name="text" />
      <input
        type="file"
        accept="image/*"
        ref={inputRef}
        onChange={handleImageSelect}
        style={{ display: 'none', color: 'primary' }}
      />
      <Grid
        container
        direction={'column'}
        justifyContent="center"
        alignItems="center"
      >
        <Button
          sx={{ marginTop: 2 }}
          color="secondary"
          onClick={handleClick}
          variant="outlined"
          endIcon={<FileUploadIcon color="primary" />}
        >
          {t('actions.submit.add')}
        </Button>
        <Box marginLeft={2} display="flex" justifyContent="center">
          <Card
            sx={{
              margin: 4,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {imageData !== '' && imageData !== null && (
              <CardMedia
                component="img"
                image={imageData}
                alt="green iguana"
              ></CardMedia>
            )}
          </Card>
        </Box>
      </Grid>
    </FormGrid>
  )
}

export default OtherDetails
