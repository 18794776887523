const organizationEditForm = {
  title: 'Ändra Organisation',
  error: {
    organizationNumber: 'Organisationsnumret ska vara på formen XXXXXXXXXX.',
    zipCode: 'Postnumret ska vara på formen XXXXX eller XXX XX.'
  },

  fields: {
    description: {
      label: 'Beskrivning'
    },

    organizationNumber: {
      label: 'Organisationsnummer'
    }
  }
}

export default organizationEditForm
