import { AppRoutePaths } from '.'
import StaticPathSegment from '../../enums/Path/StaticPathSegment'
import VariablePathSegment from '../../enums/Path/VariablePathSegment'

export type UnimplementedSegment = {
  segment: VariablePathSegment | StaticPathSegment,
  redirectTo: string,
  relativeRedirect?: boolean
}

export const UNIMPLEMENTED_SEGMENTS: UnimplementedSegment[] = [
  {
    segment: StaticPathSegment.ORGANIZATION_MEMBER,
    redirectTo: '../../..',
    relativeRedirect: true
  },
  {
    segment: VariablePathSegment.ORGANIZATION_MEMBER_ID,
    redirectTo: '../../..',
    relativeRedirect: true
  },
  {
    segment: StaticPathSegment.BEEHIVE_INSPECTION_NOTE,
    redirectTo: AppRoutePaths.LIST_NOTES_PATH,
    relativeRedirect: false
  },
  {
    segment: StaticPathSegment.OTHER_NOTE,
    redirectTo: AppRoutePaths.LIST_NOTES_PATH,
    relativeRedirect: false
  }
]
