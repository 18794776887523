import { Fragment } from 'react'
import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import ItemList from '../common/ItemList'
import ItemListActionButtons from '../common/ItemList/Row/ItemListActionButtons'
import ItemListExpandableRow from '../common/ItemList/Row/ItemListExpandableRow'
import ItemListLinkCol from '../common/ItemList/Row/ItemListLinkCol'
import SubList from '../common/ItemList/SubList'
import SubListItem from '../common/ItemList/SubList/SubListItem'
import { ApiaryOverview } from '../../models/apiary'

const COMPONENT_TRANSLATE_PREFIX = 'components.apiary.apiaryOverviewList'

type ApiaryWithBeehives = ApiaryOverview & {
  beehives: {
    id: string
    name: string
  }[]
}

type ApiariesOverviewListProps = {
  apiaries: ApiaryOverview[] | ApiaryWithBeehives[] | undefined
  pending?: boolean
  useLinkButton?: boolean
  showTitle?: boolean
}

const ApiariesOverviewList = (props: ApiariesOverviewListProps) => {
  const { apiaries, pending, useLinkButton, showTitle = true } = props
  const { t } = useTranslation(undefined, {
    keyPrefix: COMPONENT_TRANSLATE_PREFIX
  })
  if (apiaries === undefined) {
    return null
  }

  return (
    <ItemList
      title={showTitle ? t('apiaries.title') : undefined}
      pending={pending}
      expandable
    >
      <TableHead>
        <TableRow>
          <TableCell>{t('apiaries.header.name')}</TableCell>
          <TableCell>{t('apiaries.header.beehivesAmount')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {apiaries &&
          apiaries.map((apiary, index) => (
            <Fragment key={apiary.id}>
              <TableRow className="standard-row">
                <TableCell>{apiary.name}</TableCell>
                <TableCell>{apiary.beehivesAmount}</TableCell>
                {useLinkButton ? (
                  <ItemListLinkCol link={apiary.id} relative="path" />
                ) : (
                  <ItemListActionButtons data={undefined} index={index} />
                )}
              </TableRow>

              <ItemListExpandableRow index={index} colSpan={3}>
                <SubList
                  title={t('beehives')}
                  missingLabel={t('beehivesMissing')}
                >
                  {(apiary as ApiaryWithBeehives).beehives &&
                    (apiary as ApiaryWithBeehives).beehives.map(
                      (beehive, index) => (
                        <SubListItem key={beehive.id} index={index}>
                          <Typography>
                            {`${index + 1} - ${beehive.name}`}
                          </Typography>
                        </SubListItem>
                      )
                    )}
                </SubList>
              </ItemListExpandableRow>
            </Fragment>
          ))}
      </TableBody>
    </ItemList>
  )
}

export default ApiariesOverviewList
