const reviewModal = {
  sections: {
    main: {
      header: 'Review',
      body: 'We would love to hear your thoughts about the app and how we can improve it. Please write a review.'
    }
  },
  buttons: {
    cancel: 'Cancel',
    submit: 'Review'
  },
  actions: {
    error: 'An error occurred while submitting the review',
    success: 'Thank you for your review!'
  }
}

export default reviewModal
