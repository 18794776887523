const beehivePlanningDetails = {
  newDate: 'Add another date',
  date: 'Date',
  fields: {
    title: {
      label: 'Title',
      errors: {
        required: 'Title is required'
      }
    }
  }
}

export default beehivePlanningDetails
