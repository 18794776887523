
const beehivesTab = {
  addBeehive: 'Lägg till fler bikupor',
  error: {
    loadingBeehivesByApiary: 'Ett fel inträffade när bikuporna skulle laddas',
    deleteBeehive: 'Ett fel inträffade när bikupan skulle tas bort'
  },
  success: {
    deleteBeehive: 'Bikupan har tagits bort'
  }
}

export default beehivesTab
