const loggedInLoadingPage = {
  title: 'Välkommen',
  hasOrgDescription: 'Skapa en anteckning eller gå till organisationer för att hantera din biodling.',
  description: 'Du har ännu inte gått med i någon organisation. \n Skapa en ny eller gå med i en befintlig för att få tillgång till applikationens biodlingsfunktionaliteter.',
  scanBeehive: 'Skanna bikupa',
  displayOrganization: 'Gå till organisationer',
  createOrganization: 'Skapa en ny organisation',
  joinOrganization: 'Gå med i en organisation',
  listOrganizations: 'Mina organisationer',
  addApiaries: 'Lägg till dina egna bigårdar',
  downloadApp: 'Ladda ner appen',
  settings: 'Slutför inställningen av din profil',
  addNote: 'Skapa en anteckning'
}

export default loggedInLoadingPage
