import { useMutation, useQuery } from '@tanstack/react-query'
import { useApiClient } from './useApiClient'
import { CallbackVoid } from '../util/types'

export const GET_APIARY = 'getApiary'

export function useGetApiaryQuery(apiaryId: string) {
  const apiClient = useApiClient()

  return useQuery({
    queryKey: [GET_APIARY, apiaryId],
    queryFn: async () => {
      if (typeof apiaryId === 'undefined') {
        throw new Error()
      }

      return apiClient.apiary.getApiary({ id: apiaryId })
    },
    enabled: !!apiaryId
  })
}

export const createApiaries = 'postApiaries'

export function useCreateApiariesMutation(onSuccess?: CallbackVoid) {
  const apiClient = useApiClient()

  return useMutation({
    mutationFn: apiClient.apiary.createApiaries,
    onSuccess: onSuccess
  })
}

export function useDeleteApiariesMutation(onSuccess?: CallbackVoid) {
  const apiClient = useApiClient()

  return useMutation({
    mutationFn: apiClient.apiary.removeApiary,
    onSuccess: onSuccess
  })
}

export function useUpdateApiaryMutation(onSuccess?: CallbackVoid) {
  const ApiClient = useApiClient()

  return useMutation({
    mutationFn: ApiClient.apiary.updateApiary,
    onSuccess: onSuccess
  })
}
const GET_ORGANIZATION_APIARIES = 'apiary/forOrganization'
export function useGetApiariesForOrganizationQuery(id?: string) {
  const apiClient = useApiClient()

  return useQuery(
    [GET_ORGANIZATION_APIARIES, id],
    async () => {
      if (typeof id === 'undefined') {
        throw new Error()
      }
      return apiClient.apiary.getApiariesByOrganization({ id })
    },
    {
      enabled: !!id
    }
  )
}

const GET_FIRST_IOT_UNIT = 'getFirstIotUnit/userId'
export function useGetFirstIotUnit(userId: string | undefined) {
  const apiClient = useApiClient()

  return useQuery([GET_FIRST_IOT_UNIT], async () => {
    if (typeof userId === 'undefined') {
      throw new Error()
    }
    return await apiClient.apiary.getFirstIoTUnit({ userId })
  })
}
