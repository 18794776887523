import { useEffect, useState } from 'react'
import { Button, Container, Stack, Typography, Grid } from '@mui/material'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import LoadingButtonIcon from '../common/LoadingButtonIcon'
import LocationIcon from '../common/Icon/Location'
import ApiariesLocationSelect from '../Map/ApiaryLocationSelect'
import PageBodyContainer from '../common/PageBodyContainer'
import FormGrid from '../common/Form/FormGrid'
import NameField from '../common/Form/FormGrid/CommonRows/NameField'
import CoordinatesField from '../common/Form/FormGrid/CommonRows/CoordinatesField'
import MunicipalityField from '../common/Form/FormGrid/CommonRows/MunicipalityField'
import OtherField from '../common/Form/FormGrid/CommonRows/OtherField'
import BottomContentContainer from '../common/Layout/BottomContentContainer'
import ButtonListContainer from '../common/Layout/ButtonListContainer'
import ErrorsList from '../common/Form/ErrorsList'
import useFormDefaultValuesFromModel from '../../utils/hooks/formDefaultValuesFromModel'
import TextField from '../common/Form/FormGrid/CommonRows/TextField'

const COMPONENT_TRANSLATE_PREFIX = 'components.apiary.apiaryEditForm'

const COORDINATES_NAME = 'coordinates'
const NAME = 'name'
const OWNER = 'owner'
const PROPERTY_DESIGNATION = 'propertyDesignation'
const MUNICIPALITY = 'municipality'
const AREAOFUSE = 'areaOfUse'
const OTHER = 'other'

const FIELDS_TO_INHERIT_FROM_APIARY = [
  'name',
  'owner',
  'locationLat',
  'locationLng',
  'propertyDesignation',
  'municipality',
  'areaOfUse',
  'other'
]

const transformApiaryFormToApiaryFields = (form) => {
  const coord = form.coordinates.length > 0 ? form.coordinates.split(',') : null

  return {
    locationLat: coord && Number(coord[0]),
    locationLng: coord && Number(coord[1]),
    ...form
  }
}

const ApiaryEditForm = ({ initialState, forExisting, pending, onSubmit }) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: COMPONENT_TRANSLATE_PREFIX
  })
  const { t: tGlobal } = useTranslation()

  const [coordinates, setCoordinates] = useState(null)

  const defaultValues = useFormDefaultValuesFromModel(
    initialState,
    FIELDS_TO_INHERIT_FROM_APIARY
  )

  const methods = useForm({ defaultValues })

  const { handleSubmit, setValue, formState } = methods

  const [isEditingLocations, setIsEditingLocations] = useState(false)

  const onLocationsConfirm = (apiaryLocations) => {
    const selectedLoc = apiaryLocations[0]?.location
    setCoordinates(selectedLoc ? { ...selectedLoc } : null)
    setIsEditingLocations(false)
  }

  useEffect(() => {
    if (initialState?.locationLat && initialState?.locationLng) {
      setCoordinates({
        lat: initialState.locationLat,
        lng: initialState.locationLng
      })
    } else {
      setCoordinates(null)
    }
  }, [initialState])

  useEffect(() => {
    if (coordinates) {
      setValue(
        COORDINATES_NAME,
        `${coordinates.lat.toString()}, ${coordinates.lng.toString()}`
      )
    } else {
      setValue(COORDINATES_NAME, '')
    }
    if (defaultValues) {
      setValue(NAME, defaultValues.name)
      setValue(OWNER, defaultValues.owner)
      setValue(PROPERTY_DESIGNATION, defaultValues.propertyDesignation)
      setValue(MUNICIPALITY, defaultValues.municipality)
      setValue(AREAOFUSE, defaultValues.areaOfUse)
      setValue(OTHER, defaultValues.other)
    }
  }, [coordinates, defaultValues])

  return isEditingLocations ? (
    <ApiariesLocationSelect
      apiaries={[initialState]}
      onComplete={onLocationsConfirm}
      onCancel={() => setIsEditingLocations(false)}
    />
  ) : (
    <Container maxWidth="sm">
      <Stack
        spacing={2}
        component="form"
        onSubmit={handleSubmit(
          (form) =>
            onSubmit && onSubmit(transformApiaryFormToApiaryFields(form))
        )}
      >
        <PageBodyContainer>
          <Typography variant="h2">
            {' '}
            {t(`title.${forExisting ? 'update' : 'create'}`)}
          </Typography>
        </PageBodyContainer>

        <FormGrid
          translatePrefix={COMPONENT_TRANSLATE_PREFIX}
          methods={methods}
        >
          <NameField required name={NAME} />
          <TextField name="owner" />
          <CoordinatesField name={COORDINATES_NAME} />
          <TextField name="propertyDesignation" />
          <MunicipalityField />
          <OtherField
            name="areaOfUse"
            translateKey="areaOfUse"
            translatePrefix={COMPONENT_TRANSLATE_PREFIX}
          />
          <OtherField />

          <Grid item xs={12}>
            <Button
              variant="outlined"
              fullWidth
              color="secondary"
              onClick={() => setIsEditingLocations(true)}
              startIcon={<LocationIcon />}
            >
              {t('chooseApiaryLocation')}
            </Button>
          </Grid>
        </FormGrid>

        {/* <PageBodyContainer disableGutter sx={{ pt: 2 }}>
              <Typography variant="h2">
                {t('sections.qr.title')}
              </Typography>

              <Typography>
                {t('sections.qr.body')}
              </Typography>
            </PageBodyContainer>

            <Button
              variant="contained"
              startIcon={<QrIcon/>}
            >
              {t('generateQRButton')}
            </Button> */}

        <ErrorsList formState={formState} />

        <BottomContentContainer>
          <ButtonListContainer>
            <Button
              variant="outlined"
              color="secondary"
              component={Link}
              to="./.."
            >
              {tGlobal('general.cancel')}
            </Button>
            <Button variant="contained" color="secondary" type="submit">
              {pending ? (
                <LoadingButtonIcon />
              ) : forExisting ? (
                tGlobal('general.update')
              ) : (
                tGlobal('general.create')
              )}
            </Button>
          </ButtonListContainer>
        </BottomContentContainer>
      </Stack>
    </Container>
  )
}

export default ApiaryEditForm
