const updateReferenceWeight = {
  sections: {
    main: {
      header: 'Nollställa referens',
      body: 'Du håller på att nollställa referensvärdet för vikten på {{name}}. Efter detta är gjort kommer grafen för vikt utgå från den nuvarande vikten.'
    },
    priorReference: {
      header: 'Tidigare referens'
    },
    newReference: {
      header: 'Ny referens',
      nonExistentBody: 'Finns inte'
    }
  },
  actions: {
    saveRefenceWeight: {
      success: 'Referensvikten har sparats'
    }
  }
}

export default updateReferenceWeight
