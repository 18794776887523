import { Fragment, useMemo } from 'react'
import {
  Button,
  Stack,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import { Box } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { organizationMemberHelpers } from '../../../../utils/helpers'
import ItemList from '../../../common/ItemList'
import ItemListActionButtons from '../../../common/ItemList/Row/ItemListActionButtons'
import ItemListExpandableRow from '../../../common/ItemList/Row/ItemListExpandableRow'
import UnauthorizedByMembership from '../../Unauthorized/UnauthorizedByMembership'
import SettingsIcon from '../../../common/Icon/Settings'
import { Link } from 'react-router-dom'
import { AppRoutePaths, AppRoutePathSegment } from '../../../../utils/routes'
import {
  Organization,
  OrganizationMemberOverview
} from '../../../../models/organization'
import VariablePathSegment from '../../../../enums/Path/VariablePathSegment'

const COMPONENT_TRANSLATE_PREFIX =
  'components.organization.displayOrganization.tabs.membersTab'

type MembersTabProps = {
  organization?: Organization
  organizationMembers?: OrganizationMemberOverview[]
  orgMembersPending?: boolean
  authorizedMember?: OrganizationMemberOverview
}

const MembersTab = (props: MembersTabProps) => {
  const {
    organization,
    organizationMembers: orgMembers,
    orgMembersPending,
    authorizedMember
  } = props
  const { t } = useTranslation(undefined, {
    keyPrefix: COMPONENT_TRANSLATE_PREFIX
  })
  const { t: tGlobal } = useTranslation()

  const isApprovedMember =
    organizationMemberHelpers.isAuthorizedMember(authorizedMember)

  const isAdmin = organizationMemberHelpers.isAuthorizedMemberWithRole(
    authorizedMember,
    'ADMIN'
  )

  const membersWithLinks = useMemo(
    () =>
      orgMembers
        ? orgMembers.map((member) => ({
            member,
            link: AppRoutePathSegment.replaceSegments(
              AppRoutePaths.EDIT_ORGANIZATION_MEMBER_PATH,
              [
                {
                  name: VariablePathSegment.ORGANIZATION_ID,
                  value: organization!.id
                },
                {
                  name: VariablePathSegment.ORGANIZATION_MEMBER_ID,
                  value: member.userId
                }
              ]
            )
          }))
        : [],
    []
  )

  return (
    <Stack spacing={2}>
      {isApprovedMember ? (
        <ItemList
          title={t('members.title')}
          pending={orgMembersPending}
          expandable
        >
          <TableHead>
            <TableRow>
              <TableCell>{t('members.header.name')}</TableCell>
              <TableCell>{t('members.header.role')}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {membersWithLinks &&
              membersWithLinks.map(({ member, link }, rowIndex) => (
                <Fragment key={member.userId}>
                  <TableRow>
                    <TableCell>
                      {`${member.firstname} ${member.surname}`}
                    </TableCell>
                    <TableCell>
                      {member.isApproved === true
                        ? tGlobal(`general.memberRole.${member.role}`)
                        : t('member.undefined')}
                    </TableCell>
                    <ItemListActionButtons index={rowIndex} data={member} />
                  </TableRow>
                  <ItemListExpandableRow index={rowIndex} colSpan={3}>
                    <Stack spacing={2}>
                      <Box>
                        <Typography fontWeight="bold">
                          {t('member.email')}
                        </Typography>
                        <Typography>{member.email}</Typography>
                      </Box>

                      <Box>
                        <Typography fontWeight="bold">
                          {t('member.address')}
                        </Typography>
                        <Typography>
                          {member.street ?? tGlobal('general.undefined')}
                        </Typography>
                      </Box>
                      {isAdmin && (
                        <Button
                          component={Link}
                          to={link}
                          color="secondary"
                          variant="outlined"
                          startIcon={<SettingsIcon />}
                        >
                          {t('buttons.settings')}
                        </Button>
                      )}
                    </Stack>
                  </ItemListExpandableRow>
                </Fragment>
              ))}
          </TableBody>
        </ItemList>
      ) : (
        <UnauthorizedByMembership
          organizationId={organization?.id}
          member={authorizedMember}
          notMemberBody={t('section.notMember.body')}
        />
      )}
    </Stack>
  )
}

export default MembersTab
