const listNoteOverviews = {
  actions: {
    getNotes: {
      error: 'An error occurred while retrieving the notes'
    }
  },
  header: {
    noteTitle: 'Title',
    noteDate: 'Date'
  },
  data: {
    noteDate: '{{val, datetime}}'
  }
}

export default listNoteOverviews
