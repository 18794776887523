import { useEffect, useState } from 'react'

import {
  Select,
  MenuItem,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Container,
  InputLabel,
  FormControl
} from '@mui/material'
import { useTranslation } from 'react-i18next'

const COMPONENT_TRANSLATE_PREFIX = 'components.map.apiaryLocationSelect.apiarySelect'

const ApiarySelect = props => {
  const {
    open,
    selectedApiaryId,
    apiaries,
    onSelect,
    onCancel
  } = props

  const { t } = useTranslation(undefined, { keyPrefix: COMPONENT_TRANSLATE_PREFIX })

  const [locallySelectedApiaryId, setLocallySelectedApiaryId] = useState(null)

  useEffect(() => {
    setLocallySelectedApiaryId(selectedApiaryId)
  }, [selectedApiaryId])

  const handleChange = (event) => {
    setLocallySelectedApiaryId(event.target.value)
  }

  const handleClose = (event, reason) => {
    if (reason === 'backdropClick') {
      onCancel()
    }
  }

  const confirm = () => onSelect(locallySelectedApiaryId)

  return (
    <Dialog
      disableEscapeKeyDown
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>
        {t('chooseApiary')}
      </DialogTitle>
      <DialogContent>
        <Container
          maxWidth="sm"
        >
          <FormControl sx={{ mt: 2 }} fullWidth>
            <InputLabel id="apiary-map-apiary-select-label">
              {('apiaryLabel')}
            </InputLabel>

            <Select
              value={locallySelectedApiaryId}
              onChange={handleChange}
              labelId="apiary-map-apiary-select-label"
              label={('apiaryLabel')}
            >
              {apiaries && apiaries.map((apiary, index) => (
                <MenuItem
                  key={index}
                  value={apiary.sortId}
                >
                  {apiary.name}
                </MenuItem>
              ))}
            </Select>

            </FormControl>
        </Container>
      </DialogContent>

      <DialogActions>
        <Button onClick={onCancel}>
          {('cancel')}
        </Button>
        <Button onClick={confirm}>
          {('ok')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ApiarySelect
