import { Alert, Stack } from '@mui/material'
import { useMemo } from 'react'

/**
 * Return a flat array of errors in a possibly nested object
 * @param {*} errors nested or flat object containing errors from react-use-form
 * @returns
 */
const getFlatErrors = (errors) =>
  errors &&
  (Object.keys(errors).includes('ref')
    ? errors
    : Object.values(errors).map(getFlatErrors).flat())

/**
 * Renders a list of the current errors of a react-use-form instance
 * @param {*} props.name eventual name of subform
 * @param {*} props.formstate formstate of the react-use-form instance
 */
const ErrorsList = (props) => {
  const {
    name,
    formState: { errors },
    ...rest
  } = props

  const errorsToUse = name ? errors[name] : errors

  const uniqueErros = useMemo(() => {
    const flatErrors = getFlatErrors(errorsToUse)
    if (!flatErrors) {
      return []
    }

    return flatErrors.filter(
      (error, index) =>
        flatErrors.findIndex(
          (otherError) => otherError.message === error.message
        ) === index
    )
  }, [errorsToUse])

  if (!errorsToUse) {
    return <></>
  }

  return (
    <Stack spacing={2} {...rest}>
      {errorsToUse &&
        uniqueErros.map(({ message }, index) => (
          <Alert key={`${index}-${message}`} variant="filled" severity="error">
            {message}
          </Alert>
        ))}
    </Stack>
  )
}

export default ErrorsList
