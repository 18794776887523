const review = {
  title: 'Overview',
  apiaries: {
    header: {
      name: 'Name',
      beehivesAmount: 'Beehives'
    },
    beehives: {
      title: 'Beehives'
    }
  }
}

export default review
