import { Fade, Stack } from '@mui/material'
import { useEffect, useState } from 'react'

const TabPanel = (props) => {
  const { children, value, index, sx } = props

  const isOpen = value === index

  const [hasOpened, setHasOpened] = useState(false)

  useEffect(() => {
    isOpen && setHasOpened(true)
  }, [isOpen])

  return (
    <div hidden={!isOpen}>
      <Fade in={isOpen}>
          <Stack spacing={2} className="TabPanel-root" sx={sx}>
            {(isOpen || hasOpened) && children}
          </Stack>
      </Fade>
    </div>
  )
}

export default TabPanel
