import { Button, Stack, ToggleButton } from '@mui/material'
import { useForm, SubmitHandler } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import {
  BeehiveInspectionNoteResultDto,
  BeehiveInspectionNoteEditDto
} from '../../models/notes'
import useFormDefaultValuesFromModel from '../../utils/hooks/formDefaultValuesFromModel'
import { AppRoutePaths } from '../../utils/routes'
import ErrorsList from '../common/Form/ErrorsList'
import BottomContentContainer from '../common/Layout/BottomContentContainer'
import ButtonListContainer from '../common/Layout/ButtonListContainer'
import LoadingButtonIcon from '../common/LoadingButtonIcon'
import { useState } from 'react'
import BeehiveInspectionNoteForm, {
  BEEHIVE_INSPECTION_NOTE_FIELDS
} from './BeehiveInspectionNoteForm'
import { useSnackbar } from 'notistack'

type BeehiveInspectionNoteEditFormProps = {
  initialState: BeehiveInspectionNoteResultDto | BeehiveInspectionNoteEditDto
  onSubmit?: SubmitHandler<BeehiveInspectionNoteEditDto>
  pending: boolean
  isForDisplayOnly?: boolean
}

const COMPONENT_TRANSLATE_PREFIX =
  'components.note.editNote.beehiveInspectionNoteEditForm'

const BeehiveInspectionNoteFormStateWrapper = (
  props: BeehiveInspectionNoteEditFormProps
) => {
  const { initialState, onSubmit, pending, isForDisplayOnly } = props
  const [disable, setDisable] = useState(isForDisplayOnly ?? false)
  const { t: tGeneral } = useTranslation()
  const { t } = useTranslation(undefined, {
    keyPrefix: COMPONENT_TRANSLATE_PREFIX
  })
  const { enqueueSnackbar } = useSnackbar()

  const defaultValues = useFormDefaultValuesFromModel(
    initialState,
    BEEHIVE_INSPECTION_NOTE_FIELDS
  )
  const navigate = useNavigate()

  const handleDisable = () => {
    if (disable) {
      setDisable((prev) => !prev)
      enqueueSnackbar(t('actions.updatingNote.label'), {
        variant: 'info'
      })
    } else {
      setDisable((prev) => !prev)
      handleSubmit(onSubmit!)()
    }
  }

  const methods = useForm({ defaultValues })

  const { handleSubmit, formState } = methods
  /*
    The comments in the JSX sections add buttons to enable ability to edit fields
*/
  return (
    <Stack
      spacing={2}
      component="form"
      onSubmit={handleSubmit(onSubmit!)}
      autoComplete="off"
    >
      <BeehiveInspectionNoteForm methods={methods} isForDisplayOnly={disable} />

      <ErrorsList formState={formState} />
      <BottomContentContainer>
        <ButtonListContainer>
          {isForDisplayOnly ? (
            <>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => navigate(-1)}
              >
                {tGeneral('general.back')}
              </Button>
              <ToggleButton
                value={disable}
                selected={!disable}
                onChange={handleDisable}
              >
                {pending ? (
                  <LoadingButtonIcon />
                ) : disable ? (
                  t('actions.updateNote.label')
                ) : (
                  t('actions.saveNote.label')
                )}
              </ToggleButton>
            </>
          ) : (
            <>
              <Button
                variant="outlined"
                color="secondary"
                component={Link}
                to={AppRoutePaths.LIST_NOTES_PATH}
              >
                {tGeneral('general.cancel')}
              </Button>

              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  setDisable(true)
                }}
              >
                {tGeneral('general.edit')}
              </Button>
            </>
          )}
        </ButtonListContainer>
      </BottomContentContainer>
    </Stack>
  )
}

export default BeehiveInspectionNoteFormStateWrapper
