const iotTab = {
  timeIntervalTitle: 'Time Interval',
  timeIntervalStartLabel: 'From',
  timeIntervalEndLabel: 'To',
  dataFetchType: {
    live: 'Use live data',
    timeInterval: 'Use time interval'
  },

  register: {
    header: 'Scan the beehive QR code',

    integrateBeehive: 'Integrate beehive',

    actions: {
      connectIotBeehive: {
        fulfilled: 'The beehive has been integrated!',
        error: 'An error occurred while integrating the beehive!',
        conflictError: 'Beehive is already registered in the system'
      }
    },

    qrStatus: {
      scanned: 'Beehive scanned!',
      invalid: 'Invalid QR code',
      unScanned: 'Point the camera at the beehive QR code'
    },
    manualEntryPlaceholder: 'Enter the beehive code manually here',
    scanDataInfo: 'Scan result: {{qrData}}',
    reScan: 'Scan again'
  },
  actions: {
    getReferenceWeight: {
      error:
        'An error occurred while retrieving the reference weight of the beehive'
    }
  },
  buttons: {
    editReferenceWeight: 'Set reference weight',
    createAlarm: 'Create alarm'
  },
  newDigKit: {
    header: 'New Digital Kit?',
    text: 'Have you obtained a digital kit for your beehive and installed it? Click on the button below and scan the QR code on the lid to connect your new kit to this beehive.'
  },
  sensorFields: {
    innerTemp: 'Inner temperature',
    outerTemp: 'Outer temperature',
    weight: 'Weight',
    humidity: 'Humidity',
    pressure: 'Pressure',
    upperBeehiveTemp: 'Upper beehive temperature',
    lowerBeehiveTemp: 'Lower beehive temperature'
  }
}

export default iotTab
