import { Card } from '@mui/material'
import { useEffect } from 'react'
import { QrReader } from 'react-qr-reader'

const QR_SCAN_VIDEO_ID = 'app-qr-code-scanner-video'
const QR_SCAN_DELAY = 500

const getQrVideoElement = () => document.getElementById(QR_SCAN_VIDEO_ID)

const BeehiveQrScan = (props) => {
  const { onScan, onScanError, useScan } = props

  const parseBeehiveQrJsonText = (qrData) => {
    if (!useScan || !qrData?.text?.length) return

    console.log({ qrData })
    try {
      const data = JSON.parse(qrData.text)

      if (data.iotId && data.iotPassword) {
        const scanData = {
          iotId: data.iotId,
          iotPassword: data.iotPassword
        }
        onScan(scanData)
      } else {
        const imsi = qrData.text.trim()
        const scanData = {
          iotId: imsi,
          iotPassword: ''
        }

        onScan(scanData)
      }
    } catch {
      onScanError('Invalid QR data')
    }
  }

  const playQrVideo = () => {
    const vid = getQrVideoElement()
    vid?.play()
  }

  const pauseQrVideo = () => {
    const vid = getQrVideoElement()
    vid?.pause()
  }

  useEffect(() => {
    if (useScan) {
      playQrVideo()
    } else {
      pauseQrVideo()
    }
  }, [useScan])

  return (
    <Card>
      <QrReader
        onResult={parseBeehiveQrJsonText}
        scanDelay={useScan ? QR_SCAN_DELAY : false}
        videoId={QR_SCAN_VIDEO_ID}
        videoContainerStyle={{ paddingTop: '75%' }}
        constraints={{ facingMode: 'environment' }}
      />
    </Card>
  )
}

export default BeehiveQrScan
