const membersTab = {
  section: {
    notMember: {
      body: 'Join the organization to see its members'
    }
  },

  members: {
    title: 'Members',
    header: {
      name: 'Name',
      role: 'Role'
    }
  },

  member: {
    email: 'Email',
    address: 'Address',
    undefined: 'Not approved'
  },
  buttons: {
    settings: 'Settings'
  }
}

export default membersTab
