import { FormControl, FormLabel, RadioGroup, Stack } from '@mui/material'
import FormGridField from '../../FormGridField'

const FormGridRadioGroup = props => {
  const { children, showLabel, ...rest } = props
  return (
    <FormGridField
      label={showLabel ? undefined : ''}
      render={({ controller: { field }, label }) => (
        <FormControl sx={{ width: '100%' }}
          {...field}
          component="fieldset"
        >
          <FormLabel component="legend">{label}</FormLabel>
            <RadioGroup {...field}>
              <Stack spacing={2} sx={{ width: '100%' }}>
                {children}
              </Stack>
            </RadioGroup>
          </FormControl>
      )}
      {...rest}
    />
  )
}

export default FormGridRadioGroup
