import { Divider, ListItem } from '@mui/material'

const SubListItem = props => {
  const {
    index,
    children,
    ...rest
  } = props

  return (
    <>
      {index !== 0 && <Divider/>}
      <ListItem {...rest}>
        {children}
      </ListItem>
    </>
  )
}

export default SubListItem
