const displayOrganization = {
  title: 'Organization',
  tabs: {
    apiaries: 'Apiaries',
    members: 'Members',
    info: 'Information'
  },
  actions: {
    getMembers: {
      error: 'An error occurred while retrieving the organization members'
    }
  },
  success: {
    addMember: 'Request sent'
  }
}

export default displayOrganization
