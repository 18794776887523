const verifyUserEmail = {
  title: 'Verify Account',
  infoTitle: 'Verification',
  description:
    'Open the email sent to you and enter the digits to complete the registration and verify your email address.',

  actions: {
    verify: {
      success: 'Account has been verified'
    },
    resendCode: {
      success: 'A new code has been sent'
    }
  },

  resendConfirmationMail: {
    description: 'Did not receive an email?',
    link: 'Resend'
  }
}

export default verifyUserEmail
