const beehiveEditForm = {
  title: {
    create: 'Create new beehive',
    update: 'Update beehive '
  },

  section: {
    iot: {
      title: 'New Digital Kit?',
      body: 'Have you obtained a digital kit for your beehive and installed it? Click the button below and scan the QR code on the lid to connect your new kit to this beehive.'
    }
  },

  fields: {
    framesAmount: {
      label: 'Number of frames'
    },
    boxAmount: {
      label: 'Number of boxes'
    },

    lastSwarmed: {
      label: 'Last swarmed'
    },

    beeType: {
      label: 'Type of bee'
    },

    beehiveType: {
      label: 'Type of beehive'
    },

    frameType: {
      label: 'Type of frames'
    },

    queenColor: {
      label: 'Queen marking'
    },

    queenWingCut: {
      label: 'Queen wing clipped'
    }
  }
}

export default beehiveEditForm
