import apiFetcher from '../apiFetcher'

export const createBeehive = apiFetcher
  .path('/beehive/createBeehive/{apiaryId}')
  .method('post')
  .create()

export const getBeehive = apiFetcher
  .path('/beehive/byId/{id}')
  .method('get')
  .create()

export const getOrgByApiaryId = apiFetcher
  .path('/beehive/OrgByApiaryId/{id}')
  .method('get')
  .create()

export const removeBeehiveById = apiFetcher
  .path('/beehive/delete/{id}')
  .method('delete')
  .create()

export const getApiaryBeehives = apiFetcher
  .path('/beehive/{apiaryId}')
  .method('get')
  .create()

export const updateBeehiveById = apiFetcher
  .path('/beehive/update/{beehiveId}')
  .method('put')
  .create()

export const connectIotBeehive = apiFetcher
  .path('/beehive/connectIot')
  .method('post')
  .create({ beehiveId: true, iotId: true, iotPassword: true })

export const updateBeehiveLocation = apiFetcher
  .path('/beehive/moveBeehive')
  .method('put')
  .create({ id: true, newApiaryId: true })

export const setReferenceWeightById = apiFetcher
  .path('/beehive/setReferenceWeight')
  .method('post')
  .create({ beehiveId: true, weight: true })

export const getReferenceWeightById = apiFetcher
  .path('/beehive/getRefWeight/{beehiveId}')
  .method('get')
  .create()

export const getAllIoTGeneralCoOrds = apiFetcher
  .path('/iot-data/AllGeneralLocations/{beehiveId}')
  .method('get')
  .create()

export const getIoTCoOrds = apiFetcher
  .path('/iot-data/location/{beehiveId}')
  .method('get')
  .create()

// demo purposes
export const disconnectIotUnits = apiFetcher
  .path('/beehive/disconnectIot')
  .method('post')
  .create()
