import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import LoadingButtonIcon from '../../common/LoadingButtonIcon'
import LogoutIcon from '../../common/Icon/Logout'

type SignOutButtonProps = {
  hasVerifiedInitialToken: boolean
  signOut: () => void
}

const SignOutButton = (props: SignOutButtonProps) => {
  const { t: tGlobal } = useTranslation()

  return (
    <Button
      color="primary"
      variant="contained"
      style={{ width: '100%' }}
      disabled={!props.hasVerifiedInitialToken}
      onClick={props.signOut}
      startIcon={<LogoutIcon />}
    >
      {!props.hasVerifiedInitialToken ? (
        <LoadingButtonIcon />
      ) : (
        tGlobal('general.logOutUser')
      )}
    </Button>
  )
}

export default SignOutButton
