import { createContext, Dispatch, SetStateAction } from 'react'

type ItemListContextValue = {
  openIndex: number
  setOpenIndex: Dispatch<SetStateAction<number>>
  expandable: boolean
}

const ItemListContext = createContext<ItemListContextValue>({
  openIndex: -1,
  setOpenIndex: () => undefined,
  expandable: false
})

export default ItemListContext
