const apiaryOverviewList = {
  apiaries: {
    title: 'Apiaries',
    header: {
      name: 'Name',
      beehivesAmount: 'Number of beehives'
    }
  },
  beehives: 'Beehives',
  beehivesMissing: 'No beehives found'
}

export default apiaryOverviewList
