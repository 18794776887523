import {
  Button,
  Stack,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material'
import { AppRoutePaths } from '../../utils/routes'

import { useTranslation } from 'react-i18next'

import { useErrorStateSnackEffect } from '../../utils/hooks/stateSnackEffect'

import TitleImage from '../../components/common/TitleImage'
import PlusIcon from '../../components/common/Icon/Plus'
import MagnifyerIcon from '../../components/common/Icon/Magnifyer'
import { Link } from 'react-router-dom'
import ItemList from '../../components/common/ItemList'
import BottomButtonsContainer from '../../components/common/Layout/BottomButtonsContainer'
import ItemListLinkCol from '../../components/common/ItemList/Row/ItemListLinkCol'
import { useUserOrganizationsQuery } from '../../utils/api/hooks/organization'
import { QueryPagination } from '../../components/QueryPagination'

const COMPONENT_TRANSLATE_PREFIX = 'pages.organization.listOrganizations'

const ListOrganizations = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: COMPONENT_TRANSLATE_PREFIX
  })
  const { t: tGlobal } = useTranslation()

  const userOrganizationsQuery = useUserOrganizationsQuery()

  useErrorStateSnackEffect(
    userOrganizationsQuery.query.error,
    tGlobal('general.error.general')
  )

  return (
    <Stack className="pageRoot">
      <TitleImage title={t('title')} src="/images/generic-background.png" />
      <Stack className="pageContent" spacing={2}>
        <ItemList
          title={t('myOrganizations.title')}
          pending={userOrganizationsQuery.query.isLoading}
        >
          <TableHead>
            <TableRow>
              <TableCell>{t('myOrganizations.header.name')}</TableCell>
              <TableCell>
                {t('myOrganizations.header.apiariesAmount')}
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userOrganizationsQuery.query.data?.data &&
              userOrganizationsQuery.query.data?.data?.values?.map((org) => (
                <TableRow key={org.id}>
                  <TableCell>{org.name}</TableCell>
                  <TableCell>{org.apiariesAmount}</TableCell>
                  <ItemListLinkCol link={org.id} relative="path" />
                </TableRow>
              ))}
          </TableBody>
        </ItemList>

        <QueryPagination {...userOrganizationsQuery} />

        <BottomButtonsContainer direction="column">
          <Button
            variant="contained"
            component={Link}
            to={AppRoutePaths.NEW_ORGANIZATION_PATH}
            startIcon={<PlusIcon />}
          >
            {tGlobal('general.createNew')}
          </Button>

          <Button
            variant="outlined"
            color="secondary"
            component={Link}
            to={AppRoutePaths.SEARCH_ORGANIZATION_PATH}
            startIcon={<MagnifyerIcon />}
          >
            {t('searchAllOrganizations')}
          </Button>
        </BottomButtonsContainer>
      </Stack>
    </Stack>
  )
}

export default ListOrganizations
