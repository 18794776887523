import { useCallback, useEffect } from 'react'
import { Button, Stack, Link, Typography } from '@mui/material'

import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { AppRoutePaths } from '../../utils/routes'
import {
  useErrorStateSnackEffect,
  useSuccessStateSnackEffect
} from '../../utils/hooks/stateSnackEffect'
import LoadingPage from '../../components/common/LoadingPage'
import TitleImage from '../../components/common/TitleImage'
import ErrorsList from '../../components/common/Form/ErrorsList'
import LoadingButtonIcon from '../../components/common/LoadingButtonIcon'
import FormGrid from '../../components/common/Form/FormGrid'
import CodeRow from '../../components/common/Form/FormGrid/CommonRows/CodeRow'
import PageBodyContainer from '../../components/common/PageBodyContainer'
import {
  useResendAccountVerificationCodeMutation,
  useVerifyAccountMutation
} from '../../utils/api/hooks/auth'
import useIsMounted from '../../utils/hooks/isMounted'
import { promiseForForm } from '../../utils/form/helpers'
import { useSnackbar } from 'notistack'

const COMPONENT_TRANSLATE_PREFIX = 'pages.auth.verifyUserEmail'

const VERIFY_USER_CODE_LENGTH = 6
const VERIFY_USER_CODE_NAME = 'code'

type VerifyUserEmailForm = {
  [VERIFY_USER_CODE_NAME]: string[]
}

const VerifyUserEmail = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: COMPONENT_TRANSLATE_PREFIX
  })
  const { t: tGlobal } = useTranslation()

  const methods = useForm<VerifyUserEmailForm>({
    mode: 'onSubmit'
  })

  const { handleSubmit, formState } = methods

  const location = useLocation()
  const navigate = useNavigate()
  const isMounted = useIsMounted()
  const { enqueueSnackbar } = useSnackbar()

  const onVerifyAccountSuccess = useCallback(() => {
    if (isMounted()) {
      const email = location.state.email
      const password = location.state.password

      enqueueSnackbar(t('actions.verify.success'), { variant: 'success' })

      navigate(AppRoutePaths.LOGIN_PATH, {
        state: { email: email, password: password }
      })
    }
  }, [isMounted, enqueueSnackbar, navigate, t])

  const verifyAccountMutation = useVerifyAccountMutation(onVerifyAccountSuccess)

  const resendAccountVerificationCodeMutation =
    useResendAccountVerificationCodeMutation()

  const resendAcountVerificationCode = () =>
    resendAccountVerificationCodeMutation.mutate({
      email: location.state.email as string
    })

  useSuccessStateSnackEffect(
    resendAccountVerificationCodeMutation.isSuccess,
    t('actions.resendCode.success')
  )

  useErrorStateSnackEffect(
    verifyAccountMutation.isError,
    tGlobal('error.general')
  )
  useErrorStateSnackEffect(
    resendAccountVerificationCodeMutation.isError,
    tGlobal('error.general')
  )

  useEffect(() => {
    if (!location.state?.email || !location.state?.password) {
      navigate(AppRoutePaths.LOGIN_PATH)
    }
  }, [])

  const onSubmit = useCallback(
    (form: VerifyUserEmailForm) => {
      const { [VERIFY_USER_CODE_NAME]: code } = form
      const transformedCode = code.join('')

      return promiseForForm(
        verifyAccountMutation.mutateAsync({
          email: location.state.email,
          verificationCode: transformedCode
        })
      )
    },
    [verifyAccountMutation.mutateAsync]
  )

  return (
    <Stack className="pageRoot">
      <TitleImage title={t('title')} src="/images/generic-background.png" />

      {verifyAccountMutation.isLoading ? (
        <LoadingPage className="pageContent" />
      ) : (
        <Stack className="pageContent" spacing={4}>
          <Stack
            component="form"
            autoComplete="off"
            onSubmit={handleSubmit(onSubmit)}
            spacing={2}
          >
            <PageBodyContainer>
              <Typography variant="h2">{t('infoTitle')}</Typography>

              <Typography>{t('description')}</Typography>
            </PageBodyContainer>

            <FormGrid methods={methods}>
              <CodeRow length={VERIFY_USER_CODE_LENGTH} />
            </FormGrid>

            <ErrorsList formState={formState} />

            <Stack direction="row" spacing={2} justifyContent="center">
              <Button
                color="secondary"
                type="button"
                component={RouterLink}
                to={AppRoutePaths.LOGIN_PATH}
              >
                {tGlobal('general.cancel')}
              </Button>

              <Button variant="contained" color="secondary" type="submit">
                {tGlobal('general.finish')}
              </Button>
            </Stack>
          </Stack>

          <Stack alignItems="center" spacing={1}>
            <Typography>{t('resendConfirmationMail.description')}</Typography>

            {resendAccountVerificationCodeMutation.isLoading ? (
              <LoadingButtonIcon />
            ) : (
              <Link
                component="button"
                disabled={resendAccountVerificationCodeMutation.isLoading}
                onClick={resendAcountVerificationCode}
              >
                {t('resendConfirmationMail.link')}
              </Link>
            )}
          </Stack>
        </Stack>
      )}
    </Stack>
  )
}

export default VerifyUserEmail
