import { StatusCodes } from 'http-status-codes'
import { ApiError } from 'openapi-typescript-fetch'

export function isNotFoundError(error: unknown) {
  return (
    error instanceof ApiError &&
    (error.status === StatusCodes.NOT_FOUND ||
      StatusCodes.INTERNAL_SERVER_ERROR)
  )
}
