import { CallbackVoid } from '../util/types'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useApiClient } from './useApiClient'
import { getApiaryBeehives } from '../apiClient/modules/beehive'

export function useCreateBeehiveMutation(onSuccess?: CallbackVoid) {
  const apiClient = useApiClient()

  return useMutation({
    mutationFn: apiClient.beehive.createBeehive,
    onSuccess: onSuccess
  })
}

export const getBeehive = 'getBeehive'
export const getBeehiveOrganization = 'getBeehiveOrganization'

export function useGetBeehiveQuery(beehiveId: string) {
  const apiClient = useApiClient()

  return useQuery([getBeehive, beehiveId], () => {
    return apiClient.beehive.getBeehive({ id: beehiveId })
  })
}
export function useGetBeehiveManuallyQuery(beehiveId: string) {
  const apiClient = useApiClient()

  return useQuery(
    [getBeehive, beehiveId],
    () => {
      return apiClient.beehive.getBeehive({ id: beehiveId })
    },
    {
      enabled: false
    }
  )
}

export function useGetOrgByApiaryIdQuery(apiaryId: string) {
  const apiClient = useApiClient()

  const holder: string = apiaryId
  return useQuery([getBeehiveOrganization, apiaryId], () => {
    return apiClient.beehive.getOrgByApiaryId({ id: holder })
  })
}

export function useRemoveBeehiveMutation(onSuccess?: CallbackVoid) {
  const apiClient = useApiClient()
  return useMutation({
    mutationFn: apiClient.beehive.removeBeehiveById,
    onSuccess: onSuccess
  })
}

export function useGetApiaryBeehivesQuery(apiaryId: string) {
  const apiClient = useApiClient()

  return useQuery(
    [getApiaryBeehives, apiaryId],
    () => {
      return apiClient.beehive.getApiaryBeehives({ apiaryId })
    },
    {
      enabled: !!apiaryId
    }
  )
}

export function useUpdateBeehiveMutation(onSuccess?: CallbackVoid) {
  const apiClient = useApiClient()

  return useMutation({
    mutationFn: apiClient.beehive.updateBeehiveById,
    onSuccess: onSuccess
  })
}

export function useConnectIotBeehiveMutation(onSuccess?: CallbackVoid) {
  const apiClient = useApiClient()

  return useMutation({
    mutationFn: apiClient.beehive.connectIotBeehive,
    onSuccess: onSuccess
  })
}

export function useMoveBeehiveMutation(onSuccess?: CallbackVoid) {
  const apiClient = useApiClient()

  return useMutation({
    mutationFn: apiClient.beehive.updateBeehiveLocation,
    onSuccess: onSuccess
  })
}

export function useSetReferenceWeightMutation(onSuccess?: CallbackVoid) {
  const apiClient = useApiClient()

  return useMutation({
    mutationFn: apiClient.beehive.setReferenceWeightById,
    onSuccess: onSuccess
  })
}

export function useGetReferenceWeightQuery(beehiveId: string) {
  const apiClient = useApiClient()

  return useQuery(
    [beehiveId],
    () => {
      return apiClient.beehive.getReferenceWeightById({ beehiveId })
    },
    {
      enabled: !!beehiveId
    }
  )
}

export function useGetAllIoTGeneralCoOrdsQuery(beehiveId: string) {
  const apiClient = useApiClient()

  return useQuery(
    ['getAllIoTGeneralCoOrds'],
    () => {
      return apiClient.beehive.getAllIoTGeneralCoOrds({ beehiveId })
    },
    {
      enabled: true
    }
  )
}

export function useGetIoTCoOrdsQuery(beehiveId: string) {
  const apiClient = useApiClient()

  return useQuery(
    ['getIoTCoOrds'],
    () => {
      return apiClient.beehive.getIoTCoOrds({ beehiveId })
    },
    {
      enabled: true
    }
  )
}

export function usePostDisconnectIotUnits() {
  const apiClient = useApiClient()

  return useMutation({
    mutationFn: apiClient.beehive.disconnectIotUnits
  })
}
