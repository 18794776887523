import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const COMPONENT_TRANSLATE_PREFIX = 'components.overlay.sideMenu.link'

const LINK_DEFAULT_TEXT_SX = {
  color: 'white.main'
}

const LINK_ACTIVATED_TEXT_SX = {
  color: 'primary.main'
}

const SideMenuLink = props => {
  const {
    labelKey,
    path,
    showCircle,
    onClick,
    icon
  } = props

  const { t } = useTranslation(undefined, { keyPrefix: COMPONENT_TRANSLATE_PREFIX })

  const location = useLocation()

  const pathIsActivated = location.pathname.startsWith(path)

  const textSx = pathIsActivated
    ? LINK_ACTIVATED_TEXT_SX
    : LINK_DEFAULT_TEXT_SX

  return (
    <ListItemButton
      key={path}
      component={Link}
      onClick={onClick}
      to={path}
    >
      {showCircle &&
        <ListItemIcon sx={textSx}>
          { icon }
        </ListItemIcon>
      }
      <ListItemText sx={textSx}>
        {t(labelKey)}
      </ListItemText>
    </ListItemButton>
  )
}

export default SideMenuLink
