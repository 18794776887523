import {
  Button,
  Container,
  Stack,
  Typography
} from '@mui/material'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import LoadingButtonIcon from '../common/LoadingButtonIcon'
import {
  BeeType,
  BeehiveType,
  BeeQueenColor,
  BeehiveFrameType
} from '../../models'

import PageBodyContainer from '../common/PageBodyContainer'
import FormGrid from '../common/Form/FormGrid'
import NameField from '../common/Form/FormGrid/CommonRows/NameField'
import NumberTextField from '../common/Form/FormGrid/CommonRows/NumberField'
import DateField from '../common/Form/FormGrid/CommonRows/DateField'
import EnumAutoCompleteField from '../common/Form/FormGrid/CommonRows/EnumAutocompleteField'
import CheckBoxField from '../common/Form/FormGrid/CommonRows/CheckBoxField'
import BottomContentContainer from '../common/Layout/BottomContentContainer'
import ButtonListContainer from '../common/Layout/ButtonListContainer'
import ErrorsList from '../common/Form/ErrorsList'
import useFormDefaultValuesFromModel from '../../utils/hooks/formDefaultValuesFromModel'
import { getISODate } from '../../utils/date/aws'

const COMPONENT_TRANSLATE_PREFIX = 'components.beehive.beehiveEditForm'

// const REGISTER_BUTTON_PROPS = { fullWidth: true }

const transformFormVal = form => {
  return {
    ...form,
    lastSwarmed: form.lastSwarmed ? getISODate(form.lastSwarmed) : null
  }
}

const FIELDS_TO_INHERIT_FROM_BEEHIVE = [
  'name',
  {
    key: 'framesAmount',
    defaultValue: ''
  },
  {
    key: 'boxAmount',
    defaultValue: ''
  },
  {
    key: 'lastSwarmed',
    transform: d => new Date(d)
  },

  'beeType',
  'beehiveType',
  'frameType',
  'queenColor',
  'queenWingCut'
]

const BeehiveEditForm = props => {
  const {
    initialState,
    pending,
    onSubmit,
    // onIotRegister,
    forExisting
  } = props

  const { t } = useTranslation(undefined, { keyPrefix: COMPONENT_TRANSLATE_PREFIX })
  const { t: tGlobal } = useTranslation()

  const defaultValues = useFormDefaultValuesFromModel(
    initialState,
    FIELDS_TO_INHERIT_FROM_BEEHIVE
  )

  const methods = useForm({ defaultValues })

  const { handleSubmit, formState } = methods

  return (
    <Container maxWidth="sm">
      <Stack
        spacing={2}
        component="form"
        onSubmit={handleSubmit(form => onSubmit(transformFormVal(form)))}
      >
        <PageBodyContainer>
          <Typography variant="h2">
              {`${t(`title.${forExisting ? 'update' : 'create'}`)}${initialState?.name ?? ''}`}
          </Typography>
        </PageBodyContainer>

        <FormGrid
          translatePrefix={COMPONENT_TRANSLATE_PREFIX}
          methods={methods}
        >
          <NameField required/>
          <NumberTextField name="framesAmount" />
          <NumberTextField name="boxAmount" />

          <DateField
            name="lastSwarmed"
            // disables default translate key and prefix
            translateKey={null}
            translatePrefix={null}
          />

          <EnumAutoCompleteField
            name="beeType"
            optionsEnum={BeeType}
            enumTranslateKey="beeType"
          />

          <EnumAutoCompleteField
            name="beehiveType"
            optionsEnum={BeehiveType}
            enumTranslateKey="beehiveType"
          />

          <EnumAutoCompleteField
            name="frameType"
            optionsEnum={BeehiveFrameType}
            enumTranslateKey="frameType"
          />

          <EnumAutoCompleteField
            name="queenColor"
            optionsEnum={BeeQueenColor}
            enumTranslateKey="beeQueenColor"
          />

          <CheckBoxField name="queenWingCut"/>
        </FormGrid>

        {/* <PageBodyContainer disableGutter>
          <Typography variant="h2">
            {t('section.iot.title')}
          </Typography>

          <Typography>
            {t('section.iot.body')}
          </Typography>
        </PageBodyContainer>

        <Register
          buttonProps={REGISTER_BUTTON_PROPS}
          beehive={initialState}
          onRegister={onIotRegister}
        /> */}

        <ErrorsList formState={formState}/>

        <BottomContentContainer>
          <ButtonListContainer>
            <Button
              variant="outlined"
              color="secondary"
              component={Link}
              to="./.."
            >
              {tGlobal('general.cancel')}
            </Button>

            <Button
              variant="contained"
              color="secondary"
              type="submit"
            >
              {pending
                ? <LoadingButtonIcon/>
                : tGlobal(`general.${forExisting ? 'update' : 'create'}`)
              }
            </Button>
          </ButtonListContainer>
        </BottomContentContainer>
      </Stack>
    </Container>
  )
}

export default BeehiveEditForm
