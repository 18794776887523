const general = {
  save: 'Save',
  back: 'Back',
  finish: 'Finish',
  next: 'Next',
  close: 'Close',
  email: 'Email',
  phone: 'Phone',
  address: 'Address',
  street: 'Street',
  town: 'City',
  zipCode: 'Zip Code',
  country: 'Country',
  password: 'Password',
  skip: 'Skip',
  ok: 'Ok',
  verify: 'Verify',
  confirm: 'Confirm',
  createNew: 'Create New',
  add: 'Add',
  loading: 'Loading...',
  noOptions: 'No options',
  logOutUser: 'Log out',
  frameTypesInBeehive: 'Frame types',
  undefined: 'Not specified',
  edit: 'Edit',
  yes: 'Yes',
  no: 'No',
  update: 'Update',
  cancel: 'Cancel',
  name: 'Name',
  create: 'Create',
  settings: 'Settings',
  search: 'Search',

  pending: '...',
  missingInfo: 'Missing information',

  error: {
    general: 'An error occurred'
  },

  memberRole: {
    ADMIN: 'Admin',
    MAINTAINER: 'Maintainer',
    READER: 'Subscriber'
  },

  trueFalse: {
    true: 'Yes',
    false: 'No'
  },

  beeType: {
    YELLOW_ITALIAN: 'Yellow Italian',
    GRAY_KRANIUM: 'Gray Kranium',
    DARK_NORDIC: 'Dark Nordic',
    BUCKFAST: 'Buckfast',
    ELGON: 'Elgon',
    UNKKNWON: 'Unknown'
  },

  beehiveType: {
    HIKE: 'Hike',
    STATIONARY: 'Stationary',
    LAY_OFF: 'Lay off',
    TRAY: 'Tray',
    STRAW: 'Straw',
    LOG: 'Log',
    HOUSE: 'Bee pavilion/house',
    TREE: 'Tree',
    MATING: 'Mating/Droning breeding',
    OBSERVATION: 'Observation/demonstration',
    TOREBODA: 'Töreboda hive',
    NACKA: 'Nacka hive',
    OSTERVALA: 'Östervåla hive',
    HJO: 'HJO hive',
    HULT: 'Ljusabergs/Hult hive',
    MULTI: 'Multi-hive',
    REA_DAN: 'REA Dan',
    HUDDINGE: 'Huddinge hive',
    SMALAND: 'Småland hive',
    PARADISE: 'Paradise hive',
    SKALDERHUS: 'Skälderhus hive',
    OTHER: 'Other'
  },

  frameType: {
    LOW_NORMAL: 'Low-normal',
    SVEA: 'Svea',
    SVEA_HALF: 'Half Svea',
    LS: 'LS',
    LS_HALF: 'Half LS (HLS)',
    NORWEGIAN: 'Norwegian',
    NORWEGIAN_HALF: 'Half Norwegian',
    WIESLANDER: 'Wieslander',
    DADANT: 'Dadant',
    LANGSTROTH: 'Langstroth',
    LANGSTROTH_3_4: '3/4 Langstroth (Farrar)'
  },

  beehiveSocietyStrength: {
    STRONG: 'Strong',
    OK: 'Ok',
    WEAK: 'Weak'
  },

  beehiveSocietyBehavior: {
    CALM: 'Calm',
    OK: 'Ok',
    ANGRY: 'Angry'
  },

  beeQueenColor: {
    WHITE: 'White',
    YELLOW: 'Yellow',
    RED: 'Red',
    GREEN: 'Green',
    BLUE: 'Blue'
  },

  beehiveInspectionQueenControl: {
    SEEN_EXISTING_QUEEN: 'Seen existing queen',
    SEEN_NEW_QUEEN: 'Seen new queen, own exchange',
    SEEMS_QUEENLESS: 'Seems queenless'
  },

  beehiveSickness: {
    NOSEMA: 'Nosema',
    VARROAMITE: 'Varroa mite',
    AMERICANFRYROT: 'American foulbrood',
    SOOT: 'Chalkbrood',
    KUPSALWETHER: 'Kupsal beetle',
    TRAKEWETHER: 'Tracheal mite',
    TROPILAELAPWETHER: 'Tropilaelaps mite'
  },

  beehiveWildlifeAttack: {
    BADGER: 'Badger',
    MARTEN: 'Marten',
    BEAR: 'Bear',
    BIRD: 'Bird',
    ANT: 'Ant'
  },

  noteCategory: {
    ORGANIZATION: 'Organizations',
    APIARY: 'Apiaries',
    BEEHIVE: 'Beehives'
  }
}

export default general
