const passwordReset = {
  title: 'Reset Password',
  stepTitle: {
    emailSubmit: {
      title: 'Enter Email',
      description:
        'Enter the email address for the account you want to reset the password for. An email will be sent with a code that is used to reset the account.'
    },
    passwordSubmit: {
      title: 'Change Password',
      description:
        'Enter the code sent to the account email and a new password for the account.'
    }
  },
  actions: {
    resetInitiate: {
      success: 'A code has been sent to the account email address',
      error: {
        badRequest: 'Invalid email',
        tooManyRequests: 'Too many attempts, please wait and try again.'
      }
    },
    resetSubmit: {
      pending: 'Resetting password',
      success: 'Password has been reset',
      error: {
        unauthorized: 'Invalid verification code',
        badRequest:
          'Password must contain at least one uppercase letter and one special character'
      }
    }
  }
}

export default passwordReset
