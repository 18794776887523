import { Button, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import TitleImage from '../components/common/TitleImage'
import { AppRoutePaths } from '../utils/routes'

import PeopleCommunityIcon from '../components/common/Icon/PeopleCommunity'
import EditIcon from '../components/common/Icon/Edit'
import PageBodyContainer from '../components/common/PageBodyContainer'

const COMPONENT_TRANSLATE_PREFIX = 'pages.pageNotFound'

const PageNotFound = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: COMPONENT_TRANSLATE_PREFIX
  })

  return (
    <Stack className="pageRoot">
      <TitleImage title={t('title')} src="/images/generic-background.png" />
      <Stack className="pageContent" spacing={2}>
        <PageBodyContainer>
          <Typography variant="h2">{t('infoTitle')}</Typography>

          <Typography>{t('descriptionInfo')}</Typography>

          <Typography>{t('descriptionManual')}</Typography>
        </PageBodyContainer>
        <Button
          variant="contained"
          component={Link}
          to={AppRoutePaths.HOME_PATH}
          startIcon={<PeopleCommunityIcon />}
        >
          {t('goToLandingPage')}
        </Button>

        <Button
          variant="outlined"
          color="secondary"
          component={Link}
          to={AppRoutePaths.ABOUT_PATH}
          startIcon={<EditIcon />}
        >
          {t('goToInfo')}
        </Button>
      </Stack>
    </Stack>
  )
}

export default PageNotFound
