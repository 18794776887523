import { Stack } from '@mui/material'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import LoadingPage from '../../components/common/LoadingPage'
import TitleImage from '../../components/common/TitleImage'
import OrganizationEditForm from '../../components/Organization/OrganizationEditForm'
import UnauthorizedByRole from '../../components/Organization/Unauthorized/UnauthorizedByRole'
import {
  useOrganizationMemberQuery,
  useOrganizationQuery,
  useUpdateOrganizationMutation
} from '../../utils/api/hooks/organization'
import { organizationMemberHelpers } from '../../utils/helpers'
import { useSnackbar } from 'notistack'
import { useErrorStateSnackEffect } from '../../utils/hooks/stateSnackEffect'
import { OrganizationFields } from '../../components/common/ContentGeneration/Organization/types'
import {
  useGetUserQuery,
  useUpdateUserAppReviewMutation
} from '../../utils/api/hooks/user'
import useIsMounted from '../../utils/hooks/isMounted'
import { promiseForForm } from '../../utils/form/helpers'
import ReviewModal from '../../components/common/ReviewModal'
import { OrganizationMemberRoles } from '../../models/organization'

const COMPONENT_TRANSLATE_PREFIX = 'pages.organization.editOrganization'

const EditOrganization = () => {
  const isMounted = useIsMounted()
  const navigate = useNavigate()
  const { organizationId } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation(undefined, {
    keyPrefix: COMPONENT_TRANSLATE_PREFIX
  })

  const organizationQuery = useOrganizationQuery(organizationId)
  const organization = organizationQuery.data?.data

  const requestingUserQuery = useGetUserQuery()
  const requestingUser = requestingUserQuery.data?.data

  const organizationMemberQuery = useOrganizationMemberQuery(
    organizationId,
    requestingUser?.id
  )
  const organizationMember = organizationMemberQuery.data?.data

  const updateUserAppReview = useUpdateUserAppReviewMutation()

  const onCloseReviewModal = useCallback(
    (rating: number, text: string) => {
      promiseForForm(
        updateUserAppReview.mutateAsync({
          rating,
          text
        })
      )
    },
    [updateUserAppReview.mutateAsync]
  )

  const appReviewSurvey = useMemo(
    () => (
      <ReviewModal
        onClose={onCloseReviewModal}
        userData={requestingUser!}
      ></ReviewModal>
    ),
    [requestingUserQuery]
  )

  useErrorStateSnackEffect(
    organizationQuery.isError,
    t('actions.loadOrganization.error')
  )

  const initialDataPending = organizationQuery.isLoading

  const onUpdateOrganizationSuccess = useCallback(() => {
    enqueueSnackbar(t('actions.updateOrganization.success'), {
      variant: 'success'
    })
    if (isMounted()) {
      navigate('./..')
    }
  }, [t, enqueueSnackbar, isMounted])

  const updateOrganizationMutation = useUpdateOrganizationMutation(
    onUpdateOrganizationSuccess
  )

  const onSubmit = async (data: OrganizationFields) => {
    updateOrganizationMutation.mutate({
      orgId: organizationId!,
      ...data
    })
  }
  useErrorStateSnackEffect(
    updateOrganizationMutation.isError,
    t('actions.updateOrganization.error')
  )
  if (updateOrganizationMutation.isSuccess) {
    location.reload()
  }

  const isAdmin = organizationMemberHelpers.isAuthorizedMemberWithRole(
    organizationMember,
    OrganizationMemberRoles.ADMIN
  )

  return initialDataPending ? (
    <LoadingPage />
  ) : (
    <>
      <Stack className="pageRoot">
        <TitleImage
          src="/images/generic-background.png"
          title={organization?.name}
        />
        <Stack className="pageContent">
          {appReviewSurvey}
          {isAdmin ? (
            <OrganizationEditForm
              initialState={organization}
              onSubmit={onSubmit}
              pending={updateOrganizationMutation.isLoading}
            />
          ) : (
            <UnauthorizedByRole body={t('section.unauthorized.body')} />
          )}
        </Stack>
      </Stack>
    </>
  )
}

export default EditOrganization
