const beehivesTab = {
  addBeehive: 'Add more beehives',
  error: {
    loadingBeehivesByApiary: 'An error occurred while loading beehives',
    deleteBeehive: 'An error occurred while deleting the beehive'
  },
  success: {
    deleteBeehive: 'The beehive has been deleted'
  }
}

export default beehivesTab
