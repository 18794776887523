import { ReactComponent as SvgFileComponent } from 'react-scripts/lib/react-app/*.svg'
import { SvgIcon, SvgIconTypeMap } from '@mui/material'
import { DefaultComponentProps } from '@mui/material/OverridableComponent'

export type IconProps = DefaultComponentProps<SvgIconTypeMap>

export function WrapSvgIcon (
  WrappedIcon: typeof SvgFileComponent
) {
  function Icon (props: IconProps) {
    return (
        <SvgIcon {...props}>
          <WrappedIcon/>
        </SvgIcon>
    )
  }

  Icon.displayName = 'WrappedSvgIcon'
  return Icon
}
