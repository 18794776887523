import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { CallbackVoid } from '../util/types'
import { PaginationQuery, usePagination } from './pagination'
import { useApiClient } from './useApiClient'
import { useAuthenticatedUserId } from './useAuthenticatedUserId'

export const USER_NOT_CONFIRMED_MESSAGE = 'User is not confirmed.'

export function useCreateOrganizationMutation(onSuccess: CallbackVoid) {
  const apiClient = useApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: apiClient.organization.createOrganization,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [GET_USER_ORGANIZATIONS_OVERVIEW_KEY]
      })
      onSuccess()
    }
  })
}

const GET_ALL_ORGANIZATIONS_KEY = 'organization/getAll'
export function useGetOrganizationWhereUserNotMember() {
  const userId = useAuthenticatedUserId()
  const apiClient = useApiClient()

  return useQuery(
    [GET_ALL_ORGANIZATIONS_KEY, userId],
    async () => {
      return apiClient.organization.getOrganizationsWhereUserNotMember({})
    },
    {
      enabled: !!userId
    }
  )
}

const GET_USER_ORGANIZATIONS_OVERVIEW_KEY = 'organization/getUserOrganizations'
export function useUserOrganizationsQuery(initialQuery?: PaginationQuery) {
  const userId = useAuthenticatedUserId()
  const apiClient = useApiClient()

  const pagination = usePagination(initialQuery)

  return {
    query: useQuery(
      [GET_USER_ORGANIZATIONS_OVERVIEW_KEY, userId, ...pagination.queryKeys],
      () => apiClient.organization.getUserOrganizations(pagination.queryParams),
      {
        enabled: !!userId
      }
    ),
    pagination
  }
}

const GET_ORANIZATION_BY_ID = 'organization/getOrganization'
export function useOrganizationQuery(id?: string) {
  const apiClient = useApiClient()

  return useQuery(
    [GET_ORANIZATION_BY_ID, id],
    async () => {
      if (typeof id === 'undefined') {
        throw new Error()
      }

      return apiClient.organization.getOrganizationById({ id })
    },
    {
      enabled: !!id
    }
  )
}

const GET_ORGANIZATION_MEMBERS = 'organization/getOrganizationMembers'
export function useOrganizationMembersQuery(id?: string) {
  const apiClient = useApiClient()

  return useQuery(
    [GET_ORGANIZATION_MEMBERS, id],
    async () => {
      if (typeof id === 'undefined') {
        throw new Error()
      }

      return apiClient.organization.getOrganizationMembers({ id })
    },
    {
      enabled: !!id
    }
  )
}

export function useJoinOrganization(onSuccess?: CallbackVoid) {
  const apiClient = useApiClient()

  return useMutation({
    mutationFn: apiClient.organization.joinOrganization,
    onSuccess: onSuccess
  })
}

export function useAcceptOrganizationMember(onSuccess?: CallbackVoid) {
  const apiClient = useApiClient()

  return useMutation({
    mutationFn: apiClient.organization.approveOrganizationMember,
    onSuccess: onSuccess
  })
}

export function useDeclineOrganizationMember(onSuccess?: CallbackVoid) {
  const ApiClient = useApiClient()

  return useMutation({
    mutationFn: ApiClient.organization.declineOrganizationMember,
    onSuccess: onSuccess
  })
}

/**
 * TODO: apply to pages
 *
 */
export function useLeaveOrganizationMutation(onSuccess?: CallbackVoid) {
  const apiClient = useApiClient()

  return useMutation({
    mutationFn: apiClient.organization.leaveOrganization,
    onSuccess: onSuccess
  })
}

const GET_ORGANIZATION_MEMBER_BY_ID = 'organization/getOrganizationMember'
export function useOrganizationMemberQuery(id?: string, memberId?: string) {
  const apiClient = useApiClient()

  return useQuery(
    [GET_ORGANIZATION_MEMBER_BY_ID, id, memberId],
    async () => {
      if (typeof id === 'undefined') {
        throw new Error()
      }
      if (typeof memberId === 'undefined') {
        throw new Error()
      }

      return apiClient.organization.getOrganizationMemberById({ id, memberId })
    },
    {
      enabled: !!id || !!memberId
    }
  )
}
export function useUpdateOrganizationMutation(onSuccess?: CallbackVoid) {
  const ApiClient = useApiClient()

  return useMutation({
    mutationFn: ApiClient.organization.updateOrganization,
    onSuccess: onSuccess
  })
}

export function useRemoveOrganizationMutation(
  onSuccess?: CallbackVoid,
  onError?: CallbackVoid
) {
  const ApiClient = useApiClient()

  return useMutation({
    mutationFn: ApiClient.organization.removeOrganization,
    onSuccess: onSuccess,
    onError: onError
  })
}
