import { NoteOverview } from './ListNoteOverviews'
import { NoteQueryFilter } from '../../../models/notes'

export type NoteForOverviewBase = {
  id: string
  title?: string | null
  createdAt?: string | null
}

export function generateNoteOverview<NoteType extends NoteForOverviewBase>(
  /* note: AtLeast<OtherNotesResultDto, 'id'>, */
  note: NoteType,
  link: string
): NoteOverview {
  return {
    id: note.id,
    title: note.title ?? '...',
    date: note.createdAt ? new Date(note.createdAt) : undefined,
    link
  }
}

export type ListNoteTypeRenderProps = {
  filter: NoteQueryFilter
}
