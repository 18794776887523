import beehiveEventDetails from './beehiveEventDetails'
import beehiveInspectionDetails from './beehiveInspectionDetails'
import beehiveReminderDetails from './beehiveReminderDetails'
import otherDetails from './otherDetails'
import beehiveInventoryDetails from './beehiveInventoryDetails'
import beehivePlanningDetails from './beehivePlanningDetails'

const newNoteDetails = {
  beehiveInspectionDetails,
  beehiveEventDetails,
  otherDetails,
  beehiveReminderDetails,
  beehiveInventoryDetails,
  beehivePlanningDetails
}

export default newNoteDetails
