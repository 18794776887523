import {
  Dialog,
  Stack,
  Typography,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  SelectChangeEvent
} from '@mui/material'
import PageBodyContainer from '../../../../common/PageBodyContainer'
import { useTranslation } from 'react-i18next'
import { Beehive } from '../../../../../models/beehive'
import BottomContentContainer from '../../../../common/Layout/BottomContentContainer'
import ButtonListContainer from '../../../../common/Layout/ButtonListContainer'
import CheckMarkIcon from '../../../../common/Icon/CheckMark'
import CancelIcon from '../../../../common/Icon/Cancel'
import { useState } from 'react'

type AlarmDialogProps = {
  beehive: Beehive
  isOpen: boolean
  onCancel: () => void
  onSave: (thresholdValue: number, sensorID: number) => void
}
const COMPONENT_TRANSLATE_PREFIX = 'components.beehive.alarmDialog'

const AlarmDialog = (props: AlarmDialogProps) => {
  const { beehive, isOpen, onCancel, onSave } = props
  const { t } = useTranslation(undefined, {
    keyPrefix: COMPONENT_TRANSLATE_PREFIX
  })
  const { t: tGlobal } = useTranslation()

  const [selectedOption, setSelectedOption] = useState<string>('')
  const [inputValue, setInputValue] = useState<number>(0)

  const handleDropdownChange = (event: SelectChangeEvent<string>) => {
    setSelectedOption(event.target.value)
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value as unknown as number)
  }

  const submitAlarmSettings = () => {
    if (beehive && onSave) {
      onSave(inputValue, Number(selectedOption))
      onCancel()
    }
  }

  return (
    <Dialog open={isOpen} onClose={onCancel}>
      <Stack sx={{ p: 2, pt: 4 }} spacing={2}>
        <PageBodyContainer>
          <Typography variant="h2">{t('sections.main.header')}</Typography>
          <Typography>
            {t('sections.main.body', {
              name: beehive?.name ?? tGlobal('general.pending')
            })}
          </Typography>
        </PageBodyContainer>

        <Stack sx={{ p: 2, pt: 2 }} spacing={2}>
          <FormControl fullWidth sx={{}}>
            <InputLabel id="dropdown-label">Sensor</InputLabel>
            <Select
              labelId="dropdown-label"
              id="dropdown"
              label="Sensor"
              value={selectedOption}
              onChange={handleDropdownChange}
            >
              <MenuItem value={6}>Vikt</MenuItem>
              <MenuItem value={2}>Yttre temperatur</MenuItem>
              <MenuItem value={3}>Luftfuktighet</MenuItem>
              <MenuItem value={4}>Lufttryck</MenuItem>
            </Select>
          </FormControl>
          <TextField
            id="input"
            label="Tröskelvärde"
            value={inputValue}
            onChange={handleInputChange}
            sx={{ m: 1 }}
          />
        </Stack>

        <BottomContentContainer>
          <ButtonListContainer direction="column">
            <Button
              variant="contained"
              onClick={submitAlarmSettings}
              startIcon={<CheckMarkIcon />}
            >
              {tGlobal('general.confirm')}
            </Button>

            <Button
              variant="outlined"
              color="secondary"
              onClick={onCancel}
              startIcon={<CancelIcon />}
            >
              {tGlobal('general.cancel')}
            </Button>
          </ButtonListContainer>
        </BottomContentContainer>
      </Stack>
    </Dialog>
  )
}
export default AlarmDialog
