import { Autocomplete, TextField } from '@mui/material'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { COUNTRIES } from '../../../utils/countries'

const COMPONENT_TRANSLATE_PREFIX = 'countries'

const CountrySelect = props => {
  const {
    inputProps,
    value,
    onChange,
    ...rest
  } = props

  const { t } = useTranslation(undefined, { keyPrefix: COMPONENT_TRANSLATE_PREFIX })

  const countryOptions = useMemo(
    () => Object.entries(COUNTRIES).map(
      ([countryCode, translateCode]) => ({
        value: countryCode,
        label: t(translateCode)
      })
    ),
    []
  )

  const computedValue = useMemo(
    () => value
      ? typeof value === 'object'
        ? value
        : countryOptions.find(el => el.value === value)
      : value,
    [value, countryOptions]
  )

  const computedOnChange =
    (evt, data) => (onChange && onChange(
      {
        ...evt,
        target: {
          ...evt.target,
          value: data?.value
        }
      }, data?.value
    ))

  return (
    <Autocomplete
      options={countryOptions}
      fullWidth={true}
      getOptionLabel={o => o?.label || ''}
      renderInput={(params) => (
        <TextField
          {...params}
          {...inputProps}
        />
      )}
      onChange={computedOnChange}
      value={computedValue}
      {...rest}
    />
  )
}

export default CountrySelect
