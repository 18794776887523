const userProfileEdit = {
  title: 'Min profil',

  firstTime: {
    infoTitle: 'Slutför inställningar',
    description: 'Spendera några minuter och slutför alla inställningar av din profil'
  },
  ordinary: {
    infoTitle: 'Ändra uppgifter'
  },

  fields: {
    firstname: {
      label: 'Förnamn',
      errors: {
        required: 'Förnamn krävs'
      }
    },
    surname: {
      label: 'Efternamn',
      errors: {
        required: 'Efternamn krävs'
      }
    }
  },
  actions: {
    updateUser: {
      fulfilled: 'Profilen har uppdaterats'
    }
  }
}

export default userProfileEdit
