import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import BeehiveNoteType from '../../../../enums/Note/BeehiveInspectionType'
import CommonNoteType from '../../../../enums/Note/CommonNoteType'
import { NoteCategory } from '../../../../models'
import FormGrid from '../../../common/Form/FormGrid'
import FormGridRadioGroup from '../../../common/Form/FormGrid/CommonRows/OutlinedRadioGroup'
import OutlinedRadio from '../../../common/Form/FormGrid/CommonRows/OutlinedRadioGroup/OutlinedRadio'
import MagnifyerIcon from '../../../common/Icon/Magnifyer'
import NoteIcon from '../../../common/Icon/Note'

const COMPONENT_TRANSLATE_PREFIX = 'components.note.newNote.newNoteTypeSelect'

const COMMON_TYPES = {
  translatePrefix: 'commonTypeOptions',
  types: [{ name: CommonNoteType.OTHER, icon: <NoteIcon /> }]
}

const CATEGORY_TYPES = {
  [NoteCategory.BEEHIVE]: {
    translatePrefix: 'beehiveTypeOptions',
    types: [
      { name: BeehiveNoteType.INSPECTION, icon: <MagnifyerIcon /> }

      /*  ! Uncomment this section for later versions !
       { name: BeehiveNoteType.EVENT, icon: <ErrorIcon/> },
      { name: BeehiveNoteType.REMINDER, icon: <BellIcon/> },
      { name: BeehiveNoteType.PLANNING, icon: <CalendarYear/> },
      { name: BeehiveNoteType.INVENTORY, icon: <NoteIcon/> } */
    ]
  }
}

const NewNoteTypeSelect = (props) => {
  const { name, categorySelectName, values } = props

  const { t } = useTranslation(undefined, {
    keyPrefix: COMPONENT_TRANSLATE_PREFIX
  })

  const { watch } = useFormContext()

  const selectedCategory = watch(`${categorySelectName}.category`)

  const activeCategory = CATEGORY_TYPES[selectedCategory]

  const categoryTypes = activeCategory
    ? [activeCategory, COMMON_TYPES]
    : [CATEGORY_TYPES.BEEHIVE, COMMON_TYPES]

  return (
    <FormGrid name={name} translatePrefix={COMPONENT_TRANSLATE_PREFIX}>
      <FormGridRadioGroup
        name="type"
        rules={{
          required: true
        }}
      >
        {categoryTypes.map((categories) =>
          categories.types.map(({ name, icon }) => (
            <OutlinedRadio
              preSelValues={values}
              key={name}
              value={name}
              label={t(`${categories.translatePrefix}.${name}.title`)}
              description={t(
                `${categories.translatePrefix}.${name}.description`
              )}
              endIcon={icon}
            />
          ))
        )}
      </FormGridRadioGroup>
    </FormGrid>
  )
}

export default NewNoteTypeSelect
