const beehiveInspectionDetails = {
  section: {
    general: 'General',
    society: 'About the society',
    queen: 'Queen',
    other: 'Other'
  },
  defaultTitle: '{{val, datetime}}',
  fields: {
    title: {
      label: 'Title',
      errors: {
        required: ''
      }
    },

    newEggs: {
      _checkbox: {
        label: 'Newly laid eggs',
        errors: {
          required: ''
        }
      },
      value: {
        label: 'Number of frames',
        errors: {
          required: ''
        }
      }
    },
    coveredFries: {
      _checkbox: {
        label: 'Covered brood',
        errors: {
          required: ''
        }
      },
      value: {
        label: 'Number of frames',
        errors: {
          required: ''
        }
      }
    },
    filledFryRoomsWithFrames: {
      _checkbox: {
        label: 'Filled brood chamber with frames',
        errors: {
          required: ''
        }
      },
      value: {
        label: 'Number',
        errors: {
          required: ''
        }
      }
    },
    cutDroneFrame: {
      label: 'Drone frame cut',
      errors: {
        required: ''
      }
    },
    attractsPollen: {
      label: 'Colony attracting pollen',
      errors: {
        required: ''
      }
    },
    winterFed: {
      _checkbox: {
        label: 'Winter fed',
        errors: {
          required: ''
        }
      },
      value: {
        label: 'kg / liter',
        errors: {
          required: ''
        }
      }
    },
    checkedFramesWithRemainingFodder: {
      _checkbox: {
        label: 'Checked number of frames with remaining feed',
        errors: {
          required: ''
        }
      },
      value: {
        label: 'Number of frames',
        errors: {
          required: ''
        }
      }
    },
    hurled: {
      _checkbox: {
        label: 'Extracted honey',
        errors: {
          required: ''
        }
      },
      value: {
        label: 'kg',
        errors: {
          required: ''
        }
      }
    },
    performedSpringControl: {
      _checkbox: {
        label: 'Performed spring control',
        errors: {
          required: ''
        }
      }
    },
    performedBeeHouseControl: {
      _checkbox: {
        label: 'Performed beehive inspection / self-inspection',
        errors: {
          required: ''
        }
      }
    },
    societyStrength: {
      _checkbox: {
        label: 'Strength of colony',
        errors: {
          required: ''
        }
      }
    },
    societyBehavior: {
      _checkbox: {
        label: 'Behavior of colony',
        errors: {
          required: ''
        }
      }
    },
    sicknessSymptom: {
      _checkbox: {
        label: 'Symptoms of sickness',
        errors: {
          required: ''
        }
      },
      value: {
        label: '',
        errors: {
          required: ''
        }
      }
    },
    queenControl: {
      _checkbox: {
        label: 'Queen control',
        errors: {
          required: ''
        }
      }
    },
    newQueen: {
      label: 'New queen introduced',
      errors: {
        required: ''
      }
    },
    queenMarked: {
      _checkbox: {
        label: 'Queen marked',
        errors: {
          required: ''
        }
      },
      value: {
        label: 'Color',
        errors: {
          required: ''
        },
        option: {
          blue: 'Blue',
          red: 'Red',
          yellow: 'Yellow'
        }
      }
    },
    queenWingCut: {
      label: 'Wing clipped queen',
      errors: {
        required: ''
      }
    },
    other: {
      _checkbox: {
        label: 'Other',
        errors: {
          required: ''
        }
      }
    }
  }
}

export default beehiveInspectionDetails
