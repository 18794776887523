import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { useErrorStateSnackEffect } from '../../utils/hooks/stateSnackEffect'
import { useTranslation } from 'react-i18next'
import LoadingPage from '../../components/common/LoadingPage'
import { Stack } from '@mui/material'
import TitleImage from '../../components/common/TitleImage'
import ApiaryEditForm from '../../components/apiaries/ApiaryEditForm'
import { useSnackbar } from 'notistack'
import useIsMounted from '../../utils/hooks/isMounted'
import {
  useUpdateApiaryMutation,
  useGetApiaryQuery
} from '../../utils/api/hooks/apiary'
import { ApiaryInput } from '../../models/apiary'

const COMPONENT_TRANSLATE_PREFIX = 'components.apiary.apiaryEditForm'

const EditApiary = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: COMPONENT_TRANSLATE_PREFIX
  })
  const { apiaryId } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const isMounted = useIsMounted()

  const [formDefaultValue, setFormDefaultValue] = useState<ApiaryInput>()

  const apiaryQuery = useGetApiaryQuery(apiaryId!)
  const apiary = apiaryQuery.data?.data

  useErrorStateSnackEffect(apiaryQuery.error, t('error.updateApiary'))

  const onUpdateApiarySuccess = useCallback(() => {
    enqueueSnackbar(t('success.updateApiary'), { variant: 'success' })
    if (isMounted()) {
      navigate('./..')
    }
  }, [t, enqueueSnackbar, isMounted, navigate])

  const updateApiaryMutation = useUpdateApiaryMutation(onUpdateApiarySuccess)

  useErrorStateSnackEffect(updateApiaryMutation.error, t('error.updateApiary'))

  useEffect(() => {
    if (apiary) {
      setFormDefaultValue({
        ...apiary
      })
    }
  }, [apiary])

  const onSubmit = (apiaryForm: ApiaryInput) => {
    updateApiaryMutation.mutate({ id: apiary!.id, ...apiaryForm })
  }
  return apiaryQuery.isLoading ? (
    <LoadingPage />
  ) : (
    <>
      <Stack className="pageRoot">
        <TitleImage src="/images/generic-background.png" title={apiary?.name} />
      </Stack>
      <Stack className="pageContent">
        <ApiaryEditForm
          initialState={formDefaultValue}
          pending={updateApiaryMutation.isLoading}
          forExisting
          onSubmit={onSubmit}
        />
      </Stack>
    </>
  )
}

export default EditApiary
