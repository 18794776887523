const iotTab = {
  timeIntervalTitle: 'Tidsintervall',
  timeIntervalStartLabel: 'Från',
  timeIntervalEndLabel: 'Till',
  dataFetchType: {
    live: 'Använd livedata',
    timeInterval: 'Använd tidsInterval'
  },

  register: {
    header: 'Skanna bikupans QR-kod',

    integrateBeehive: 'Integrera bikupa',

    actions: {
      connectIotBeehive: {
        fulfilled: 'Bikupan har integrerats!',
        error: 'Ett fel inträffade när bikupan skulle integreras!',
        conflictError: 'Kupan finns redan registrerad i systemet'
      }
    },

    qrStatus: {
      scanned: 'Bikupa scannad!',
      invalid: 'Ej giltig QR-kod',
      unScanned: 'Rikta kameran mot bikupans QR-kod'
    },
    manualEntryPlaceholder: 'Ange bikupans kod manuellt här',
    scanDataInfo: 'Scan resultat: {{qrData}}',
    reScan: 'Scanna igen'
  },
  actions: {
    getReferenceWeight: {
      error: 'Ett fel inträffade när bikupans referensvikt skulle hämtas'
    }
  },
  buttons: {
    editReferenceWeight: 'Ställ in referensvikt',
    createAlarm: 'Skapa larm'
  },
  newDigKit: {
    header: 'Nytt Digitalt Kit?',
    text: 'Har du fixat ett digitalt kit till din bikupa och installerat det? Klicka på knappen nedan och skanna QR-koden på locket för att koppla ditt nya kit till denna bikupa.'
  },
  sensorFields: {
    innerTemp: 'Inre temperatur',
    outerTemp: 'Yttre temperatur',
    weight: 'Vikt',
    humidity: 'Luftfuktighet',
    pressure: 'Lufttryck',
    upperBeehiveTemp: 'Övre bikupans temperatur',
    lowerBeehiveTemp: 'Nedre bikupans temperatur'
  }
}

export default iotTab
