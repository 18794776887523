import { useContext, useEffect, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import FormGridContext from '../FormGridContext'
import FormGridRow from '../FormGridRow'
import TextField from './TextField'

export const VALID_EMAIL_PATTERN = /\S+@\S+\.\S+/

const getSegmentName = (name, index) => `${name ?? 'code'}.${index}`

const CodeRow = (props) => {
  const { name, length, rowProps, inputProps, ...rest } = props

  const { setValue } = useFormContext()

  const { namePrefix } = useContext(FormGridContext)

  const [pastedData, setPastedData] = useState(null)

  useEffect(() => {
    if (pastedData) {
      const isValidPasteData = !isNaN(+pastedData)

      for (let i = 0; i < length; i++) {
        setValue(
          `${(namePrefix && `${namePrefix}.`) ?? ''}${getSegmentName(name, i)}`,
          isValidPasteData ? pastedData[i] ?? '' : ''
        )
      }
    }
  }, [length, pastedData, namePrefix])

  const indexArray = useMemo(
    () => new Array(length).fill(null).map((_, index) => index),
    [length]
  )

  return (
    <FormGridRow {...rowProps}>
      {indexArray.map((index) => (
        <TextField
          key={index}
          xs={12 / length}
          name={getSegmentName(name, index)}
          translateKey="code"
          label=""
          translatePrefix="form"
          onChange={(value) => value.slice(0, 1)}
          rules={{
            required: {
              value: true,
              nameOverride: 'code'
            }
          }}
          inputProps={{
            autoComplete: 'off',
            onPaste: (evt) => setPastedData(evt.clipboardData.getData('text')),
            inputProps: {
              style: {
                fontWeight: 'bold',
                fontSize: '150%',
                textAlign: 'center'
              }
            },
            ...inputProps
          }}
          {...rest}
        />
      ))}
    </FormGridRow>
  )
}

export default CodeRow
