import { Button, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import PlusIcon from '../../../Icon/Plus'

const AddApiaries = (props) => {
  const { addApiaries, sx } = props
  const { t: tGlobal } = useTranslation()

  const submit = () =>
    addApiaries({
      apiariesAmount: 1,
      beehivesAmount: 1
    })

  return (
    <Stack direction="column" spacing={2} sx={sx}>
      <Button
        variant="contained"
        sx={{ height: '100%', width: '100%' }}
        startIcon={<PlusIcon />}
        onClick={submit}
      >
        {tGlobal('general.add')}
      </Button>
    </Stack>
  )
}

export default AddApiaries
