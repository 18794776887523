const aboutTab = {
  header: 'Information',
  editInSettings: '* Edited in settings',
  deleteAccount: 'Delete account',
  deleteConfirmation: 'Are you sure you want to delete your account?',
  deleteInformation:
    'Your account will be permanently deleted and you will no longer be able to access your beehives and data.',
  cancel: 'Cancel',
  error:
    'Something went wrong when you tried to delete your account. Please try again later.',
  success: 'Your account has been deleted.'
}

export default aboutTab
