import { Grid, GridTypeMap, Stack, Typography } from '@mui/material'
import { DefaultComponentProps } from '@mui/types'
import { useTranslation } from 'react-i18next'

type InfoGridTextProps = {
  header?: string,
  body?: string | null,
} & DefaultComponentProps<GridTypeMap>

const InfoGridText = (props: InfoGridTextProps) => {
  const { header, body, ...rest } = props
  const { t } = useTranslation()

  return (
    <Grid item xs={12} sm={6} {...rest}>
      <Stack direction="column">
        <Typography fontWeight="bold">
          {header}
        </Typography>

        <Typography>
          {body ?? t('general.missingInfo')}
        </Typography>
      </Stack>
    </Grid>
  )
}

export default InfoGridText
