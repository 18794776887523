import { useMemo } from 'react'
import { matchPath, useLocation } from 'react-router-dom'
import { AppRoutePaths } from '../../../utils/routes'
import { Fade, Stack, useMediaQuery } from '@mui/material'
import BreadCrumb from './BreadCrumb'

import { THEME_CONSTANTS } from '../../../styles/themeConstants'

const ROUTES = Object.values(AppRoutePaths)

const BreadCrumbs = () => {
  const isMobile = useMediaQuery('(max-width:600px)')
  const location = useLocation()
  const rootStyle = (theme) => ({
    position: 'absolute',
    top: isMobile
      ? THEME_CONSTANTS.mobileToolbarHeight
      : THEME_CONSTANTS.toolbarHeight,
    p: 2,
    alignItems: 'top',
    left: 0,
    flexWrap: 'wrap',

    '& .MuiTypography-root': {
      color: theme.palette.white.main,
      fontSize: '0.75rem'
    }
  })
  const activeMatch = useMemo(
    () =>
      ROUTES.map((path) =>
        matchPath(
          {
            path,
            caseSensitive: true
          },
          location.pathname
        )
      ).find((m) => m !== null),
    [location.pathname]
  )

  const segments = useMemo(
    () => (activeMatch ? activeMatch.pattern.path.split('/').splice(1) : []),
    [activeMatch]
  )

  return (
    <Fade in={segments?.length > 1}>
      <Stack direction="row" spacing={0.5} sx={rootStyle}>
        {segments &&
          segments.map((segment, segmentIndex) => (
            <BreadCrumb
              key={`${segment}-${activeMatch.pathname}`}
              segment={segment}
              segmentIndex={segmentIndex}
              match={activeMatch}
              isLast={segmentIndex === segments.length - 1}
            />
          ))}
      </Stack>
    </Fade>
  )
}

export default BreadCrumbs
