import { Dispatch, SetStateAction, useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack
} from '@mui/material'
import DoDisturbIcon from '@mui/icons-material/DoDisturb'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { useTranslation } from 'react-i18next'
import InfoGridText from '../../components/common/InfoGridText'
import { OrganizationMemberRoleInput } from '../../models/organization'

const COMPONENT_TRANSLATE_PREFIX = 'pages.organization.editOrganizationMember'

type ChangeMemberRoleProps = {
  open: boolean
  pending: boolean
  setOpenSelectRoleDialog: Dispatch<SetStateAction<boolean>>
  memberRole: OrganizationMemberRoleInput | undefined
  changeRoleFunction: (memberRole: OrganizationMemberRoleInput) => any
}

const ChangeMemberRole = (props: ChangeMemberRoleProps) => {
  const {
    open,
    pending = false,
    setOpenSelectRoleDialog,
    changeRoleFunction,
    memberRole
  } = props

  const { t } = useTranslation(undefined, {
    keyPrefix: COMPONENT_TRANSLATE_PREFIX
  })
  const { t: tGeneral } = useTranslation()

  const ROLE_FIELDS_TO_SHOW = [
    {
      key: tGeneral('general.memberRole.ADMIN'),
      roleDescription: t('actions.changeRole.adminDescription'),
      value: 'ADMIN'
    },
    {
      key: tGeneral('general.memberRole.MAINTAINER'),
      roleDescription: t('actions.changeRole.maintainerDescription'),
      value: 'MAINTAINER'
    },
    {
      key: tGeneral('general.memberRole.READER'),
      roleDescription: t('actions.changeRole.readerDescription'),
      value: 'READER'
    }
  ]

  const handleSubmit = () => {
    if (role !== undefined) {
      if (role === 'ADMIN') {
        changeRoleFunction('ADMIN')
      } else if (role === 'MAINTAINER') {
        changeRoleFunction('MAINTAINER')
      } else if (role === 'READER') {
        changeRoleFunction('READER')
      }
    }
    setOpenSelectRoleDialog(false)
  }

  const [role, setRole] = useState<string>(
    memberRole !== undefined ? memberRole : 'READER'
  )

  return (
    <Dialog open={open} onClose={() => setOpenSelectRoleDialog(false)}>
      <DialogTitle style={{ fontWeight: 'bold' }}>
        {t('actions.changeRole.title')}
      </DialogTitle>
      <DialogContent>
        <RadioGroup
          name="role"
          defaultValue={memberRole}
          onChange={(e) => setRole(e.target.value)}
        >
          {ROLE_FIELDS_TO_SHOW.map((field) => {
            return (
              <div style={{ marginBottom: '35px' }} key={field.key}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  style={{ width: '100%' }}
                >
                  <InfoGridText
                    header={field.key}
                    body={field.roleDescription}
                  ></InfoGridText>
                  <FormControlLabel
                    label=""
                    labelPlacement="start"
                    value={field.value}
                    control={<Radio color="secondary" />}
                  />
                </Stack>
              </div>
            )
          })}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={pending}
          variant="outlined"
          color="secondary"
          onClick={() => setOpenSelectRoleDialog(false)}
        >
          <DoDisturbIcon style={{ fontSize: '32px', color: 'red' }} />
        </Button>
        <Button
          disabled={pending}
          variant="outlined"
          color="secondary"
          type="submit"
          onClick={handleSubmit}
        >
          <CheckCircleOutlineIcon
            style={{ fontSize: '32px', color: 'green' }}
          />
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ChangeMemberRole
