const unauthorizedByMembership = {
  action: {
    createRequest: {
      label: 'Gå med i organisation',
      fulfilled: 'Förfrågan har skickats',
      error: 'Ett fel inträffade när förfågan skulle skickas'
    },

    cancelRequest: {
      label: 'Avbryt förfrågan',
      fulfilled: 'Förfrågan har avbrutits',
      error: 'Ett fel inträffade när förfrågan skulle avbrytas'
    }
  },

  section: {
    pendingMember: {
      title: 'Medlemsförfrågan väntar på svar',
      body: 'Förfrågan om att gå med i organisationen väntar på svar från organisationens administratörer.'
    },

    notMember: {
      title: 'Ej medlem'
    }
  }
}

export default unauthorizedByMembership
