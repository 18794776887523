/* eslint-disable */
enum StaticPathSegment {
  LOGIN = 'login',
  PASSWORD_RESET = 'reset-password',
  REGISTER = 'register',
  VERIFY_USER_EMAIL = 'verify-user-email',
  HOME = 'home',
  ABOUT = 'about',
  GUIDE = 'guide',
  ORGANIZATION = 'organization',
  APIARY = 'apiary',
  NEW_APIARIES = 'new-apiaries',
  BEEHIVE = 'beehive',
  ALARM = 'alarm',
  NOTE = 'note',
  OTHER_NOTE = 'otherNote',
  ALL_NOTES = 'list',
  PROFILE = 'profile',
  MY_PROFILE = 'my-profile',
  ADMIN = 'admin',
  STATIC_ORG = 'static-org',
  NEW = 'new',
  EDIT = 'edit',
  SEARCH = 'search',
  ORGANIZATION_MEMBER = 'organizationMember',
  CATEGORY = 'category',
  BEEHIVE_INSPECTION_NOTE = 'beehive-inspection-note'
}

export default StaticPathSegment
