import { Button, Container, Stack, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import ORGANIZATION_RULES from '../../utils/form/rules/organization'
import useFormDefaultValuesFromModel from '../../utils/hooks/formDefaultValuesFromModel'
import ErrorsList from '../common/Form/ErrorsList'
import FormGrid from '../common/Form/FormGrid'
import AddressField from '../common/Form/FormGrid/CommonRows/AddressField'
import CountryField from '../common/Form/FormGrid/CommonRows/CountryField'
import NameField from '../common/Form/FormGrid/CommonRows/NameField'
import TextField from '../common/Form/FormGrid/CommonRows/TextField'
import TownRow from '../common/Form/FormGrid/CommonRows/TownRow'
import BottomContentContainer from '../common/Layout/BottomContentContainer'
import ButtonListContainer from '../common/Layout/ButtonListContainer'
import LoadingButtonIcon from '../common/LoadingButtonIcon'
import PageBodyContainer from '../common/PageBodyContainer'

const COMPONENT_TRANSLATE_PREFIX = 'components.organization.organizationEditForm'

const FIELDS_TO_INHERIT_FROM_ORANIZATION = [
  'name',
  'description',
  'organizationNumber',
  'zipCode',
  'town',
  'street',
  'country'
]

const OrganizationEditForm = props => {
  const {
    initialState,
    onSubmit,
    pending
  } = props

  const { t } = useTranslation(undefined, { keyPrefix: COMPONENT_TRANSLATE_PREFIX })

  const { t: tGeneral } = useTranslation()

  const defaultValues = useFormDefaultValuesFromModel(initialState, FIELDS_TO_INHERIT_FROM_ORANIZATION)

  const methods = useForm({ defaultValues })

  const { handleSubmit, formState } = methods

  return (
    <Container maxWidth="sm">
      <Stack
        spacing={2}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
      >
        <PageBodyContainer>
          <Typography variant="h2">
            {t('title')}
          </Typography>
        </PageBodyContainer>

        <FormGrid
          translatePrefix={COMPONENT_TRANSLATE_PREFIX}
          rules={{ required: true }}
          methods={methods}
        >
          <NameField rules={ORGANIZATION_RULES.name}/>
          <TextField name="description" rules={ORGANIZATION_RULES.description}/>
          <TextField name="organizationNumber"/>
          <TownRow/>
          <AddressField name="street"/>
          <CountryField/>
        </FormGrid>

        <ErrorsList formState={formState}/>

        <BottomContentContainer>
          <ButtonListContainer>
            <Button
              variant="outlined"
              color="secondary"
              component={Link}
              to="./.."
            >
              {tGeneral('general.cancel')}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              type="submit"
            >
              {pending
                ? <LoadingButtonIcon/>
                : tGeneral('general.update')
                }
            </Button>
          </ButtonListContainer>
        </BottomContentContainer>
      </Stack>
    </Container>
  )
}

export default OrganizationEditForm
