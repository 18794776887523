import { useContext } from 'react'
import { useFormContext } from 'react-hook-form'
import FormGridContext from '../FormGridContext'
import PasswordField from './PasswordField'

const PasswordRepeatField = props => {
  const { rules, passwordName, ...rest } = props

  const { namePrefix } = useContext(FormGridContext)
  const { watch } = useFormContext()

  const passwordNameToWatch =
    `${(namePrefix && `${namePrefix}.`) ?? ''}${passwordName ?? 'password'}`

  return (
    <PasswordField
      name="passwordRepeat"
      translateKey="passwordRepeat"
      rules={{
        validate: value => value === watch(passwordNameToWatch) || 'notEqual',
        ...rules
      }}
      {...rest}
    />
  )
}

export default PasswordRepeatField
