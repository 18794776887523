/* eslint-disable camelcase */
import { useQuery, useMutation } from '@tanstack/react-query'
import { CallbackVoid } from '../util/types'
import { useApiClient } from './useApiClient'

export function useGetIoTDataQuery(beehiveId: string, sensorId: number) {
  const apiClient = useApiClient()

  return useQuery(['getIotData', beehiveId, sensorId], () => {
    return apiClient.iot.getIotData({
      beehiveId: beehiveId,
      sensor_id: sensorId
    })
  })
}
export function useGetSensorsLatestValQuery(thingId: string) {
  const apiClient = useApiClient()

  return useQuery(['getAllSensorsLatestVal', thingId], () => {
    return apiClient.iot.getAllSensorsLatestVal({
      thingId: thingId
    })
  })
}

export function useGetIoTDataDateRangeQuery(
  beehiveId: string,
  from: Date,
  to: Date,
  sensor_id: number
) {
  const apiClient = useApiClient()
  return useQuery(
    ['getAllSensorsLatestVal', beehiveId, from, to, sensor_id],
    () => {
      return apiClient.iot.getIotDataByDateRange({
        beehiveId: beehiveId,
        sensor_id: sensor_id,
        from: from.toString(),
        to: to.toString()
      })
    },
    { enabled: !!beehiveId && !!from && !!to && !!sensor_id }
  )
}

export function useSubscribeToNotificationMutation(onSuccess?: CallbackVoid) {
  const apiClient = useApiClient()
  return useMutation({
    mutationFn: apiClient.iot.subscribeToNotification,
    onSuccess: onSuccess
  })
}

/*  return useQuery({
    queryKey: ['getIotData', beehiveId, sensorId],
    queryFn: async () => {
      apiClient.iot.getIotData({
        beehiveId: beehiveId,
        sensor_id: sensorId
      })
    },
    enabled: !!beehiveId && !!sensorId
  }) */

export function useGetIotaDataQuery(beehiveId: string) {
  const apiClient = useApiClient()
  return useQuery(
    ['getIotaData', beehiveId],
    () => {
      return apiClient.iot.getIotaData({
        beehiveId: beehiveId
      })
    },
    { enabled: !!beehiveId }
  )
}
