import { Button, Stack, Theme, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import PeopleCommunityIcon from '../components/common/Icon/PeopleCommunity'
import PlusIcon from '../components/common/Icon/Plus'
import StaticBackground from '../components/common/StaticBackground'
import { AppRoutePaths } from '../utils/routes'
import NoteIcon from '../components/common/Icon/Note'
import LoadingPage from '../components/common/LoadingPage'
import PageBodyContainer from '../components/common/PageBodyContainer'
import { useUserOrganizationsQuery } from '../utils/api/hooks/organization'

const COMPONENT_TRANSLATE_PREFIX = 'pages.loggedInLoadingPage'

const titleStyle = (theme: Theme) => ({
  pt: theme.spacing(2),
  fontWeight: 'bold',
  overflow: 'hidden',
  WebkitLineClamp: '2',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  color: theme.palette.darkContrast.main,

  [theme.breakpoints.up('md')]: {
    width: '100%'
  }
})

const LoggedInLandingPage = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: COMPONENT_TRANSLATE_PREFIX
  })
  // const { t: tGeneral } = useTranslation()

  const userOrganizationsQuery = useUserOrganizationsQuery()

  const hasOrganizations = !!userOrganizationsQuery.query.data?.data?.total

  /*   useErrorStateSnackEffect(
    userOrganizationsQuery.query.error,
    tGeneral('general.error.general')
  ) */

  return userOrganizationsQuery.query.isLoading ? (
    <LoadingPage />
  ) : (
    <>
      <Stack className="pageRoot">
        <StaticBackground src="/images/generic-background.png" />

        <Stack
          className="pageContent noBG"
          spacing={2}
          sx={{ color: 'darkContrast.main' }}
        >
          <PageBodyContainer>
            <Typography variant="h1" sx={titleStyle}>
              {t('title')}
            </Typography>

            <Typography whiteSpace="pre-line">
              {hasOrganizations ? t('hasOrgDescription') : t('description')}
            </Typography>
          </PageBodyContainer>

          {hasOrganizations ? (
            <>
              <Button
                variant="contained"
                component={Link}
                to={AppRoutePaths.NEW_NOTE_PATH}
                startIcon={<NoteIcon />}
              >
                {t('addNote')}
              </Button>

              <Button
                variant="contained"
                component={Link}
                to={AppRoutePaths.LIST_ORGANIZATIONS_PATH}
                startIcon={<PeopleCommunityIcon />}
              >
                {t('listOrganizations')}
              </Button>
            </>
          ) : (
            <>
              <Button
                variant="contained"
                component={Link}
                to={AppRoutePaths.NEW_ORGANIZATION_PATH}
                startIcon={<PlusIcon />}
              >
                {t('createOrganization')}
              </Button>
              <Button
                variant="contained"
                component={Link}
                to={AppRoutePaths.SEARCH_ORGANIZATION_PATH}
                startIcon={<PeopleCommunityIcon />}
              >
                {t('joinOrganization')}
              </Button>
            </>
          )}
        </Stack>
      </Stack>
    </>
  )
}

export default LoggedInLandingPage
