import { useMemo, useRef, useState } from 'react'
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded'
import MagnifyerIcon from '../../common/Icon/Magnifyer'
import BeehiveNoteType from '../../../enums/Note/BeehiveInspectionType'
import CommonNoteType from '../../../enums/Note/CommonNoteType'
import { ListOtherNotes } from './Common/ListOtherNotes'
import { ListNoteTypeRenderProps } from './util'
import PageBodyContainer from '../../common/PageBodyContainer'
import { ListBeehiveInspectionNotes } from './Beehive/ListBeehiveInspectionNotes'
import { NoteQueryFilter } from '../../../models/notes'
import { NoteCategory } from '../../../models'

const COMPONENT_TRANSLATE_PREFIX = 'pages.note.listNotes'

const SELECTABLE_COMMON_NOTE_TYPES = [CommonNoteType.OTHER]
const SELECTABLE_BEEHIVE_NOTE_TYPES = [BeehiveNoteType.INSPECTION]

const DEFAULT_SELECTED_NOTE_TYPE = SELECTABLE_COMMON_NOTE_TYPES[0]

type NoteCateogryListComponentRenderFn = (
  props: ListNoteTypeRenderProps
) => JSX.Element

const InspectionTypeRenderFns: {
  [key: string]: NoteCateogryListComponentRenderFn
} = {
  [BeehiveNoteType.INSPECTION]: (props) => (
    <ListBeehiveInspectionNotes {...props} />
  ),
  [CommonNoteType.OTHER]: (props) => <ListOtherNotes {...props} />
}

type ListNotesProps = {
  categorySelectName: string
  entitySelectName: string
}

const ListNotesStep = (props: ListNotesProps) => {
  const { categorySelectName, entitySelectName } = props

  const [activeSearchFilter, setActiveSearchFilter] =
    useState<NoteQueryFilter | null>(null)

  const { t } = useTranslation(undefined, {
    keyPrefix: COMPONENT_TRANSLATE_PREFIX
  })

  const { t: tGlobal } = useTranslation()

  const { watch } = useFormContext()

  const componentInitializationDate = useMemo(() => new Date(), [])

  const initialStartDate = useMemo(
    () =>
      new Date(
        componentInitializationDate.getFullYear(),
        componentInitializationDate.getMonth(),
        componentInitializationDate.getDate() - 7
      ),
    [componentInitializationDate]
  )

  const initialEndDate = useMemo(
    () => new Date(componentInitializationDate),
    [componentInitializationDate]
  )

  const [startDate, setStartDate] = useState(initialStartDate)
  const [endDate, setEndDate] = useState(initialEndDate)

  const noteTypeSelectRef = useRef<HTMLInputElement | null>(null)

  const selectedCategory = watch(`${categorySelectName}.category`)
  const selectedOrganizationId = watch(`${entitySelectName}.organization.id`)
  const selectedApiaryId = watch(`${entitySelectName}.apiary.id`)
  const selectedBeehiveId = watch(`${entitySelectName}.beehive.id`)

  const selectableCategoryNoteTypes = useMemo(
    () =>
      selectedCategory === NoteCategory.BEEHIVE
        ? [...SELECTABLE_BEEHIVE_NOTE_TYPES, ...SELECTABLE_COMMON_NOTE_TYPES]
        : SELECTABLE_COMMON_NOTE_TYPES,
    [selectedCategory]
  )

  const getCurrSearchFilter = (): NoteQueryFilter => ({
    selectedCategory,
    organizationId: selectedOrganizationId,
    apiaryId: selectedApiaryId,
    beehiveId: selectedBeehiveId,
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString()
  })

  const initialSearchFilter = useMemo(getCurrSearchFilter, [])

  const updateSearchFilter = () => {
    setActiveSearchFilter(getCurrSearchFilter())
  }

  const noteRenderProps = useMemo(
    () => ({
      filter: activeSearchFilter ?? initialSearchFilter
    }),
    [initialSearchFilter, activeSearchFilter]
  )

  // Only update selected type when filter is updated. Prevents updates aside from clicking search btn.
  const selectedNoteType = useMemo(
    () => noteTypeSelectRef.current?.value ?? DEFAULT_SELECTED_NOTE_TYPE,
    [activeSearchFilter]
  )

  return (
    <>
      <Stack
        spacing={2}
        direction="row"
        alignItems={'center'}
        justifyContent="space-between"
      >
        <MobileDatePicker
          disableFuture
          label={t('label.fromDate')}
          value={startDate}
          inputFormat="yyyy-MM-dd"
          onChange={(date: Date | null) => {
            if (date !== null) {
              date.setHours(0, 0, 1)
              setStartDate(date)
            }
          }}
          renderInput={(params: any) => (
            <TextField {...params} style={{ width: '49%' }} />
          )}
        />

        <ArrowForwardIosRoundedIcon fontSize="inherit" />

        <MobileDatePicker
          disableFuture
          label={t('label.toDate')}
          value={endDate}
          inputFormat="yyyy-MM-dd"
          onChange={(date: Date | null) => {
            if (date !== null) {
              date.setHours(23, 59, 59)
              setEndDate(date)
            }
          }}
          renderInput={(params: any) => (
            <TextField {...params} style={{ width: '49%' }} />
          )}
        />
      </Stack>

      <FormControl>
        <InputLabel id="note-type-select-label">{t('type')}</InputLabel>
        <Select
          labelId="note-type-select-label"
          defaultValue={DEFAULT_SELECTED_NOTE_TYPE}
          input={
            <OutlinedInput inputRef={noteTypeSelectRef} label={t('type')} />
          }
        >
          {selectableCategoryNoteTypes.map((type) => (
            <MenuItem key={type} value={type}>
              {t('noteTypes.' + type.toString())}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Button
        variant="contained"
        onClick={updateSearchFilter}
        startIcon={<MagnifyerIcon />}
      >
        {tGlobal('general.search')}
      </Button>

      {InspectionTypeRenderFns[selectedNoteType] ? (
        InspectionTypeRenderFns[selectedNoteType](noteRenderProps)
      ) : (
        <PageBodyContainer>
          <Typography variant="h3">{t('header.noteTypeNotFound')}</Typography>
          <Typography>{t('description.noteTypeNotFound')}</Typography>
        </PageBodyContainer>
      )}
    </>
  )
}

export default ListNotesStep
