import VariablePathSegment from '../../../../enums/Path/VariablePathSegment'
import { BeehiveInspectionNoteResultDto } from '../../../../models/notes'
import { AppRoutePaths, AppRoutePathSegment } from '../../../../utils/routes'
import { ListNoteOverviews, NoteOverview } from '../ListNoteOverviews'
import { generateNoteOverview, ListNoteTypeRenderProps } from '../util'
import { useGetBeehiveInspectionNotesQuery } from '../../../../utils/api/hooks/inspectionNote'

const generateInspectionNoteOverview = (
  note: BeehiveInspectionNoteResultDto
): NoteOverview =>
  generateNoteOverview(
    note,
    AppRoutePathSegment.replaceSegments(
      AppRoutePaths.VIEW_BEEHIVE_INSPECTION_NOTE_PATH,
      [
        {
          name: VariablePathSegment.BEEHIVE_INSPECTION_NOTE_ID,
          value: note.id
        }
      ]
    )
  )

export const ListBeehiveInspectionNotes = (props: ListNoteTypeRenderProps) => {
  const { filter } = props

  return (
    <ListNoteOverviews
      queryResult={useGetBeehiveInspectionNotesQuery({
        selectedCategory: filter.selectedCategory,
        organizationId: filter.organizationId,
        apiaryId: filter?.apiaryId ? filter.apiaryId : null,
        beehiveId: filter!.beehiveId,
        startDate: filter!.startDate,
        endDate: filter!.endDate
      })}
      noteTransformFn={generateInspectionNoteOverview}
    />
  )
}
