/* eslint-disable */
enum BeehiveNoteType {
  INSPECTION = 'inspection',
  EVENT = 'event',
  REMINDER = 'reminder',
  PLANNING = 'planning',
  INVENTORY = 'inventory'
}

export default BeehiveNoteType
