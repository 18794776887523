import TextField from './TextField'

const PhoneField = props => {
  return (
    <TextField
      name="phone"
      translateKey="phone"
      translatePrefix="form"
      {...props}
    />
  )
}

export default PhoneField
