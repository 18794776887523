import { Button, Container, Stack, Typography } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import TitleImage from '../../components/common/TitleImage'
import { AppRoutePaths } from '../../utils/routes'
import { Link, useNavigate } from 'react-router-dom'
import PageBodyContainer from '../../components/common/PageBodyContainer'
import BottomContentContainer from '../../components/common/Layout/BottomContentContainer'
import ButtonListContainer from '../../components/common/Layout/ButtonListContainer'
import { useUpdateUserHasSeenStartupGuideutation } from '../../utils/api/hooks/user'
import AuthContext from '../../utils/api/AuthContext'

const COMPONENT_TRANSLATE_PREFIX = 'pages.guide.gettingStartedGuide'

const GUIDE_STEPS = [
  {
    name: 'pwaExample',
    img: '/images/gettingStarted/pwa-example.png'
  },
  {
    name: 'organizations',
    img: '/images/gettingStarted/organizations.png'
  },
  {
    name: 'digitalBeehives',
    img: '/images/gettingStarted/digital-beehives.png'
  },
  {
    name: 'notes',
    img: '/images/gettingStarted/notes.png'
  }
]

const GettingStartedGuide = () => {
  const [activeStep, setActiveStep] = useState(0)
  const { t: tGeneral } = useTranslation(undefined, { keyPrefix: 'general' })
  const { t } = useTranslation(undefined, {
    keyPrefix: COMPONENT_TRANSLATE_PREFIX
  })
  const navigate = useNavigate()
  const setHasSeenStartupGuideMutation =
    useUpdateUserHasSeenStartupGuideutation()

  const authContext = useContext(AuthContext)

  useEffect(() => {
    setHasSeenStartupGuideMutation.mutate()

    if (authContext.authState) {
      authContext.setAuthState({
        ...authContext.authState,
        hasSeenStartupGuide: true
      })
    }
  }, [])

  return (
    <Stack className="pageRoot">
      <TitleImage
        src="/images/generic-background.png"
        title={t('title')}
        stepsAmount={GUIDE_STEPS.length}
        activeStep={activeStep}
      />
      <Stack className="pageContent">
        <PageBodyContainer>
          <Typography variant="h2">
            {t(`${GUIDE_STEPS[activeStep].name}.heading`)}
          </Typography>

          <Typography variant="p2">
            {t(`${GUIDE_STEPS[activeStep].name}.content`)}
          </Typography>
        </PageBodyContainer>

        <Container maxWidth="sm">
          <Stack
            component="img"
            className="contained"
            src={`${GUIDE_STEPS[activeStep].img}`}
          />
        </Container>

        <BottomContentContainer>
          <ButtonListContainer>
            {activeStep < GUIDE_STEPS.length - 1 && (
              <Button
                fullWidth
                color="secondary"
                component={Link}
                to={AppRoutePaths.HOME_PATH}
                onClick={() => {
                  navigate(AppRoutePaths.HOME_PATH)
                }}
              >
                {tGeneral('skip')}
              </Button>
            )}

            {activeStep > 0 && (
              <Button
                color="secondary"
                onClick={() =>
                  setActiveStep((prevActiveStep) => prevActiveStep - 1)
                }
                variant="outlined"
                fullWidth
              >
                {tGeneral('back')}
              </Button>
            )}

            {activeStep !== GUIDE_STEPS.length - 1 ? (
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                onClick={() =>
                  setActiveStep((prevActiveStep) => prevActiveStep + 1)
                }
              >
                {tGeneral('next')}
              </Button>
            ) : (
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                onClick={() => {
                  navigate(AppRoutePaths.HOME_PATH)
                }}
              >
                {t('getStarted')}
              </Button>
            )}
          </ButtonListContainer>
        </BottomContentContainer>
      </Stack>
    </Stack>
  )
}

export default GettingStartedGuide
