import {
  Container
} from '@mui/material'
const Alarms = () => {
  return (
    <Container sx={{ pt: '80px', pb: '80px' }} maxWidth='sm'>
      future alarm page
    </Container>
  )
}

export default Alarms
