import { List, Stack, Typography } from '@mui/material'
import { Children } from 'react'

const SubList = props => {
  const {
    title,
    missingLabel,
    children
  } = props

  const childrenLength = Children.count(children)

  return (
    <Stack className="itemListSubList">
      <Typography fontWeight="bold">
        {title}
      </Typography>
      <List>
        {childrenLength
          ? children
          : <Typography>
              {missingLabel}
            </Typography>
        }
      </List>
    </Stack>
  )
}

export default SubList
