const passwordReset = {
  title: 'Återställ Lösenord',
  stepTitle: {
    emailSubmit: {
      title: 'Ange E-post',
      description:
        'Ange E-posten för det konto du vill återställa lösenordet för. Ett mail kommer att skickas med en kod som används för att återställa kontot.'
    },
    passwordSubmit: {
      title: 'Byt lösenord',
      description:
        'Ange koden som skickats till kontots E-post och ett nytt lösenord för kontot.'
    }
  },
  actions: {
    resetInitiate: {
      success: 'Ett kod har skickats via mail till kontots E-post adress',
      error: {
        badRequest: 'Felaktig epost',
        tooManyRequests: 'För många försök, vänta en stund och försök igen.'
      }
    },
    resetSubmit: {
      pending: 'Återställer lösenord',
      success: 'Lösenordet har återställts',
      error: {
        unauthorized: 'Felaktig verifikationskod',
        badRequest:
          'Lösenordet måste innehålla minst en stor bokstav och ett specialtecken'
      }
    }
  }
}

export default passwordReset
