import TextField from './TextField'

const MunicipalityField = props => {
  return (
    <TextField
      name="municipality"
      translateKey="municipality"
      translatePrefix="form"
      {...props}
    />
  )
}

export default MunicipalityField
