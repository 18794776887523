const sideMenu = {
  link: {
    organisations: 'Organizations',
    apiaries: 'Apiaries',
    beehives: 'Beehives',
    notes: 'Notes',
    alarms: 'Alarms',
    profile: 'My Profile',
    signIn: 'Sign In',
    register: 'Register',
    logOut: 'Log Out',
    reports: 'Reports',
    about: 'About Beelab'
  },

  actions: {
    logOut: {
      pending: 'Logging out...',
      fulfilled: 'You have been logged out'
    }
  }
}

export default sideMenu
