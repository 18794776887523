import AutocompleteField from './AutocompleteField'

const EnumAutoCompleteField = props => {
  const {
    optionsEnum,
    enumTranslateKey,
    ...rest
  } = props

  return (
    <AutocompleteField
      getOptionKey={val => val}
      translateOptions
      optionTranslatePrefix={`general.${enumTranslateKey}`}
      options={Object.values(optionsEnum)}
      {...rest}
    />
  )
}

export default EnumAutoCompleteField
