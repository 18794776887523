const landingPage = {
  title: 'Välkommen!',
  description:
    'Logga in eller skapa ett konto för att hänga med när vi digitaliserar Sveriges bin. Det här är en gratis webb-app för att skapa manuella anteckningar om dina bigårdar och bisamhällen, eller för att ansluta digitalt sensormonitorerade bikupor.',

  signIn: 'Logga in',
  register: 'Registrera nytt konto'
}

export default landingPage
