import displayOrganization from './displayOrganization'
import listOrganizations from './listOrganizations'
import newOrganization from './newOrganization'
import searchOrganization from './searchOrganization'
import editOrganization from './editOrganization'
import editOrganizationMember from './editOrganizationMember'

const organization = {
  newOrganization,
  listOrganizations,
  displayOrganization,
  searchOrganization,
  editOrganization,
  editOrganizationMember
}

export default organization
