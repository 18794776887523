const form = {
  fields: {
    name: {
      label: 'Namn',
      placeHolder: 'Min organisation',
      errors: {
        required: 'Namn krävs',
        minLength: 'Namnet måste innehålla minst {{minLength}} bokstäver',
        maxLength: 'Namnet får inte innehålla mer än {{maxLength}} bokstäver'
      }
    },
    code: {
      errors: {
        required: 'Kod krävs'
      }
    },
    email: {
      label: 'E-post',
      errors: {
        required: 'E-post krävs',
        pattern: 'Ej giltig E-post'
      }
    },
    password: {
      label: 'Lösenord',
      errors: {
        required: 'Lösenord krävs'
      }
    },
    passwordRepeat: {
      label: 'Repetera lösenord',
      errors: {
        required: 'Repetera lösenord',
        notEqual: 'Lösenorden matchar inte'
      }
    },
    street: {
      label: 'Adress',
      placeHolder: '',
      errors: {
        required: 'Adress krävs',
        minLength: 'Adressen måste innehålla minst {{minLength}} bokstäver',
        maxLength: 'Adressen får inte innehålla mer än {{maxLength}} bokstäver'
      }
    },
    beehive: {
      label: 'Bikupa',
      placeHolder: '',
      noOptions: 'Bikupor saknas',
      errors: {
        required: 'Bikupa krävs'
      }
    },
    apiary: {
      label: 'Bigård',
      placeHolder: '',
      noOptions: 'Bigårdar saknas',
      errors: {
        required: 'Bigård krävs'
      }
    },
    organization: {
      label: 'Organisation',
      placeHolder: '',
      noOptions: 'Organisationer saknas',
      errors: {
        required: 'Organisation krävs'
      }
    },
    town: {
      label: 'Stad',
      placeHolder: '',
      errors: {
        required: 'Stad krävs'
      }
    },
    zipCode: {
      label: 'Postnummer',
      placeHolder: '12345',
      errors: {
        required: 'Postnummer krävs',
        minLength: 'Postnumret måste innehålla {{minLength}} siffror',
        maxLength: 'Postnumret måste innehålla {{maxLength}} siffror',
        pattern: 'Postnumret får endast innehålla siffror'
      }
    },
    country: {
      label: 'Land',
      placeholder: 'Välj land',
      errors: {
        required: 'Land krävs'
      }
    },
    phone: {
      label: 'Telefonnummer',
      errors: {
        required: 'Telefonnummer krävs'
      }
    },
    freeText: {
      label: 'Fritext'
    },
    radioField: {
      option: {
        other: 'Annat'
      },
      otherFreeTextLabel: 'Fritext'
    },
    date: {
      label: 'Datum',
      errors: {
        required: 'Datum krävs'
      }
    },
    time: {
      label: 'Klockslag',
      errors: {
        required: 'Klockslag krävs'
      }
    },
    coordinates: {
      label: 'Koordinater',
      placeholder: 'Ex: 56.6608, 16.3628',
      errors: {
        required: 'Koordinater krävs',
        pattern: 'Fel format på koordinater'
      }
    },
    other: {
      label: 'Övrigt'
    },

    municipality: {
      label: 'Kommun',
      errors: {
        required: 'Kommun krävs'
      }
    }
  }
}

export default form
