const filterDialog = {
  description: 'Choose different options to create a filter.',
  save: 'Save',
  startdate: 'Start date',
  enddate: 'End date',
  time: 'Time interval',
  section: {
    filter: 'Filter notes',
    time: 'Time interval'
  },
  fields: {
    category: {
      _checkbox: {
        label: 'Categories'
      }
    }
  }
}

export default filterDialog
