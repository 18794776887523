import AutocompleteField from './AutocompleteField'

const OrganizationSelectField = props => {
  const { organizations, ...rest } = props

  return (
    <AutocompleteField
      name="organization"
      translateKey="organization"
      translatePrefix="form"
      options={organizations}
      customNoOptionsText
      getOptionLabel={o => o?.name || ''}
      optionKeyById
      {...rest}
    />
  )
}

export default OrganizationSelectField
