import about from './about'
import admin from './admin'
import apiary from './apiary'
import auth from './auth'
import note from './note'
import organization from './organization'
import pageNotFound from './pageNotFound'
import user from './user'
import guide from './guide'
import beehive from './beehive'
import loggedInLoadingPage from './loggedInLandingPage'

const pages = {
  organization,
  admin,
  apiary,
  auth,
  pageNotFound,
  user,
  about,
  note,
  guide,
  beehive,
  loggedInLoadingPage
}

export default pages
