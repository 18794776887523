import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  LOCAL_STORAGE_JWT_TOKEN_KEY,
  UNAUTHORIZED_EVENT_TYPE
} from './apiClient/apiFetcher'
import AuthContext, { AuthState } from './AuthContext'

type AuthContextProviderProps = {
  children: JSX.Element | JSX.Element[]
}

const LOCAL_STORAGE_AUTH_STATE_KEY = 'api-auth-state'

const AuthContextProvider: React.FC<AuthContextProviderProps> = (props) => {
  const initialAuthState = useMemo(() => {
    const rawInitialState = localStorage.getItem(LOCAL_STORAGE_AUTH_STATE_KEY)

    if (rawInitialState) {
      try {
        return JSON.parse(rawInitialState) as AuthState
      } catch (err) {
        localStorage.removeItem(LOCAL_STORAGE_AUTH_STATE_KEY)
      }
    }

    return null
  }, [])

  const [authState, setAuthState] = useState<AuthState | null>(initialAuthState)

  const contextSetAuthState = useCallback((state: AuthState | null) => {
    setAuthState(state)

    if (state) {
      localStorage.setItem(LOCAL_STORAGE_AUTH_STATE_KEY, JSON.stringify(state))
      localStorage.setItem(LOCAL_STORAGE_JWT_TOKEN_KEY, state.sessionJwtToken)
    } else {
      localStorage.removeItem(LOCAL_STORAGE_AUTH_STATE_KEY)
      localStorage.removeItem(LOCAL_STORAGE_JWT_TOKEN_KEY)
    }
  }, [])

  const contextClearAuthState = useCallback(() => contextSetAuthState(null), [])

  useEffect(() => {
    addEventListener(UNAUTHORIZED_EVENT_TYPE, contextClearAuthState)

    return () => {
      removeEventListener(UNAUTHORIZED_EVENT_TYPE, contextClearAuthState)
    }
  }, [contextClearAuthState])

  const authContextValue = useMemo(
    () => ({
      authState,
      setAuthState: contextSetAuthState,
      clearAuthState: contextClearAuthState
    }),
    [authState, contextSetAuthState, contextClearAuthState]
  )

  return <AuthContext.Provider value={authContextValue} {...props} />
}

export default AuthContextProvider
