import apiFetcher from '../apiFetcher'

export const updateMemberRole = apiFetcher
  .path('/organizationMember/updateRole/{orgId}/{id}')
  .method('put')
  .create()

export const deleteMember = apiFetcher
  .path('/organizationMember/delete/{orgId}/{id}')
  .method('delete')
  .create()
