const pageNotFound = {
  title: '404',

  infoTitle: 'Oops',
  descriptionInfo: 'It seems like you got a little lost.',
  descriptionManual:
    'Unfortunately, there is nothing to see here, click one of the buttons below to find more exciting places!',
  goToLandingPage: 'Go to landing page',
  goToInfo: 'Learn more about beelab'
}

export default pageNotFound
