export enum SensorType {
  TEMP_BOT = 'TEMP_BOT',
  TEMP_TOP = 'TEMP_TOP',
  TEMP_OUT = 'TEMP_OUT',
  HUMIDITY = 'HUMIDITY',
  PRESSURE = 'PRESSURE',
  WEIGHT = 'WEIGHT'
}

export enum OrganizationMemberRole {
  ADMIN = 'ADMIN',
  MAINTAINER = 'MAINTAINER',
  READER = 'READER'
}

export enum BeeBehaviorType {
  CALM = 'CALM',
  BETWEEN = 'BETWEEN',
  ANGRY = 'ANGRY'
}

export enum StaticOrganizationType {
  ASSOCIATION = 'ASSOCIATION',
  DISTRICT = 'DISTRICT',
  NATION = 'NATION'
}

export enum BeehiveType {
  HIKE = 'HIKE',
  STATIONARY = 'STATIONARY',
  TRAY = 'TRAY',
  LAY_OFF = 'LAY_OFF',
  STRAW = 'STRAW',
  LOG = 'LOG',
  HOUSE = 'HOUSE',
  TREE = 'TREE',
  MATING = 'MATING',
  OBSERVATION = 'OBSERVATION',
  TOREBODA = 'TOREBODA',
  NACKA = 'NACKA',
  OSTERVALA = 'OSTERVALA',
  HJO = 'HJO',
  HULT = 'HULT',
  MULTI = 'MULTI',
  REA_DAN = 'REA_DAN',
  HUDDINGE = 'HUDDINGE',
  SMALAND = 'SMALAND',
  PARADISE = 'PARADISE',
  SKALDERHUS = 'SKALDERHUS',
  OTHER = 'OTHER'
}

export enum BeeType {
  YELLOW_ITALIAN = 'YELLOW_ITALIAN',
  GRAY_KRANIUM = 'GRAY_KRANIUM',
  DARK_NORDIC = 'DARK_NORDIC',
  BUCKFAST = 'BUCKFAST',
  ELGON = 'ELGON',
  UNKKNWON = 'UNKKNWON'
}

export enum BeeQueenColor {
  WHITE = 'WHITE',
  YELLOW = 'YELLOW',
  RED = 'RED',
  GREEN = 'GREEN',
  BLUE = 'BLUE'
}

export enum BeehiveFrameType {
  LOW_NORMAL = 'LOW_NORMAL',
  SVEA = 'SVEA',
  SVEA_HALF = 'SVEA_HALF',
  LS = 'LS',
  LS_HALF = 'LS_HALF',
  NORWEGIAN = 'NORWEGIAN',
  NORWEGIAN_HALF = 'NORWEGIAN_HALF',
  WIESLANDER = 'WIESLANDER',
  DADANT = 'DADANT',
  LANGSTROTH = 'LANGSTROTH',
  LANGSTROTH_3_4 = 'LANGSTROTH_3_4'
}

export enum BeehiveSocietyStrength {
  STRONG = 'STRONG',
  OK = 'OK',
  WEAK = 'WEAK'
}

export enum BeehiveSocietyBehavior {
  CALM = 'CALM',
  OK = 'OK',
  ANGRY = 'ANGRY'
}

export enum BeehiveInspectionQueenControl {
  SEEN_EXISTING_QUEEN = 'SEEN_EXISTING_QUEEN',
  SEEN_NEW_QUEEN = 'SEEN_NEW_QUEEN',
  SEEMS_QUEENLESS = 'SEEMS_QUEENLESS'
}

export enum BeehiveSickness {
  NOSEMA = 'NOSEMA',
  VARROAMITE = 'VARROAMITE',
  AMERICANFRYROT = 'AMERICANFRYROT',
  SOOT = 'SOOT',
  KUPSALWETHER = 'KUPSALWETHER',
  TRAKEWETHER = 'TRAKEWETHER',
  TROPILAELAPWETHER = 'TROPILAELAPWETHER'
}

export enum NoteCategory {
  BEEHIVE = 'BEEHIVE',
  APIARY = 'APIARY',
  ORGANIZATION = 'ORGANIZATION'
}

export enum BeehiveWildlifeAttack {
  BADGER = 'BADGER',
  MARTEN = 'MARTEN',
  BEAR = 'BEAR',
  BIRD = 'BIRD',
  ANT = 'ANT'
}
