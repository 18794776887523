import {
  Button,
  Stack,
  TextField,
  TableRow,
  TableCell,
  TableBody
} from '@mui/material'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch
} from 'react-hook-form'
import { v4 as uuid } from 'uuid'
import AddApiaries from './AddApiaries'
import { useTranslation } from 'react-i18next'

import ApiariesLocationSelect from '../../../../Map/ApiaryLocationSelect'
import ItemList from '../../../ItemList'

import TrashCanIcon from '../../../Icon/TrashCan'
import LocationIcon from '../../../Icon/Location'
import ItemListActionButtons, {
  GenericActionButtonConfigArray
} from '../../../ItemList/Row/ItemListActionButtons'
import {
  APIARIES_FORM_NAME,
  ApiaryData,
  BeehiveData,
  BEEHIVES_FORM_NAME,
  CreateApiariesForm
} from '../types'

const COMPONENT_TRANSLATE_PREFIX =
  'components.forms.organizationGeneration.apiariesOptions'

export const getFormApiariesToDisplay = (form: CreateApiariesForm) =>
  form[APIARIES_FORM_NAME].apiaries.map(({ location, ...apiary }) => ({
    ...apiary,
    locationLat: location?.lat,
    locationLng: location?.lng,
    id: apiary.sortId,
    name: apiary.name || apiary.defaultName,
    beehives: form[BEEHIVES_FORM_NAME].beehives
      .filter((beehive) => beehive.apiaryId === apiary.sortId)
      .sort((a, b) => a.apiarySortIndex - b.apiarySortIndex)
      .map((beehive) => ({
        ...beehive,
        id: `${beehive.apiaryId}-${beehive.apiarySortIndex}`,
        name: beehive.name || beehive.defaultName
      }))
  }))

export const transformApiariesToApiInputs = (
  apiaries: ApiaryData[],
  beehives: BeehiveData[]
) =>
  apiaries.map((apiary) => ({
    name: apiary.name.length ? apiary.name : apiary.defaultName,
    locationLat: apiary.location?.lat,
    locationLng: apiary.location?.lng,
    beehives: beehives
      .filter((b) => b.apiaryId === apiary.sortId)
      .sort((b1, b2) => b1.apiarySortIndex - b2.apiarySortIndex)
      .map((b) => ({ name: b.name || b.defaultName }))
  }))

type ApiariesOptionsProps = { required?: boolean }

const ApiariesOptions = (props: ApiariesOptionsProps) => {
  const { required } = props

  const { t } = useTranslation(undefined, {
    keyPrefix: COMPONENT_TRANSLATE_PREFIX
  })

  const methods = useFormContext<CreateApiariesForm>()
  const { control, setError } = methods

  const apiariesListName = 'apiaries.apiaries'

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'apiaries.apiaries'
  })

  const apiaries = useWatch({
    control,
    name: apiariesListName
  })

  useEffect(() => {
    if (required && !apiaries?.length) {
      setError('apiaries.apiaries', {
        type: 'required',
        message: t('fields.apiaries.errors.required')
      })
    }
  }, [required, apiaries?.length])

  const deleteApiary = useCallback(
    (index: number) => {
      for (let i = index + 1; i < fields.length; i++) {
        update(i, {
          ...apiaries[i],
          defaultName: t('apiaryDefaultName', { index: i })
        })
      }
      remove(index)
    },
    [update, remove, fields]
  )

  const apiariesRowActions = useMemo<GenericActionButtonConfigArray<undefined>>(
    () => [
      {
        perform: ({ rowIndex }) => deleteApiary(rowIndex),
        icon: <TrashCanIcon />,
        color: 'error'
      }
    ],
    [deleteApiary]
  )

  const [isEditingLocations, setIsEditingLocations] = useState(false)

  const addApiaries = ({
    apiariesAmount,
    beehivesAmount
  }: {
    apiariesAmount: number
    beehivesAmount: number
  }) => {
    append(
      [...Array(apiariesAmount).fill(null)]
        .map((_, i) => i + fields.length)
        .map(() => ({
          id: uuid(),
          sortId: uuid(),
          name: '',
          defaultName: t('apiaryDefaultName', ''),
          beehivesAmount: beehivesAmount,
          location: null
        }))
    )
  }

  const onLocationsConfirm = (
    apiaryLocations: {
      apiary: ApiaryData
      location: { lat: number; lng: number }
    }[]
  ) => {
    apiaryLocations.forEach(({ apiary, location }) => {
      const fieldIndexToUpdate = fields.findIndex(
        (f) => f.sortId === apiary.sortId
      )

      update(fieldIndexToUpdate, {
        ...apiaries[fieldIndexToUpdate],
        location
      })
    })

    setIsEditingLocations(false)
  }

  return isEditingLocations ? (
    <ApiariesLocationSelect
      apiaries={apiaries}
      onComplete={onLocationsConfirm}
      onCancel={() => setIsEditingLocations(false)}
    />
  ) : (
    <Stack spacing={2}>
      <AddApiaries addApiaries={addApiaries} namePrefix={APIARIES_FORM_NAME} />

      {fields?.length > 0 && (
        <ItemList>
          <TableBody>
            {fields.map((field, rowIndex) => (
              <TableRow key={field.id}>
                <TableCell>
                  <Controller
                    name={`${apiariesListName}.${rowIndex}.name`}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        label={t('apiariesList.header.name')}
                        placeholder={field.defaultName}
                        variant="outlined"
                        value={value}
                        onChange={onChange}
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                      />
                    )}
                  />
                </TableCell>
                <TableCell>
                  <Controller
                    name={`${apiariesListName}.${rowIndex}.beehivesAmount`}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        label={t('apiariesList.header.beehivesAmount')}
                        variant="outlined"
                        value={value >= 0 ? +value : 0}
                        onChange={onChange}
                        type="number"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                      />
                    )}
                  />
                </TableCell>
                <ItemListActionButtons
                  index={rowIndex}
                  actions={apiariesRowActions}
                  data={undefined}
                />
              </TableRow>
            ))}
          </TableBody>
        </ItemList>
      )}

      <Stack>
        <Button
          variant="outlined"
          fullWidth
          color="secondary"
          onClick={() => setIsEditingLocations(true)}
          startIcon={<LocationIcon />}
          disabled={!fields?.length}
        >
          {t('chooseApiariesLocations')}
        </Button>
      </Stack>
    </Stack>
  )
}

export default ApiariesOptions
