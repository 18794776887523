const BeehivePlanningForm = {
  date: 'Datum',
  chooseDate: 'Välj ett datum',
  fields: {
    reminder: {
      _checkbox: {
        label: 'Sätt på en påminnelse'
      },
      value: {
        label: ''
      }
    },
    winterFed: {
      _checkbox: {
        label: 'Vinterfodra'
      }
    },
    addBoxes: {
      _checkbox: {
        label: 'Sätta på skattlådor'
      },
      value: {
        label: 'Antal skattlådor'
      }
    },
    springInspection: {
      _checkbox: {
        label: 'Vårinspektion'
      }
    },
    boxInspection: {
      _checkbox: {
        label: 'Inspektera skattlådor'
      }
    },
    findQueen: {
      _checkbox: {
        label: 'Leta efter drottningen'
      }
    },
    findEggs: {
      _checkbox: {
        label: 'Leta efter lagda ägg/yngel'
      }
    },
    wingCut: {
      _checkbox: {
        label: 'Skära bort drönararmar'
      }
    },
    newEggs: {
      _checkbox: {
        label: 'Tillsätt ramar med yngel'
      }
    },
    other: {
      _checkbox: {
        label: 'Övrigt'
      }
    }
  }
}

export default BeehivePlanningForm
