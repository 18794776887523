import { useState } from 'react'
import { Stack, Tab, Tabs } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import TitleImage from '../../components/common/TitleImage'
import LoadingPage from '../../components/common/LoadingPage'
import TabPanel from '../../components/common/TabPanel'
import { useErrorStateSnackEffect } from '../../utils/hooks/stateSnackEffect'
import BeehivesTab from '../../components/apiaries/ViewApiary/Tabs/BeehivesTab'
import ApiaryInfoTab from '../../components/apiaries/ViewApiary/Tabs/ApiaryInfoTab'
import { useGetApiaryQuery } from '../../utils/api/hooks/apiary'
import { Apiary } from '../../models/apiary'
import { isNotFoundError } from '../../utils/api/util/isNotFoundError'
import PageNotFound from '../PageNotFound'
import { useOrganizationQuery } from '../../utils/api/hooks/organization'

const COMPONENT_TRANSLATE_PREFIX = 'pages.apiary.viewApiary'

const COMPONENT_TABS = [
  {
    name: 'beehives',
    render: (apiary: Apiary) => <BeehivesTab apiary={apiary} />
  },
  {
    name: 'info',
    render: (apiary: Apiary) => <ApiaryInfoTab apiary={apiary} />
  }
]

const ViewApiary = () => {
  const { apiaryId, organizationId } = useParams()
  const { t } = useTranslation(undefined, {
    keyPrefix: COMPONENT_TRANSLATE_PREFIX
  })
  const { t: tGlobal } = useTranslation()
  const [openTabIndex, setOpenTabIndex] = useState(0)

  const organizationQuery = useOrganizationQuery(organizationId)
  const organization = organizationQuery.data?.data

  const apiaryQuery = useGetApiaryQuery(apiaryId!)
  const apiary = apiaryQuery.data?.data
  useErrorStateSnackEffect(apiaryQuery.isError, t('error.loadingApiary'))

  const pagePending = apiaryQuery.isLoading
  const anyTabRenderPropUndefined = !apiary

  if (isNotFoundError(apiaryQuery.error)) {
    return <PageNotFound />
  }

  return (
    <Stack className="pageRoot">
      <TitleImage
        src="/images/generic-background.png"
        title={organization ? organization.name : tGlobal('general.pending')}
        description={
          organization ? organization.description : tGlobal('general.pending')
        }
      />

      <Stack className="pageContent" sx={{ p: 0 }}>
        <Tabs
          value={openTabIndex}
          onChange={(...[, val]) => setOpenTabIndex(val)}
          variant="fullWidth"
          color="secondary"
        >
          {COMPONENT_TABS.map(({ name }, index) => (
            <Tab key={index} label={t(`tabs.${name}`)} />
          ))}
        </Tabs>
        {!apiary || pagePending ? (
          <LoadingPage />
        ) : anyTabRenderPropUndefined ? (
          <PageNotFound />
        ) : (
          COMPONENT_TABS.map(({ render }, index) => (
            <TabPanel key={index} index={index} value={openTabIndex}>
              {render(apiary)}
            </TabPanel>
          ))
        )}
      </Stack>
    </Stack>
  )
}

export default ViewApiary
