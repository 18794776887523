const landingPage = {
  title: 'Welcome!',
  description:
    'Log in or create an account to keep up as we digitize beehives. This is a free web app to create notes about your apiaries and bee communities, or to connect digitally sensor-monitored beehives.',

  signIn: 'Sign In',
  register: 'Register New Account'
}

export default landingPage
