import {
  Collapse,
  Fade,
  IconButton,
  IconButtonProps,
  Stack,
  TableCell
} from '@mui/material'
import { Box } from '@mui/system'
import { useCallback, useContext } from 'react'
import ItemListContext from '../ItemListContext'

import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined'
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined'
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined'
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined'
import { Link } from 'react-router-dom'

type IconButtonOnClickHandler = Required<
  Parameters<typeof IconButton>[0]
>['onClick']

type IconButtonOnClickEvent = Parameters<IconButtonOnClickHandler>[0]

export type ItemListRowActionFunctionProps<DataType> = {
  rowIndex: number
  data: DataType
}

export type FunctionLessItemListActionButtonConfig = {
  color?: IconButtonProps['color']
  icon: JSX.Element
}

export type FunctionBasedItemListActionButtonConfig<DataType> =
  FunctionLessItemListActionButtonConfig & {
    perform?: (props: ItemListRowActionFunctionProps<DataType>) => void
  }

export type LinkBasedItemListActionButtonConfig<DataType> =
  FunctionLessItemListActionButtonConfig & {
    getLink: (props: ItemListRowActionFunctionProps<DataType>) => string
  }

export type GenericActionButtonConfigArray<DataType> = (
  | FunctionBasedItemListActionButtonConfig<DataType>
  | LinkBasedItemListActionButtonConfig<DataType>
)[]

export type ItemListActionButtonsProps<DataType> = {
  index: number
  data: DataType
  actions?: GenericActionButtonConfigArray<DataType>
}

function ItemListActionButtons<DataType = undefined>(
  props: ItemListActionButtonsProps<DataType>
) {
  const { index: rowIndex, data, actions } = props

  const { openIndex, setOpenIndex, expandable } = useContext(ItemListContext)

  if (rowIndex === undefined || rowIndex === null) {
    throw new Error(
      "Input: 'index' is required for component ItemListActionButtons"
    )
  }

  const isOpen = rowIndex === openIndex

  const toggleOpen = useCallback(() => {
    isOpen ? setOpenIndex(-1) : setOpenIndex(rowIndex)
  }, [isOpen, setOpenIndex])

  const onActionClick = useCallback(
    (
      evt: IconButtonOnClickEvent,
      action: FunctionBasedItemListActionButtonConfig<DataType>
    ) => {
      action.perform && action.perform({ rowIndex, data })
      toggleOpen()

      // Do this to avoid re-focus on dialog functionalitities
      const target = evt.currentTarget as HTMLButtonElement
      target.blur()
    },
    [toggleOpen]
  )

  return (
    <>
      <TableCell className="itemListActionButtons">
        <Collapse
          orientation="horizontal"
          className="itemListActionButtonsCollapse"
          in={expandable || isOpen}
          collapsedSize={'100%'}
        >
          <Stack
            direction="row"
            spacing={2}
            className="itemListActionButtonsStack"
          >
            {expandable ? (
              <IconButton color="secondary" onClick={toggleOpen}>
                {isOpen ? (
                  <KeyboardArrowUpOutlinedIcon />
                ) : (
                  <KeyboardArrowDownOutlinedIcon />
                )}
              </IconButton>
            ) : (
              <IconButton color="secondary" onClick={toggleOpen}>
                {isOpen ? (
                  <ChevronRightOutlinedIcon />
                ) : (
                  <ChevronLeftOutlinedIcon />
                )}
              </IconButton>
            )}

            {actions &&
              actions.map((action, index) =>
                (action as LinkBasedItemListActionButtonConfig<DataType>)
                  ?.getLink ? (
                  <IconButton
                    key={index}
                    disabled={!isOpen}
                    color={action.color || 'secondary'}
                    component={Link}
                    to={(
                      action as LinkBasedItemListActionButtonConfig<DataType>
                    ).getLink({ rowIndex, data })}
                  >
                    {action.icon}
                  </IconButton>
                ) : (
                  <IconButton
                    key={index}
                    disabled={!isOpen}
                    color={action.color || 'secondary'}
                    onClick={(evt) =>
                      onActionClick(
                        evt,
                        action as FunctionBasedItemListActionButtonConfig<DataType>
                      )
                    }
                  >
                    {action.icon}
                  </IconButton>
                )
              )}
          </Stack>
        </Collapse>
      </TableCell>

      {!expandable && (
        <TableCell className="itemListContentOverlayTableCell">
          <Fade in={!expandable && isOpen}>
            <Box onClick={toggleOpen} className="itemListContentOverlay" />
          </Fade>
        </TableCell>
      )}
    </>
  )
}

export default ItemListActionButtons
