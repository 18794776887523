import { Grid } from '@mui/material'
import { Box } from '@mui/system'
import { useTranslation } from 'react-i18next'
import InfoGridText from './InfoGridText'

const InformationList = props => {
  const {
    rows,
    data,
    translatePrefix,
    fieldProps,
    ...rest
  } = props

  const { t } = useTranslation(undefined, { keyPrefix: translatePrefix })

  return (
    <Box>
      <Grid container spacing={2} {...rest}>
        {rows && rows.map((row, rowIndex) => (
          <InfoGridText
            key={rowIndex}
            xs={12}
            header={`${t(row.labelKey ?? row.key)} ${row.showAsterix ? '*' : ''}`}
            body={row.render
              ? row.render(data)
              : data && data[row.key]
            }
            {...fieldProps}
          />
        ))}
      </Grid>
    </Box>
  )
}

export default InformationList
