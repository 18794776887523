const newNotePage = {
  title: 'New Note',
  sections: {
    main: {
      header: 'Choose Note Type',
      inspection: 'Inspection',
      freeText: 'Free Text'
    },
    apiary: {
      header: 'Choose Apiary',
      organizationDropdown: 'Organization',
      apiaryDropdown: 'Apiary...'
    }
  },
  actions: {
    submit: {
      pending: 'Saving Note',
      fulfilled: 'Note has been saved',
      error: 'An error occurred while saving the note',
      add: 'Add Picture'
    }
  },
  stepTitle: {
    category: {
      title: 'Choose Category',
      description: 'Select what the category is for'
    },
    entity: {
      BEEHIVE: {
        title: 'Beehive',
        description: 'Select the beehive for which you want to create the note'
      },
      APIARY: {
        title: 'Apiary',
        description: 'Select the apiary for which you want to create the note'
      },
      ORGANIZATION: {
        title: 'Organization',
        description:
          'Select the organization for which you want to create the note'
      }
    },
    type: {
      title: 'Choose Note Type',
      description: 'Select the type of note you want to create'
    },
    details: {
      common: {
        other: {
          title: 'Other'
        }
      },
      BEEHIVE: {
        inspection: {
          title: 'Inspection'
        },
        event: {
          title: 'Event'
        },
        reminder: {
          title: 'Reminder'
        },
        inventory: {
          title: 'Inventory'
        },
        planning: {
          title: 'Planning'
        }
      }
    }
  }
}

export default newNotePage
