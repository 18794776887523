const apiariesOptions = {
  addApiaries: {
    title: 'Add more apiaries',
    fields: {
      apiariesAmount: {
        label: 'Apiaries'
      },
      beehivesAmount: {
        label: 'Beehives'
      }
    }
  },
  apiaryDefaultName: 'Apiary',
  apiariesList: {
    header: {
      name: 'Name',
      beehivesAmount: 'Beehives'
    }
  },
  chooseApiariesLocations: 'Place the apiaries on a map'
}

export default apiariesOptions
