import AutocompleteField from './AutocompleteField'

const BeehiveSelectField = props => {
  const { beehives, ...rest } = props

  return (
    <AutocompleteField
      name="beehive"
      translateKey="beehive"
      translatePrefix="form"
      options={beehives}
      customNoOptionsText
      getOptionLabel={o => o?.name || ''}
      optionKeyById
      {...rest}
    />
  )
}

export default BeehiveSelectField
