const sensorDataChart = {
  sensorType: {
    TEMP_BOT: 'Temperature lower part',
    TEMP_TOP: 'Temperature upper part',
    TEMP_OUT: 'Temperature outside',
    HUMIDITY: 'Humidity',
    PRESSURE: 'Pressure',
    WEIGHT: 'Weight'
  },
  error: {
    noData: 'No data to display'
  }
}

export default sensorDataChart
