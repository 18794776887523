import { NoteQueryFilter } from '../../../models/notes'
import { useApiClient } from './useApiClient'
import { PaginationQuery, usePagination } from './pagination'
import { useQuery, useMutation } from '@tanstack/react-query'
import { CallbackVoid } from '../util/types'

export const CREATE_BEEHIVE_INSPECTION_NOTE_KEY = 'BeehiveInspectionNote/create'

export function useCreateBeehiveInspectionNoteMutation(
  onSuccess: CallbackVoid
) {
  const apiClient = useApiClient()

  return useMutation({
    mutationFn: apiClient.InspectionNote.createBeehiveInspectionNote,
    onSuccess: onSuccess
  })
}

export function useDeleteBeehiveInspectionNoteMutation(
  onSuccess: CallbackVoid
) {
  const apiClient = useApiClient()

  return useMutation({
    mutationFn: apiClient.InspectionNote.deleteBeehiveInspectionNoteById,
    onSuccess: onSuccess
  })
}

export const GET_BEEHIVE_INSPECTION_NOTES_LIST_KEY =
  'BeehiveInspectionNotesList/get'

export function useGetBeehiveInspectionNotesQuery(
  filterQuery: NoteQueryFilter,
  initialQuery?: PaginationQuery
) {
  const apiClient = useApiClient()

  const pagination = usePagination(initialQuery)

  return {
    query: useQuery(
      [GET_BEEHIVE_INSPECTION_NOTES_LIST_KEY, ...pagination.queryKeys],
      () => apiClient.InspectionNote.getBeehiveInspectionNotes(filterQuery),
      {
        enabled: !!filterQuery.beehiveId
      }
    ),
    pagination
  }
}

export const GET_BEEHIVE_INSP_NOTES_HURLED_LIST_KEY =
  'BeehiveInspNotesHurled/get'

export function useGetBeehiveInspNotesHurledQuery(
  filterQuery: NoteQueryFilter
) {
  const apiClient = useApiClient()

  return {
    query: useQuery(
      [GET_BEEHIVE_INSP_NOTES_HURLED_LIST_KEY],
      () =>
        apiClient.InspectionNote.getBeehiveInspNotesHurledAmount(filterQuery),
      {
        enabled: !!filterQuery.beehiveId
      }
    )
  }
}

export const GET_BEEHIVE_INSPECTION_NOTE_BY_ID_KEY = 'BeehiveInspectionNote/get'

export function useGetBeehiveInspectionNoteById(id: string) {
  const apiClient = useApiClient()

  return useQuery(
    [GET_BEEHIVE_INSPECTION_NOTE_BY_ID_KEY, id],
    () => {
      return apiClient.InspectionNote.getBeehiveInspectionNoteById({ id: id })
    },
    { enabled: !!id }
  )
}

export function usePutBeehiveInspectionNote(onSuccess?: CallbackVoid) {
  const apiClient = useApiClient()

  return useMutation({
    mutationFn: apiClient.InspectionNote.editBeehiveInspectionNote,
    onSuccess: onSuccess
  })
}
