import apiFetcher from '../apiFetcher'

export const createOtherNote = apiFetcher
  .path('/other-note/create')
  .method('post')
  .create()

export const getOtherNotes = apiFetcher
  .path('/other-note/getOtherNotes')
  .method('get')
  .create()

export const getOtherNoteById = apiFetcher
  .path('/other-note/byId/{id}')
  .method('get')
  .create()

export const deleteOtherNoteById = apiFetcher
  .path('/other-note/delete')
  .method('delete')
  .create({ id: true, organizationId: true })

export const editOtherNote = apiFetcher
  .path('/other-note/edit/{id}')
  .method('put')
  .create()
