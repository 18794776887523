const membersTab = {
  section: {
    notMember: {
      body: 'Gå med i organisationen för att se dess medlemmar'
    }
  },

  members: {
    title: 'Medlemmar',
    header: {
      name: 'Namn',
      role: 'Roll'
    }
  },

  member: {
    email: 'Email',
    address: 'Adress',
    undefined: 'Ej godkänd'
  },
  buttons: {
    settings: 'Inställningar'
  }
}

export default membersTab
