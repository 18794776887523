import { Grid } from '@mui/material'

const FormGridRow = props => {
  const {
    children,
    ...rest
  } = props

  return (
    <Grid
      item
      container
      columnSpacing={2}
      rowSpacing={2}
      xs={12}
      sx={{ mx: -2 }}
      {...rest}
    >
      {children}
    </Grid>
  )
}

export default FormGridRow
