const apiariesOptions = {
  addApiaries: {
    title: 'Lägg till fler bigårdar',
    fields: {
      apiariesAmount: {
        label: 'Bigårdar'
      },
      beehivesAmount: {
        label: 'Bikupor'
      }
    }
  },
  apiaryDefaultName: 'Bigård',
  apiariesList: {
    header: {
      name: 'Namn',
      beehivesAmount: 'Bikupor'
    }
  },
  chooseApiariesLocations: 'Placera gårdarna på en karta'
}

export default apiariesOptions
