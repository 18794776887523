import { Collapse, TableCell, TableRow } from '@mui/material'
import { Box } from '@mui/system'
import { useContext } from 'react'
import ItemListContext from '../ItemListContext'

export type ItemListExpandableRowProps = {
  index: number,
  children: JSX.Element | JSX.Element[],
  colSpan: number
}

function ItemListExpandableRow (props: ItemListExpandableRowProps) {
  const {
    index: rowIndex,
    children,
    colSpan
  } = props

  const { openIndex } = useContext(ItemListContext)

  if (rowIndex === undefined || rowIndex === null) {
    throw new Error("Input: 'index' is required for component ItemListActionButtons")
  }

  const isOpen = rowIndex === openIndex

  return (
    <TableRow className="expandable-row">
      <TableCell colSpan={colSpan}>
        <Collapse in={isOpen}>
          <Box className="expandable-row-content">
            {children}
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  )
}

export default ItemListExpandableRow
