const register = {
  title: 'Create Account',
  infoTitle: 'Information',
  descriptionCredentials: 'Enter your information and choose a password.',
  descriptionMail:
    'An email will be sent to your email address with a verification code that is used in the next step',

  registerSuccess: 'User has been created',

  actions: {
    register: {
      errors: {
        conflict: 'User already exists',
        weakPassword:
          'Password does not meet password policy, include at least one uppercase letter and one special character'
      }
    }
  },
  fields: {
    firstname: {
      label: 'First Name',
      errors: {
        required: 'First name is required'
      }
    },
    surname: {
      label: 'Last Name',
      errors: {
        required: 'Last name is required'
      }
    }
  }
}

export default register
