import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import SideMenu from './SideMenu'
import AppToolbar from './Toolbar'

export type AppOverlayProps = {
  hasVerifiedInitialToken: boolean
}

const AppOverlay = (props: AppOverlayProps) => {
  const [sideMenuOpen, setSideMenuOpen] = useState(false)

  const toggleSideMenu = (open = !sideMenuOpen) => {
    if (open !== sideMenuOpen) {
      setSideMenuOpen(open)
    }
  }

  const location = useLocation()

  useEffect(() => {
    setSideMenuOpen(false)
  }, [location.key])

  return (
    <>
      <SideMenu
        hasVerifiedInitialToken={props.hasVerifiedInitialToken}
        open={sideMenuOpen}
        setOpen={toggleSideMenu}
      />
      <AppToolbar toggleSideMenu={toggleSideMenu} />
    </>
  )
}

export default AppOverlay
