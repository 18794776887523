const ListNotes = {
  noteTypes: {
    other: 'Övriga anteckningar',
    inspection: 'Inspektions-anteckningar'
  },
  title: 'Alla anteckningar',
  header: {
    chooseCategory: 'Välj Kategori',
    filterNotes: 'Filtrera Anteckningar',
    noteTypeNotFound: 'Anteckningstyp hittades inte'
  },
  description: {
    chooseCategory: 'Välj vilken kategori du vill se anteckningar för',
    noteTypeNotFound: 'Anteckningar kunde inte hittas för den angivna anteckningstypen.'
  },
  label: {
    fromDate: 'Från',
    toDate: 'Till',
    showNotes: 'Visa anteckningarna',
    update: 'Uppdatera'
  },
  filter: 'Skapa ett filter',
  notes: 'Anteckningar',
  type: 'Typ',
  error: 'Ett fel inträffade när anteckningarna skulle laddas.',
  text: 'Text',
  freeText: 'Fritext',
  olderNotes: 'Äldre anteckningar',

  // Following are alternate translations used in NewNoteCategorySelect.js (should be renamed to
  // NoteCategorySelect.js). The translations are used to change buttons to make sense for selecting
  // categories when viewing old notes instead of creating new notes which is the original intent
  // of NewNoteCategorySelect.js

  fields: {
    category: {
      options: {
        BEEHIVE: {
          title: 'Bikupa',
          description: 'Anteckningar för alla bikupor du har tillgång till'
        },
        APIARY: {
          title: 'Bigård',
          description: 'Anteckningar för alla bigårdar du har tillgång till'
        },
        ORGANIZATION: {
          title: 'Organisation',
          description: 'Anteckningar för alla organisationer du har tillgång till'
        }
      },
      errors: {
        required: 'Välj en kategori'
      }
    }
  }

}

export default ListNotes
