const organizationOptions = {
  fields: {
    description: {
      label: 'Description',
      placeHolder: 'The organization has existed since...',
      errors: {
        required: 'Description is required',
        minLength: 'Description must be at least {{minLength}} characters long',
        maxLength: 'Description cannot be longer than {{maxLength}} characters'
      }
    },
    organizationNumber: {
      label: 'Organization number',
      placeHolder: '12345678912',
      errors: {
        required: 'Organization number is required'
      }
    }
  }
}

export default organizationOptions
