const apiaryOverviewList = {
  apiaries: {
    title: 'Bigårdar',
    header: {
      name: 'Namn',
      beehivesAmount: 'Antal bikupor'
    }
  },
  beehives: 'Bikupor',
  beehivesMissing: 'Bikupor saknas'
}

export default apiaryOverviewList
