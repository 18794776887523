// eslint-disable-next-line no-use-before-define
import React, { useContext, useMemo } from 'react'
import {
  Drawer,
  FormControl,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Select,
  SelectChangeEvent,
  Theme
} from '@mui/material'
import { AppRoutePaths } from '../../../utils/routes'
import SideMenuLink from './SideMenuLink'
import SignOutButton from './SignOutButton'
import { Box } from '@mui/system'
import NoteIcon from '../../common/Icon/Note'
import PeopleSingle from '../../common/Icon/PeopleSingle'
import PeopleCommunityIcon from '../../common/Icon/PeopleCommunity'
import InfoIcon from '../../common/Icon/Info'
import { THEME_CONSTANTS } from '../../../styles/themeConstants'
import { useIsAuthenticated } from '../../../utils/api/hooks/useIsAuthenticated'
import AuthContext from '../../../utils/api/AuthContext'
import { useTranslation } from 'react-i18next'

const drawerRootStyles = (theme: Theme) => ({
  zIndex: theme.zIndex.sideMenu
})

const drawerPaperStyles = (theme: Theme) => ({
  mt: THEME_CONSTANTS.toolbarHeight,
  backgroundColor: theme.palette.secondary.main,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    maxWidth: theme.spacing(35)
  }
})
// const COMPONENT_TRANSLATE_PREFIX = 'components.overlay.toolbar'

const PRIVATE_LINKS = [
  {
    labelKey: 'profile',
    icon: <PeopleSingle />,
    path: AppRoutePaths.MY_PROFILE_PATH
  },
  {
    labelKey: 'notes',
    icon: <NoteIcon />,
    path: AppRoutePaths.NOTES_PATH
  },
  // {
  //   labelKey: 'alarms',
  //   icon: <AlarmIcon/>,
  //   path: AppRoutePaths.ALARMS_PATH
  // },
  // {
  //   labelKey: 'reports',
  //   icon: <ReportIcon/>,
  //   path: AppRoutePaths
  // },
  {
    labelKey: 'organisations',
    icon: <PeopleCommunityIcon />,
    path: AppRoutePaths.LIST_ORGANIZATIONS_PATH
  },
  {
    labelKey: 'about',
    icon: <InfoIcon />,
    path: AppRoutePaths.ABOUT_PATH
  }
]

const NON_PRIVATE_LINKS = [
  {
    labelKey: 'signIn',
    path: AppRoutePaths.LOGIN_PATH,
    icon: null
  },
  {
    labelKey: 'register',
    path: AppRoutePaths.REGISTER_PATH,
    icon: null
  }
]

type SideMenuProps = {
  hasVerifiedInitialToken: boolean
  open: boolean
  setOpen: (open: boolean) => void
}

const SideMenu = (props: SideMenuProps) => {
  const [langValue, setLangValue] = React.useState('')
  const { i18n } = useTranslation()

  const handleChange = (event: SelectChangeEvent) => {
    i18n.changeLanguage(event.target.value as string)
    setLangValue(event.target.value as string)
    // setAge(event.target.value as string)
  }
  const { open, setOpen } = props

  const isAuthenticated = useIsAuthenticated()

  const links = useMemo(
    () => (isAuthenticated ? PRIVATE_LINKS : NON_PRIVATE_LINKS),
    [isAuthenticated]
  )

  const authContext = useContext(AuthContext)
  const signOut = authContext.clearAuthState

  // TODO: Implement sign out
  // const { t } = useTranslation(undefined, { keyPrefix: COMPONENT_TRANSLATE_PREFIX })

  // const dispatch = useDispatch()

  // const logoutPending = useSelector(Selectors.Auth.selectIsLoggingOut)
  // const logoutError = useSelector(Selectors.Auth.selectLogoutError)

  // useStateSnackEffect(logoutPending, t('actions.logout.pending'))
  // useErrorStateSnackEffect(logoutError, t('actions.logout.error'), false)

  return (
    <Drawer
      sx={drawerRootStyles}
      PaperProps={{
        sx: drawerPaperStyles
      }}
      anchor="right"
      open={open}
      onClose={() => setOpen(false)}
    >
      <Box sx={{ mx: 4 }}>
        <List>
          {links.map((link) => (
            <SideMenuLink
              key={link.path}
              labelKey={link.labelKey}
              path={link.path}
              icon={link.icon}
              showCircle
            />
          ))}
        </List>

        {isAuthenticated && (
          <>
            <ListItem sx={{ mt: 14 }}>
              <SignOutButton
                hasVerifiedInitialToken={props.hasVerifiedInitialToken}
                signOut={signOut}
              />
            </ListItem>
          </>
        )}
        <FormControl
          fullWidth
          sx={{
            color: '#FFFFFF',
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: 'rgba(228, 219, 233, 0.25)'
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: 'rgba(228, 219, 233, 0.25)'
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: 'rgba(228, 219, 233, 0.25)'
            },
            '.MuiSvgIcon-root ': {
              fill: 'white !important'
            }
          }}
        >
          <InputLabel sx={{ color: '#FFFFFF' }}>Language</InputLabel>
          <Select
            id="language-selector"
            value={langValue}
            label="Language"
            sx={{
              color: '#FFFFFF'
            }}
            onChange={handleChange}
          >
            <MenuItem value={'sv'}>Swedish</MenuItem>
            <MenuItem value={'en'}>English</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </Drawer>
  )
}

export default SideMenu
