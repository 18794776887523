import { Button, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import {
  Organization,
  OrganizationMemberOverview,
  OrganizationMemberRoles
} from '../../../../models/organization'
import { organizationMemberHelpers } from '../../../../utils/helpers'
import { AppRoutePathSegment } from '../../../../utils/routes'
import ApiariesOverviewList from '../../../apiaries/ApiaryOverviewList'
import PlusIcon from '../../../common/Icon/Plus'
import BottomContentContainer from '../../../common/Layout/BottomContentContainer'
import ButtonListContainer from '../../../common/Layout/ButtonListContainer'
import UnauthorizedByMembership from '../../Unauthorized/UnauthorizedByMembership'
import { useGetApiariesForOrganizationQuery } from '../../../../utils/api/hooks/apiary'
import { useErrorStateSnackEffect } from '../../../../utils/hooks/stateSnackEffect'

const COMPONENT_TRANSLATE_PREFIX =
  'components.organization.displayOrganization.tabs.apiariesTab'

type ApiariesTabProps = {
  organization?: Organization
  authorizedMember?: OrganizationMemberOverview
}

const ApiariesTab = (props: ApiariesTabProps) => {
  const { organization, authorizedMember } = props

  const { t } = useTranslation(undefined, {
    keyPrefix: COMPONENT_TRANSLATE_PREFIX
  })

  const { t: tGeneral } = useTranslation()

  const apiariesByOrganization = useGetApiariesForOrganizationQuery(
    organization?.id
  )

  useErrorStateSnackEffect(
    apiariesByOrganization.isError,
    tGeneral('general.error')
  )

  const isApprovedMember =
    organizationMemberHelpers.isAuthorizedMember(authorizedMember)

  const isAdmin = organizationMemberHelpers.isAuthorizedMemberWithRole(
    authorizedMember,
    OrganizationMemberRoles.ADMIN
  )

  return (
    <Stack spacing={2}>
      {isApprovedMember ? (
        <>
          <ApiariesOverviewList
            apiaries={apiariesByOrganization.data?.data.values}
            useLinkButton
          />

          {isAdmin && (
            <BottomContentContainer>
              <ButtonListContainer>
                <Button
                  component={Link}
                  to={AppRoutePathSegment.NEW_APIARIES}
                  variant="contained"
                  startIcon={<PlusIcon />}
                >
                  {t('action.addApiaries.label')}
                </Button>
              </ButtonListContainer>
            </BottomContentContainer>
          )}
        </>
      ) : (
        <UnauthorizedByMembership
          organizationId={organization?.id}
          member={authorizedMember}
          notMemberBody={t('section.notMember.body')}
        />
      )}
    </Stack>
  )
}

export default ApiariesTab
