import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material/'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import DoDisturbIcon from '@mui/icons-material/DoDisturb'
import LoadingPage from './common/LoadingPage'
import { useTranslation } from 'react-i18next'

const DeleteConfirmationDialog = props => {
  const {
    open,
    pending = false,
    setOpenDeleteDialog,
    deleteFunction
  } = props

  const { t } = useTranslation()

  return (
      <Dialog
        open={open}
        onClose={() => setOpenDeleteDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
        {t('general.remove')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          {t('general.removeQuestion')}
          </DialogContentText>
          {pending && <LoadingPage/>}
        </DialogContent>
        <DialogActions>
          <Button
            disabled={pending}
            variant='outlined'
            color='secondary'
            sx={{ color: 'red' }}
            onClick={() => setOpenDeleteDialog(false)}
          >
            {t('general.no')}
            <DoDisturbIcon style={{ fontSize: '32px', color: 'red' }} />
          </Button>
          <Button
            disabled={pending}
            variant='outlined'
            color='secondary'
            sx={{ color: 'green' }}
            onClick={deleteFunction}
          >
            {t('general.yes')}
          <CheckCircleOutlineIcon style={{ fontSize: '32px', color: 'green' }} />
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteConfirmationDialog
