const newNoteTypeSelect = {
  fields: {
    type: {
      errors: {
        required: 'Välj en typ av anteckning att skapa'
      }
    }
  },

  commonTypeOptions: {
    other: {
      title: 'Övrigt',
      description: 'Anteckning i fritext'
    }
  },

  beehiveTypeOptions: {
    inspection: {
      title: 'Inspektion',
      description: 'Skriv ner observationer från besök hos bikupan. Till exempel; situationen med drottningen, mängd ägg och styrka i samhället.'
    },
    event: {
      title: 'Händelse',
      description: 'Notera större händelser i bikupan. Till exempel, röveri och svärmning'
    },
    reminder: {
      title: 'Påminnelse',
      description: 'Sätt upp en notis för att inte glömma bort något.'
    },
    planning: {
      title: 'Planering',
      description: 'Anteckna vad som behöver göras vid nästa besök hos bikupan.'
    },
    inventory: {
      title: 'Inventering',
      description: 'Gör en inventering av exempelvis ramar, lådor och foder samt hur mycket som behöver inhandlas.'
    }
  }
}

export default newNoteTypeSelect
