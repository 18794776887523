const editOrganization = {
  section: {
    unauthorized: {
      body: 'You must be an administrator of the organization to edit its information.'
    }
  },
  actions: {
    loadOrganization: {
      error: 'An error occurred while loading the organization.'
    },
    loadCurrMember: {
      error:
        'An error occurred while loading the membership status for the organization.'
    },
    updateOrganization: {
      error: 'An error occurred while saving the changes.',
      success: 'The changes have been saved.'
    }
  }
}

export default editOrganization
