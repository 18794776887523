
const filterDialog = {
  description: 'Välj olika alternativ för att skapa ett filter.',
  save: 'Spara',
  startdate: 'Startdatum',
  enddate: 'Slutdatum',
  time: 'Tidsintervall',
  section: {
    filter: 'Filtrera anteckningar',
    time: 'Tidsintervall'
  },
  fields: {
    category: {
      _checkbox: {
        label: 'Kategorier'
      }
    }
  }
}

export default filterDialog
