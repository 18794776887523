import { Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import TitleImage from '../../components/common/TitleImage'
import LoadingPage from '../../components/common/LoadingPage'
import { useErrorStateSnackEffect } from '../../utils/hooks/stateSnackEffect'
// import TabPanel from '../../components/common/TabPanel'
import AboutTab from '../../components/User/UserProfile/Tabs/AboutTab'
import {
  useGetUserQuery,
  useUpdateUserAppReviewMutation
} from '../../utils/api/hooks/user'
import { useCallback, useMemo } from 'react'
import { promiseForForm } from '../../utils/form/helpers'
import ReviewModal from '../../components/common/ReviewModal'

// import PageBodyContainer from '../../components/common/PageBodyContainer'

const COMPONENT_TRANSLATE_PREFIX = 'pages.user.userProfile'

// const COMPONENT_TABS = [
//   {
//     name: 'about',
//     render: props => <AboutTab {...props}/>
//   },
//   {
//     name: 'settings',
//     render: () => 'inställningar'
//   }
// ]

const UserProfile = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: COMPONENT_TRANSLATE_PREFIX
  })
  const { t: tGeneral } = useTranslation(undefined, { keyPrefix: 'general' })
  // const [openTabIndex, setOpenTabIndex] = useState(0)

  const getUserQuery = useGetUserQuery()
  const userData = getUserQuery.data?.data

  const updateUserAppReview = useUpdateUserAppReviewMutation()

  const onCloseReviewModal = useCallback(
    (rating: number, text: string) => {
      promiseForForm(
        updateUserAppReview.mutateAsync({
          rating,
          text
        })
      )
    },
    [updateUserAppReview.mutateAsync]
  )

  const appReviewSurvey = useMemo(
    () => (
      <ReviewModal
        onClose={onCloseReviewModal}
        userData={userData}
      ></ReviewModal>
    ),
    [getUserQuery]
  )
  useErrorStateSnackEffect(updateUserAppReview.error, tGeneral('error.general'))
  useErrorStateSnackEffect(getUserQuery.error, tGeneral('error.general'))

  return (
    <Stack className="pageRoot">
      <TitleImage title={t('title')} src="/images/generic-background.png" />
      <Stack className="pageContent" spacing={2}>
        {/* <Tabs
          value={openTabIndex}
          onChange={(evt, val) => setOpenTabIndex(val)}
          aria-label="basic tabs example"
          variant="fullWidth"
          color="secondary"
        >
          {COMPONENT_TABS.map(({ name }, index) => (
            <Tab
              key={index}
              label={t(`tabs.${name}`)}
            />
          ))}
        </Tabs> */}

        {getUserQuery.isLoading ? (
          <LoadingPage />
        ) : (
          <>
            {appReviewSurvey}
            {/* : COMPONENT_TABS.map(({ render }, index) => (
              <TabPanel
                key={index}
                index={index}
                value={openTabIndex}
                >
                <PageBodyContainer>
                  <Typography variant="h2">
                    {t(`tabs.${COMPONENT_TABS[openTabIndex].name}`)}
                  </Typography>
                </PageBodyContainer>
                {render({ user })}
              </TabPanel>
          ))}  */}
            <AboutTab user={getUserQuery.data?.data} />
          </>
        )}
      </Stack>
    </Stack>
  )
}

export default UserProfile
