import { useMemo } from 'react'
import TextField from './TextField'

const COORDINATE_PATTERN_RULE = { pattern: /^(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)$|^$/ }

const CoordinatesField = props => {
  const {
    rules,
    ...rest
  } = props

  const rulesToUse = useMemo(
    () => rules ? { ...COORDINATE_PATTERN_RULE, ...rules } : COORDINATE_PATTERN_RULE,
    [rules]
  )

  return (
    <TextField
      name="coordinates"
      translateKey="coordinates"
      translatePrefix="form"
      rules={rulesToUse}
      placeholder
      {...rest}
    />
  )
}

export default CoordinatesField
