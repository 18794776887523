import V from '../../enums/Path/VariablePathSegment'
import * as S from './pathSegment'
import * as ApiClient from '../api/apiClient'

type BreadCrumbResolverFunction = (
  param: string
) => Promise<string | undefined | null>

export const variableBreadCrumbResolvers: {
  [key: string]: BreadCrumbResolverFunction
} = {
  [S.ORGANIZATION_ID]: (id) =>
    ApiClient.organization
      .getOrganizationById({ id: id })
      .then((org) => org?.data.name),
  [S.APIARY_ID]: (id) =>
    ApiClient.apiary.getApiary({ id: id }).then((apiary) => apiary?.data.name),
  [S.BEEHIVE_ID]: (id) =>
    ApiClient.beehive
      .getBeehive({ id: id })
      .then((beehive) => beehive?.data.name),
  [S.PROFILE_ID]: (id) =>
    ApiClient.user.getUser({ id: id }).then((user) => user?.data.email),
  [V.OTHER_NOTE_ID]: (id) =>
    ApiClient.OtherNote.getOtherNoteById({ id: id }).then(
      (note) => note?.data.title
    ),
  [V.ORGANIZATION_MEMBER_ID]: (id) =>
    ApiClient.user
      .getUser({ id: id })
      .then(
        (user) => user?.data && `${user.data.firstname} ${user.data.surname}`
      ),
  [V.BEEHIVE_INSPECTION_NOTE_ID]: (id) =>
    ApiClient.InspectionNote.getBeehiveInspectionNoteById({ id: id }).then(
      (note) => note?.data.title
    )
}
