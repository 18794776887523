import {
  Pagination,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material'
import { Stack } from '@mui/system'
import { UseQueryResult } from '@tanstack/react-query'
import { ApiResponse } from 'openapi-typescript-fetch'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useErrorStateSnackEffect } from '../../../utils/hooks/stateSnackEffect'
import ItemList from '../../common/ItemList'
import ItemListLinkCol from '../../common/ItemList/Row/ItemListLinkCol'
import LoadingPage from '../../common/LoadingPage'

const COMPONENT_TRANSLATE_PREFIX = 'components.note.listNotes.listNoteOverviews'
const NOTES_PER_PAGE = 15

export type NoteOverview = {
  id: string
  link: string
  title: string
  date?: Date
}
type QueryResult<NoteType> = {
  query: UseQueryResult<NoteType, unknown>
  pagination?: any
}

export type ListNoteOverviewsProps<NoteType> = {
  noteTransformFn: any
  queryResult: QueryResult<ApiResponse<NoteType[]>>
}

export function ListNoteOverviews<NoteType>(
  props: ListNoteOverviewsProps<NoteType>
) {
  const { t } = useTranslation(undefined, {
    keyPrefix: COMPONENT_TRANSLATE_PREFIX
  })
  const { t: tGlobal } = useTranslation()

  const { queryResult, noteTransformFn } = props

  const rawNotes = queryResult.query.data?.data
  const rawNotesError = queryResult.query.isError
  const rawNotesPending = queryResult.query.isLoading

  useErrorStateSnackEffect(rawNotesError, t('actions.getNotes.error'))

  const noteOverviews = useMemo(
    () => (rawNotes ? rawNotes.map(noteTransformFn) : []),
    [rawNotes, noteTransformFn]
  )

  const pagesAmount = useMemo(
    () => Math.ceil(noteOverviews.length / NOTES_PER_PAGE),
    [noteOverviews]
  )

  const [pageIndex, setPageIndex] = useState(0)

  const notesToRender: any = useMemo(
    () =>
      noteOverviews.splice(
        pageIndex * NOTES_PER_PAGE,
        (pageIndex + 1) * NOTES_PER_PAGE
      ),
    [noteOverviews, pagesAmount]
  )

  if (rawNotesPending) {
    return <LoadingPage />
  } else {
    return (
      <>
        <ItemList expandable>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
            </TableRow>

            <TableRow>
              <TableCell>{t('header.noteTitle')}</TableCell>
              <TableCell>{t('header.noteDate')}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {notesToRender.map((note: any) => (
              <TableRow key={note.id}>
                <TableCell>{note.title}</TableCell>
                <TableCell>
                  {note.date
                    ? t('data.noteDate', {
                        val: note.date,
                        formatParams: {
                          val: { dateStyle: 'short' }
                        }
                      })
                    : tGlobal('general.pending')}
                </TableCell>
                <ItemListLinkCol link={note.link} relative="path" />
              </TableRow>
            ))}
          </TableBody>
        </ItemList>
        {pagesAmount > 1 && (
          <Stack direction="row" spacing={2} justifyContent="center">
            <Pagination
              onChange={(_e, pageIndex) => setPageIndex(pageIndex)}
              count={pagesAmount}
              shape="rounded"
              siblingCount={1}
              boundaryCount={1}
            />
          </Stack>
        )}
      </>
    )
  }
}
