import { Button, Grid, Link as MuiLink, Stack, Typography } from '@mui/material'
import { Link, useLocation } from 'react-router-dom'
import { Box } from '@mui/system'
import { Fragment, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import TitleImage from '../components/common/TitleImage'
import PageBodyContainer from '../components/common/PageBodyContainer'

const COMPONENT_TRANSLATE_PREFIX = 'pages.about'

const CONTACT_SECTION_ID = 'contact-section'

const COMPONENT_SECTIONS = [
  {
    name: 'background',
    sections: [
      'intro',
      'details',
      {
        render: props =>
          <Box sx={{ position: 'relative', paddingBottom: '56.25%' }} {...props}>
            <iframe
              style={{ position: 'absolute' }}
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/PYCV6jwXdug"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </Box>
      }
    ]
  },
  {
    name: 'vision'
  },
  {
    name: 'strategy'
  },
  {
    name: 'history',
    sections: [
      {
        showTitle: true,
        name: 'hsb'
      },
      {
        render: props => (
          <img src="/images/about/history-hsb.jpg" {...props}/>
        )
      },
      {
        showTitle: true,
        name: 'beelab'
      },
      {
        render: props => (
          <img src="/images/about/history-beelab.jpg" {...props}/>
        )
      }
    ]
  }
]

const AboutPage = () => {
  const { t } = useTranslation(undefined, { keyPrefix: COMPONENT_TRANSLATE_PREFIX })
  const { t: tGlobal } = useTranslation()

  const { hash } = useLocation()

  const contactSectionRef = useRef()

  useEffect(() => {
    if (hash === `#${CONTACT_SECTION_ID}`) {
      contactSectionRef.current
        ?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [hash, contactSectionRef])

  return (
    <Stack className="pageRoot">
      <TitleImage
        title={t('title')}
        src='/images/generic-background.png'
      />

      <Stack
        className="pageContent"
        sx={{ p: 2 }}
        spacing={2}
      >
        <Box sx={{ py: 2 }}>
          <Button
            fullWidth
            variant="contained"
            component={Link}
            to={`#${CONTACT_SECTION_ID}`}
          >
            {t('contactButton')}
          </Button>
        </Box>

        {COMPONENT_SECTIONS.map((section, index) => (
          <Fragment key={index}>
            {section.innerHtml
              ? section.innerHtml
              : <PageBodyContainer>
                  <Typography variant="h2">
                    {t(`sections.${section.name}.title`)}
                  </Typography>

                  {(section.sections ?? ['default']).map(
                    (subsection, subsectionIndex) => (
                      <Fragment key={subsectionIndex}>
                        {typeof subsection === 'object'
                          ? subsection.innerHtml ??
                            subsection.render
                            ? subsection.render()
                            : <Typography>
                                {typeof subsection === 'object' && subsection.showTitle &&
                                  <span style={{ fontWeight: 'bold' }}>
                                    {t(`sections.${section.name}.sections.${subsection.name}.title`)}
                                  </span>
                                }

                                {t(`sections.${section.name}.sections.${subsection.name ? `${subsection.name}.` : ''}default`)}
                              </Typography>
                          : <Typography>
                              {t(`sections.${section.name}.sections.${subsection}`)}
                          </Typography>}
                      </Fragment>
                    )
                  )}
                </PageBodyContainer>}
          </Fragment>
        ))}

        <Stack spacing={2} ref={contactSectionRef} >
          <Typography
            variant="h2"
            fontWeight="bold"
          >
            {t('contactTitle')}
          </Typography>

          <Stack sx={{ ml: -1 }}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={1}>
                <Typography fontWeight='bold'>
                  {tGlobal('general.email')}
                </Typography>

              </Grid>

              <Grid item xs={12} md={11}>
                <Typography component={MuiLink} href="mailto:info@beelab.se">
                  info@beelab.se
                </Typography>
              </Grid>

              <Grid item xs={12} md={1}>
                <Typography fontWeight='bold'>
                  {tGlobal('general.phone')}
                </Typography>

              </Grid>

              <Grid item xs={12} md={11}>
                <Typography component={MuiLink} href="tel:+4670-370-02-89">
                  +46 70-370 02 89
                </Typography>
              </Grid>

              <Grid item xs={12} md={1}>
                <Typography fontWeight='bold'>
                  {tGlobal('general.address')}
                </Typography>
              </Grid>

              <Grid item xs={12} md={11}>
                <Typography>
                  Adressvägen 10, 123 45 Stad
                </Typography>
              </Grid>

            </Grid>

          </Stack>
          <Typography
          gutterBottom
            variant="h2"
            fontWeight="bold"
          >
            {t('sections.GDPR.title')}
          </Typography>
          <Typography
            display='block'
            style={{ whiteSpace: 'pre-wrap' }}
            variant="body1"
            gutterBottom
          >
            {t('sections.GDPR.informationText')}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default AboutPage
