const review = {
  title: 'Översikt',
  apiaries: {
    header: {
      name: 'Namn',
      beehivesAmount: 'beehivesAmount'
    },
    beehives: {
      title: 'Bikupor'
    }
  }
}

export default review
