const beehiveReminderDetails = {
  section: {
    general: 'Allmänt'
  },
  fields: {
    title: {
      label: 'Titel'
    },
    bringExtraFrames: {
      _checkbox: {
        label: 'Ta med extra ramar'
      },
      value: {
        label: 'Antal'
      }
    },
    bringHoneyChambers: {
      _checkbox: {
        label: 'Ta med skattlådor'
      },
      value: {
        label: 'Antal'
      }
    },
    bringChildFrames: {
      _checkbox: {
        label: 'Ta med yngelramar'
      },
      value: {
        label: 'Antal'
      }
    },
    bringFodder: {
      _checkbox: {
        label: 'Ta med foder'
      },
      value: {
        label: 'Kg'
      }
    },
    bringFodderCup: {
      label: 'Ta med foderkopp'
    },
    bringEquipment: {
      _checkbox: {
        label: 'Ta med utrustning'
      }
    },
    getNewQueen: {
      label: 'Skaffa ny drottning'
    },
    cutDroneFrames: {
      label: 'Skär ut drönarramar'
    },
    seachForChildren: {
      label: 'Leta efter yngel/ägg'
    },
    checkQueen: {
      label: 'Kontrollera drottningen'
    },
    other: {
      _checkbox: {
        label: 'Övrigt'
      }
    }
  }
}

export default beehiveReminderDetails
