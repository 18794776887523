import { Button, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import BottomContentContainer from '../../common/Layout/BottomContentContainer'
import ButtonListContainer from '../../common/Layout/ButtonListContainer'
import PageBodyContainer from '../../common/PageBodyContainer'

const COMPONENT_TRANSLATE_PREFIX = 'components.organization.unauthorized.unauthorizedByRole'
const UnauthorizedByRole = props => {
  const { body, buttonLink } = props

  const { t: tGlobal } = useTranslation()
  const { t } = useTranslation(undefined, { keyPrefix: COMPONENT_TRANSLATE_PREFIX })

  return (
    <>
      <PageBodyContainer disableGutter>
      <Typography variant="h2">
        {t('title')}
      </Typography>

      <Typography>
        {body}
      </Typography>
    </PageBodyContainer>

    <BottomContentContainer>
      <ButtonListContainer>
        <Button
          variant="outlined"
          color="secondary"
          component={Link}
          to={buttonLink ?? './..'}
        >
          {tGlobal('general.back')}
        </Button>
      </ButtonListContainer>
    </BottomContentContainer>
  </>

  )
}

export default UnauthorizedByRole
