const editOrganizationMember = {
  actions: {
    approveMember: {
      title: 'Godkänn medlem',
      description: 'Godkänn en medlem och tilldela en roll i organisationen.'
    },
    changeRole: {
      title: 'Byt roll',
      description: 'Roller bestämmer vad en medlem kan göra inom organisation.',
      buttonText: 'Byt Roll',
      fullfilled: 'Roll uppdaterad',
      error: 'Roll kunde inte uppdateras',
      adminDescription:
        'En administratör kan redigera, lägga till och ta bort information om organisationen och dess medlemmar',
      maintainerDescription:
        'En biskötare kan visa, redigera och lägga till information om organizationen och dess medlemmar.',
      readerDescription:
        'En prenumerant kan enbart visa information om organisationen och dess medlemmar.'
    },
    removeMember: {
      title: 'Ta bort medlem',
      description: 'Detta går inte att ångra.',
      buttonText: 'Ta bort',
      fullfilled: 'Medlem borttagen',
      error: 'Borttagning misslyckades..'
    },
    getMember: {
      error: 'Ett fel inträffade när medlemmen skulle hämtas'
    },
    getAuthMember: {
      error: 'Ett fel inträffade när din roll för organisationen skulle hämtas'
    }
  },
  header: {
    name: 'Namn',
    role: 'Roll',
    email: 'E-mail',
    phoneNumber: 'Telefonnummer',
    address: 'Adress'
  },
  body: {
    roleMissing: 'Ej bekräftad'
  }
}

export default editOrganizationMember
