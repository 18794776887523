const beehivesOptions = {
  apiarySelectLabel: 'Bigård',
  noApiaries: 'Inga bigårdar att skapa',
  beehivesListLabel: {
    empty: 'Inga bikupor att skapa'
  },
  name: 'Namn',
  addBeehive: 'Lägg till bikupa',
  defaultBeehiveName: '{{apiaryName}}-Bikupa-{{index}}'
}

export default beehivesOptions
