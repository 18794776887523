import { useEffect, useMemo } from 'react'
import { Button, Stack, Link as MuiLink, Typography } from '@mui/material'

import { AppRoutePaths } from '../../utils/routes'
import { useForm } from 'react-hook-form'
import { useErrorStateSnackEffect } from '../../utils/hooks/stateSnackEffect'
import { useTranslation } from 'react-i18next'
import { useNavigate, Link, useLocation } from 'react-router-dom'

import TitleImage from '../../components/common/TitleImage'
import LoadingPage from '../../components/common/LoadingPage'
import ErrorsList from '../../components/common/Form/ErrorsList'
import FormGrid from '../../components/common/Form/FormGrid'
import EmailField from '../../components/common/Form/FormGrid/CommonRows/EmailField'
import PasswordField from '../../components/common/Form/FormGrid/CommonRows/PasswordField'
import PageBodyContainer from '../../components/common/PageBodyContainer'
import BottomButtonsContainer from '../../components/common/Layout/BottomButtonsContainer'
import { useLoginMutation } from '../../utils/api/hooks/auth'
import { StatusCodes } from 'http-status-codes'
import { ApiError } from 'openapi-typescript-fetch'
import useIsMounted from '../../utils/hooks/isMounted'

const COMPONENT_TRANSLATE_PREFIX = 'pages.auth.login'

type LoginCredentials = {
  email: string
  password: string
}

const Login = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: COMPONENT_TRANSLATE_PREFIX
  })
  const { t: tGlobal } = useTranslation()

  const navigate = useNavigate()
  const location = useLocation()

  const isMounted = useIsMounted()

  const loginMutation = useLoginMutation((err) => {
    if (
      isMounted() &&
      err instanceof ApiError &&
      err.status === StatusCodes.UNPROCESSABLE_ENTITY
    ) {
      const { email, password } = getValues()
      navigate(AppRoutePaths.VERIFY_USER_EMAIL_PATH, {
        state: { email: email, password: password }
      })
    }
  })

  const initialFormState = useMemo(
    () => ({
      email: location.state?.email ?? '',
      password: location.state?.password ?? ''
    }),
    []
  )

  const methods = useForm({ defaultValues: initialFormState })
  const { handleSubmit, getValues, formState } = methods

  const accountIsNotConfirmed =
    loginMutation.error instanceof ApiError &&
    loginMutation.error.status === StatusCodes.UNPROCESSABLE_ENTITY

  const loginErrorMessage: string | undefined = useMemo(() => {
    if (
      loginMutation.error instanceof ApiError &&
      loginMutation.error.status === StatusCodes.UNAUTHORIZED
    ) {
      return t('actions.login.error.badCredentials')
    }
    return t('actions.login.error.general')
  }, [loginMutation.error, t, tGlobal])

  useErrorStateSnackEffect(
    loginMutation.error && !accountIsNotConfirmed,
    loginErrorMessage,
    false
  )

  useEffect(() => {
    if (initialFormState.email?.length && initialFormState.password?.length) {
      login(initialFormState)
    }
  }, [initialFormState])

  const login = (credentials: LoginCredentials) => {
    loginMutation.mutate(credentials)
  }

  return (
    <Stack className="pageRoot">
      <TitleImage title={t('title')} src="/images/generic-background.png" />

      {loginMutation.isLoading ? (
        <LoadingPage className="pageContent" />
      ) : (
        <Stack
          className="pageContent"
          component="form"
          onSubmit={handleSubmit(login)}
          spacing={2}
        >
          <PageBodyContainer>
            <Typography variant="h2">{t('infoTitle')}</Typography>
          </PageBodyContainer>

          <FormGrid
            methods={methods}
            translatePrefix={COMPONENT_TRANSLATE_PREFIX}
            rules={{ required: true }}
          >
            <EmailField />
            <PasswordField />
          </FormGrid>

          <ErrorsList formState={formState} />

          <BottomButtonsContainer>
            <Button
              color="secondary"
              component={Link}
              to={AppRoutePaths.LANDING_PATH}
            >
              {tGlobal('general.back')}
            </Button>

            <Button variant="contained" color="secondary" type="submit">
              {t('actions.login.label')}
            </Button>
          </BottomButtonsContainer>

          <Stack sx={{ pt: 2 }} alignItems="center">
            <Typography>
              <MuiLink component={Link} to={AppRoutePaths.PASSWORD_RESET_PATH}>
                {t('actions.forgotPassword.label')}
              </MuiLink>
            </Typography>
          </Stack>
        </Stack>
      )}
    </Stack>
  )
}

export default Login
