import { Typography } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import BeehiveNoteType from '../../../../enums/Note/BeehiveInspectionType'
import CommonNoteType from '../../../../enums/Note/CommonNoteType'
import { NoteCategory } from '../../../../models'
import CreateBeehiveInspection from './CreateBeehiveInspection'
import OtherDetails from './OtherDetails'
import { useEffect } from 'react'

const COMMON_NOTE_RENDER_FNS = {
  [CommonNoteType.OTHER]: (props) => <OtherDetails {...props} />
}

const BEEHIVE_NOTE_RENDER_FNS = {
  [BeehiveNoteType.INSPECTION]: (props) => (
    <CreateBeehiveInspection {...props} />
  )
  /*  [BeehiveNoteType.EVENT]: props => <BeehiveEventDetails {...props}/>,
  [BeehiveNoteType.REMINDER]: props => <BeehiveReminderDetails {...props}/>,
  [BeehiveNoteType.INVENTORY]: props => <BeehiveInventoryDetails {...props}/>,
  [BeehiveNoteType.PLANNING]: props => <BeehivePlanningDetails {...props}/> */
}

const APIARY_NOTE_RENDER_FNS = {}

const ORGANIZATION_NOTE_RENDER_FNS = {}

const TYPE_RENDER_FNS = {
  [NoteCategory.BEEHIVE]: BEEHIVE_NOTE_RENDER_FNS,
  [NoteCategory.APIARY]: APIARY_NOTE_RENDER_FNS,
  [NoteCategory.ORGANIZATION]: ORGANIZATION_NOTE_RENDER_FNS
}

const CategoryTypeNotFound = (props) => {
  const { category, type } = props

  return (
    <Typography>
      Note edit not found for category {category} with type {type}
    </Typography>
  )
}

const NewNoteDetails = (props) => {
  const {
    categorySelectName,
    entitySelectName,
    typeSelectName,
    onSubmitRef,
    name,
    byId,
    preSelValues
  } = props
  const otherFormStepNames = {
    categorySelectName,
    entitySelectName,
    typeSelectName
  }

  const { watch, setValue } = useFormContext()
  useEffect(() => {
    if (preSelValues !== null) {
      setValue('type.category', 'BEEHIVE')

      // Set entity
      setValue('type.entity.apiary', {
        id: preSelValues.apiaryId,
        name: preSelValues.apiaryName,
        beehivesAmount: 0
      })
      setValue('type.entity.beehive', {
        id: preSelValues.id,
        name: preSelValues.name
      })
      setValue('type.entity.organization', {
        id: preSelValues.organizationId,
        name: '',
        apiariesAmount: 0
      })
    }
  }, [preSelValues, setValue])

  const selectedType = watch(`${typeSelectName}.type`)
  const selectedCategory =
    selectedType === 'other' && preSelValues !== undefined
      ? undefined
      : selectedType === 'inspection' && preSelValues !== undefined
      ? 'BEEHIVE'
      : watch(`${categorySelectName}`)

  const id = preSelValues ? preSelValues.id : byId
  const renderFn =
    TYPE_RENDER_FNS[selectedCategory]?.[selectedType] ??
    COMMON_NOTE_RENDER_FNS[selectedType]

  return renderFn ? (
    renderFn({
      ...otherFormStepNames,
      onSubmitRef: onSubmitRef,
      name: `${name}.${selectedType}`,
      byId: id
    })
  ) : (
    <CategoryTypeNotFound category={selectedCategory} type={selectedType} />
  )
}

export default NewNoteDetails
