const toolbar = {
  actions: {
    logout: {
      pending: 'Logging out...',
      success: 'You have been logged out',
      error: 'An error occurred while logging out'
    }
  }
}

export default toolbar
