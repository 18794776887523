const pageNotFound = {
  title: '404',

  infoTitle: 'Hoppsan',
  descriptionInfo: 'Det verkar som du kommit lite vilse.',
  descriptionManual: 'Det finns tyvärr inget att se här, klicka på en av knapparna nedan för att hitta till roligare platser!',
  goToLandingPage: 'Gå till startsidan',
  goToInfo: 'Läs mer om beelab'
}

export default pageNotFound
