import V from '../../enums/Path/VariablePathSegment'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useErrorStateSnackEffect } from '../../utils/hooks/stateSnackEffect'
import LoadingPage from '../../components/common/LoadingPage'
import { Stack } from '@mui/material'
import TitleImage from '../../components/common/TitleImage'
import BeehiveInspectionNoteFormStateWrapper from '../../components/Note/BeehiveInspectionNoteFormStateWrapper'
import {
  useGetBeehiveInspectionNoteById,
  usePutBeehiveInspectionNote
} from '../../utils/api/hooks/inspectionNote'
import { useCallback } from 'react'
import { BeehiveInspectionNoteEditDto } from '../../models/notes'
import { promiseForForm } from '../../utils/form/helpers'
import { replaceContentCheckRowValues } from '../../components/common/Form/FormGrid/CommonRows/CheckRow/ContentCheckRow'
import { useSnackbar } from 'notistack'
import useIsMounted from '../../utils/hooks/isMounted'

const COMPONENT_TRANSLATE_PREFIX = 'pages.note.displayBeehiveInspectionNote'

const convertToInspectionNoteEditInput = (data: any) => {
  Object.keys(data).forEach((key) => {
    if (data[key] === null) {
      data[key] = undefined
    }
  })
  const updatedInspectionNote = {
    ...replaceContentCheckRowValues(data)
  } as BeehiveInspectionNoteEditDto

  return updatedInspectionNote
}

const DisplayBeehiveInspectionNote = () => {
  const { [V.BEEHIVE_INSPECTION_NOTE_ID]: noteId } = useParams()
  const { t } = useTranslation(undefined, {
    keyPrefix: COMPONENT_TRANSLATE_PREFIX
  })
  const { enqueueSnackbar } = useSnackbar()
  const isMounted = useIsMounted()
  const navigate = useNavigate()

  const useGetBeehiveInspectionNoteByIdQuery = useGetBeehiveInspectionNoteById(
    noteId!
  )
  const note = useGetBeehiveInspectionNoteByIdQuery.data?.data
  const getPending = useGetBeehiveInspectionNoteByIdQuery.isLoading
  const getError = useGetBeehiveInspectionNoteByIdQuery.isError

  const onUpdateInspectionNoteSuccess = useCallback(() => {
    enqueueSnackbar(t('actions.updateInspectionNote.success'), {
      variant: 'success'
    })
  }, [t, enqueueSnackbar, isMounted, navigate])

  const usePutNote = usePutBeehiveInspectionNote(onUpdateInspectionNoteSuccess)
  const putError = usePutNote.isError

  useErrorStateSnackEffect(putError, t('actions.updateInspectionNote.error'))
  useErrorStateSnackEffect(getError, t('actions.loadInspectionNote.error'))

  const onSubmit = useCallback(
    (data: any) =>
      promiseForForm(
        usePutNote.mutateAsync({
          id: noteId!,
          ...convertToInspectionNoteEditInput(data)
        })
      ),
    [usePutNote.mutateAsync]
  )

  return getPending ? (
    <LoadingPage />
  ) : (
    <Stack className="Pageroot">
      <TitleImage src="/images/generic-background.png" title={note!.title} />
      <Stack className="pageContent viewNotePage">
        <BeehiveInspectionNoteFormStateWrapper
          initialState={note!}
          key={note!.id}
          pending={getPending}
          onSubmit={onSubmit}
          isForDisplayOnly={true}
        />
      </Stack>
    </Stack>
  )
}

export default DisplayBeehiveInspectionNote
