import { Stack, Typography } from '@mui/material'
import { useCallback, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import TitleImage from '../../components/common/TitleImage'
import ApiariesOptions, {
  transformApiariesToApiInputs
} from '../../components/common/ContentGeneration/Organization/ApiariesOptions'
import BeehivesOptions from '../../components/common/ContentGeneration/Organization/BeehivesOptions'
import Review from '../../components/common/ContentGeneration/Organization/ApiariesOptions/Review'
import {
  useErrorStateSnackEffect,
  useStateSnackEffect
} from '../../utils/hooks/stateSnackEffect'
import { AppRoutePathSegment } from '../../utils/routes'

import PageBodyContainer from '../../components/common/PageBodyContainer'
import { FormStepsContainer } from '../../components/common/Form/FormSteps'
import {
  APIARIES_FORM_NAME,
  BEEHIVES_FORM_NAME,
  CreateApiariesForm,
  ApiaryData
} from '../../components/common/ContentGeneration/Organization/types'
import { useCreateApiariesMutation } from '../../utils/api/hooks/apiary'
import { useSnackbar } from 'notistack'
import useIsMounted from '../../utils/hooks/isMounted'
import { promiseForForm } from '../../utils/form/helpers'

const COMPONENT_TRANSLATE_PREFIX = 'pages.apiary.addApiaries'

type AddApiariesHeaderProp = {
  name: string
}

const COMPONENT_FORM_STEPS = [
  {
    name: APIARIES_FORM_NAME,
    renderForm: () => <ApiariesOptions />,
    validationRules: [
      {
        field: 'apiaries',
        rules: [
          {
            type: 'minLength',
            value: 0,
            condition: (apiaries: ApiaryData[], value: number) =>
              apiaries.length > value
          }
        ]
      }
    ],
    validationTranslatePrefix: COMPONENT_TRANSLATE_PREFIX
  },
  {
    name: BEEHIVES_FORM_NAME,
    renderForm: () => <BeehivesOptions />
  },
  {
    name: 'review',
    renderForm: () => <Review />
  }
]

const AddApiariesHeader = (props: AddApiariesHeaderProp) => {
  const { name } = props
  const { t } = useTranslation(undefined, {
    keyPrefix: `${COMPONENT_TRANSLATE_PREFIX}.stepTitle`
  })

  return (
    <PageBodyContainer>
      <Typography variant="h5">{t(name)}</Typography>
    </PageBodyContainer>
  )
}

const convertToApiaryCreateInput = (
  organizationId: string,
  form: CreateApiariesForm
) => {
  const apiaries = transformApiariesToApiInputs(
    form.apiaries.apiaries,
    form.beehives.beehives
  )

  return { organizationId, apiaries }
}

const AddApiaries = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: COMPONENT_TRANSLATE_PREFIX
  })
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const isMounted = useIsMounted()

  const { [AppRoutePathSegment.ORGANIZATION_ID]: organizationId } = useParams()

  const methods = useForm({
    mode: 'onSubmit'
  })

  const onCreateApiariesSuccess = useCallback(() => {
    enqueueSnackbar(t('actions.createApiaries.fulfilled'), {
      variant: 'success'
    })
    if (isMounted()) {
      navigate('./../')
    }
  }, [t, enqueueSnackbar, isMounted, navigate])

  const createApiariesMutation = useCreateApiariesMutation(
    onCreateApiariesSuccess
  )

  const submit = useCallback(
    (form: CreateApiariesForm) =>
      promiseForForm(
        createApiariesMutation.mutateAsync(
          convertToApiaryCreateInput(organizationId!, form)
        )
      ),
    [createApiariesMutation.mutateAsync]
  )

  useStateSnackEffect(
    createApiariesMutation.isLoading,
    t('actions.createApiaries.pending')
  )

  useErrorStateSnackEffect(
    createApiariesMutation.error,
    t('actions.createApiaries.error')
  )

  const [activeStep, setActiveStep] = useState(0)

  return (
    <FormProvider {...methods}>
      <Stack>
        <TitleImage
          title={t('title')}
          src="/images/generic-background.png"
          stepsAmount={COMPONENT_FORM_STEPS.length}
          activeStep={activeStep}
        />
      </Stack>

      <FormStepsContainer
        className="pageContent"
        steps={COMPONENT_FORM_STEPS}
        onSubmit={submit}
        renderTitle={(props) => <AddApiariesHeader {...props} />}
        activeStepIndex={activeStep}
        onStepChange={setActiveStep}
        onCancel={() => navigate('./../')}
      />
    </FormProvider>
  )
}

export default AddApiaries
