import { Stack, Tab, Tabs } from '@mui/material'
import { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import LoadingPage from '../../components/common/LoadingPage'
import { useErrorStateSnackEffect } from '../../utils/hooks/stateSnackEffect'
import { useTranslation } from 'react-i18next'
import TitleImage from '../../components/common/TitleImage'
import TabPanel from '../../components/common/TabPanel'
import BeehiveInfo from '../../components/beehives/BeehiveDetails/Tabs/BeehiveInfo'
import IotTab from '../../components/beehives/BeehiveDetails/Tabs/IotTab'
import { useGetBeehiveQuery } from '../../utils/api/hooks/beehive'
import { isNotFoundError } from '../../utils/api/util/isNotFoundError'
import PageNotFound from '../PageNotFound'

const COMPONENT_TRANSLATE_PREFIX = 'pages.beehive.displayBeehive'

const DisplayBeehive = () => {
  const { t: tGlobal } = useTranslation()
  const { beehiveId, apiaryId, organizationId } = useParams()

  const beehiveQuery = useGetBeehiveQuery(beehiveId || '')
  const beehive = beehiveQuery.data?.data

  const pagePending = beehiveQuery.isLoading
  const anyTabRenderPropUndefined = !beehive

  const COMPONENT_TABS = useMemo(
    () => [
      {
        name: 'info',
        render: () => (
          <BeehiveInfo
            beehiveId={beehive?.id}
            apiaryId={apiaryId}
            orgId={organizationId}
          />
        )
      },
      {
        name: 'iot',
        render: () => <IotTab beehive={beehive} />
      }
    ],
    [beehive]
  )

  const [openTabIndex, setOpenTabIndex] = useState(0)
  const { t } = useTranslation(undefined, {
    keyPrefix: COMPONENT_TRANSLATE_PREFIX
  })

  useErrorStateSnackEffect(
    beehiveQuery.isError,
    tGlobal('error.loadingBeehives')
  )

  if (isNotFoundError(beehiveQuery.error)) {
    return <PageNotFound />
  }

  return (
    <Stack className="pageRoot">
      <TitleImage src="/images/generic-background.png" title={beehive?.name} />
      <Stack className="pageContent" sx={{ p: 0 }}>
        <Tabs
          value={openTabIndex}
          variant="fullWidth"
          onChange={(...[, val]) => setOpenTabIndex(val)}
        >
          {COMPONENT_TABS.map(({ name }, index) => (
            <Tab key={index} label={t(`tabs.${name}`)} />
          ))}
        </Tabs>
        {!beehive || pagePending ? (
          <LoadingPage />
        ) : anyTabRenderPropUndefined ? (
          <PageNotFound />
        ) : (
          COMPONENT_TABS.map(({ render }, index) => (
            <TabPanel key={index} index={index} value={openTabIndex}>
              {render()}
            </TabPanel>
          ))
        )}
      </Stack>
    </Stack>
  )
}
export default DisplayBeehive
