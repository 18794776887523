// TODO: Migrate old path segments to new segment enum
import { AppRoutePathSegment as SEG } from '../../../routes'
import S_SEG from '../../../../enums/Path/StaticPathSegment'

const routes = {
  [SEG.HOME]: 'Home',
  [SEG.ABOUT]: 'About us',
  [SEG.NEW]: 'Create new',
  [SEG.EDIT]: 'Edit',
  [SEG.PASSWORD_RESET]: 'Reset password',
  [SEG.LOGIN]: 'Log in',
  [SEG.REGISTER]: 'Register account',
  [SEG.VERIFY_USER_EMAIL]: 'Verify account',
  [SEG.ORGANIZATION]: 'Organizations',
  [SEG.ORGANIZATION_ID]: 'Organization',
  [SEG.APIARY]: 'Apiaries',
  [SEG.APIARY_ID]: 'Apiary',
  [SEG.NEW_APIARIES]: 'Add apiaries',
  [SEG.BEEHIVE]: 'Beehives',
  [SEG.BEEHIVE_ID]: 'Beehive',
  [SEG.ALARM]: 'Alarm',
  [SEG.NOTE]: 'Notes',
  [SEG.NOTE_ID]: 'Note',
  [SEG.ALL_NOTES]: 'All notes',
  [SEG.PROFILE]: 'User',
  [SEG.PROFILE_ID]: 'User',
  [SEG.MY_PROFILE]: 'My Profile',
  [SEG.ADMIN]: 'Administration',
  [SEG.STATIC_ORG]: 'Static organizations',
  [SEG.SEARCH]: 'Search',
  [S_SEG.ORGANIZATION_MEMBER]: 'Member',
  [S_SEG.OTHER_NOTE]: 'Free-text note',
  [S_SEG.BEEHIVE_INSPECTION_NOTE]: 'Inspection note'
}

export default routes
