import { useMemo, useRef } from 'react'
import {
  Marker,
  Tooltip
} from 'react-leaflet'

import * as L from 'leaflet'

const MARKER_ICONS_PATH = '/images/map/icons'

const LeafIcon = L.Icon.extend({
  options: {}
})

const GENERATE_MARKER_ICON = color => new LeafIcon({
  iconUrl: `${MARKER_ICONS_PATH}/marker-icon-${color}.png`,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [0, -41]
})

const MARKER_ICONS = {
  gray: GENERATE_MARKER_ICON('gray'),
  orange: GENERATE_MARKER_ICON('orange')
}

const ApiaryMarker = props => {
  const {
    location,
    setLocation,
    apiary,
    draggable,
    markersAmount,
    index,
    select
  } = props
  const markerRef = useRef(null)

  if (markerRef) {
    markerRef?.leafletElement?.openPopup()
  }
  const eventHandlers = useMemo(
    () => ({
      dragend () {
        const marker = markerRef.current
        if (marker != null) {
          setLocation && setLocation(marker.getLatLng())
        }
      },
      click: () => select(apiary.sortId)
    }),
    []
  )

  return (
    <Marker
      draggable={draggable}
      marker_index={index}
      eventHandlers={eventHandlers}
      position={location}
      icon={draggable
        ? MARKER_ICONS.orange
        : MARKER_ICONS.gray
      }
      ref={markerRef}
      zIndexOffset={draggable ? markersAmount : 0}
    >
      <Tooltip
        offset={[0, 0]}
        opacity={1}
        permanent
      >
        {apiary.name.length
          ? apiary.name
          : apiary.defaultName
        }
      </Tooltip>

    </Marker>
  )
}

export default ApiaryMarker
