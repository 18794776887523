import { Pagination } from '@mui/material'
import { PaginationQuery } from '../utils/api/hooks/pagination'

type QueryPaginationProps = {
  query: {
    data?: {
      data?: {
        total: number
      }
    }
    isLoading: boolean
  }
  pagination: {
    queryParams: PaginationQuery
    setPage: (page: number) => void
  }
}

export const QueryPagination = (props: QueryPaginationProps) => {
  const data = props.query?.data?.data
  const queryParams = props.pagination.queryParams

  if (
    props.query.isLoading ||
    !data?.total ||
    data?.total <= queryParams.take
  ) {
    return null
  }

  const totalPages = (data?.total ?? 0) / (queryParams.take || 1)

  return (
    <Pagination
      count={totalPages}
      onChange={(_, page) => props.pagination.setPage(page)}
    />
  )
}
