import { useContext } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import AuthContext from '../../utils/api/AuthContext'
import { useIsAuthenticated } from '../../utils/api/hooks/useIsAuthenticated'
import { AppRoutePaths } from '../../utils/routes'

type PrivateRouteProps = {
  authorizedGroups?: string[]
  element: JSX.Element
  type?: 'private' | 'anonymous'
}

const ProtectedRoute = (props: PrivateRouteProps) => {
  const {
    // authorizedGroups,
    element,
    type = 'private'
  } = props
  const location = useLocation()

  const isAuthenticated = useIsAuthenticated()

  const authContext = useContext(AuthContext)

  // const isAuthorizedForGroups =
  //   !authorizedGroups ||
  //     authorizedGroups?.find(group => authenticatedUserGroups?.indexOf(group) >= 0)

  const redirectToStartupGuide =
    isAuthenticated &&
    location.pathname !== AppRoutePaths.GETTING_STARTED_PATH &&
    !authContext.authState?.hasSeenStartupGuide

  const redirectToHome =
    (type === 'private' && !isAuthenticated) ||
    (type === 'anonymous' && isAuthenticated)

  const redirectPath = redirectToStartupGuide
    ? AppRoutePaths.GETTING_STARTED_PATH
    : type === 'private'
    ? AppRoutePaths.LANDING_PATH
    : AppRoutePaths.HOME_PATH

  return redirectToHome || redirectToStartupGuide ? (
    <Navigate to={redirectPath} state={{ from: location }} />
  ) : (
    element
  )
}

export default ProtectedRoute
