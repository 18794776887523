const newNoteCategorySelect = {
  fields: {
    category: {
      options: {
        BEEHIVE: {
          title: 'Bikupa',
          description: 'Gör en anteckning som gäller en specifik bikupa'
        },
        APIARY: {
          title: 'Bigård',
          description: 'Gör en anteckning för en hel bigård'
        },
        ORGANIZATION: {
          title: 'Organisation',
          description:
            'Skapa en anteckning som gäller för en organisation som helhet'
        }
      },
      errors: {
        required: 'Välj en kategori'
      }
    },

    type: {
      errors: {
        required: 'Välj en typ av anteckning att skapa'
      }
    }
  },

  stepTitle: {
    category: {
      title: 'Välj kategori',
      description: 'Välj för vad kategorin gäller'
    },
    entity: {
      BEEHIVE: {
        title: 'Bikupa',
        description: 'Välj för vilken bikupa du vill göra anteckningen'
      },
      APIARY: {
        title: 'Bigård',
        description: 'Välj för vilken bigård du vill göra anteckningen'
      },
      ORGANIZATION: {
        title: 'Organisation',
        description: 'Välj för vilken organisation du vill göra anteckningen'
      }
    },
    type: {
      title: 'Välj typ av anteckning',
      description: 'Välj vilken typ av anteckning du vill göra'
    },
    details: {
      common: {
        other: {
          title: 'Övrigt'
        }
      },
      BEEHIVE: {
        inspection: {
          title: 'Inspektion'
        },
        event: {
          title: 'Händelse'
        },
        reminder: {
          title: 'Påminnelse'
        },
        inventory: {
          title: 'Inventering'
        },
        planning: {
          title: 'Planering'
        }
      }
    }
  },

  commonTypeOptions: {
    other: {
      title: 'Övrigt',
      description: 'Anteckning i fritext'
    }
  },

  beehiveTypeOptions: {
    inspection: {
      title: 'Inspektion',
      description:
        'Skriv ner observationer från besök hos bikupan. Till exempel; situationen med drottningen, mängd ägg och styrka i samhället.'
    },
    event: {
      title: 'Händelse',
      description:
        'Notera större händelser i bikupan. Till exempel, röveri och svärmning'
    },
    reminder: {
      title: 'Påminnelse',
      description: 'Sätt upp en notis för att inte glömma bort något.'
    },
    planning: {
      title: 'Planering',
      description: 'Anteckna vad som behöver göras vid nästa besök hos bikupan.'
    },
    inventory: {
      title: 'Inventering',
      description:
        'Gör en inventering av exempelvis ramar, lådor och foder samt hur mycket som behöver inhandlas.'
    }
  }
}

export default newNoteCategorySelect
