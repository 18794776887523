import { useContext } from 'react'

import AuthContext from '../AuthContext'

export function useAuthenticatedUserId(): string | undefined {
  const authContext = useContext(AuthContext)
  if (!authContext) {
    throw new Error('Auth context is not set')
  }

  return authContext.authState?.userId
}
