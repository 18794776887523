import TextField from './TextField'

const OtherField = props => {
  const { inputProps, ...rest } = props
  return (
    <TextField
      name="other"
      translateKey="other"
      translatePrefix="form"
      inputProps={{
        multiline: true,
        rows: 4,
        autoComplete: 'off',
        ...inputProps
      }}
      {...rest}
    />
  )
}

export default OtherField
