const createStaticOrganizations = {
  title: 'Create Organizations',
  fileSelect: {
    inputLabel: 'Select file',
    valuePrefix: 'Selected file: ',
    required: 'File required!'
  },
  parentOrgSelectLabel: 'Parent organization',
  staticOrgsMissing: 'There are no existing static organizations',

  staticOrgTypeSelectLabel: 'Type of organization',
  staticOrganization: {
    nation: 'Nation',
    district: 'District',
    association: 'Association'
  },

  createOrganizations: {
    success: 'The organizations have been created',
    error: 'An error occurred while creating some organizations'
  }
}

export default createStaticOrganizations
