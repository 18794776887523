import {
  Button,
  CircularProgress,
  Dialog,
  Grid,
  Stack,
  Typography
} from '@mui/material'
import { Box } from '@mui/system'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import VariablePathSegment from '../../../../../enums/Path/VariablePathSegment'
import { Beehive } from '../../../../../models/beehive'
import {
  useGetReferenceWeightQuery,
  useSetReferenceWeightMutation
} from '../../../../../utils/api/hooks/beehive'
import CancelIcon from '../../../../common/Icon/Cancel'
import CheckMarkIcon from '../../../../common/Icon/CheckMark'
import InfoGridText from '../../../../common/InfoGridText'
import BottomContentContainer from '../../../../common/Layout/BottomContentContainer'
import ButtonListContainer from '../../../../common/Layout/ButtonListContainer'
import PageBodyContainer from '../../../../common/PageBodyContainer'

type UpdateReferenceWeightProps = {
  beehive?: Beehive
  open: boolean
  onReferenceWeightSave?: (rw: number) => void
  onCancel: () => void
  latestWeight?: number
}

const COMPONENT_TRANSLATE_PREFIX = 'components.beehive.updateReferenceWeight'

function UpdateReferenceWeight(props: UpdateReferenceWeightProps) {
  const { open, beehive, onReferenceWeightSave, onCancel, latestWeight } = props

  const { t } = useTranslation(undefined, {
    keyPrefix: COMPONENT_TRANSLATE_PREFIX
  })
  const { t: tGlobal } = useTranslation()

  const { [VariablePathSegment.ORGANIZATION_ID]: organizationId } = useParams()

  const [weightHolder, setWeightHolder] = useState<{
    weight: number | undefined
  }>({ weight: latestWeight })

  const currentRefWeightQuery = useGetReferenceWeightQuery(beehive!.id)
  const currentRefWeight = currentRefWeightQuery.data?.data
  if (currentRefWeight && !weightHolder) {
    setWeightHolder({ weight: latestWeight })
  }

  const setReferenceWeightMutation = useSetReferenceWeightMutation()

  const submitSaveReferenceWeight = () => {
    if (beehive && organizationId && latestWeight && onReferenceWeightSave) {
      setReferenceWeightMutation.mutate({
        beehiveId: beehive.id,
        weight: latestWeight
      })
      setWeightHolder({ weight: latestWeight })
      onReferenceWeightSave(latestWeight)
      onCancel()
    }
  }

  const handleBackdropClick = () => {
    if (!setReferenceWeightMutation.isLoading) {
      onCancel()
    }
  }

  return (
    <Dialog open={open} onClose={handleBackdropClick}>
      <Stack sx={{ p: 2, pt: 4 }} spacing={2}>
        <PageBodyContainer>
          <Typography variant="h2">{t('sections.main.header')}</Typography>

          <Typography>
            {t('sections.main.body', {
              name: beehive?.name ?? tGlobal('general.pending')
            })}
          </Typography>
        </PageBodyContainer>

        <Box>
          <Grid container spacing={2}>
            <InfoGridText
              xs={12}
              header={t('sections.priorReference.header')}
              body={
                !currentRefWeightQuery.isLoading
                  ? `${currentRefWeight} ${tGlobal('unit.kg')}`
                  : t('sections.priorReference.nonExistentBody')
              }
            />

            <InfoGridText
              xs={12}
              header={t('sections.newReference.header')}
              body={
                latestWeight
                  ? `${latestWeight} ${tGlobal('unit.kg')}`
                  : tGlobal('general.pending')
              }
            />
          </Grid>
        </Box>

        <BottomContentContainer>
          {setReferenceWeightMutation.isLoading ? (
            <CircularProgress />
          ) : (
            <ButtonListContainer direction="column">
              <Button
                variant="contained"
                disabled={!beehive || !weightHolder}
                onClick={submitSaveReferenceWeight}
                startIcon={<CheckMarkIcon />}
              >
                {tGlobal('general.confirm')}
              </Button>

              <Button
                variant="outlined"
                color="secondary"
                onClick={onCancel}
                startIcon={<CancelIcon />}
              >
                {tGlobal('general.cancel')}
              </Button>
            </ButtonListContainer>
          )}
        </BottomContentContainer>
      </Stack>
    </Dialog>
  )
}

export default UpdateReferenceWeight
