import { components } from '../utils/api/schema'

export type BeehiveInput = components['schemas']['UpdateBeehiveInputDto']

export type BeehiveOverview = components['schemas']['BeehiveOverviewDto']

export type Beehive = components['schemas']['BeehiveResponseDto']

export type BeehiveByApiaryResponse = {
  id: string
  name: string
}

export type ConnectIotBeehiveInput = {
  iotId?: string
  beehiveId: string
  iotPassword: string
}

export type MoveBeehiveQueryInput = {
  id: string
  newApiaryId: string
}

export enum SensorType {
  TEMP_BOT = 'TEMP_BOT',
  TEMP_TOP = 'TEMP_TOP',
  TEMP_OUT = 'TEMP_OUT',
  HUMIDITY = 'HUMIDITY',
  PRESSURE = 'PRESSURE',
  WEIGHT = 'WEIGHT'
}
