import { IconButton, TableCell } from '@mui/material'
import { Link, RelativeRoutingType } from 'react-router-dom'

import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined'

export type ItemListLinkColProps = {
  link: string
  relative?: RelativeRoutingType
}

function ItemListLinkCol(props: ItemListLinkColProps) {
  const { link, relative } = props

  return (
    <TableCell align="center" className="itemListLinkCell">
      <IconButton component={Link} to={link} relative={relative}>
        <ChevronRightOutlinedIcon />
      </IconButton>
    </TableCell>
  )
}

export default ItemListLinkCol
