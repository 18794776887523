const editNote = {
  actions: {
    loadInspectionNote: {
      error: 'Ett fel uppstod när anteckningen skulle laddas'
    },
    updateInspectionNote: {
      error: 'Ett fel uppstod när ändringarna skulle sparas',
      success: 'Ändringarna har sparats.'
    }
  }
}

export default editNote
