import Register from './Register'
import SensorCharts from './SensorCharts'
import { useTranslation } from 'react-i18next'
import { Typography, Stack } from '@mui/material'
import BottomContentContainer from '../../../../common/Layout/BottomContentContainer'

const COMPONENT_TRANSLATE_PREFIX = 'components.beehive.iotTab'

const IotTab = (props) => {
  const { beehive } = props
  const { t } = useTranslation(undefined, {
    keyPrefix: COMPONENT_TRANSLATE_PREFIX
  })

  return (
    <>
      {beehive &&
        (beehive.iotId ? (
          <SensorCharts beehive={beehive} />
        ) : (
          <Stack sx={{ mx: 4 }}>
            <Typography fontWeight="bold">{t('newDigKit.header')}</Typography>

            <Typography variant="body2" fontWeight="light">
              {t('newDigKit.text')}
            </Typography>

            <BottomContentContainer>
              <Register beehive={beehive} />
            </BottomContentContainer>
          </Stack>
        ))}
    </>
  )
}

export default IotTab
