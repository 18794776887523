import listNotes from './listNotes'
import newNote from './newNote'
import editNote from './editNote'

const note = {
  newNote,
  listNotes,
  editNote
}

export default note
