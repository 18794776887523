import { Stack } from '@mui/material'

const BottomContentContainer = props => {
  const { children, sx, ...rest } = props

  return (
    <Stack
      sx={{ px: 2, pt: 3, ...sx }}
      spacing={2}
      {...rest}
    >
      {children}
    </Stack>
  )
}

export default BottomContentContainer
