const displayOtherNote = {
  actions: {
    loadOtherNote: {
      error: 'Ett fel uppstod när anteckningen skulle laddas'
    },
    updateOtherNote: {
      error: 'Ett fel uppstod när ändringarna skulle sparas',
      success: 'Ändringarna har sparats.'
    }
  },
  fields: {
    titles: {
      otherNote: 'Övrig Anteckning'
    },
    title: 'Titel',
    text: 'Text'
  },
  buttons: {
    addPicture: 'Lägg till bild',
    save: 'Spara',
    edit: 'Redigera',
    abort: 'Avbryt'
  }
}

export default displayOtherNote
