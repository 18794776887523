const beehivesOptions = {
  apiarySelectLabel: 'Apiary',
  noApiaries: 'No apiaries to create',
  beehivesListLabel: {
    empty: 'No beehives to create'
  },
  name: 'Name',
  addBeehive: 'Add beehive',
  defaultBeehiveName: '{{apiaryName}}-Beehive-{{index}}'
}

export default beehivesOptions
