import { ApiError } from 'openapi-typescript-fetch'
import { useEffect, useMemo, useState } from 'react'
import useIsMounted from '../../hooks/isMounted'
import { isUnauthorizedError } from '../apiClient/helpers'
import { useApiClient } from './useApiClient'
import { useIsAuthenticated } from './useIsAuthenticated'

export const useInitialTokenVerified = () => {
  const apiClient = useApiClient()
  const isMounted = useIsMounted()

  const isAuthenticated = useIsAuthenticated()

  const initialState = useMemo(
    () => ({
      hasVerified: !isAuthenticated, // if we are not authenticated at start, we do not need to verify the token
      error: null
    }),
    []
  )

  const [verificationState, setVerificationState] = useState(initialState)

  useEffect(() => {
    if (!verificationState.hasVerified) {
      apiClient.auth
        .verifyToken(null)
        .then(() => {
          if (isMounted()) {
            setVerificationState((state) => ({ ...state, hasVerified: true }))
          }
        })
        .catch((error: Error) => {
          if (isMounted()) {
            if (isUnauthorizedError(error as ApiError)) {
              setVerificationState((state) => ({ ...state, hasVerified: true }))
            } else {
              setVerificationState((state) => ({ ...state, err: error }))
            }
          }
        })
    }
  }, [])

  return verificationState
}
