const unauthorizedByMembership = {
  action: {
    createRequest: {
      label: 'Join organization',
      fulfilled: 'Request has been sent',
      error: 'An error occurred while sending the request'
    },

    cancelRequest: {
      label: 'Cancel request',
      fulfilled: 'Request has been cancelled',
      error: 'An error occurred while cancelling the request'
    }
  },

  section: {
    pendingMember: {
      title: 'Membership request pending',
      body: 'The request to join the organization is pending approval from the organization administrators.'
    },

    notMember: {
      title: 'Not a member'
    }
  }
}

export default unauthorizedByMembership
