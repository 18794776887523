import apiFetcher from '../apiFetcher'

export const uploadImage = apiFetcher
  .path('/aws/postImageToS3')
  .method('post')
  .create()

export const getSignedS3URL = apiFetcher
  .path('/aws/file/byFileName')
  .method('get')
  .create()
export const removeUser = apiFetcher
  .path('/aws/remove-user')
  .method('post')
  .create()
