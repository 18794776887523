import NewNoteTypeSelect from './NewNoteTypeSelect.js'
import NewNoteEntitySelect from './NewNoteEntitySelect'
import NewNoteCategorySelect from './NewNoteCategorySelect'

type NewNoteSelectProps = {
  categorySelectName: string
  entitySelectName: string
  typeSelectName: string
  preSelValues: {
    apiaryId: string
    apiaryName: string
    beeType: string
    beehiveType: string
    boxAmount: number
    createdAt: string
    frameType: string
    framesAmount: number
    id: string
    iotId: string
    lastSwarmed: string
    name: string
    organizationId: string
    queenColor: string
    queenWingCut: boolean
    updatedAt: string
  }
}

const NewNoteSelect = (props: NewNoteSelectProps) => {
  const { categorySelectName, entitySelectName, typeSelectName, preSelValues } =
    props

  return (
    <>
      <NewNoteTypeSelect values={preSelValues} {...props} />
      <div>&nbsp;</div>
      {!preSelValues && (
        <>
          <NewNoteCategorySelect name={typeSelectName} />
          <div>&nbsp;</div>
          <NewNoteEntitySelect
            name={`type.${entitySelectName}`}
            categorySelectName={`type.${categorySelectName}`}
          />
        </>
      )}
    </>
  )
}

export default NewNoteSelect
