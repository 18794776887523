import apiariesOptions from './ApiariesOptions'
import beehivesOptions from './BeehivesOptions'
import organizationOptions from './OrganizationOptions'
import review from './Review'

const organizationGeneration = {
  organizationOptions,
  apiariesOptions,
  beehivesOptions,
  review
}

export default organizationGeneration
