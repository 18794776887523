const sensorDataChart = {
  sensorType: {
    TEMP_BOT: 'Temperatur nedre del',
    TEMP_TOP: 'Temperatur övre del',
    TEMP_OUT: 'Temperatur ute',
    HUMIDITY: 'Luftfuktighet',
    PRESSURE: 'Tryck',
    WEIGHT: 'Vikt'
  },
  error: {
    noData: 'Ingen data att visa'
  }
}

export default sensorDataChart
