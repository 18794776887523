import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import { Link } from 'react-router-dom'
import MenuIcon from '@mui/icons-material/Menu'

import { Stack } from '@mui/material'

const AppToolbar = (props) => {
  const { toggleSideMenu } = props

  return (
    <Box>
      <AppBar position="fixed" color="secondary">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            aria-label="menu"
            component={Link}
            to="/"
          >
            <img
              style={{ maxWidth: '100px' }}
              src="/images/logo.png"
              alt="logo"
            />
          </IconButton>

          <Box sx={{ flexGrow: 1 }} />

          <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
            {/* <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
            >
              <NotificationsNoneIcon/>
            </IconButton> */}

            <IconButton
              color="primary"
              component="span"
              onClick={() => toggleSideMenu()}
            >
              <MenuIcon />
            </IconButton>
          </Stack>
        </Toolbar>
      </AppBar>
    </Box>
  )
}
export default AppToolbar
