import { Stack, Typography } from '@mui/material'
import { useMemo, useState } from 'react'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { FormStepsContainer } from '../../components/common/Form/FormSteps'
import PageBodyContainer from '../../components/common/PageBodyContainer'
import TitleImage from '../../components/common/TitleImage'
import ListNotesStep from '../../components/Note/ListNotes/ListNotesStep'
import NewNoteCategorySelect from '../../components/Note/NewNote/NewNoteCategorySelect'
import NewNoteEntitySelect from '../../components/Note/NewNote/NewNoteEntitySelect'

const COMPONENT_TRANSLATE_PREFIX = 'pages.note.listNotes'

const CATEGORY_SELECT_NAME = 'category'
const ENTITY_SELECT_NAME = 'entity'
const SHOW_LISTED_NOTES = 'notes'

const NewNoteHeader = (props: { name: any }) => {
  const { name } = props
  const { t } = useTranslation(undefined, {
    keyPrefix: `${COMPONENT_TRANSLATE_PREFIX}`
  })
  const { watch } = useFormContext()
  let entityNames = [watch('entity.apiary.name'), watch('entity.beehive.name')]
  entityNames = entityNames.filter(Boolean)

  return (
    <PageBodyContainer>
      {name !== 'notes' ? (
        <Stack spacing={4}>
          <Typography variant="h2">{t('header.chooseCategory')}</Typography>
          <Typography>{t('description.chooseCategory')}</Typography>
        </Stack>
      ) : (
        <Stack spacing={2}>
          <Typography variant="h2">{t('header.filterNotes')}</Typography>
          <Typography>
            {watch('entity.organization.name')}
            {entityNames.map((entityName) => ' - ' + entityName)}
          </Typography>
        </Stack>
      )}
    </PageBodyContainer>
  )
}

const ListNotes = () => {
  const navigate = useNavigate()
  const methods = useForm()
  const { t } = useTranslation(undefined, {
    keyPrefix: `${COMPONENT_TRANSLATE_PREFIX}`
  })
  const [activeStep, setActiveStep] = useState(0)

  const componentSteps = useMemo(
    () => [
      {
        name: CATEGORY_SELECT_NAME,
        renderForm: (props: any) => (
          <NewNoteCategorySelect
            translatePrefix={'pages.note.listNotes'}
            {...props}
          />
        )
      },
      {
        name: ENTITY_SELECT_NAME,
        renderForm: (props: any) => (
          <NewNoteEntitySelect
            categorySelectName={`${CATEGORY_SELECT_NAME}.category`}
            {...props}
          />
        )
      },
      {
        name: SHOW_LISTED_NOTES,
        renderForm: (props: any) => (
          <ListNotesStep
            categorySelectName={CATEGORY_SELECT_NAME}
            entitySelectName={ENTITY_SELECT_NAME}
            {...props}
          />
        )
      }
    ],
    []
  )

  return (
    <Stack className="pageRoot" justifyContent={'center'}>
      <FormProvider {...methods}>
        <TitleImage
          title={t('olderNotes')}
          activeStep={activeStep}
          stepsAmount={componentSteps.length}
        />

        <Stack className="pageContent" spacing={2}>
          <FormStepsContainer
            hideSubmitButton={activeStep === componentSteps.length - 1}
            steps={componentSteps}
            activeStepIndex={activeStep}
            renderTitle={(props: JSX.IntrinsicAttributes & { name: any }) => (
              <NewNoteHeader {...props} />
            )}
            onStepChange={setActiveStep}
            onCancel={() => navigate('./../')}
          />
        </Stack>
      </FormProvider>
    </Stack>
  )
}

export default ListNotes
