const updateReferenceWeight = {
  sections: {
    main: {
      header: 'Reset Reference',
      body: 'You are about to reset the reference weight for {{name}}. After this is done, the weight graph will be based on the current weight.'
    },
    priorReference: {
      header: 'Previous Reference'
    },
    newReference: {
      header: 'New Reference',
      nonExistentBody: 'Does not exist'
    }
  },
  actions: {
    saveRefenceWeight: {
      success: 'The reference weight has been saved'
    }
  }
}

export default updateReferenceWeight
