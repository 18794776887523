import apiFetcher from '../apiFetcher'

export const login = apiFetcher.path('/auth/login').method('post').create()

export const register = apiFetcher
  .path('/auth/register')
  .method('post')
  .create()

export const initPasswordReset = apiFetcher
  .path('/auth/reset-password/init')
  .method('post')
  .create()

export const verifyPasswordReset = apiFetcher
  .path('/auth/reset-password/verify')
  .method('post')
  .create()

export const verifyAccount = apiFetcher
  .path('/auth/verify-account')
  .method('post')
  .create()

export const resendAccountVerificationCode = apiFetcher
  .path('/auth/verify-account/resend')
  .method('post')
  .create()

export const verifyToken = apiFetcher
  .path('/auth/verify-token')
  .method('get')
  .create()

export const signOut = apiFetcher.path('/auth/log-out').method('post').create()
