/**
 *
 * DEPRECATED
 *
 */
import {
  Stack,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
  Button,
  TextField,
  Box,
  TableRow,
  TableCell,
  TableBody
} from '@mui/material'
import { useEffect, useMemo } from 'react'
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import PlusIcon from '../../../Icon/Plus'
import TrashCanIcon from '../../../Icon/TrashCan'

import ItemList from '../../../ItemList'
import ItemListActionButtons, {
  GenericActionButtonConfigArray
} from '../../../ItemList/Row/ItemListActionButtons'
import {
  APIARIES_FORM_NAME,
  ApiaryData,
  BeehiveData,
  CreateOrganizationForm
} from '../types'

const COMPONENT_TRANSLATE_PREFIX =
  'components.forms.organizationGeneration.beehivesOptions'

const DEFAULT_SELECTED_APIARY = 0

const BeehivesOptions = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: COMPONENT_TRANSLATE_PREFIX
  })

  const getDefaultBeehiveName = (apiary: ApiaryData, index: number) =>
    t('defaultBeehiveName', {
      apiaryName: apiary.name.length ? apiary.name : apiary.defaultName,
      index: +index + 1
    })

  const methods = useFormContext<CreateOrganizationForm>()

  const { control, getValues, setValue } = methods

  const selectedApiaryIndex = useWatch({
    control,
    name: 'beehives.selectedApiaryIndex'
  })

  const apiaries = useWatch({
    control,
    name: 'apiaries.apiaries'
  })

  const { fields, append, remove, update } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'beehives.beehives' // unique name for your Field Array,
  })

  const _createBeehiveByApiary = (
    apiary: ApiaryData,
    apiarySortIndex: number
  ) => ({
    apiaryId: apiary.sortId,
    apiarySortIndex: +apiarySortIndex,
    name: '',
    defaultName: getDefaultBeehiveName(apiary, apiarySortIndex)
  })

  useEffect(() => {
    const {
      [APIARIES_FORM_NAME]: { apiaries }
    } = getValues()

    remove(
      fields
        .map((field, index) => {
          const apiary = apiaries.find(
            (apiary) => apiary.sortId === field.apiaryId
          )

          if (!apiary || field.apiarySortIndex >= apiary.beehivesAmount) {
            return index
          }

          return -1
        })
        .filter((index) => index !== -1)
    )

    append(
      apiaries
        .map((apiary) => {
          const beehiveFields = []

          for (let i = 0; i < apiary.beehivesAmount; i++) {
            const foundFieldIndex = fields.findIndex(
              (field) =>
                field.apiaryId === apiary.sortId && field.apiarySortIndex === i
            )

            const foundField = foundFieldIndex >= 0 && fields[foundFieldIndex]

            if (foundField) {
              const newName = getDefaultBeehiveName(apiary, i)
              update(foundFieldIndex, {
                ...foundField,
                defaultName: newName
              })
            } else {
              beehiveFields.push(_createBeehiveByApiary(apiary, i))
            }
          }

          return beehiveFields
        })
        .flat()
    )
  }, [])

  const selectedApiary = useMemo(
    () =>
      selectedApiaryIndex >= 0 && selectedApiaryIndex < apiaries.length
        ? apiaries[selectedApiaryIndex]
        : apiaries[0],
    [apiaries, selectedApiaryIndex]
  )

  const selectedApiaryFields = useMemo(
    () =>
      fields
        ? fields
            .map((el, index) => ({ ...el, index }))
            .filter((el) => el.apiaryId === selectedApiary?.sortId)
        : [fields],
    [selectedApiary?.sortId, fields]
  )

  const _updateApiaryBeehiveAmount = (index: number, newAmount: number) => {
    setValue(`apiaries.apiaries.${index}.beehivesAmount`, newAmount)
  }

  const addBeehive = () => {
    const {
      apiaries: { apiaries }
    } = getValues()

    const apiaryIndex = apiaries.findIndex(
      (a) => a.sortId === selectedApiary?.sortId
    )

    const apiary = apiaries[apiaryIndex]

    append(_createBeehiveByApiary(apiary, apiary.beehivesAmount))
    _updateApiaryBeehiveAmount(apiaryIndex, +apiary.beehivesAmount + 1)
  }

  const deleteBeehive = (apiaryId: string, apiarySortIndex: number) => {
    const {
      beehives: { beehives },
      apiaries: { apiaries }
    } = getValues()

    const beehiveIndex = beehives.findIndex(
      (b) => b.apiaryId === apiaryId && b.apiarySortIndex === apiarySortIndex
    )

    const beehive = beehives[beehiveIndex]

    const apiaryIndex = apiaries.findIndex((a) => a.sortId === beehive.apiaryId)

    const apiary = apiaries[apiaryIndex]

    for (
      let i = +beehive.apiarySortIndex + 1;
      i < +apiary.beehivesAmount;
      i++
    ) {
      const beehiveToUpdateIndex = beehives.findIndex(
        (b) => b.apiaryId === apiaryId && +b.apiarySortIndex === i
      )

      update(beehiveToUpdateIndex, {
        ...beehives[beehiveToUpdateIndex],
        apiarySortIndex: i - 1,
        defaultName: getDefaultBeehiveName(apiary, i - 1)
      })
    }

    remove(beehiveIndex)
    _updateApiaryBeehiveAmount(apiaryIndex, +apiary.beehivesAmount - 1)
  }

  const beehiveRowActions = useMemo<
    GenericActionButtonConfigArray<BeehiveData>
  >(
    () => [
      {
        perform: ({ data: field }) =>
          selectedApiary?.sortId &&
          deleteBeehive(selectedApiary?.sortId, field.apiarySortIndex),
        icon: <TrashCanIcon />,
        color: 'error'
      }
    ],
    [deleteBeehive, selectedApiary]
  )

  return (
    <Stack spacing={2}>
      <Controller
        control={control}
        name={'beehives.selectedApiaryIndex'}
        defaultValue={DEFAULT_SELECTED_APIARY}
        render={({ field: { onChange, value } }) => (
          <Box sx={{ px: 2 }}>
            <FormControl fullWidth>
              <InputLabel id="beehives-create-select-apiary-label">
                {t('apiarySelectLabel')}
              </InputLabel>
              <Select
                onChange={onChange}
                value={value}
                labelId="beehives-create-select-apiary-label"
                label="Apiary"
              >
                {apiaries.length ? (
                  apiaries.map((apiary, index) => {
                    return (
                      <MenuItem key={index} value={index}>
                        {apiary.name.length ? apiary.name : apiary.defaultName}
                      </MenuItem>
                    )
                  })
                ) : (
                  <MenuItem value={0}>{t('noApiaries')}</MenuItem>
                )}
              </Select>
            </FormControl>
          </Box>
        )}
      />

      {selectedApiary && (
        <>
          {selectedApiaryFields && selectedApiaryFields.length > 0 ? (
            <ItemList>
              <TableBody>
                {selectedApiaryFields.map((field, rowIndex) => (
                  <TableRow key={field.id}>
                    <TableCell>
                      <Controller
                        name={`beehives.beehives.${field.index}.name`}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            label={t('name')}
                            placeholder={field.defaultName}
                            variant="outlined"
                            value={value ?? ''}
                            onChange={onChange}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                          />
                        )}
                      />
                    </TableCell>
                    <ItemListActionButtons
                      data={field}
                      index={rowIndex}
                      actions={beehiveRowActions}
                    />
                  </TableRow>
                ))}
              </TableBody>
            </ItemList>
          ) : (
            <Typography sx={{ px: 2 }} variant="h6">
              {t('beehivesListLabel.empty')}
            </Typography>
          )}

          <Box sx={{ px: 2 }}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              startIcon={<PlusIcon />}
              onClick={addBeehive}
            >
              {t('addBeehive')}
            </Button>
          </Box>
        </>
      )}
    </Stack>
  )
}

export default BeehivesOptions
