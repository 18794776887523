import { CircularProgress, Stack } from '@mui/material'

const LoadingPage = props => {
  const { ...rest } = props

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      flexGrow="1"
      {...rest}
    >
      <CircularProgress />
    </Stack>
  )
}

export default LoadingPage
