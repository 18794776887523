import { OptionsObject, TransitionCloseHandler, useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'

/*
This file contains custom hooks to automatically present snackbar texts when certain conditions are met.
*/

/**
 * Hook for automatically presenting a snackbar text when a condition is met.
 * @param {*} controlVal condition for presenting the snackbar
 * @param {*} snackText the text to be presented in the snackbar
 * @param {*} options options to forward to the snackbar
 */
export const useStateSnackEffect = <T>(
  controlVal: T,
  snackText: string,
  options?: OptionsObject
) => {
  const { enqueueSnackbar } = useSnackbar()
  const [prevControlVal, setPrevControlVal] = useState<T | null>(null)

  useEffect(() => {
    if (controlVal && !prevControlVal) {
      enqueueSnackbar(snackText, options)
    }
    setPrevControlVal(controlVal)
  }, [controlVal])
}

/**
 * Hook for automatically presenting a success snackbar text when a condition is met.
 * @param {*} controlVal condition for presenting the snackbar
 * @param {*} snackText the text to be presented in the snackbar
 * @param {*} onClose function to be invoked when the snackbar is closed
 * @param {*} options options to forward to the snackbar
 */
export const useSuccessStateSnackEffect = <T>(
  controlVal: T,
  snackText: string,
  onClose?: TransitionCloseHandler,
  options?: OptionsObject
) => {
  useStateSnackEffect(controlVal, snackText, {
    onClose,
    variant: 'success',
    ...options
  })
}

/**
 * Hook for automatically presenting a error snackbar text when a condition is met.
 * @param {*} controlVal condition for presenting the snackbar (also presented as suffix after the text)
 * @param {*} snackText the text to be presented in the snackbar (as prefix before the error)
 * @param {*} showCause whether to display the cause of the error
 * @param {*} onClose function to be invoked when the snackbar is closed
 * @param {*} options options to forward to the snackbar
 */
export const useErrorStateSnackEffect = <T>(
  controlVal: T,
  snackText?: string,
  showCause?: boolean,
  onClose?: TransitionCloseHandler,
  options?: OptionsObject
) => {
  const { t } = useTranslation()

  if (!snackText) {
    snackText = t('general.error.general') ?? 'Error'
  }

  useStateSnackEffect(controlVal, snackText, {
    onClose: () => (controlVal ? onClose : {}),
    variant: 'error',
    ...options
  })
}
