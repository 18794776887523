import { Stack } from '@mui/material'

const PageBodyContainer = props => {
  const {
    sx,
    children,
    disableGutter,
    ...rest
  } = props

  return (
    <Stack spacing={2} sx={{ pb: !disableGutter && 3, ...sx }} {...rest}>
      {children}
    </Stack>
  )
}

export default PageBodyContainer
