import { useCallback, useMemo, useState } from 'react'
import {
  Button,
  Stack,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material'
import ItemList from '../../../common/ItemList'
import { useErrorStateSnackEffect } from '../../../../utils/hooks/stateSnackEffect'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import { AppRoutePaths, AppRoutePathSegment } from '../../../../utils/routes'
import PlusIcon from '../../../common/Icon/Plus'
import ItemListActionButtons, {
  GenericActionButtonConfigArray
} from '../../../common/ItemList/Row/ItemListActionButtons'
import TrashCanIcon from '../../../common/Icon/TrashCan'
import InfoIcon from '../../../common/Icon/Info'
import BottomContentContainer from '../../../common/Layout/BottomContentContainer'
import DeleteConfirmationDialog from '../../../DeleteConfirmationDialog'
import { Apiary } from '../../../../models/apiary'
import { BeehiveByApiaryResponse } from '../../../../models/beehive'
import V from '../../../../enums/Path/VariablePathSegment'
import {
  useGetApiaryBeehivesQuery,
  useRemoveBeehiveMutation
} from '../../../../utils/api/hooks/beehive'
import isMounted from '../../../../utils/hooks/isMounted'
import { useSnackbar } from 'notistack'

const COMPONENT_TRANSLATE_PREFIX =
  'components.apiary.viewApiary.tabs.beehivesTab'

type BeehivesTabProps = {
  apiary: Apiary
}

const BeehivesTab = (props: BeehivesTabProps) => {
  const { enqueueSnackbar } = useSnackbar()
  const { apiary } = props
  const { organizationId, apiaryId } = useParams()
  const { t } = useTranslation(undefined, {
    keyPrefix: COMPONENT_TRANSLATE_PREFIX
  })
  const { t: tGeneral } = useTranslation()
  const [beehiveToDelete, beehiveIdToDelete] = useState<string | null>(null)

  const apiaryBeehivesQuery = useGetApiaryBeehivesQuery(apiaryId!)
  const beehives = apiaryBeehivesQuery.data?.data

  const onRemoveBeehiveSuccess = useCallback(() => {
    enqueueSnackbar(t('success.deleteBeehive'), {
      variant: 'success'
    })
    location.reload()
  }, [t, enqueueSnackbar, isMounted])

  const removeBeehiveMutation = useRemoveBeehiveMutation(onRemoveBeehiveSuccess)

  function deleteBeehive(beehiveToDelete: string) {
    removeBeehiveMutation.mutate({ id: beehiveToDelete })
    beehiveIdToDelete(null)
  }

  useErrorStateSnackEffect(
    apiaryBeehivesQuery.isError,
    tGeneral('error.loadingBeehivesByApiary')
  )
  useErrorStateSnackEffect(
    removeBeehiveMutation.isError,
    t('error.deleteBeehive')
  )

  // Icons hidden since their respective funtions are not yet implemented.
  const beehiveRowActions: GenericActionButtonConfigArray<BeehiveByApiaryResponse> =
    useMemo(
      () => [
        {
          icon: <TrashCanIcon />,
          color: 'error',
          perform: ({ data }) => beehiveIdToDelete(data?.id)
        },
        // {
        //   icon: <QrIcon />
        // },
        // {
        //   icon: <ReportIcon />
        // },
        {
          icon: <InfoIcon />,
          getLink: ({ data }) => data?.id
        }
        // {
        //   icon: <NoteIcon />
        // }
      ],
      []
    )

  return (
    <Stack>
      <ItemList
        title={apiary ? apiary.name! : tGeneral('general.pending')}
        pending={apiaryBeehivesQuery.isLoading}
      >
        <TableHead></TableHead>
        <TableBody>
          {beehives &&
            beehives.values.map((beehive, index) => (
              <TableRow key={beehive.id}>
                <TableCell>{beehive.name}</TableCell>
                <ItemListActionButtons
                  index={index}
                  data={beehive}
                  actions={beehiveRowActions}
                />
              </TableRow>
            ))}
        </TableBody>
      </ItemList>

      <DeleteConfirmationDialog
        open={!!beehiveToDelete}
        pending={removeBeehiveMutation.isLoading}
        setOpenDeleteDialog={(open: boolean) => {
          !open && beehiveIdToDelete(null)
        }}
        deleteFunction={() => deleteBeehive(beehiveToDelete!)}
      />

      <BottomContentContainer>
        <Button
          component={Link}
          to={AppRoutePathSegment.replaceSegments(
            AppRoutePaths.NEW_BEEHIVE_PATH,
            [
              {
                name: V.APIARY_ID,
                value: apiary?.id
              },
              {
                name: V.ORGANIZATION_ID,
                value: organizationId!
              }
            ]
          )}
          variant="contained"
          startIcon={<PlusIcon />}
          fullWidth
        >
          {t('addBeehive')}
        </Button>
      </BottomContentContainer>
    </Stack>
  )
}

export default BeehivesTab
