import { Button, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import MagnifyerIcon from '../../components/common/Icon/Magnifyer'
import PlusIcon from '../../components/common/Icon/Plus'
import PageBodyContainer from '../../components/common/PageBodyContainer'
import TitleImage from '../../components/common/TitleImage'
import { AppRoutePaths } from '../../utils/routes'

const COMPONENT_TRANSLATE_PREFIX = 'pages.note.notesLandingPage'

const NotesLandingPage = () => {
  const { t } = useTranslation(undefined, { keyPrefix: COMPONENT_TRANSLATE_PREFIX })
  const { t: tGeneral } = useTranslation(undefined, { keyPrefix: 'general' })

  return (
    <Stack className="pageRoot">
      <TitleImage title={t('title')}/>
      <Stack className="pageContent" spacing={2}>
        <PageBodyContainer>
          <Typography variant="h2">
            {t('infoTitle')}
          </Typography>

          <Typography>
            {t('description')}
          </Typography>
        </PageBodyContainer>

        <Button
          component={Link}
          to={AppRoutePaths.NEW_NOTE_PATH}
          startIcon={<PlusIcon/>}
          variant="contained"
        >
          {tGeneral('create')}
        </Button>

        <Button
          component={Link}
          to={AppRoutePaths.LIST_NOTES_PATH}
          startIcon={<MagnifyerIcon/>}
          variant="outlined"
          color="secondary"
        >
          {t('showOlder')}
        </Button>
      </Stack>
    </Stack>
  )
}

export default NotesLandingPage
