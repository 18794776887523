import { Stack, Button, Typography } from '@mui/material'

import { Link, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { AppRoutePaths } from '../../utils/routes'
import { useTranslation } from 'react-i18next'
import {
  useErrorStateSnackEffect,
  useSuccessStateSnackEffect
} from '../../utils/hooks/stateSnackEffect'
import TitleImage from '../../components/common/TitleImage'
import ErrorsList from '../../components/common/Form/ErrorsList'
import LoadingPage from '../../components/common/LoadingPage'
import FormGrid from '../../components/common/Form/FormGrid'
import EmailField from '../../components/common/Form/FormGrid/CommonRows/EmailField'
import PasswordField from '../../components/common/Form/FormGrid/CommonRows/PasswordField'
import PasswordRepeatField from '../../components/common/Form/FormGrid/CommonRows/PasswordRepeatField'
import PageBodyContainer from '../../components/common/PageBodyContainer'
import BottomButtonsContainer from '../../components/common/Layout/BottomButtonsContainer'
import TextField from '../../components/common/Form/FormGrid/CommonRows/TextField'
import { useCallback, useMemo } from 'react'
import { useRegisterMutation } from '../../utils/api/hooks/auth'
import useIsMounted from '../../utils/hooks/isMounted'
import { ApiError } from 'openapi-typescript-fetch'
import { StatusCodes } from 'http-status-codes'
import { useSnackbar } from 'notistack'

const COMPONENT_TRANSLATE_PREFIX = 'pages.auth.register'

type RegisterFormValue = {
  firstname: string
  surname: string
  email: string
  password: string
  passwordRepeat: string
}

const Register = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: COMPONENT_TRANSLATE_PREFIX
  })
  const { t: tGlobal } = useTranslation()

  const methods = useForm<RegisterFormValue>({
    mode: 'onSubmit'
  })

  const { getValues, handleSubmit, formState } = methods

  const navigate = useNavigate()
  const isMounted = useIsMounted()
  const { enqueueSnackbar } = useSnackbar()

  const onSubmitSuccess = useCallback(() => {
    if (isMounted()) {
      const form = getValues()

      enqueueSnackbar(t('registerSuccess'), { variant: 'success' })

      navigate(AppRoutePaths.VERIFY_USER_EMAIL_PATH, {
        state: {
          email: form.email,
          password: form.password
        }
      })
    }
  }, [isMounted, getValues, enqueueSnackbar, t])

  const registerMutation = useRegisterMutation(onSubmitSuccess)

  useSuccessStateSnackEffect(registerMutation.isSuccess, t('registerSuccess'))

  const registerErrorMessage = useMemo(() => {
    if (registerMutation.error instanceof ApiError) {
      switch (registerMutation.error.status) {
        case StatusCodes.CONFLICT:
          return t('actions.register.errors.conflict')
        case StatusCodes.BAD_REQUEST:
          return t('actions.register.errors.weakPassword')
      }
    }
  }, [registerMutation.error, t, tGlobal])

  useErrorStateSnackEffect(registerMutation.isError, registerErrorMessage)

  const onSubmit = (form: RegisterFormValue) => {
    registerMutation.mutate(form)
  }

  return (
    <Stack className="pageRoot">
      <TitleImage title={t('title')} src="/images/generic-background.png" />

      {registerMutation.isLoading ? (
        <LoadingPage className="pageContent" />
      ) : (
        <Stack
          className="pageContent"
          component="form"
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
          spacing={2}
        >
          <PageBodyContainer>
            <Typography variant="h2">{t('infoTitle')}</Typography>

            <Typography>
              {t('descriptionCredentials')}
              <br />
              {t('descriptionMail')}
            </Typography>
          </PageBodyContainer>

          <FormGrid
            methods={methods}
            translatePrefix={COMPONENT_TRANSLATE_PREFIX}
            rules={{ required: true }}
          >
            <TextField name="firstname" xs={6} />

            <TextField name="surname" xs={6} />

            <EmailField />

            <PasswordField />
            <PasswordRepeatField />
          </FormGrid>

          <ErrorsList formState={formState} />

          <BottomButtonsContainer>
            <Button
              color="secondary"
              component={Link}
              to={AppRoutePaths.LOGIN_PATH}
            >
              {tGlobal('general.cancel')}
            </Button>

            <Button variant="contained" color="secondary" type="submit">
              {tGlobal('general.finish')}
            </Button>
          </BottomButtonsContainer>
        </Stack>
      )}
    </Stack>
  )
}

export default Register
