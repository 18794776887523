import { useCallback, useMemo, useState } from 'react'
import { UseStateArray } from '../../models/state'
import useIsMounted from './isMounted'

/**
 *              !!!!!! DEPRECATED !!!!!!
 *                     DO NOT USE
 *
 * See readme for more information
 */
type UseAsyncRequestState<RequestFnArgs extends any[], ReqeustFnResult> = {
  data: UseStateArray<ReqeustFnResult | null>
  pending: UseStateArray<boolean>
  fulfilled: UseStateArray<boolean>
  error: UseStateArray<any>
  invoked: UseStateArray<boolean>
  execute: (...args: RequestFnArgs) => Promise<void>
}

/**
 *
 * @param {*} requestFn function to invoke request
 * @param {*} onFulfilled (optional) separate function to call on fulfilled invoke
 * @returns {Object} state The state for the request data
 * @returns {Array} state.data [data, setData]
 * @returns {Array} state.pending [pending, setPending]
 * @returns {Array} state.fullfilled [fulfilled, setFulfilled]
 * @returns {Array} state.error [error, setError]
 * @returns {Array} state.invoked [invoked, setInvoked]
 * @returns {void} state.error The function to invoke the request method. Forwards arguments.
 */
function useAsyncRequest<RequestFnArgs extends any[], ReqeustFnResult>(
  requestFn: (...a: RequestFnArgs) => Promise<ReqeustFnResult>,
  onFulfilled?: (data: ReqeustFnResult) => void
) {
  const [data, setData] = useState<ReqeustFnResult | null>(null)
  const [pending, setPending] = useState<boolean>(false)
  const [invoked, setInvoked] = useState<boolean>(false)
  const [fulfilled, setFulfilled] = useState<boolean>(false)
  const [error, setError] = useState<Error | null>(null)

  const isMounted = useIsMounted()

  const execute = useCallback(
    async (...args: RequestFnArgs) => {
      setInvoked(true)
      setPending(true)
      setFulfilled(false)
      setError(null)
      setData(null)

      return requestFn(...args)
        .then((res) => {
          if (!isMounted()) return
          setData(res)
          setFulfilled(true)
          setPending(false)
          if (onFulfilled) {
            onFulfilled(res)
          }
        })
        .catch((err) => {
          if (!isMounted()) return
          setError(err)
          setPending(false)
          throw err
        })
    },
    [requestFn]
  )

  return useMemo(
    () => ({
      data: [data, setData],
      pending: [pending, setPending],
      fulfilled: [fulfilled, setFulfilled],
      error: [error, setError],
      invoked: [invoked, setInvoked],
      execute
    }),
    [data, pending, fulfilled, error, invoked, execute]
  ) as UseAsyncRequestState<RequestFnArgs, ReqeustFnResult>
}

export default useAsyncRequest
