import beehiveEditForm from './beehiveEditForm'
import iotTab from './iotTab'
import sensorDataChart from './sensorDataChart'
import beeHiveInfo from './beeHiveInfo'
import updateReferenceWeight from './updateReferenceWeight'
import alarmDialog from './alarmDialog'

const beehive = {
  sensorDataChart,
  iotTab,
  beehiveEditForm,
  beeHiveInfo,
  updateReferenceWeight,
  alarmDialog
}

export default beehive
