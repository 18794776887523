import {
  Button,
  Grid,
  Modal,
  Rating,
  TextField,
  Typography
} from '@mui/material'
import { useCallback, useState } from 'react'
import { UserData } from '../../models/user'
import { useTranslation } from 'react-i18next'
import useIsMounted from '../../utils/hooks/isMounted'
import { useSnackbar } from 'notistack'
import dayjs from 'dayjs'

const COMPONENT_TRANSLATE_PREFIX = 'components.common.reviewModal'

export type DialogCloseCallback = (ratingValue: number, text: string) => void

type ReviewModalProps = {
  onClose: DialogCloseCallback
  userData?: UserData
}

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 800,
  minWidth: 300,
  bgcolor: 'background.paper',
  border: '2px solid #292929',
  boxShadow: 24,
  p: 4,
  s: 4,
  borderRadius: 2
}

const ReviewModal = (props: ReviewModalProps) => {
  const { onClose, userData } = props
  const { t } = useTranslation(undefined, {
    keyPrefix: COMPONENT_TRANSLATE_PREFIX
  })
  const isMounted = useIsMounted()
  const { enqueueSnackbar } = useSnackbar()

  const successSnackbar = useCallback(() => {
    if (isMounted()) {
      enqueueSnackbar(t('actions.success'), {
        variant: 'success'
      })
    }
  }, [isMounted])

  const openModal = () => {
    if (
      userData?.hasReviewedApp &&
      dayjs().diff(dayjs(userData.reviewRequestDate), 'day') > 4
    ) {
      return true
    }
    return false
  }

  const [open, setOpen] = useState(() => openModal())
  const [text, setText] = useState('')
  const [rating, setRating] = useState(2.5)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleRatingChange = (event: any, newValue: number | null) => {
    if (newValue === null) return

    const clampedValue = Math.max(0, Math.min(5, newValue))
    setRating(clampedValue)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleTextChange = (event: any) => {
    setText(event.target.value)
  }

  const handleClose = () => {
    successSnackbar()
    setOpen(false)
    onClose(rating, text)
  }
  // will not save the review, only set new review request date
  const closeByEscape = () => {
    setOpen(false)
    onClose(-1, '')
  }

  return (
    <Modal open={open} onClose={() => closeByEscape}>
      <Grid sx={style}>
        <Typography variant="h1" sx={{ paddingBottom: 2 }}>
          {t('sections.main.header')}
        </Typography>
        <Typography variant="body1" sx={{ paddingBottom: 2 }}>
          {t('sections.main.body')}
        </Typography>
        <Rating
          name="half-rating"
          defaultValue={2.5}
          precision={0.5}
          value={rating}
          size="large"
          onChange={handleRatingChange}
        />
        <TextField
          id="outlined-basic"
          variant="outlined"
          multiline
          fullWidth
          rows={10}
          onChange={handleTextChange}
        />
        <Grid
          container
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          justifyContent="center"
          alignItems="center"
          sx={{ paddingTop: 2 }}
        >
          <Grid item xs="auto">
            <Button
              size="small"
              variant="text"
              color="secondary"
              onClick={closeByEscape}
            >
              {t('buttons.cancel')}
            </Button>
          </Grid>
          <Grid item xs="auto">
            <Button
              size="large"
              variant="contained"
              color="primary"
              onClick={handleClose}
            >
              {t('buttons.submit')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  )
}

export default ReviewModal
