import { useMutation, useQuery } from '@tanstack/react-query'
import { CallbackVoid } from '../util/types'
import { useApiClient } from './useApiClient'
import { NoteQueryFilter } from '../../../models/notes'
import { PaginationQuery, usePagination } from './pagination'

export function useCreateOtherNoteMutation(onSuccess?: CallbackVoid) {
  const apiClient = useApiClient()

  return useMutation({
    mutationFn: apiClient.OtherNote.createOtherNote,
    onSuccess: onSuccess
  })
}

export function useDeleteOtherNoteMutation(onSuccess?: CallbackVoid) {
  const apiClient = useApiClient()

  return useMutation({
    mutationFn: apiClient.OtherNote.deleteOtherNoteById,
    onSuccess: onSuccess
  })
}

export const GET_OTHER_NOTES_LIST_KEY = 'OtherNoteList/get'

export function useGetOtherNotesQuery(
  filterQuery: NoteQueryFilter,
  initialQuery?: PaginationQuery
) {
  const apiClient = useApiClient()
  const pagination = usePagination(initialQuery)

  return {
    query: useQuery(
      [GET_OTHER_NOTES_LIST_KEY, ...pagination.queryKeys],
      () => apiClient.OtherNote.getOtherNotes(filterQuery),
      {
        enabled:
          !!filterQuery.apiaryId ||
          !!filterQuery.beehiveId ||
          !!filterQuery.organizationId
      }
    ),
    pagination
  }
}

export const GET_OTHER_NOTE_BY_ID_KEY = 'OtherNoteById/get'

export function useGetOtherNoteByIdQuery(id: string) {
  const apiClient = useApiClient()

  return useQuery(
    [GET_OTHER_NOTE_BY_ID_KEY, id],
    async () => {
      return apiClient.OtherNote.getOtherNoteById({
        id: id
      })
    },
    { enabled: !!id }
  )
}

export function usePutEditOtherNote(onSuccess?: CallbackVoid) {
  const apiClient = useApiClient()

  return useMutation({
    mutationFn: apiClient.OtherNote.editOtherNote,
    onSuccess: onSuccess
  })
}
