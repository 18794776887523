const addApiaries = {
  title: 'Add Apiaries',
  stepTitle: {
    apiaries: 'Add Apiaries',
    beehives: 'Adjust Beehives',
    review: 'Overview'
  },
  actions: {
    createApiaries: {
      pending: 'Creating apiaries',
      fulfilled: 'Apiaries have been created!',
      error: 'An error occurred while creating apiaries'
    }
  },
  fields: {
    apiaries: {
      errors: {
        minLength: 'Apiaries are required'
      }
    }
  }
}

export default addApiaries
