import apiary from './apiary'
import beehive from './beehive'
import forms from './forms'
import map from './map'
import note from './note'
import organization from './organization'
import overlay from './overlay'
import routes from './routes'
import user from './user'
import notes from './notes'
import common from './common'
import navigation from './navigation'

const components = {
  forms,
  overlay,
  beehive,
  map,
  routes,
  organization,
  apiary,
  user,
  note,
  notes,
  common,
  navigation
}

export default components
