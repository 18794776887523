import { Grid, GridTypeMap } from '@mui/material'
import { DefaultComponentProps } from '@mui/material/OverridableComponent'
import { JSXElementConstructor, ReactElement, useContext, useMemo } from 'react'
import { Controller, ControllerProps, useFormContext } from 'react-hook-form'
import { TFunction, useTranslation } from 'react-i18next'
import {
  ControllerRenderProps,
  FormGridFieldCustomRenderProps,
  FormGridRules
} from '../../../../models/form'
import FormGridContext from './FormGridContext'

const getFieldTitle = (translateKey: string, t: TFunction) =>
  t(`fields.${translateKey}.label`)

export const transformRules = (
  t: TFunction,
  translateKey: string,
  rules?: FormGridRules | null
) =>
  rules
    ? Object.entries(rules).reduce(
        (res, [ruleKey, ruleValue]) => ({
          ...res,
          [ruleKey]: translatedRule(t, translateKey, ruleKey, ruleValue)
        }),
        {}
      )
    : []

const translatedRule = (
  t: TFunction,
  translateKey: string,
  ruleKey: string,
  ruleValue: any
) => {
  if (typeof ruleValue === 'object' && !(ruleValue instanceof RegExp)) {
    translateKey = ruleValue.nameOverride
    ruleValue = ruleValue.value
  }

  return ruleKey === 'validate'
    ? (value: any) => {
        const res = ruleValue(value)
        return res === true || t(`fields.${translateKey}.errors.${res}`)
      }
    : {
        value: ruleValue,
        message: t(`fields.${translateKey}.errors.${ruleKey}`, {
          [ruleKey]: ruleValue
        })
      }
}

export type FormGridFieldProps<
  InputProps,
  OnChangeValueType,
  OutputValueType = OnChangeValueType
> = {
  name: string
  label?: string
  required?: boolean
  placeholder?: string | boolean
  labelSuffix?: string
  translatePrefix?: string
  translateKey?: string
  onChange?: (val: OnChangeValueType) => OutputValueType
  render: (
    props: FormGridFieldCustomRenderProps<
      InputProps,
      OnChangeValueType,
      OutputValueType
    >
  ) => ReactElement<any, string | JSXElementConstructor<any>>
  rules?: FormGridRules
  controllerProps?: ControllerProps
  inputProps?: InputProps
  disabled?: boolean
} & Omit<DefaultComponentProps<GridTypeMap>, 'placeholder' | 'onChange'>

/**
 * Generic component for standardizing grid and react-use-form functionalities for form fields
 * @param {*} props.name name of the field
 * @param {*} props.label optional label override for the field
 * @param {*} props.placeholder optional placeholder to use, if string -> supplied string is shown, if true, translatekey 'placeholder' under translateobj is shown
 * @param {*} props.labelSuffix optional suffix to append to the label
 * @param {*} props.translateKey optional translateKey to override automatic key based on name
 * @param {*} props.translatePrefix optional translateKey to override form global prefix
 * @param {*} props.onChange optional function to intercept onChange of the field before it is passed to the controller
 * @param {*} props.inputProps optional props object to supply to the field render function or default render
 * @param {*} props.render optional render function to render field content, recieves object {name, label, required, controller, ...inputProps}
 * @param {*} props.controllerProps optional props to supply to the controller component
 * @param {*} props.disabled optional whether the component is disabled
 */
function FormGridField<
  InputProps,
  FieldValueType = string,
  OutputValueType = FieldValueType
>(props: FormGridFieldProps<InputProps, FieldValueType, OutputValueType>) {
  const {
    name,
    label,
    placeholder,
    labelSuffix,
    translateKey,
    translatePrefix: propsTranslatePrefix,
    onChange,
    render,
    rules,
    controllerProps,
    inputProps,
    required,
    disabled,
    ...rest
  } = props

  const { control } = useFormContext()
  const formContext = useContext(FormGridContext)

  const { namePrefix, translatePrefix, gridRules } = formContext

  const translatePrefixToUse = propsTranslatePrefix ?? translatePrefix
  const translateKeyToUse = translateKey ?? name

  const { t } = useTranslation(undefined, { keyPrefix: translatePrefixToUse })

  const rulesToUse = useMemo(
    () => ({
      ...gridRules,
      ...rules,
      required: required ?? rules?.required ?? gridRules?.required ?? false
    }),
    [gridRules, rules]
  )

  const transformedRules = useMemo(
    () => transformRules(t, name, rulesToUse),
    []
  )

  const computedName = useMemo(
    () => (namePrefix ? `${namePrefix}.${name}` : name),
    [namePrefix, name]
  )

  const renderContent = (controller: ControllerRenderProps) => {
    const labelPrefix = label ?? getFieldTitle(translateKeyToUse, t)
    const labelCustomSuffix = labelSuffix ? ` ${labelSuffix}` : ''

    let shouldDisplayMaxLengthLabelSuffix = false

    if (rulesToUse?.maxLength && inputProps && 'type' in inputProps) {
      const inputPropsType = (inputProps as any).type as string
      shouldDisplayMaxLengthLabelSuffix =
        inputPropsType === 'number' ||
        inputPropsType === undefined ||
        inputPropsType === 'text'
    }

    let fieldValueLength
    let labelMaxLengthSuffix

    if (shouldDisplayMaxLengthLabelSuffix) {
      const fieldValue = controller.field.value

      if (fieldValue === null || fieldValue === undefined) {
        fieldValueLength = 0
      } else if (((inputProps as any)?.type as string) === 'number') {
        fieldValueLength = fieldValue.toString().length
      } else {
        fieldValueLength = fieldValue.length
      }

      labelMaxLengthSuffix = `(${fieldValueLength}/${rulesToUse?.maxLength})`
    } else {
      labelMaxLengthSuffix = ''
    }

    const labelToUse = `${labelPrefix}${labelCustomSuffix}${labelMaxLengthSuffix}`

    const renderProps: FormGridFieldCustomRenderProps<
      InputProps,
      FieldValueType,
      OutputValueType
    > = {
      name: name,
      label: labelToUse,
      translatePrefix: translatePrefixToUse,
      required: !!rulesToUse?.required,
      placeholder: placeholder
        ? placeholder === true
          ? t(`fields.${translateKeyToUse}.placeholder`)
          : placeholder
        : '',

      controller: {
        ...controller,
        field: {
          ...controller.field,
          value:
            controller.field.value === undefined ||
            controller.field.value === null
              ? (inputProps as any)?.type === 'number'
                ? 0
                : ''
              : controller.field.value
        }
      },
      inputProps
    }

    if (onChange) {
      renderProps.onChange = onChange
    }

    if (disabled) {
      renderProps.disabled = disabled
    }

    return render(renderProps)
  }

  return (
    <Grid item xs={12} {...rest}>
      <Controller
        name={computedName}
        control={control}
        render={renderContent}
        rules={transformedRules}
        {...controllerProps}
      />
    </Grid>
  )
}

export default FormGridField
