const listOrganizations = {
  title: 'Organizations',
  searchAllOrganizations: 'Search all organizations',
  myOrganizations: {
    title: 'My organizations',
    header: {
      name: 'Name',
      apiariesAmount: 'Number of apiaries'
    }
  }
}

export default listOrganizations
