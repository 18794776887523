const editNote = {
  actions: {
    loadInspectionNote: {
      error: 'An error occurred while loading the note'
    },
    updateInspectionNote: {
      error: 'An error occurred while saving the changes',
      success: 'Changes have been saved.'
    }
  }
}

export default editNote
