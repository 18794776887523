import './styles/fonts.css'
import './styles/index.css'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'

import { I18nextProvider } from 'react-i18next'
import i18n from './i18n'

import { SnackbarProvider } from 'notistack'
import { QueryClientProvider } from '@tanstack/react-query'
import { queryClient } from './utils/api/queryClient'
import * as apiClient from './utils/api/apiClient'
import ApiClientProvider from './utils/api/ApiClientProvider'
import AuthContextProvider from './utils/api/AuthContextProvider'

ReactDOM.render(
  // <React.StrictMode>
  <I18nextProvider i18n={i18n}>
    <SnackbarProvider maxSnack={3}>
      <AuthContextProvider>
        <ApiClientProvider client={apiClient}>
          <QueryClientProvider client={queryClient}>
            <App />
          </QueryClientProvider>
        </ApiClientProvider>
      </AuthContextProvider>
    </SnackbarProvider>
  </I18nextProvider>,
  // </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
