import { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import FormGrid from '../../common/Form/FormGrid'
import ApiarySelectField from '../../common/Form/FormGrid/CommonRows/ApiarySelectField'
import BeehiveSelectField from '../../common/Form/FormGrid/CommonRows/BeehiveSelectField'
import OrganizationSelectField from '../../common/Form/FormGrid/CommonRows/OrganizationSelectField'
import { useErrorStateSnackEffect } from '../../../utils/hooks/stateSnackEffect'
import { useTranslation } from 'react-i18next'
import { NoteCategory } from '../../../models'
import { useUserOrganizationsQuery } from '../../../utils/api/hooks/organization'
import { useGetApiariesForOrganizationQuery } from '../../../utils/api/hooks/apiary'
import { useGetApiaryBeehivesQuery } from '../../../utils/api/hooks/beehive'

const COMPONENT_TRANSLATE_PREFIX = 'components.note.newNote.newNoteEntitySelect'

type NewNoteEntitySelectProps = {
  name: string
  categorySelectName: string
}

const NewNoteEntitySelect = (props: NewNoteEntitySelectProps) => {
  const { name, categorySelectName /*, entitySelectName */ } = props
  const { t: tGeneral } = useTranslation(undefined, { keyPrefix: 'general' })

  const { watch } = useFormContext()

  const watchSubField = (fieldName: string) => watch(`${name}.${fieldName}`)

  const selectedCategory = watch(categorySelectName)
  const selectedOrganization = watchSubField('organization')
  const selectedApiary = watchSubField('apiary')

  const userOrganizationsQuery = useUserOrganizationsQuery()
  const organizations = userOrganizationsQuery.query.data?.data.values
  const organizationsPending = userOrganizationsQuery.query.isLoading
  const organizationsError = userOrganizationsQuery.query.isError

  useErrorStateSnackEffect(organizationsError, tGeneral('error'))

  const useGetApiariesByOrganizationIdQuery =
    useGetApiariesForOrganizationQuery(selectedOrganization?.id)
  const apiaries = useGetApiariesByOrganizationIdQuery.data?.data.values
  const apiariesPending = useGetApiariesByOrganizationIdQuery.isLoading
  const apiariesError = useGetApiariesByOrganizationIdQuery.isError

  useErrorStateSnackEffect(apiariesError, tGeneral('error'))

  const useGetBeehivesByApiaryQuery = useGetApiaryBeehivesQuery(
    selectedApiary?.id
  )
  const beehives = useGetBeehivesByApiaryQuery.data?.data.values
  const beehivesPending = useGetBeehivesByApiaryQuery.isLoading
  const beehivesError = useGetBeehivesByApiaryQuery.isError

  useErrorStateSnackEffect(beehivesError, tGeneral('error'))

  const organizationsToDisplay = useMemo(
    () => organizations?.map((org) => org),
    [organizations]
  )

  return (
    <FormGrid
      name={name}
      translatePrefix={COMPONENT_TRANSLATE_PREFIX}
      rules={{ required: true }}
    >
      <OrganizationSelectField
        loading={organizationsPending}
        options={organizationsToDisplay}
      />

      {selectedCategory !== NoteCategory.ORGANIZATION && (
        <>
          <ApiarySelectField
            loading={apiariesPending}
            disabled={!selectedOrganization}
            options={apiaries}
          />

          {selectedCategory !== NoteCategory.APIARY && (
            <BeehiveSelectField
              loading={beehivesPending}
              disabled={!selectedApiary}
              options={beehives}
            />
          )}
        </>
      )}
    </FormGrid>
  )
}

export default NewNoteEntitySelect
