import { Routes, Route } from 'react-router-dom'

import NewOrganization from './pages/organizations/NewOrganization'
// import ListApiaries from './pages/apiaries/ListApiaries'
import EditApiary from './pages/apiaries/EditApiary'
// import ListBeehives from './pages/beehives/ListBeehives'
import NewBeehive from './pages/beehives/NewBeehive'
import EditBeehive from './pages/beehives/EditBeehive'
import { AppRoutePaths } from './utils/routes'

import Login from './pages/Auth/Login'
import Register from './pages/Auth/Register'
import VerifyUserEmail from './pages/Auth/VerifyUserEmail'

import ListOrganizations from './pages/organizations/ListOrganizations'
import DisplayOrganization from './pages/organizations/DisplayOrganization'
import Alarms from './pages/Alarms'
import DisplayBeehive from './pages/beehives/DisplayBeehive'
import AddApiaries from './pages/apiaries/AddApiaries'
import AuthLandingPage from './pages/Auth/LandingPage'
import PasswordReset from './pages/Auth/PasswordReset'
import PageNotFound from './pages/PageNotFound'
import UserProfileEdit from './pages/User/UserProfileEdit'
import UserProfile from './pages/User/UserProfile'
import AboutPage from './pages/About'
import NotesLandingPage from './pages/Note/NotesLandingPage'
import SearchOrganization from './pages/organizations/SearchOrganization'

import GettingStartedGuide from './pages/Guide/GettingStartedGuide'
import ListNotes from './pages/Note/ListNotes'
import EditOrganization from './pages/organizations/EditOrganization'
import EditMemberPage from './pages/organizations/EditMember'
import DisplayOtherNote from './pages/Note/DisplayOtherNote'
import EditBeehiveInspectionNote from './pages/Note/EditBeehiveInspectionNote'
import DisplayBeehiveInspectionNote from './pages/Note/DisplayBeehiveInspectionNote'
import LoggedInLandingPage from './pages/LoggedInLandingPage'
import ProtectedRoute from './components/route/ProtectedRoute'
import ViewApiary from './pages/apiaries/ViewApiary'
import NewNotePage from './pages/Note/NewNotePage'

const AppRoutes = () => {
  return (
    <Routes>
      <Route
        path={AppRoutePaths.LANDING_PATH}
        element={
          <ProtectedRoute type="anonymous" element={<AuthLandingPage />} />
        }
      />

      <Route
        path={AppRoutePaths.EDIT_ORGANIZATION_MEMBER_PATH}
        element={<ProtectedRoute element={<EditMemberPage />} />}
      />

      <Route
        path={AppRoutePaths.LOGIN_PATH}
        element={<ProtectedRoute element={<Login />} type="anonymous" />}
      />

      <Route
        path={AppRoutePaths.PASSWORD_RESET_PATH}
        element={
          <ProtectedRoute element={<PasswordReset />} type="anonymous" />
        }
      />

      <Route
        path={AppRoutePaths.REGISTER_PATH}
        element={<ProtectedRoute element={<Register />} type="anonymous" />}
      />

      <Route
        path={AppRoutePaths.VERIFY_USER_EMAIL_PATH}
        element={
          <ProtectedRoute element={<VerifyUserEmail />} type="anonymous" />
        }
      />

      <Route
        path={AppRoutePaths.HOME_PATH}
        element={
          <ProtectedRoute
            element={<ProtectedRoute element={<LoggedInLandingPage />} />}
          />
        }
      />

      <Route
        path={AppRoutePaths.ABOUT_PATH}
        element={<ProtectedRoute element={<AboutPage />} />}
      />

      <Route
        path={AppRoutePaths.MY_PROFILE_PATH}
        element={<ProtectedRoute element={<UserProfile />} />}
      />

      <Route
        path={AppRoutePaths.EDIT_PROFILE_PATH}
        element={<ProtectedRoute element={<UserProfileEdit />} />}
      />

      <Route
        path={AppRoutePaths.NOTES_PATH}
        element={<ProtectedRoute element={<NotesLandingPage />} />}
      />

      <Route
        path={AppRoutePaths.LIST_NOTES_PATH}
        element={<ProtectedRoute element={<ListNotes />} />}
      />

      <Route
        path={AppRoutePaths.VIEW_OTHER_NOTE_PATH}
        element={<ProtectedRoute element={<DisplayOtherNote />} />}
      />

      <Route
        path={AppRoutePaths.NEW_NOTE_PATH}
        element={<ProtectedRoute element={<NewNotePage />} />}
      />

      <Route
        path={AppRoutePaths.LIST_NOTES_PATH}
        element={<ProtectedRoute element={<ListNotes />} />}
      />

      <Route
        path={AppRoutePaths.EDIT_BEEHIVE_INSPECTION_NOTE_PATH}
        element={<ProtectedRoute element={<EditBeehiveInspectionNote />} />}
      />

      <Route
        path={AppRoutePaths.VIEW_BEEHIVE_INSPECTION_NOTE_PATH}
        element={<ProtectedRoute element={<DisplayBeehiveInspectionNote />} />}
      />

      <Route
        path={AppRoutePaths.EDIT_BEEHIVE_INSPECTION_NOTE_PATH}
        element={<ProtectedRoute element={<EditBeehiveInspectionNote />} />}
      />

      <Route
        path={AppRoutePaths.LIST_ORGANIZATIONS_PATH}
        element={<ProtectedRoute element={<ListOrganizations />} />}
      />

      <Route
        path={AppRoutePaths.NEW_ORGANIZATION_PATH}
        element={<ProtectedRoute element={<NewOrganization />} />}
      />

      <Route
        path={AppRoutePaths.DISPLAY_ORGANIZATION_PATH}
        element={<ProtectedRoute element={<DisplayOrganization />} />}
      />

      <Route
        path={AppRoutePaths.EDIT_ORGANIZATION_PATH}
        element={<ProtectedRoute element={<EditOrganization />} />}
      />

      <Route
        path={AppRoutePaths.ALARMS_PATH}
        element={<ProtectedRoute element={<Alarms />} />}
      />

      <Route
        path={AppRoutePaths.PROFILE_PATH}
        element={<ProtectedRoute element={<UserProfile />} />}
      />

      <Route
        path={AppRoutePaths.ADD_APIARIES_PATH}
        element={<ProtectedRoute element={<AddApiaries />} />}
      />

      <Route
        path={AppRoutePaths.EDIT_APIARY_PATH}
        element={<ProtectedRoute element={<EditApiary />} />}
      />

      {/*       <Route
        path={AppRoutePaths.LIST_APIARIES_PATH}
        element={<ProtectedRoute element={<ListApiaries />} />}
      /> */}

      {/*  No longer used, is replaced by beehivesTab.tsx
     <Route
        path={AppRoutePaths.LIST_BEEHIVES_PATH}
        element={<ProtectedRoute element={<ListBeehives />} />}
      /> */}

      <Route
        path={AppRoutePaths.NEW_BEEHIVE_PATH}
        element={<ProtectedRoute element={<NewBeehive />} />}
      />

      <Route
        path={AppRoutePaths.EDIT_BEEHIVE_PATH}
        element={<ProtectedRoute element={<EditBeehive />} />}
      />

      <Route
        path={AppRoutePaths.DISPLAY_BEEHIVE_PATH}
        element={<ProtectedRoute element={<DisplayBeehive />} />}
      />

      <Route
        path={AppRoutePaths.SEARCH_ORGANIZATION_PATH}
        element={<ProtectedRoute element={<SearchOrganization />} />}
      />

      <Route
        path={AppRoutePaths.APIARY_VIEW_PATH}
        element={<ProtectedRoute element={<ViewApiary />} />}
      />

      <Route
        path={AppRoutePaths.GETTING_STARTED_PATH}
        element={<ProtectedRoute element={<GettingStartedGuide />} />}
      />

      <Route path="*" element={<PageNotFound />} />
    </Routes>
  )
}

export default AppRoutes
