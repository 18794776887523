import { createContext } from 'react'
import { useSignOutMutation } from './hooks/auth'

export type AuthState = {
  sessionJwtToken: string
  userId: string
  hasSeenStartupGuide: boolean
}

export type AuthContextValue = {
  authState: AuthState | null
  setAuthState: (data: AuthState | null) => void
  clearAuthState: () => void
}

const AuthContext = createContext<AuthContextValue>({
  authState: null,
  setAuthState: () => undefined,
  clearAuthState: () => {
    useSignOutMutation().mutate({})
  }
})

export default AuthContext
