const about = {
  title: 'About us',
  contactButton: 'Contact us',
  contactTitle: 'Contact',

  sections: {
    background: {
      title: 'Background',
      sections: {
        intro:
          'BeeLab Technology Sweden AB is a newly started Swedish company based in Kalmar. We are a group of enthusiasts who care about bees and want to work towards developing beekeeping. Our team consists of experienced back-end specialists, technology developers, UX/UI experts, professional craftsmen, and of course, bee expertise! The company CEO is Mikael Ekström with extensive experience in beekeeping.',
        details:
          'BeeLab is a company founded to provide a series of products to facilitate modern beekeeping. In addition to offering two models of state-of-the-art and easy-to-use hives, BeeLab App is also provided, where beekeepers can monitor their hives in real-time and obtain historical data such as weight, humidity, and temperature. The app also provides solutions for documenting beekeeping. To provide this data, there is also BeeLab Gateway - a sensor kit that is placed in the hive to collect information.'
      }
    },
    vision: {
      title: 'Vision',
      sections: {
        default:
          "The bee mortality is difficult and it requires significantly more research, data, and work to truly understand the underlying causes of the disaster that is currently affecting bees and bee communities. This disaster is global and affects all beekeepers, large and small. At BeeLab, we consider it our highest priority to find the root of bee mortality so that solutions can be found immediately. BeeLab's services can be very important to initiate such an initiative, as they contribute to long-term global sustainability and biological diversity - because if bees die, the planet dies!"
      }
    },
    strategy: {
      title: 'Strategy',
      sections: {
        default:
          ' At BeeLab, we care about bees and are passionate about developing technology and environments that make it easier for both beekeepers and bees. In our team, we have everything from bee experts to woodworking specialists and technology enthusiasts. This unique combination of knowledge and experience creates a dynamic that delivers results. As a result of our genuine interest, we have the insight needed to understand the market, production, and what works best - through hands-on experience!'
      }
    },
    history: {
      title: 'History',
      sections: {
        hsb: {
          title: 'How the BeeLab Project was founded',
          default:
            ' In Gothenburg, there has been a project called HSB Living Lab for several years. It is a residential building that also functions as a research site with sensors and other equipment to find long-term and smart solutions. BeeLab was founded as a 3-year project between Tieto Evry and HSB Living Lab to create a beehive with the same function as the apartments in Gothenburg – to monitor essential data and find new solutions to beekeeping problems.'
        },
        beelab: {
          title: 'BeeLab Technology Sweden AB',
          default:
            " was founded in 2020 with the aim of building on the BeeLab project that is only running between 2019-2022. Through this company, we have continued to develop the hives that were built during the project era, but also created new hives to meet the demand that exists. Our connected hives are now located throughout Sweden, including at Tieto Evry Kalmar, HSB Living Labs in Gothenburg, and at the Beekeepers' office in Skänninge."
        }
      }
    },
    GDPR: {
      title: 'GDPR',
      informationText:
        'In accordance with Article 6.1.b of the EU General Data Protection Regulation, we store the personal data that you provide when you register to use the service. Apart from this information, we do not collect any additional data about you. We do not use cookies or similar tools to track you, our servers are located in Sweden, and we do not share your information with third parties. \n\nThe person responsible for processing personal data is Mikael Ekström. \n\nEmail:  mikael.ekstrom@beelab.se'
    }
  }
}

export default about
