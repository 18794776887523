const apiaryLocationSelect = {
  yourLocation: 'Your location',
  apiarySelect: {
    chooseApiary: 'Choose apiary',
    apiaryLabel: 'Apiary'
  },
  overlay: {
    buttons: {
      save: 'Save',
      menu: 'Menu',
      confirm: 'Accept places',
      cancel: 'Cancel',
      chooseApiary: 'Choose apiary',
      moveApiaryToCurrCamLocation: 'Move marker here',
      goToApiary: 'Go to apiary',
      moveCameraToCurrLocation: 'Go to current location',
      showAllApiaries: 'Show all apiaries',
      showOnlySelectedApiary: 'Show only selected apiary'
    }
  }
}

export default apiaryLocationSelect
