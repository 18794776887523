// TODO: Migrate old path segments to new segment enum
import { AppRoutePathSegment as SEG } from '../../../routes'
import S_SEG from '../../../../enums/Path/StaticPathSegment'

const routes = {
  [SEG.HOME]: 'Start',
  [SEG.ABOUT]: 'Om oss',
  [SEG.NEW]: 'Skapa ny',
  [SEG.EDIT]: 'Redigera',
  [SEG.PASSWORD_RESET]: 'Återställ lösenord',
  [SEG.LOGIN]: 'Logga in',
  [SEG.REGISTER]: 'Registrera konto',
  [SEG.VERIFY_USER_EMAIL]: 'Verifiera konto',
  [SEG.ORGANIZATION]: 'Organisationer',
  [SEG.ORGANIZATION_ID]: 'Organisation',
  [SEG.APIARY]: 'Bigårdar',
  [SEG.APIARY_ID]: 'Bigård',
  [SEG.NEW_APIARIES]: 'Lägg till bigårdar',
  [SEG.BEEHIVE]: 'Bikupor',
  [SEG.BEEHIVE_ID]: 'Bikupa',
  [SEG.ALARM]: 'Alarm',
  [SEG.NOTE]: 'Anteckningar',
  [SEG.NOTE_ID]: 'Anteckning',
  [SEG.ALL_NOTES]: 'Alla anteckningar',
  [SEG.PROFILE]: 'Användare',
  [SEG.PROFILE_ID]: 'Användare',
  [SEG.MY_PROFILE]: 'Min Profil',
  [SEG.ADMIN]: 'Aministration',
  [SEG.STATIC_ORG]: 'Statiska organisationer',
  [SEG.SEARCH]: 'Sök',
  [S_SEG.ORGANIZATION_MEMBER]: 'Medlem',
  [S_SEG.OTHER_NOTE]: 'Anteckning fritext',
  [S_SEG.BEEHIVE_INSPECTION_NOTE]: 'Inspektions anteckning'
}

export default routes
