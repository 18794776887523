import { StatusCodes } from 'http-status-codes'
import { ApiError } from 'openapi-typescript-fetch'

export const isUnauthorizedError = (error: ApiError) => {
  return error.status === StatusCodes.UNAUTHORIZED
}

export const isConflictError = (error: ApiError) => {
  return error.status === StatusCodes.CONFLICT
}
