import newNoteCategorySelect from './newNoteCategorySelect'
import newNoteEntitySelect from './newNoteEntitySelect'
import newNoteDetails from './newNoteDetails'
import newNoteTypeSelect from './newNoteTypeSelect'
import newNotePlanningForm from './newNotePlanningForm.js'

const newNote = {
  newNoteCategorySelect,
  newNoteEntitySelect,
  newNoteTypeSelect,
  newNoteDetails,
  newNotePlanningForm
}

export default newNote
