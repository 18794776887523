import { Typography } from '@mui/material'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import useAsyncRequest from '../../../utils/hooks/asyncRequest'
import { UNIMPLEMENTED_SEGMENTS } from '../../../utils/routes/unimplementedSegments'
import { variableBreadCrumbResolvers } from '../../../utils/routes/variableBreadCrumbResolvers'

const COMPONENT_TRANSLATE_PREFIX = 'components.routes'

const getVariableSegmentLabel = (variableSegKey, data) => {
  if (variableBreadCrumbResolvers[variableSegKey]) {
    return variableBreadCrumbResolvers[variableSegKey](data)
  } else {
    throw new Error(
      `No breadcrumb resolver for path segment '${variableSegKey}' is implemented`
    )
  }
}

const BreadCrumb = (props) => {
  const { segment, segmentIndex, isLast, match } = props

  const { t } = useTranslation(undefined, {
    keyPrefix: COMPONENT_TRANSLATE_PREFIX
  })
  const { t: tGlobal } = useTranslation()

  const {
    data: [segmentDataLabel, setSegmentDataLabel],
    execute: getSegmentDataLabel
  } = useAsyncRequest(getVariableSegmentLabel)

  const isVariableSegment = useMemo(() => segment.startsWith(':'), [segment])

  const segmentKey = useMemo(
    () => (isVariableSegment ? segment.slice(1) : segment),
    [isVariableSegment, segment]
  )

  const unimplementedKeyConfig = useMemo(
    () => UNIMPLEMENTED_SEGMENTS.find((u) => u.segment === segmentKey),
    [segmentKey]
  )

  const link = useMemo(() => {
    if (unimplementedKeyConfig) {
      return unimplementedKeyConfig.redirectTo
    }

    return match.pathname
      .split('/')
      .slice(0, segmentIndex + 2)
      .join('/')
  }, [unimplementedKeyConfig, match?.pathname, segmentIndex])

  useEffect(() => {
    if (isVariableSegment && segmentKey && match.params[segmentKey]) {
      getSegmentDataLabel(segmentKey, match.params[segmentKey])
    } else if (segmentDataLabel) {
      setSegmentDataLabel(null)
    }
  }, [isVariableSegment, segmentKey, match.params[segmentKey]])

  return (
    <>
      <Typography
        variant="body2"
        component={link !== null ? Link : undefined}
        to={link}
        relative={unimplementedKeyConfig?.relativeRedirect ? 'path' : undefined}
        fontWeight={isLast && 'bold'}
        sx={{
          cursor: 'pointer',
          textDecoration: 'none'
        }}
      >
        {isVariableSegment
          ? segmentDataLabel ?? tGlobal('general.pending')
          : t(segmentKey)}
      </Typography>

      {!isLast && <Typography variant="body2">/</Typography>}
    </>
  )
}

export default BreadCrumb
