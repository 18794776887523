import apiFetcher from '../apiFetcher'

export const createBeehiveInspectionNote = apiFetcher
  .path('/inspection-note/create')
  .method('post')
  .create()

export const getBeehiveInspectionNotes = apiFetcher
  .path('/inspection-note/getInspectionNotes')
  .method('get')
  .create()

export const getBeehiveInspNotesHurledAmount = apiFetcher
  .path('/inspection-note/getInspNotesHurledAmount')
  .method('get')
  .create()

export const getBeehiveInspectionNoteById = apiFetcher
  .path('/inspection-note/byId/{id}')
  .method('get')
  .create()

export const deleteBeehiveInspectionNoteById = apiFetcher
  .path('/inspection-note/delete')
  .method('delete')
  .create({ id: true, organizationId: true })

export const editBeehiveInspectionNote = apiFetcher
  .path('/inspection-note/edit/{id}')
  .method('put')
  .create()
