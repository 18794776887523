const newNoteCategorySelect = {
  fields: {
    category: {
      options: {
        BEEHIVE: {
          title: 'Beehive',
          description: 'Make a note about a specific beehive'
        },
        APIARY: {
          title: 'Apiary',
          description: 'Make a note about an entire apiary'
        },
        ORGANIZATION: {
          title: 'Organization',
          description:
            'Create a note that applies to the organization as a whole'
        }
      },
      errors: {
        required: 'Choose a category'
      }
    },

    type: {
      errors: {
        required: 'Choose a type of note to create'
      }
    }
  },

  stepTitle: {
    category: {
      title: 'Choose category',
      description: 'Select what the category applies to'
    },
    entity: {
      BEEHIVE: {
        title: 'Beehive',
        description: 'Select which beehive you want to make the note for'
      },
      APIARY: {
        title: 'Apiary',
        description: 'Select which apiary you want to make the note for'
      },
      ORGANIZATION: {
        title: 'Organization',
        description: 'Select which organization you want to make the note for'
      }
    },
    type: {
      title: 'Choose type of note',
      description: 'Select what type of note you want to make'
    },
    details: {
      common: {
        other: {
          title: 'Other'
        }
      },
      BEEHIVE: {
        inspection: {
          title: 'Inspection'
        },
        event: {
          title: 'Event'
        },
        reminder: {
          title: 'Reminder'
        },
        inventory: {
          title: 'Inventory'
        },
        planning: {
          title: 'Planning'
        }
      }
    }
  },

  commonTypeOptions: {
    other: {
      title: 'Other',
      description: 'Free-form note'
    }
  },

  beehiveTypeOptions: {
    inspection: {
      title: 'Inspection',
      description:
        'Write down observations from a visit to the beehive. For example, the situation with the queen, amount of eggs, and strength of the colony.'
    },
    event: {
      title: 'Event',
      description:
        'Note major events in the beehive. For example, robbing and swarming.'
    },
    reminder: {
      title: 'Reminder',
      description: 'Set a reminder to not forget something.'
    },
    planning: {
      title: 'Planning',
      description:
        'Write down what needs to be done at the next visit to the beehive.'
    },
    inventory: {
      title: 'Inventory',
      description:
        'Take an inventory of items such as frames, boxes, and feed, as well as how much needs to be purchased.'
    }
  }
}

export default newNoteCategorySelect
