const aboutTab = {
  header: 'Information',
  editInSettings: '* Ändras i inställningar',
  deleteAccount: 'Ta bort konto',
  deleteConfirmation: 'Är du säker på att du vill ta bort ditt konto?',
  deleteInformation:
    'Ditt konto kommer att tas bort permanent och du kan inte längre komma åt dina bikupor och din data',
  cancel: 'Avbryt',
  error:
    'Något gick fel när du försökte ta bort ditt konto. Vänligen försök igen senare.',
  success: 'Ditt konto har tagits bort.'
}

export default aboutTab
