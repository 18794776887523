const beehivePlanningDetails = {
  newDate: 'Lägg till ett till datum',
  date: 'Datum',
  fields: {
    title: {
      label: 'Titel',
      errors: {
        required: 'Titel krävs'
      }
    }
  }
}

export default beehivePlanningDetails
