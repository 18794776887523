const apiariesTab = {
  action: {
    addApiaries: {
      label: 'Add apiaries'
    }
  },

  section: {
    notMember: {
      body: 'Join the organization to access its apiaries'
    }
  }
}

export default apiariesTab
