const toolbar = {
  actions: {
    logout: {
      pending: 'Loggar ut...',
      success: 'Du har loggats ut',
      error: 'Ett fel inträffade vid utloggning'
    }
  }
}

export default toolbar
