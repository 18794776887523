import { Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import PageBodyContainer from '../../components/common/PageBodyContainer'
import TitleImage from '../../components/common/TitleImage'
import { useState, useRef, useMemo } from 'react'
import { useNavigate } from 'react-router'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { AppRoutePaths } from '../../utils/routes'
import CommonNoteType from '../../enums/Note/CommonNoteType'
import NewNoteSelect from '../../components/Note/NewNote/NewNoteSelect'
import NewNoteDetails from '../../components/Note/NewNote/NewNoteDetails'
import { FormStepsContainer } from '../../components/common/Form/FormSteps'
import { useLocation } from 'react-router-dom'

const COMPONENT_TRANSLATE_PREFIX = 'pages.note.newNotePage'

const CATEGORY_SELECT_NAME = 'category'
const ENTITY_SELECT_NAME = 'entity'
const TYPE_SELECT_NAME = 'type'
const NOTE_DETAILS_NAME = 'details'

const NewNoteHeader = (props: { name: any }) => {
  const { name } = props

  const { t } = useTranslation(undefined, {
    keyPrefix: `${COMPONENT_TRANSLATE_PREFIX}.stepTitle`
  })
  const { watch } = useFormContext()

  let translateKey
  let computedDescription

  const selectedCategory = watch(`type.${CATEGORY_SELECT_NAME}`)
  const selectedEntities = watch(`type.${ENTITY_SELECT_NAME}`)
  const selectedType = watch(`${TYPE_SELECT_NAME}.type`)

  switch (name) {
    case ENTITY_SELECT_NAME:
      translateKey = `${ENTITY_SELECT_NAME}.${selectedCategory}`
      break
    case NOTE_DETAILS_NAME:
      if (Object.values(CommonNoteType).includes(selectedType)) {
        translateKey = `${NOTE_DETAILS_NAME}.common.${selectedType}`
      } else {
        translateKey = `${NOTE_DETAILS_NAME}.${selectedCategory}.${selectedType}`
      }

      computedDescription = [
        selectedEntities?.organization,
        selectedEntities?.apiary,
        selectedEntities?.beehive
      ]
        .filter((el) => el)
        .map((el) => el.name)
        .join(' - ')
      break
    default:
      translateKey = name
  }

  return (
    <PageBodyContainer>
      <Typography variant="h2">{t(`${translateKey}.title`)}</Typography>

      <Typography>
        {computedDescription ?? t(`${translateKey}.description`)}
      </Typography>
    </PageBodyContainer>
  )
}

const NewNotePage = () => {
  const location = useLocation()
  const state = location.state

  const { t } = useTranslation(undefined, {
    keyPrefix: COMPONENT_TRANSLATE_PREFIX
  })
  const navigate = useNavigate()
  const methods = useForm()
  const [activeStep, setActiveStep] = useState(0)

  const onSubmitRef = useRef<any>()

  const navigateToNotes = () => navigate(AppRoutePaths.NOTES_PATH)

  async function onSubmit(data: any) {
    return onSubmitRef?.current && (await onSubmitRef.current(data))
  }

  const componentSteps = useMemo(
    () => [
      {
        name: TYPE_SELECT_NAME,
        renderForm: (props: any) => (
          <NewNoteSelect
            preSelValues={state}
            categorySelectName={CATEGORY_SELECT_NAME}
            entitySelectName={ENTITY_SELECT_NAME}
            typeSelectName={TYPE_SELECT_NAME}
            {...props}
          />
        )
      },
      {
        name: NOTE_DETAILS_NAME,
        renderForm: (props: any) => (
          <NewNoteDetails
            preSelValues={state}
            categorySelectName={`type.${CATEGORY_SELECT_NAME}`}
            entitySelectName={`type.${ENTITY_SELECT_NAME}`}
            typeSelectName={TYPE_SELECT_NAME}
            onSubmitRef={onSubmitRef}
            {...props}
          />
        )
      }
    ],
    [onSubmitRef]
  )

  return (
    <FormProvider {...methods}>
      <Stack className="pageRoot">
        <TitleImage
          title={t('title')}
          activeStep={activeStep}
          stepsAmount={componentSteps.length}
        />

        <Stack className="pageContent" spacing={2}>
          <FormStepsContainer
            steps={componentSteps}
            activeStepIndex={activeStep}
            renderTitle={(props) => <NewNoteHeader {...props} />}
            onStepChange={setActiveStep}
            onSubmit={onSubmit}
            onCancel={navigateToNotes}
          />
        </Stack>
      </Stack>
    </FormProvider>
  )
}

export default NewNotePage
