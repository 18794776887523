import { Collapse, Grid } from '@mui/material'
import { useContext } from 'react'
import CheckRow from './CheckRow'
import { useFormContext } from 'react-hook-form'
import FormGridContext from '../../FormGridContext'

const CHECKBOX_NAME_SUFFIX = '_checkbox'

export const replaceContentCheckRowValues = (form) => {
  const res = {}
  // these fields does not have a checkbox, but if the keys have a value, they need to be processed the same way
  const exceptions = ['sicknessSymptom', 'societyBehavior', 'societyStrength']
  Object.entries(form).forEach(([key, value]) => {
    let fieldValue = value
    if (
      typeof value === 'object' &&
      Object.keys(value).includes(CHECKBOX_NAME_SUFFIX)
    ) {
      if (value[CHECKBOX_NAME_SUFFIX]) {
        fieldValue = value.value
      } else if (exceptions.includes(key) && value.value) {
        fieldValue = value.value
      } else {
        fieldValue = undefined
      }
    }

    res[key] = fieldValue
  })

  return res
}

const ContentCheckRow = (props) => {
  const { alwaysOpen, renderContent, name } = props

  const formGridContext = useContext(FormGridContext)

  const { watch } = useFormContext()

  const formGridNamePrefix = formGridContext?.namePrefix

  const checkName = `${name}.${CHECKBOX_NAME_SUFFIX}`

  const checkNameToWatch = [formGridNamePrefix, checkName]
    .filter((el) => el)
    .join('.')

  const checked = watch(`${checkNameToWatch}`)

  const showContent = alwaysOpen || checked

  return (
    <Grid container className="checkRow">
      <CheckRow name={checkName} disableGutter hideCheckbox={alwaysOpen} />
      <Collapse className="content-checkrow-content" in={showContent}>
        <Grid container className="checkRowContent" spacing={2}>
          {renderContent &&
            renderContent({
              name: `${name}.value`
            })}
        </Grid>
      </Collapse>
    </Grid>
  )
}

export default ContentCheckRow
