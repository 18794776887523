import { useEffect } from 'react'

import { ThemeProvider } from '@mui/material/styles'
import theme from './styles/theme'
import { Container, Stack, useMediaQuery, Box } from '@mui/material'
import AppRoutes from './AppRoutes'
import AppOverlay from './components/overlay'
import { BrowserRouter } from 'react-router-dom'
import BreadCrumbs from './components/route/Breadcrumbs'
import AppGlobalStyles from './styles/GlobalStyles'
import { useTranslation } from 'react-i18next'
import enLocale from 'date-fns/locale/en-US'
import svLocale from 'date-fns/locale/sv'
import { Locale } from 'date-fns'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { useQueryClient } from '@tanstack/react-query'
import { useIsAuthenticated } from './utils/api/hooks/useIsAuthenticated'
import { useInitialTokenVerified } from './utils/api/hooks/useInitialAuthVerification'
import { BottomNav } from './components/common/BottomNavigation'

import LoadingPage from './components/common/LoadingPage'

const localeMap: { [key: string]: Locale } = {
  en: enLocale,
  sv: svLocale
}

const App = () => {
  const isMobile = useMediaQuery('(max-width:600px)')

  const { i18n } = useTranslation()

  // const online = useSelector(Selectors.Online.selectIsOnline)

  const isAuthenticated = useIsAuthenticated()
  const { hasVerified: hasVerifiedInitialToken } = useInitialTokenVerified()

  const query = useQueryClient()

  useEffect(() => {
    if (isAuthenticated) {
      if (!hasVerifiedInitialToken) {
        query.clear()
      }
    }
  }, [isAuthenticated, hasVerifiedInitialToken])

  // Note: Can be issues with this method, alternative solution is heartbeat HTTP request
  const updateOnlineStatus = () => {
    // dispatch(Actions.Online.setOnline(navigator.onLine))
  }

  useEffect(() => {
    addEventListener('online', updateOnlineStatus)
    addEventListener('offline', updateOnlineStatus)
    return () => {
      removeEventListener('online', updateOnlineStatus)
      removeEventListener('offline', updateOnlineStatus)
    }
  }, [])

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          locale={localeMap[i18n.resolvedLanguage] ?? localeMap.svLocale}
        >
          {AppGlobalStyles}

          <AppOverlay hasVerifiedInitialToken={hasVerifiedInitialToken} />

          <Container>
            <Stack className={isMobile ? 'appRootMobile' : 'appRoot'}>
              {hasVerifiedInitialToken ? (
                <AppRoutes></AppRoutes>
              ) : (
                /* {online === false && <OnlineStatus />} */
                <LoadingPage className="pageRoot" />
              )}
              {isMobile && <Box sx={{ flexGrow: 1 }} />}
              {isMobile && isAuthenticated && (
                <Box
                  sx={{
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    zIndex: 1000
                  }}
                >
                  <BottomNav />
                </Box>
              )}
            </Stack>
          </Container>
          <BreadCrumbs />
        </LocalizationProvider>
      </ThemeProvider>
    </BrowserRouter>
  )
}

export default App
