import TextField from './TextField'

const PasswordField = props => {
  const { rules, inputProps, ...rest } = props

  return (
    <TextField
      name="password"
      translateKey="password"
      translatePrefix="form"
      rules={{
        required: true,
        ...rules
      }}
      inputProps={{
        type: 'password',
        ...inputProps
      }}
      {...rest}
    />
  )
}

export default PasswordField
