const beehiveInspectionNoteEditForm = {
  section: {
    general: 'General',
    society: 'About the society',
    queen: 'Queen',
    other: 'Other'
  },
  actions: {
    updateNote: {
      label: 'Edit'
    },
    updatingNote: {
      label: 'Editing...'
    },
    saveNote: {
      label: 'Save'
    }
  },
  defaultTitle: '{{val, datetime}}',
  fields: {
    title: {
      label: 'Title'
    },
    newEggs: {
      _checkbox: {
        label: 'Newly laid eggs'
      },
      value: {
        label: 'Number of frames'
      }
    },
    coveredFries: {
      _checkbox: {
        label: 'Covered brood'
      },
      value: {
        label: 'Number of frames'
      }
    },
    filledFryRoomsWithFrames: {
      _checkbox: {
        label: 'Filled brood chambers with frames'
      },
      value: {
        label: 'Number'
      }
    },
    cutDroneFrame: {
      label: 'Cut drone frame'
    },
    attractsPollen: {
      label: 'Collected pollen'
    },
    winterFed: {
      _checkbox: {
        label: 'Winter fed'
      },
      value: {
        label: 'kg / liter'
      }
    },
    checkedFramesWithRemainingFodder: {
      _checkbox: {
        label: 'Checked frames with remaining fodder'
      },
      value: {
        label: 'Number of frames'
      }
    },
    hurled: {
      label: 'Spun honey'
    },
    performedSpringControl: {
      _checkbox: {
        label: 'Performed spring inspection'
      }
    },
    performedBeeHouseControl: {
      _checkbox: {
        label: 'Performed bee house inspection / self-control'
      }
    },
    societyStrength: {
      _checkbox: {
        label: 'Strength of the society'
      }
    },
    societyBehavior: {
      _checkbox: {
        label: 'Behavior of colony'
      }
    },
    sicknessSymptom: {
      _checkbox: {
        label: 'Symptoms of sickness'
      },
      value: {
        label: ''
      }
    },
    queenControl: {
      _checkbox: {
        label: 'Queen control'
      }
    },
    newQueen: {
      label: 'New queen introduced'
    },
    queenMarked: {
      _checkbox: {
        label: 'Marked queen'
      },
      value: {
        label: 'Color',
        option: {
          blue: 'Blue',
          red: 'Red',
          yellow: 'Yellow'
        }
      }
    },
    queenWingCut: {
      label: 'Clipped queen wings'
    },
    other: {
      _checkbox: {
        label: 'Other'
      }
    }
  }
}

export default beehiveInspectionNoteEditForm
