import { Autocomplete, TextField } from '@mui/material'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import FormGridField, { FormGridFieldProps } from '../FormGridField'

export type AutocompleteFieldProps<InputProps, ValueType> = Omit<
  FormGridFieldProps<InputProps, ValueType, ValueType>,
  'render'
> & {
  options?: ValueType[]
  getOptionLabel: (option: ValueType, translatePrefix: string) => string
  optionKeyById?: boolean
  getOptionKey?: (option: ValueType) => string
  customNoOptionsText?: string
  optionTranslatePrefix?: string
  loading?: boolean
  translateOptions?: boolean
}

function AutocompleteField<InputProps, ValueType>(
  props: AutocompleteFieldProps<InputProps, ValueType>
) {
  const {
    options,
    getOptionLabel,
    optionKeyById,
    getOptionKey,
    customNoOptionsText,
    optionTranslatePrefix,
    loading,
    inputProps,
    translateOptions,
    ...rest
  } = props

  const { t } = useTranslation()

  const { translateKey, name } = rest

  const computedGetOptionLabel = useMemo(
    () =>
      translateOptions
        ? (option: ValueType, translatePrefix: string) =>
            typeof option === 'string' && option?.length
              ? t(
                  `${
                    optionTranslatePrefix ??
                    `${translatePrefix}.fields.${name}.option`
                  }.${option}`
                )
              : ''
        : getOptionLabel,
    [getOptionLabel, translateOptions]
  )

  return (
    <FormGridField
      render={({
        label,
        controller: {
          field: { onChange, onBlur, value },
          fieldState: { error }
        },
        translatePrefix,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onChange: customOnChange,
        inputProps,
        ...renderProps
      }) => (
        <Autocomplete
          options={options ?? []}
          fullWidth={true}
          loading={loading}
          loadingText={t('general.loading')}
          noOptionsText={t(
            customNoOptionsText
              ? `${translatePrefix}.fields.${translateKey ?? name}.noOptions`
              : 'general.noOptions'
          )}
          getOptionLabel={(option: ValueType) =>
            computedGetOptionLabel
              ? computedGetOptionLabel(option, translatePrefix)
              : `${option}`
          }
          renderOption={(props, option: any) => (
            <li
              {...props}
              key={
                getOptionKey
                  ? getOptionKey(option)
                  : optionKeyById
                  ? option.id
                  : option.key
              }
            >
              {(props as any)?.key}
            </li>
          )}
          renderInput={(params) => (
            <TextField label={label} error={!!error} {...params} />
          )}
          value={value}
          onChange={(_, value) => onChange(value)}
          onBlur={onBlur}
          {...renderProps}
          {...inputProps}
        />
      )}
      inputProps={inputProps}
      {...rest}
    />
  )
}

export default AutocompleteField
