import sv from './sv'
import en from './en'
import old from './old'

export const GENERAL_TRANSLATE_PREFIX = 'general'

const data = {
  ...old,
  sv: {
    translation: {
      ...old.sv.translation,
      ...sv.translation,
      general: {
        ...old.sv.translation.general,
        ...sv.translation.general
      }
    }
  },
  en: {
    translation: {
      ...en.translation,
      general: {
        ...en.translation.general
      }
    }
  }
}

export default data
