const beehiveEventDetails = {
  section: {
    general: 'General'
  },
  fields: {
    title: {
      label: 'Title',
      errors: {
        required: 'Title is required'
      }
    },
    swarm: {
      label: 'Swarming'
    },
    plundering: {
      label: 'Plundering'
    },
    wildlifeAttack: {
      _checkbox: {
        label: 'Wildlife attack'
      }
    },
    vandalism: {
      _checkbox: {
        label: 'Vandalism'
      }
    },
    societySold: {
      label: 'The society has died'
    },
    societyDied: {
      label: 'The society has been sold'
    },
    other: {
      _checkbox: {
        label: 'Other'
      }
    }
  }
}

export default beehiveEventDetails
