import {
  Button,
  Grid,
  Typography,
  Modal,
  Fade,
  Box,
  CardActions,
  Container,
  Card,
  CardHeader,
  CardContent
} from '@mui/material'
import Backdrop from '@mui/material/Backdrop'
import { useTranslation } from 'react-i18next'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { useOrganizationQuery } from '../../../../utils/api/hooks/organization'
import { useErrorStateSnackEffect } from '../../../../utils/hooks/stateSnackEffect'
import { AppRoutePaths, AppRoutePathSegment } from '../../../../utils/routes'
import SettingsIcon from '../../../common/Icon/Settings'
import InfoGridText from '../../../common/InfoGridText'
import BottomContentContainer from '../../../common/Layout/BottomContentContainer'
import PageBodyContainer from '../../../common/PageBodyContainer'
import { useState } from 'react'
import { useDeleteApiariesMutation } from '../../../../utils/api/hooks/apiary'
import { useSnackbar } from 'notistack'

const COMPONENT_TRANSLATE_PREFIX =
  'components.apiary.viewApiary.tabs.apiaryInfoTab'

const APIARY_FIELDS_TO_SHOW = [
  { key: 'owner', getBody: ({ apiary }) => apiary?.owner },
  {
    key: 'coordinates',
    getBody: ({ apiary }) =>
      apiary?.locationLat &&
      apiary?.locationLng &&
      `${apiary.locationLat}, ${apiary.locationLng}`
  },
  { key: 'town', getBody: ({ apiary }) => apiary?.municipality },
  { key: 'propertyDesignation' },
  { key: 'areaOfUse' },
  { key: 'other' }
]

const modalRootStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%'
}

const ApiaryInfoTab = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { enqueueSnackbar } = useSnackbar()
  const removeApiaryMutation = useDeleteApiariesMutation()
  const [open, setOpen] = useState(false)
  const { organizationId } = useParams()
  const { t } = useTranslation(undefined, {
    keyPrefix: COMPONENT_TRANSLATE_PREFIX
  })

  // eslint-disable-next-line no-unused-vars
  const apiary = props.apiary

  const organizationQuery = useOrganizationQuery(organizationId)
  const organization = organizationQuery.data?.data
  const navigate = useNavigate()

  useErrorStateSnackEffect(
    organizationQuery.isError,
    t('error.loadingOrganization')
  )

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleRemoveApiary = () => {
    removeApiaryMutation.mutate({ id: apiary.id, orgId: organizationId })
    enqueueSnackbar(t('info.deleteSucces'), { variant: 'success' })
    navigate(
      AppRoutePathSegment.replaceSegments(
        AppRoutePaths.DISPLAY_ORGANIZATION_PATH,
        [{ name: AppRoutePathSegment.ORGANIZATION_ID, value: organizationId }]
      )
    )
  }

  return (
    <>
      <PageBodyContainer>
        <Typography variant="h2">{apiary?.name}</Typography>
      </PageBodyContainer>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <Box sx={modalRootStyle}>
            <Container maxWidth="xs">
              <Card sx={{ padding: 2 }}>
                <CardHeader title={t('info.deleteConfirmation')} />
                <CardContent sx={{ pt: 0 }}>
                  {t('info.deleteInformation')}
                </CardContent>
                <CardActions sx={{ justifyContent: 'space-evenly' }}>
                  <Button
                    // expand={expanded}
                    onClick={handleClose}
                    variant="outlined"
                    aria-expanded={''}
                    aria-label="show more"
                    color="secondary"
                  >
                    {t('button.cancel')}
                  </Button>
                  <Button
                    // expand={expanded}
                    onClick={handleRemoveApiary}
                    sx={{
                      background: '#C23F38',
                      outline: '#000000',
                      ':hover': { background: '#C23F38' },

                      color: '#FFFFFF'
                    }}
                    variant="contained"
                    aria-expanded={''}
                    aria-label="show more"
                  >
                    {t('button.deleteApiary')}
                  </Button>
                </CardActions>
              </Card>
            </Container>
          </Box>
        </Fade>
      </Modal>
      <Box>
        <Grid container spacing={2}>
          {APIARY_FIELDS_TO_SHOW &&
            APIARY_FIELDS_TO_SHOW.map(({ key, getBody }) => (
              <InfoGridText
                key={key}
                xs={12}
                header={t(`apiary.${key}`)}
                body={
                  getBody
                    ? getBody({ apiary, org: organization })
                    : apiary?.[key]
                }
              />
            ))}
        </Grid>
      </Box>

      <BottomContentContainer>
        <Button
          component={Link}
          to={AppRoutePathSegment.replaceSegments(
            AppRoutePaths.EDIT_APIARY_PATH,
            [
              {
                name: AppRoutePathSegment.APIARY_ID,
                value: apiary?.id
              },
              {
                name: AppRoutePathSegment.ORGANIZATION_ID,
                value: apiary?.organizationId
              }
            ]
          )}
          variant="contained"
          fullWidth
          startIcon={<SettingsIcon />}
        >
          {t('settings')}
        </Button>
        <Button
          sx={{ color: '#000000', textDecoration: 'underline' }}
          // variant="text"
          onClick={handleOpen}
        >
          {t('button.deleteApiary')}
        </Button>
      </BottomContentContainer>
    </>
  )
}

export default ApiaryInfoTab
