
const viewApiary = {
  tabs: {
    beehives: 'Bikupor',
    info: 'Information'
  },
  error: {
    loadingApiary: 'Ett fel inträffade när bigården skulle laddas'
  }
}

export default viewApiary
