import general from './general'
import components from './components'
import pages from './pages'
import countries from './countries'
import form from './form'
import unit from './unit'
import time from './time'

const sv = {
  translation: {
    general,
    pages,
    components,
    countries,
    form,
    unit,
    time
  }
}

export default sv
