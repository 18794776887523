export function getSplitISODate (date: Date) {
  return date.toISOString().split('T')
}

export function getISODate (date: Date) {
  return getSplitISODate(date)[0]
}

export function getISOTime (date: Date) {
  return getSplitISODate(date)[1]
}
