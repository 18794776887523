import { ApiError, Fetcher, Middleware } from 'openapi-typescript-fetch'
import { paths } from '../schema'
import { isUnauthorizedError } from './helpers'

// declare fetcher for paths
const apiFetcher = Fetcher.for<paths>()

export const LOCAL_STORAGE_JWT_TOKEN_KEY = 'api-jwt-token'
export const UNAUTHORIZED_EVENT_TYPE = 'apiClient-unauthorized'

export const jwtAppender: Middleware = async (url, init, next) => {
  const currSessionToken = localStorage.getItem(LOCAL_STORAGE_JWT_TOKEN_KEY)

  if (currSessionToken) {
    init.headers.set('Authorization', `Bearer ${currSessionToken}`)
  }

  try {
    return await next(url, init)
  } catch (err) {
    if (err instanceof ApiError) {
      if (isUnauthorizedError(err)) {
        window.dispatchEvent(new Event(UNAUTHORIZED_EVENT_TYPE))
      }
    }

    console.error('Error during request:', err)
    throw err
  }
}

apiFetcher.configure({
  baseUrl: process.env.REACT_APP_API_URL,
  use: [jwtAppender]
})

export default apiFetcher
