import apiFetcher from '../apiFetcher'

export const getUser = apiFetcher.path('/user/me').method('get').create()

export const updateUser = apiFetcher
  .path('/user/me/edit')
  .method('put')
  .create()

export const updateHasSeenStartupGuide = apiFetcher
  .path('/user/me/hasSeenStartupGuide')
  .method('put')
  .create()

export const updateUserAppReview = apiFetcher
  .path('/user/me/appReview')
  .method('put')
  .create()
