import VariablePathSegment from '../../enums/Path/VariablePathSegment'

/** @WARN Deprecated, use enums StaticPathSegment or VariablePathSegment instead  */
export const LOGIN = 'login'
/** @WARN Deprecated, use enums StaticPathSegment or VariablePathSegment instead  */
export const PASSWORD_RESET = 'reset-password'
/** @WARN Deprecated, use enums StaticPathSegment or VariablePathSegment instead  */
export const REGISTER = 'register'
/** @WARN Deprecated, use enums StaticPathSegment or VariablePathSegment instead  */
export const VERIFY_USER_EMAIL = 'verify-user-email'
/** @WARN Deprecated, use enums StaticPathSegment or VariablePathSegment instead  */
export const HOME = 'home'
/** @WARN Deprecated, use enums StaticPathSegment or VariablePathSegment instead  */
export const ABOUT = 'about'
/** @WARN Deprecated, use enums StaticPathSegment or VariablePathSegment instead  */
export const GUIDE = 'guide'
/** @WARN Deprecated, use enums StaticPathSegment or VariablePathSegment instead  */
export const ORGANIZATION = 'organization'
/** @WARN Deprecated, use enums StaticPathSegment or VariablePathSegment instead  */
export const ORGANIZATION_ID = 'organizationId'
/** @WARN Deprecated, use enums StaticPathSegment or VariablePathSegment instead  */
export const APIARY = 'apiary'
/** @WARN Deprecated, use enums StaticPathSegment or VariablePathSegment instead  */
export const APIARY_ID = 'apiaryId'
/** @WARN Deprecated, use enums StaticPathSegment or VariablePathSegment instead  */
export const NEW_APIARIES = 'new-apiaries'
/** @WARN Deprecated, use enums StaticPathSegment or VariablePathSegment instead  */
export const BEEHIVE = 'beehive'
/** @WARN Deprecated, use enums StaticPathSegment or VariablePathSegment instead  */
export const BEEHIVE_ID = 'beehiveId'
/** @WARN Deprecated, use enums StaticPathSegment or VariablePathSegment instead  */
export const ALARM = 'alarm'
/** @WARN Deprecated, use enums StaticPathSegment or VariablePathSegment instead  */
export const NOTE = 'note'
/** @WARN Deprecated, use enums StaticPathSegment or VariablePathSegment instead  */
export const NOTE_ID = 'noteId'
/** @WARN Deprecated, use enums StaticPathSegment or VariablePathSegment instead  */
export const ALL_NOTES = 'list'
/** @WARN Deprecated, use enums StaticPathSegment or VariablePathSegment instead  */
export const PROFILE = 'profile'
/** @WARN Deprecated, use enums StaticPathSegment or VariablePathSegment instead  */
export const ORGANIZATION_MEMBER_ID = 'organizationMemberId'
/** @WARN Deprecated, use enums StaticPathSegment or VariablePathSegment instead  */
export const PROFILE_ID = 'profileId'
/** @WARN Deprecated, use enums StaticPathSegment or VariablePathSegment instead  */
export const MY_PROFILE = 'my-profile'
/** @WARN Deprecated, use enums StaticPathSegment or VariablePathSegment instead  */
export const ADMIN = 'admin'
/** @WARN Deprecated, use enums StaticPathSegment or VariablePathSegment instead  */
export const STATIC_ORG = 'static-org'
/** @WARN Deprecated, use enums StaticPathSegment or VariablePathSegment instead  */
export const NEW = 'new'
/** @WARN Deprecated, use enums StaticPathSegment or VariablePathSegment instead  */
export const EDIT = 'edit'
/** @WARN Deprecated, use enums StaticPathSegment or VariablePathSegment instead  */
export const SEARCH = 'search'

export const createPath = (...segments: string[]) => {
  const path = `${Array.isArray(segments)
    ? segments.join('/')
    : segments
  }`

  return path.startsWith('/')
    ? path
    : `/${path}`
}

export const variableSegment =
  (segment: VariablePathSegment) => `:${segment}`

export type PathSegmentReplaceValue = {
  name: VariablePathSegment,
  value: string
}

export const replaceSegments = (pathName: string | null | undefined, segments: PathSegmentReplaceValue[]) => {
  if (typeof pathName !== 'string') {
    throw new Error(`Error when replacing segments in path: Supplied pathname: ${pathName} is not a string`)
  }

  if (!segments?.reduce) {
    throw new Error(`Error when replacing segments in path: Supplied segments: ${segments} is not an array`)
  }

  return segments.reduce(
    (path, { name, value }) => path.replace(`:${name}`, value),
    pathName
  )
}
