import displayOrganization from './displayOrganization'
import organizationEditForm from './organizationEditForm'
import unauthorized from './unauthorized'

const organization = {
  displayOrganization,
  organizationEditForm,
  unauthorized
}

export default organization
