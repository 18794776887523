import { useMemo } from 'react'

type ModelTransformOption = string | {
  key: string,
  transform: (arg: any) => any,
  defaultValue?: any
}

/**
 * Used to initialize a subset of keys of a model to create an initial state for a form using useForm
 * @param {*} model model create default values from
 * @param {*} keys keys which to include, may be object.
 * If object it contains:
 * * key - key to use
 * * transform - function to transform value
 * * defaultValue - (optional) default value if model[key] is not defined
 * @returns object constructed from the model and supplied keys
 */
const useFormDefaultValuesFromModel = (model: any, keys: ModelTransformOption[]) => {
  return useMemo(
    () => keys && model &&
    keys.reduce(
      (res, key) => {
        if (key instanceof Object) {
          res[key.key] =
            model[key.key]
              ? key.transform
                ? key.transform(model[key.key])
                : model[key.key]
              : key.defaultValue
        } else {
          res[key] = model[key]
        }
        return res
      },
      {} as any
    ),
    [model, keys]
  )
}

export default useFormDefaultValuesFromModel
