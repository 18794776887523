const alarmDialog = {
  sections: {
    main: {
      header: 'Alarm',
      body: 'You are about to activate an alarm for {{name}}. Once activated, the alarm will notify you via email of any unusual activity in this beehive.'
    }
  }
}

export default alarmDialog
