import {
  Stack,
  Typography,
  Button,
  Box,
  CardMedia,
  CardContent
} from '@mui/material'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Modal from '@mui/material/Modal'
import { useTranslation } from 'react-i18next'
import { useState, useEffect, useMemo } from 'react'
import { Link, useParams } from 'react-router-dom'
import BottomContentContainer from '../../components/common/Layout/BottomContentContainer'
import ButtonListContainer from '../../components/common/Layout/ButtonListContainer'
import LoadingPage from '../../components/common/LoadingPage'
import TitleImage from '../../components/common/TitleImage'
import {
  useErrorStateSnackEffect,
  useSuccessStateSnackEffect
} from '../../utils/hooks/stateSnackEffect'
import {
  useGetOtherNoteByIdQuery,
  usePutEditOtherNote
} from '../../utils/api/hooks/otherNote'
import { useGetSignedS3URL } from '../../utils/api/hooks/aws'

const COMPONENT_TRANSLATE_PREFIX = 'pages.note.displayOtherNote'

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 800,
  minWidth: 300,
  bgcolor: 'background.paper',
  border: '2px solid #292929',
  boxShadow: 24,
  p: 4,
  s: 4,
  borderRadius: 2
}

const DisplayOtherNote = () => {
  const [imageData, setImageData] = useState<string | null>(null)
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const { otherNoteId } = useParams()
  const { t } = useTranslation(undefined, {
    keyPrefix: COMPONENT_TRANSLATE_PREFIX
  })
  const { t: tGeneral } = useTranslation()
  const updateNoteMutation = usePutEditOtherNote()

  const getOtherNoteByIdQuery = useGetOtherNoteByIdQuery(otherNoteId || '')
  const otherNote = getOtherNoteByIdQuery.data?.data
  const [otherNoteTitle, setOtherNoteTitle] = useState(
    getOtherNoteByIdQuery.data?.data?.title
  )
  const [otherNoteText, setOtherNoteText] = useState(
    getOtherNoteByIdQuery.data?.data?.text
  )
  const otherNotePending = getOtherNoteByIdQuery.isLoading
  const otherNoteError = getOtherNoteByIdQuery.isError

  let noteImageFileName = ''
  if (otherNote?.organizationId !== undefined && otherNote?.id !== undefined) {
    noteImageFileName =
      '!!OrgID:' + otherNote.organizationId + '!!otherNoteID:' + otherNote.id
  }

  const useGetSignedUrl = useGetSignedS3URL(noteImageFileName)

  useEffect(() => {
    if (otherNote) {
      setOtherNoteTitle(otherNote.title)
      setOtherNoteText(otherNote.text)
      useGetSignedUrl.refetch()
    }
  }, [otherNote])

  useMemo(() => {
    const signedUrl = useGetSignedUrl.data?.data

    if (signedUrl) {
      setImageData(signedUrl)
    }
  }, [otherNote, useGetSignedUrl.isFetched])

  const handleSubmit = (
    title: string | undefined,
    text: string | undefined
  ) => {
    if (
      title !== undefined &&
      text !== undefined &&
      otherNoteId !== undefined &&
      otherNote !== undefined
    ) {
      updateNoteMutation.mutate({
        category: otherNote.category,
        title: title,
        text: text,
        organizationId: otherNote?.organizationId,
        id: otherNoteId
      })
    }
    setOpen(false)
  }

  useSuccessStateSnackEffect(
    updateNoteMutation.isSuccess,
    t('actions.updateOtherNote.success')
  )
  useErrorStateSnackEffect(
    updateNoteMutation.error,
    t('actions.loadOtherNote.error')
  )
  useErrorStateSnackEffect(otherNoteError, t('actions.loadOtherNote.error'))

  return otherNotePending ? (
    <LoadingPage />
  ) : (
    <Stack className="pageRoot">
      <TitleImage src="/images/generic-background.png" title={otherNoteTitle} />
      <Stack className="pageContent">
        <Box sx={{ margin: 2 }}>
          <CardContent>
            {otherNoteText !== undefined && (
              <Typography
                margin={0}
                dangerouslySetInnerHTML={{
                  __html: otherNoteText?.replace(/(?:\r\n|\r|\n)/g, '<br>')
                }}
              />
            )}
          </CardContent>

          {imageData !== null && (
            <CardMedia
              sx={{ margin: 0 }}
              component="img"
              // height="140"
              image={imageData}
            ></CardMedia>
          )}
        </Box>
        <Box sx={{ flexGrow: 1 }} />

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Grid sx={style}>
            <TextField
              sx={{ paddingBottom: 2 }}
              id="outlined-basic"
              label={t('fields.title')}
              defaultValue={otherNoteTitle}
              onChange={(e) => {
                setOtherNoteTitle(e.target.value)
              }}
              variant="outlined"
              fullWidth
            />
            <TextField
              id="outlined-basic"
              label={t('fields.text')}
              defaultValue={otherNoteText}
              onChange={(e) => {
                setOtherNoteText(e.target.value)
              }}
              variant="outlined"
              multiline
              fullWidth
              rows={10}
            />
            <Grid
              container
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              justifyContent="center"
              alignItems="center"
              sx={{ paddingTop: 2 }}
            >
              <Grid item xs="auto">
                <Button
                  sx={{}}
                  size="small"
                  variant="text"
                  color="secondary"
                  onClick={handleClose}
                >
                  {t('buttons.abort')}
                </Button>
              </Grid>
              <Grid item xs="auto">
                <Button
                  size="large"
                  variant="contained"
                  onClick={() => {
                    handleSubmit(otherNoteTitle, otherNoteText)
                  }}
                  color="primary"
                >
                  {t('buttons.save')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Modal>

        <BottomContentContainer>
          <ButtonListContainer>
            <Button
              fullWidth
              size="large"
              variant="outlined"
              color="secondary"
              sx={{ maxWidth: 300 }}
              component={Link}
              to="./../.."
            >
              {tGeneral('general.back')}
            </Button>
            <Button
              fullWidth
              size="large"
              variant="text"
              color="secondary"
              onClick={handleOpen}
              sx={{ maxWidth: 300 }}
            >
              {t('buttons.edit')}
            </Button>
          </ButtonListContainer>
        </BottomContentContainer>
      </Stack>
    </Stack>
  )
}

export default DisplayOtherNote
