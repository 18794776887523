import apiaryOverviewList from './apiaryOverviewList'
import viewApiary from './viewApiary'
import apiaryEditForm from './apiaryEditForm'

const apiary = {
  apiaryOverviewList,
  viewApiary,
  apiaryEditForm
}

export default apiary
