import { FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField, Typography } from '@mui/material'
import { useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import FormGridContext from '../FormGridContext'
import FormGridField from '../FormGridField'

const OTHER_NAME = 'other'

const COMPONENT_TRANSLATE_PREFIX = 'form.fields.radioField'

const RadioGroupField = props => {
  const {
    options,
    hideLabel,
    cols,
    includeOther,
    optionTranslatePrefix,
    defaultValue,
    disabled,
    ...rest
  } = props

  const { name, translatePrefix: fieldTranslatePrefix } = rest

  const { translatePrefix: formGridTranslatePrefix } = useContext(FormGridContext)

  const translatePrefixToUse = fieldTranslatePrefix ?? formGridTranslatePrefix
  const optionsTranslatePrefixToUse = optionTranslatePrefix ?? `${translatePrefixToUse}.fields.${name}.option`

  const { t } = useTranslation(undefined, { keyPrefix: COMPONENT_TRANSLATE_PREFIX })
  const { t: tGlobal } = useTranslation()

  const colSize = cols ? 12 / cols : 12

  const optionsToRender = useMemo(
    () => includeOther ? [...options, OTHER_NAME] : options,
    [options, includeOther]
  )

  const [isOther, setIsOther] = useState(false)

  const onRadioChange = (controllerOnChange, evt) => {
    const value = evt.target.value || null

    const otherRadioChecked = value === OTHER_NAME

    if (isOther !== otherRadioChecked) {
      setIsOther(otherRadioChecked)
    }

    if (otherRadioChecked) {
      controllerOnChange('')
    } else {
      controllerOnChange(value)
    }
  }

  return (
    <>
      <FormGridField
        className="radioGroupField"
        label={hideLabel ? '' : undefined}
        defaultValue={defaultValue ?? null}
        render={
          ({
            controller: {
              field: { onChange, onBlur, value },
              fieldState: { error }
            },
            label
          }) =>
            <FormControl style={{ width: '100%' }}>
              {!hideLabel &&
                <FormLabel>{label}</FormLabel>
              }
              <RadioGroup
                onChange={evt => onRadioChange(onChange, evt)}
                onBlur={onBlur}
                value={value}
              >
                <Grid container spacing={2} style={{ width: '100%' }}>
                  {optionsToRender && optionsToRender.map((option, index) =>
                    <Grid item key={index} xs={colSize}>
                      <FormControlLabel
                        value={option}
                        control={
                          <Radio
                            color="secondary"
                            disabled={disabled}
                            checked={option === OTHER_NAME
                              ? isOther
                              : undefined
                            }
                          />
                        }
                        label={
                          <Typography variant="body1">
                            {
                              option === OTHER_NAME
                                ? t('option.other')
                                : tGlobal(`${optionsTranslatePrefixToUse}.${option}`)
                            }
                          </Typography>
                        }
                        />
                    </Grid>
                  )}

                  {isOther &&
                    <Grid item xs={12}>
                      <TextField
                        label={t('otherFreeTextLabel')}
                        InputLabelProps={{ shrink: true }}
                        placeholder="..."
                        multiline
                        minRows={3}
                        fullWidth
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        error={!!error}
                      />
                    </Grid>
                  }
                </Grid>
              </RadioGroup>
            </FormControl>
        }
        {...rest}
      />
    </>
  )
}

export default RadioGroupField
