const beehiveInventoryDetails = {
  section: {
    general: 'General'
  },

  measureFields: {
    quantity: {
      available: 'Quantity at home',
      toGet: 'Quantity to buy'
    },
    amount: {
      available: 'Amount at home',
      toGet: 'Amount to buy'
    }
  },

  fields: {
    title: {
      label: 'Title',
      errors: {
        required: 'Title is required'
      }
    },
    frames: {
      _checkbox: {
        label: 'Frames'
      }
    },
    boxes: {
      _checkbox: {
        label: 'Boxes'
      }
    },
    coverboard: {
      _checkbox: {
        label: 'Cover boards'
      }
    },
    wax: {
      _checkbox: {
        label: 'Wax'
      }
    },
    feed: {
      _checkbox: {
        label: 'Feed'
      }
    },
    other: {
      _checkbox: {
        label: 'Other'
      }
    }
  }
}

export default beehiveInventoryDetails
