const gettingStartedGuide = {
  title: 'Introduction',
  getStarted: 'Get Started',

  pwaExample: {
    heading: 'Add to Homescreen',
    content:
      'For easier access and a better experience, you can save a shortcut to the app directly on your homescreen.'
  },
  organizations: {
    heading: 'Organizations',
    content:
      'Organizations gather apiaries and beehives in one place for easy maintenance and overview. Create your own or join an existing one.'
  },
  digitalBeehives: {
    heading: 'Digital Beehives',
    content:
      'Buy a digital kit for your existing beehive or buy fully digital beehives to be able to see important information about the beehives directly from the start screen or from the beehive information.'
  },
  notes: {
    heading: 'Notes',
    content:
      'Create notes for inspections, planning, reminders, and more to easily keep track of all your beehives and apiaries.'
  }
}

export default gettingStartedGuide
