import { useCallback, useMemo, useState } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import ApiariesOptions, {
  transformApiariesToApiInputs
} from '../../components/common/ContentGeneration/Organization/ApiariesOptions'
import BeehivesOptions from '../../components/common/ContentGeneration/Organization/BeehivesOptions'
import OrganizationOptions from '../../components/common/ContentGeneration/Organization/OrganizationOptions'
import { useTranslation } from 'react-i18next'
import Review from '../../components/common/ContentGeneration/Organization/Review'
import { Stack, Typography } from '@mui/material'
import { AppRoutePaths } from '../../utils/routes'
import {
  useErrorStateSnackEffect,
  useStateSnackEffect
} from '../../utils/hooks/stateSnackEffect'
import TitleImage from '../../components/common/TitleImage'
import { useNavigate } from 'react-router-dom'
import useIsMounted from '../../utils/hooks/isMounted'
import {
  FormStepConfiguration,
  FormStepsContainer
} from '../../components/common/Form/FormSteps'
import PageBodyContainer from '../../components/common/PageBodyContainer'
import { CreateOrganizationForm } from '../../components/common/ContentGeneration/Organization/types'
import { useCreateOrganizationMutation } from '../../utils/api/hooks/organization'
import { promiseForForm } from '../../utils/form/helpers'
import { useSnackbar } from 'notistack'

const COMPONENT_TRANSLATE_PREFIX = 'pages.organization.newOrganization'

type NewOrganizationHeaderProps = {
  name: string
}

const NewOrganizationHeader = (props: NewOrganizationHeaderProps) => {
  const { name } = props
  const { t } = useTranslation(undefined, {
    keyPrefix: `${COMPONENT_TRANSLATE_PREFIX}.stepTitle`
  })

  return (
    <PageBodyContainer>
      <Typography variant="h2">{t(name)}</Typography>
    </PageBodyContainer>
  )
}

const COMPONENT_TRANSLATE_FN = () =>
  useTranslation(undefined, { keyPrefix: COMPONENT_TRANSLATE_PREFIX })

const ORGANIATION_FORM_NAME = 'organization'
const APIARIES_FORM_NAME = 'apiaries'
const BEEHIVES_FORM_NAME = 'beehives'

const convertToOrgCreateInput = (form: CreateOrganizationForm) => {
  const {
    apiaries: { apiaries: formApiaries },
    beehives: { beehives: formBeehives },
    organization: formOrganization
  } = form

  const apiaries = transformApiariesToApiInputs(formApiaries, formBeehives)

  return {
    ...formOrganization,
    apiaries
  }
}

export default function ApiariesForOrganizationForm() {
  const navigate = useNavigate()

  const { t } = COMPONENT_TRANSLATE_FN()

  const [activeStep, setActiveStep] = useState(0)

  const methods = useForm<CreateOrganizationForm>({
    mode: 'onSubmit'
  })

  const isMounted = useIsMounted()
  const { enqueueSnackbar } = useSnackbar()

  const onCreateOrgSuccess = useCallback(() => {
    enqueueSnackbar(t('actions.createOrganization.fulfilled'), {
      variant: 'success'
    })
    if (isMounted()) {
      navigate(AppRoutePaths.LIST_ORGANIZATIONS_PATH)
    }
  }, [t, enqueueSnackbar, isMounted, navigate])

  const createOrganizationMutation =
    useCreateOrganizationMutation(onCreateOrgSuccess)

  const submit = useCallback(
    (form: CreateOrganizationForm) =>
      promiseForForm(
        createOrganizationMutation.mutateAsync(convertToOrgCreateInput(form))
      ),
    [createOrganizationMutation.mutateAsync]
  )

  useStateSnackEffect(
    createOrganizationMutation.isLoading,
    t('actions.createOrganization.pending')
  )
  useErrorStateSnackEffect(
    createOrganizationMutation.error,
    t('actions.createOrganization.error')
  )

  const formSteps = useMemo<FormStepConfiguration<CreateOrganizationForm>[]>(
    () => [
      {
        name: ORGANIATION_FORM_NAME,
        title: t('stepTitle.organization'),
        renderForm: () => <OrganizationOptions />
      },
      {
        name: APIARIES_FORM_NAME,
        title: t('stepTitle.apiaries'),
        renderForm: () => <ApiariesOptions />
      },
      {
        name: BEEHIVES_FORM_NAME,
        title: t('stepTitle.beehives'),
        renderForm: () => <BeehivesOptions />
      },
      {
        name: 'review',
        title: t('stepTitle.review'),
        renderForm: () => <Review />
      }
    ],
    []
  )

  return (
    <FormProvider {...methods}>
      <Stack className="pageRoot">
        <TitleImage
          title={t('title')}
          src="/images/generic-background.png"
          stepsAmount={formSteps.length}
          activeStep={activeStep}
        />
        <Stack className="pageContent">
          <FormStepsContainer
            onSubmit={submit}
            steps={formSteps}
            activeStepIndex={activeStep}
            renderTitle={(props) => <NewOrganizationHeader {...props} />}
            onStepChange={setActiveStep}
            onCancel={() => navigate(AppRoutePaths.LIST_ORGANIZATIONS_PATH)}
          />
        </Stack>
      </Stack>
    </FormProvider>
  )
}
