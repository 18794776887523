import { OrganizationMemberRoleOverview } from '../../models/organization'

type OrganizationMember = {
  isApproved?: boolean | null
  role?: OrganizationMemberRoleOverview | null
}

type PossibleOrganizationMember = OrganizationMember | undefined | null

export function isAuthorizedMember(member: PossibleOrganizationMember) {
  return !!member?.isApproved
}

export function isAuthorizedMemberWithRole(
  member: PossibleOrganizationMember,
  role: OrganizationMemberRoleOverview
) {
  return isAuthorizedMember(member) && member?.role === role
}
