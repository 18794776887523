const userProfile = {
  title: 'Min profil',
  tabs: {
    about: 'Om mig',
    settings: 'Inställningar'
  }

}

export default userProfile
