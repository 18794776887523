import { Checkbox, FormControlLabel, Stack } from '@mui/material'
import FormGridField from '../FormGridField'

const CheckBoxField = props => {
  const { defaultValue, ...rest } = props

  return (
    <FormGridField
      defaultValue={!!defaultValue}
      render={
        ({
          controller: {
            field: { onChange, onBlur, value }
          },
          label
        }) =>
        <Stack alignItems="center">
          <FormControlLabel
            label={label}
            control={
              <Checkbox
                checked={!!value}
                onChange={onChange}
                onBlur={onBlur}
              />
            }
          />
        </Stack>
      }
      {...rest}
    />
  )
}

export default CheckBoxField
