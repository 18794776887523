const reviewModal = {
  sections: {
    main: {
      header: 'Recension',
      body: 'Vi vill gärna veta vad du tycker om appen och hur vi kan förbättra den. Skriv gärna en recension.'
    }
  },
  buttons: {
    cancel: 'Avbryt',
    submit: 'Recensera'
  },
  actions: {
    error: 'Ett fel uppstod när recensionen skulle skickas',
    success: 'Tack för din recension!'
  }
}

export default reviewModal
