/* eslint-disable */
enum VariablePathSegment {
  ORGANIZATION_ID = 'organizationId',
  APIARY_ID = 'apiaryId',
  BEEHIVE_ID = 'beehiveId',
  NOTE_ID = 'noteId',
  PROFILE_ID = 'profileId',
  OTHER_NOTE_ID = 'otherNoteId',
  ORGANIZATION_MEMBER_ID = 'organizationMemberId',
  BEEHIVE_INSPECTION_NOTE_ID = 'beehiveInspectionNoteId'
}

export default VariablePathSegment
