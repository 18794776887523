import CountrySelect from '../../../Country/Country'
import FormGridField from '../FormGridField'

const CountryField = props => {
  return (
    <FormGridField
      name="country"
      translateKey="country"
      translatePrefix="form"
      render={
        ({
          controller: {
            field: { onChange, onBlur, value },
            fieldState: { error }
          },
          label,
          placeholder,
          inputProps
        }) =>
          <CountrySelect
            inputProps={{
              label,
              placeholder,
              error: !!error,
              ...inputProps
            }}
            variant="outlined"
            value={value}
            onChange={onChange}
            onBlur={onBlur}
          />
      }
      {...props}
    />
  )
}

export default CountryField
