import { Button, Stack, Typography } from '@mui/material'
import { useMemo, useCallback } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import TitleImage from '../../components/common/TitleImage'
import LoadingPage from '../../components/common/LoadingPage'
import { AppRoutePaths } from '../../utils/routes'
import { useErrorStateSnackEffect } from '../../utils/hooks/stateSnackEffect'
import ErrorsList from '../../components/common/Form/ErrorsList'
import FormGrid from '../../components/common/Form/FormGrid'
import PhoneField from '../../components/common/Form/FormGrid/CommonRows/Phone'
// import AddressField from '../../components/common/Form/FormGrid/CommonRows/AddressField'
// import TownRow from '../../components/common/Form/FormGrid/CommonRows/TownRow'
import FormGridRow from '../../components/common/Form/FormGrid/FormGridRow'
import PageBodyContainer from '../../components/common/PageBodyContainer'
import BottomButtonsContainer from '../../components/common/Layout/BottomButtonsContainer'
import TextField from '../../components/common/Form/FormGrid/CommonRows/TextField'
import {
  useGetUserQuery,
  useUpdateUserMutation
} from '../../utils/api/hooks/user'
import useIsMounted from '../../utils/hooks/isMounted'
import { useSnackbar } from 'notistack'

const COMPONENT_TRANSLATE_PREFIX = 'pages.user.userProfileEdit'

type UserProfileEditFormValue = {
  id: string
  email: string
  firstname: string
  surname: string
  bio: string
  hasUpdatedInfo: boolean
  phone: string
  street: string
  town: string
  zipCode: string
  country: string
  hasReviewedApp: boolean
  reviewRequestDate: string
}

type UserProfileEditBodyProps = {
  prefix: string
  showInfoText: boolean
}

const UserProfileEditBody = (props: UserProfileEditBodyProps) => {
  const { prefix, showInfoText } = props
  const { t } = useTranslation(undefined, {
    keyPrefix: COMPONENT_TRANSLATE_PREFIX
  })
  return (
    <PageBodyContainer>
      <Typography variant="h2">{t(`${prefix}.infoTitle`)}</Typography>

      {showInfoText && <Typography>{t(`${prefix}.description`)}</Typography>}
    </PageBodyContainer>
  )
}

const UserProfileEdit = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: COMPONENT_TRANSLATE_PREFIX
  })
  const { t: tGeneral } = useTranslation(undefined, { keyPrefix: 'general' })

  const methods = useForm<UserProfileEditFormValue>({ mode: 'onSubmit' })
  const isMounted = useIsMounted()
  const navigate = useNavigate()

  const { handleSubmit, formState } = methods

  const getUser = useGetUserQuery()

  useErrorStateSnackEffect(getUser.isError, tGeneral('error.general'))
  const { enqueueSnackbar } = useSnackbar()

  const onSubmitSuccess = useCallback(() => {
    if (isMounted()) {
      enqueueSnackbar(t('actions.updateUser.fulfilled'), { variant: 'success' })
      navigate(AppRoutePaths.MY_PROFILE_PATH)
    }
  }, [isMounted])

  const updateUser = useUpdateUserMutation(onSubmitSuccess)

  useErrorStateSnackEffect(updateUser.isError, tGeneral('error.general'))

  const pageBody = useMemo(
    () => (
      <UserProfileEditBody
        showInfoText={!getUser.data?.data?.hasUpdatedInfo}
        prefix={getUser.data?.data?.hasUpdatedInfo ? 'ordinary' : 'firstTime'}
      />
    ),
    [getUser.data?.data?.hasUpdatedInfo]
  )

  const onSubmit = (form: UserProfileEditFormValue) => {
    form.hasUpdatedInfo = true
    return updateUser.mutate(form)
  }

  return (
    <Stack className="pageRoot">
      <TitleImage title={t('title')} src="/images/generic-background.png" />
      <Stack
        className="pageContent"
        spacing={2}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        {getUser.isLoading || updateUser.isLoading ? (
          <LoadingPage />
        ) : (
          <>
            {pageBody}

            <FormGrid
              methods={methods}
              translatePrefix={COMPONENT_TRANSLATE_PREFIX}
              rules={{ required: true }}
            >
              {getUser.data?.data?.hasUpdatedInfo && (
                <FormGridRow rules={{ required: true }}>
                  <TextField sm={6} name="firstname" />
                  <TextField sm={6} name="surname" />
                </FormGridRow>
              )}

              <PhoneField />
              {/*                 <AddressField/>
                <TownRow/> */}
            </FormGrid>
            <ErrorsList formState={formState} />

            <BottomButtonsContainer>
              <Button
                component={Link}
                to={AppRoutePaths.MY_PROFILE_PATH}
                color="secondary"
              >
                {tGeneral('skip')}
              </Button>

              <Button color="secondary" variant="contained" type="submit">
                {tGeneral('save')}
              </Button>
            </BottomButtonsContainer>
          </>
        )}
      </Stack>
    </Stack>
  )
}

export default UserProfileEdit
