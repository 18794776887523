// eslint-disable-next-line no-use-before-define
import React, { useCallback, useContext } from 'react'
import Backdrop from '@mui/material/Backdrop'
import {
  Button,
  Fade,
  Box,
  CardActions,
  Stack,
  Typography,
  Container,
  Card,
  CardHeader,
  CardContent,
  Modal
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { AppRoutePaths } from '../../../../utils/routes'
import EditIcon from '../../../common/Icon/Edit'
import InformationList from '../../../common/InformationList'
import BottomContentContainer from '../../../common/Layout/BottomContentContainer'
import ButtonListContainer from '../../../common/Layout/ButtonListContainer'
import PageBodyContainer from '../../../common/PageBodyContainer'
import { useRemoveUser } from '../../../../utils/api/hooks/aws'
import AuthContext from '../../../../utils/api/AuthContext'
import { useSnackbar } from 'notistack'
import useIsMounted from '../../../../utils/hooks/isMounted'
import { useErrorStateSnackEffect } from '../../../../utils/hooks/stateSnackEffect'

const modalRootStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%'
}

const COMPONENT_TRANSLATE_PREFIX = 'components.user.userProfile.aboutTab'

const USER_ROWS = [
  {
    labelKey: 'general.name',
    render: (user) => user && `${user.firstname} ${user.surname}`
  },
  {
    labelKey: 'form.fields.email.label',
    key: 'email',
    showAsterix: true
  },
  {
    labelKey: 'form.fields.phone.label',
    key: 'phone'
  } /* ,
  {
    labelKey: 'form.fields.street.label',
    render: user => user &&
      `${user.street}, ${user.zipCode} ${user.town}`
  } */
]

const AboutTab = (props) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: COMPONENT_TRANSLATE_PREFIX
  })
  const { t: tGlobal } = useTranslation()
  const { user } = props
  const [open, setOpen] = React.useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const isMounted = useIsMounted()
  const authContext = useContext(AuthContext)
  const signOut = authContext.clearAuthState

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const onRemoveUserSuccess = useCallback(() => {
    enqueueSnackbar(t('success'), { variant: 'success' })
    if (isMounted()) {
      signOut()
    }
  }, [t, enqueueSnackbar, isMounted, signOut])

  const removeUserMutation = useRemoveUser(onRemoveUserSuccess)

  useErrorStateSnackEffect(removeUserMutation.isError, t('error'))

  const handleRemoveUser = () => {
    removeUserMutation.mutate()
  }

  return (
    <Stack spacing={2}>
      <PageBodyContainer>
        <Typography variant="h2">{t('header')}</Typography>
      </PageBodyContainer>

      <InformationList rows={USER_ROWS} data={user} />
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <Box sx={modalRootStyle}>
            <Container maxWidth="xs">
              <Card sx={{ padding: 2 }}>
                <CardHeader title={t('deleteConfirmation')} />
                <CardContent sx={{ pt: 0 }}>
                  {t('deleteInformation')}
                </CardContent>
                <CardActions sx={{ justifyContent: 'space-evenly' }}>
                  <Button
                    // expand={expanded}
                    onClick={handleClose}
                    variant="outlined"
                    aria-expanded={''}
                    aria-label="show more"
                    color="secondary"
                  >
                    {t('cancel')}
                  </Button>
                  <Button
                    // expand={expanded}
                    onClick={handleRemoveUser}
                    sx={{
                      background: '#C23F38',
                      outline: '#000000',
                      ':hover': { background: '#C23F38' },

                      color: '#FFFFFF'
                    }}
                    variant="contained"
                    aria-expanded={''}
                    aria-label="show more"
                  >
                    {t('deleteAccount')}
                  </Button>
                </CardActions>
              </Card>
            </Container>
          </Box>
        </Fade>
      </Modal>

      <BottomContentContainer>
        <ButtonListContainer
          sx={{ justifyContent: 'space-evenly', paddingTop: 8 }}
        >
          <Button
            sx={{ color: '#000000', textDecoration: 'underline' }}
            variant="text"
            onClick={handleOpen}
          >
            {t('deleteAccount')}
          </Button>
          <Button
            variant="contained"
            component={Link}
            to={AppRoutePaths.EDIT_PROFILE_PATH}
            startIcon={<EditIcon />}
          >
            {tGlobal('general.edit')}
          </Button>
        </ButtonListContainer>
      </BottomContentContainer>

      {/* <Typography variant="hint">
        {t('editInSettings')}
      </Typography> */}
    </Stack>
  )
}

export default AboutTab
