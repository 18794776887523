const form = {
  fields: {
    name: {
      label: 'Name',
      placeHolder: 'My organization',
      errors: {
        required: 'Name is required',
        minLength: 'Name must be at least {{minLength}} letters long',
        maxLength: 'Name cannot be longer than {{maxLength}} letters'
      }
    },
    code: {
      errors: {
        required: 'Code is required'
      }
    },
    email: {
      label: 'Email',
      errors: {
        required: 'Email is required',
        pattern: 'Invalid email'
      }
    },
    password: {
      label: 'Password',
      errors: {
        required: 'Password is required'
      }
    },
    passwordRepeat: {
      label: 'Repeat password',
      errors: {
        required: 'Repeat password is required',
        notEqual: 'Passwords do not match'
      }
    },
    street: {
      label: 'Address',
      placeHolder: '',
      errors: {
        required: 'Address is required',
        minLength: 'Address must be at least {{minLength}} letters long',
        maxLength: 'Address cannot be longer than {{maxLength}} letters'
      }
    },
    beehive: {
      label: 'Beehive',
      placeHolder: '',
      noOptions: 'No beehives available',
      errors: {
        required: 'Beehive is required'
      }
    },
    apiary: {
      label: 'Apiary',
      placeHolder: '',
      noOptions: 'No apiaries available',
      errors: {
        required: 'Apiary is required'
      }
    },
    organization: {
      label: 'Organization',
      placeHolder: '',
      noOptions: 'No organizations available',
      errors: {
        required: 'Organization is required'
      }
    },
    town: {
      label: 'Town',
      placeHolder: '',
      errors: {
        required: 'Town is required'
      }
    },
    zipCode: {
      label: 'Zip code',
      placeHolder: '12345',
      errors: {
        required: 'Zip code is required',
        minLength: 'Zip code must be {{minLength}} digits long',
        maxLength: 'Zip code must be {{maxLength}} digits long',
        pattern: 'Zip code can only contain digits'
      }
    },
    country: {
      label: 'Country',
      placeholder: 'Select country',
      errors: {
        required: 'Country is required'
      }
    },
    phone: {
      label: 'Phone number',
      errors: {
        required: 'Phone number is required'
      }
    },
    freeText: {
      label: 'Free text'
    },
    radioField: {
      option: {
        other: 'Other'
      },
      otherFreeTextLabel: 'Free text'
    },
    date: {
      label: 'Date',
      errors: {
        required: 'Date is required'
      }
    },
    time: {
      label: 'Time',
      errors: {
        required: 'Time is required'
      }
    },
    coordinates: {
      label: 'Coordinates',
      placeholder: 'Ex: 56.6608, 16.3628',
      errors: {
        required: 'Coordinates are required',
        pattern: 'Invalid format for coordinates'
      }
    },
    other: {
      label: 'Other'
    },
    municipality: {
      label: 'Municipality',
      errors: {
        required: 'Municipality is required'
      }
    }
  }
}

export default form
