const beehiveEventDetails = {
  section: {
    general: 'Allmänt'
  },
  fields: {
    title: {
      label: 'Titel',
      errors: {
        required: 'Titel krävs'
      }
    },
    swarm: {
      label: 'Svärmning'
    },
    plundering: {
      label: 'Röveri'
    },

    wildlifeAttack: {
      _checkbox: {
        label: 'Viltangrepp'
      }
    },

    vandalism: {
      _checkbox: {
        label: 'Skadegörelse'
      }
    },

    societySold: {
      label: 'Samhället har dött'
    },

    societyDied: {
      label: 'Samhället har sålts'
    },

    other: {
      _checkbox: {
        label: 'Övrigt'
      }
    }
  }
}

export default beehiveEventDetails
