const organizationOptions = {
  fields: {
    description: {
      label: 'Beskrivning',
      placeHolder: 'Organisationen har existerat sedan...',
      errors: {
        required: 'Beskrivning krävs',
        minLength: 'Beskrivningen måste innehålla minst {{minLength}} bokstäver',
        maxLength: 'Beskrivningen får inte innehålla mer än {{maxLength}} bokstäver'
      }
    },
    organizationNumber: {
      label: 'Organisationsnummer',
      placeHolder: '12345678912',
      errors: {
        required: 'Organisationsnummer krävs'
      }
    }

  }
}

export default organizationOptions
