import { Container, Paper } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'

import { MapContainer, TileLayer, Marker, Tooltip } from 'react-leaflet'
import LoadingPage from '../../common/LoadingPage'
import ApiaryMarker from './ApiaryMarker'
import ApiaryMapOverlay from './ApiaryMapOverlay'
import ApiarySelect from './ApiarySelect'
import { useTranslation } from 'react-i18next'
import useAsyncRequest from '../../../utils/hooks/asyncRequest'
import { THEME_CONSTANTS } from '../../../styles/themeConstants'

const MAP_TILE_LAYER_PROPS = {
  attribution:
    '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
  url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
}

const COMPONENT_TRANSLATE_PREFIX = 'components.map.apiaryLocationSelect'

const containerStyles = (theme) => ({
  height: '100%',
  [theme.breakpoints.down('md')]: {
    padding: '0 !important'
  }
})

const rootStyles = (theme) => ({
  height: `calc(100vh - ${THEME_CONSTANTS.toolbarHeight})`,
  width: '100vw',
  position: 'fixed',
  top: THEME_CONSTANTS.toolbarHeight,
  left: 0,
  mt: 0,
  zIndex: theme.zIndex.map
})

const MAX_LOCATION_NOISE = 0.02
const DEFAULT_LOCATION = { lat: 56.956022, lng: 16.396883 }

const randomOffset = () => Math.random() - 0.5

const locationWithNoise = ({ lat, lng }) => ({
  lat: lat + MAX_LOCATION_NOISE * randomOffset(),
  lng: lng + MAX_LOCATION_NOISE * randomOffset()
})

const getUserGeoLocation = () =>
  new Promise((resolve, reject) =>
    navigator.geolocation.getCurrentPosition(
      (location) =>
        resolve({
          lat: location.coords.latitude,
          lng: location.coords.longitude
        }),
      (err) => reject(err)
    )
  )

const ApiariesLocationSelect = (props) => {
  const { apiaries, onComplete, onCancel } = props

  const { t } = useTranslation(undefined, {
    keyPrefix: COMPONENT_TRANSLATE_PREFIX
  })

  const [defaultLocation, setDefaultLocation] = useState(null)
  const [apiaryLocations, setApiaryLocations] = useState([])

  // eslint-disable-next-line
  const [showAllApiaries, setShowAllApiaries] = useState(true)
  const [selectedApiaryId, setSelectedApiaryId] = useState('1')
  const [isSelectingApiary, setIsSelectingApiary] = useState(false)

  const [selectedApiaryLocation, setSelectedApiaryLocation] = useState(null)

  const {
    data: [userLocation],
    pending: [userLocationError],
    execute: getUserLocation
  } = useAsyncRequest(getUserGeoLocation)

  useEffect(() => {
    getUserLocation()
  }, [])

  useEffect(() => {
    if (userLocation) {
      setDefaultLocation(userLocation)
    } else if (userLocationError) {
      setDefaultLocation({ ...DEFAULT_LOCATION })
    }
  }, [userLocation, userLocationError])

  useEffect(() => {
    if (apiaries?.length && defaultLocation) {
      setApiaryLocations(
        apiaries.map((apiary) => ({
          apiary,
          location: apiary.locationLat
            ? { lat: apiary.locationLat, lng: apiary.locationLng }
            : locationWithNoise(defaultLocation)
        }))
      )
      setSelectedApiaryId(apiaries[0].sortId || apiaries[0].id)
    } else {
      setApiaryLocations([])
    }
  }, [apiaries, defaultLocation])

  useEffect(() => {
    if (selectedApiaryId && apiaryLocations) {
      setSelectedApiaryLocation(
        apiaryLocations.find(
          ({ apiary }) => (apiary.sortId || apiary.id) === selectedApiaryId
        )
      )
    } else if (selectedApiaryLocation) {
      setSelectedApiaryLocation(null)
    }
  }, [selectedApiaryId, apiaryLocations, selectedApiaryLocation])

  const updateApiaryLocation = (apiaryId, newLocation) => {
    setApiaryLocations((prevLocations) =>
      prevLocations.map(({ apiary, location }) => ({
        apiary,
        location:
          (apiary.sortId || apiary.id) === apiaryId ? newLocation : location
      }))
    )

    setSelectedApiaryLocation(({ apiary }) => ({ apiary, newLocation }))
  }

  const selectApiary = (apiaryId) => {
    setSelectedApiaryId(apiaryId)

    setSelectedApiaryLocation(
      apiaryLocations.find(
        ({ apiary }) => (apiary.sortId || apiary.id) === apiaryId
      )
    )

    setIsSelectingApiary(false)
  }

  const updateSelectedApiary = useCallback((event) => {
    selectApiary(event.sortId || event.id)
  }, [])

  return (
    <Paper sx={rootStyles}>
      <Container maxWidth="xl" sx={containerStyles}>
        {defaultLocation ? (
          <MapContainer
            style={{ width: '100%', height: '100%' }}
            center={defaultLocation}
            zoom={13}
          >
            <TileLayer {...MAP_TILE_LAYER_PROPS} />
            <ApiaryMapOverlay
              onConfirm={() => onComplete(apiaryLocations)}
              onCancel={onCancel}
              selectedApiaryLocation={selectedApiaryLocation}
              apiaries={apiaries}
              updateSelectedApiary={updateSelectedApiary}
            />
            {apiaryLocations
              .map((el, index) => ({ ...el, index }))
              .filter(
                ({ apiary }) =>
                  showAllApiaries || apiary.sortId === selectedApiaryId
              )
              .map(({ apiary, location, index }) => (
                <ApiaryMarker
                  key={index}
                  index={index}
                  apiary={apiary}
                  location={location}
                  select={selectApiary}
                  draggable={(apiary.sortId || apiary.id) === selectedApiaryId}
                  markersAmount={apiaryLocations?.length}
                  setLocation={(location) =>
                    updateApiaryLocation(apiary.sortId || apiary.id, location)
                  }
                />
              ))}
            {userLocation && (
              <Marker
                position={userLocation}
                marker_index={-1}
                zIndexOffset={-1}
              >
                <Tooltip offset={[0, 0]} opacity={1} permanent>
                  {t('yourLocation')}
                </Tooltip>
              </Marker>
            )}
          </MapContainer>
        ) : (
          <LoadingPage />
        )}
        <ApiarySelect
          apiaries={apiaries}
          selectedApiaryId={selectedApiaryId}
          onSelect={selectApiary}
          onCancel={() => setIsSelectingApiary(false)}
          open={isSelectingApiary}
        />
      </Container>
    </Paper>
  )
}

export default ApiariesLocationSelect
