const newNoteTypeSelect = {
  fields: {
    type: {
      errors: {
        required: 'Please choose a type of note to create'
      }
    }
  },

  commonTypeOptions: {
    other: {
      title: 'Other',
      description: 'Note in free text'
    }
  },

  beehiveTypeOptions: {
    inspection: {
      title: 'Inspection',
      description:
        'Write down observations from the visit to the beehive. For example; the situation with the queen, amount of eggs, and strength of the colony.'
    },
    event: {
      title: 'Event',
      description:
        'Note major events in the beehive. For example, robbing and swarming.'
    },
    reminder: {
      title: 'Reminder',
      description: 'Set a reminder to not forget anything.'
    },
    planning: {
      title: 'Planning',
      description:
        'Note what needs to be done on the next visit to the beehive.'
    },
    inventory: {
      title: 'Inventory',
      description:
        'Take inventory of, for example, frames, boxes, and feed, and how much needs to be purchased.'
    }
  }
}

export default newNoteTypeSelect
