const searchOrganization = {
  title: 'Organizations',
  search: 'Search',
  description: 'Search for the organization you are looking for',
  header: {
    name: 'Name',
    description: 'Description'
  },
  error: {
    loadingOrganizations: 'An error occurred while loading organizations',
    loadingMembers: 'An error occurred while loading members'
  }
}

export default searchOrganization
