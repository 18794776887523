import TextField from './TextField'

export const VALID_EMAIL_PATTERN = /\S+@\S+\.\S+/

const EmailField = props => {
  const { rules, ...rest } = props

  return (
    <TextField
      name="email"
      translateKey="email"
      translatePrefix="form"
      rules={{
        pattern: VALID_EMAIL_PATTERN,
        ...rules
      }}
      {...rest}
    />
  )
}

export default EmailField
