import { Stack } from '@mui/material'
import { THEME_CONSTANTS } from '../../styles/themeConstants'

const imageStyle = (theme) => ({
  filter: THEME_CONSTANTS.darkenImageBrightness,
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  objectFit: 'cover',
  backgroundColor: theme.palette.imagePlaceholder.main,
  zIndex: -1
})

const StaticBackground = (props) => {
  return (
    <Stack
      className="staticBackground"
      component="img"
      {...props}
      sx={(theme) => ({
        ...imageStyle(theme),
        ...props.sx
      })}
    />
  )
}

export default StaticBackground
