const newNotePage = {
  title: 'Ny Anteckning',
  sections: {
    main: {
      header: 'Välj typ av anteckning',
      inspection: 'Inspektion',
      freeText: 'Fritext'
    },
    apiary: {
      header: 'Välj bigård',
      organizationDropdown: 'Organisation',
      apiaryDropdown: 'Bigård...'
    }
  },
  actions: {
    submit: {
      pending: 'Anteckningen sparas',
      fulfilled: 'Anteckningen har sparats',
      error: 'Ett fel inträffade när anteckningen skulle sparas',
      add: 'Lägg till bild'
    }
  },
  stepTitle: {
    category: {
      title: 'Välj kategori',
      description: 'Välj för vad kategorin gäller'
    },
    entity: {
      BEEHIVE: {
        title: 'Bikupa',
        description: 'Välj för vilken bikupa du vill göra anteckningen'
      },
      APIARY: {
        title: 'Bigård',
        description: 'Välj för vilken bigård du vill göra anteckningen'
      },
      ORGANIZATION: {
        title: 'Organisation',
        description: 'Välj för vilken organisation du vill göra anteckningen'
      }
    },
    type: {
      title: 'Välj typ av anteckning',
      description: 'Välj vilken typ av anteckning du vill göra'
    },
    details: {
      common: {
        other: {
          title: 'Övrigt'
        }
      },
      BEEHIVE: {
        inspection: {
          title: 'Inspektion'
        },
        event: {
          title: 'Händelse'
        },
        reminder: {
          title: 'Påminnelse'
        },
        inventory: {
          title: 'Inventering'
        },
        planning: {
          title: 'Planering'
        }
      }
    }
  }
}

export default newNotePage
