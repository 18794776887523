const newOrganization = {
  title: 'Skapa organisation',

  stepTitle: {
    organization: 'Om organisationen',
    apiaries: 'Lägg till bigårdar',
    beehives: 'Justera bikuporna',
    review: 'Översikt'
  },

  actions: {
    createOrganization: {
      pending: 'Organisationen skapas...',
      error: 'Ett fel inträffade när organisationen skulle skapas',
      fulfilled: 'Organisationen har skapats'
    }
  }
}

export default newOrganization
