import { Stack } from '@mui/material'

/**
 * DEPRECATED: Use BottomContentContainer in combination with ButtonListContainer instead.
 * @param {*} props
 * @returns
 */
const BottomButtonsContainer = props => {
  const { children, sx, ...rest } = props

  return (
    <Stack
      sx={{ px: 2, pt: 4, ...sx }}
      direction="row"
      justifyContent="center"
      spacing={2}
      {...rest}
    >
      {children}
    </Stack>
  )
}

export default BottomButtonsContainer
