import { useMutation, useQuery } from '@tanstack/react-query'
import { useApiClient } from './useApiClient'
import { CallbackVoid } from '../util/types'

export const GET_S3_FILE = 'getS3File'
export const REMOVE_USER = 'reomveUser'

export function uploadImageMutation() {
  const apiClient = useApiClient()

  // a mutation for uploading an image to S3 via a nestjs controller
  return useMutation({
    mutationFn: apiClient.aws.uploadImage
  })
}

export function useGetSignedS3URL(fileName: string) {
  const apiClient = useApiClient()

  return useQuery(
    [GET_S3_FILE, fileName],
    () => {
      return apiClient.aws.getSignedS3URL({ fileName })
    },
    {
      enabled: false
    }
  )
}

export function useRemoveUser(onSuccess?: CallbackVoid) {
  const apiClient = useApiClient()

  return useMutation({
    mutationFn: apiClient.aws.removeUser,
    onSuccess: onSuccess
  })
}
