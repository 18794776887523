import { Stack, Table, Typography } from '@mui/material'
import { useMemo, useState } from 'react'
import LoadingPage from '../LoadingPage'
import ItemListContext from './ItemListContext'

export type ItemListProps = {
  title?: string,
  expandable?: boolean,
  pending?: boolean | null,
  children: JSX.Element | JSX.Element[],
  overflowPageContentPadding?: boolean
}

function ItemList (props: ItemListProps) {
  const {
    title,
    pending,
    expandable,
    children,
    overflowPageContentPadding,
    ...rest
  } = props

  const [openIndex, setOpenIndex] = useState(-1)

  const itemListContextValue = useMemo(
    () => ({
      openIndex,
      setOpenIndex,
      expandable: !!expandable
    }),
    [openIndex, setOpenIndex, expandable]
  )

  const className = useMemo(
    () => `overflowPageContentPadding ${expandable ? 'expandable' : 'non-expandable'} itemList`,
    [expandable]
  )

  return (
    <ItemListContext.Provider
      value={itemListContextValue}
    >
      <Stack
        direction="column"
        spacing={2}
        className={overflowPageContentPadding ? 'overflowPageContentPadding' : undefined}
      >
        {title &&
          <Typography variant="h2">
            {title}
          </Typography>
        }

        <Table
          className={className}
          {...rest}
        >
          {children}
        </Table>

        {pending &&
          <Stack direction="row" justifyContent="center">
            <LoadingPage/>
          </Stack>
        }
      </Stack>
    </ItemListContext.Provider>
  )
}

export default ItemList
