import FormGridRow from '../FormGridRow'
import TextField from './TextField'

const ZIP_CODE_RULES = {
  maxLength: 5,
  minLength: 5,
  pattern: /^\d{5}$/
}

const TownRow = props => {
  const { rowProps, townProps, zipCodeProps } = props
  return (
    <FormGridRow {...rowProps}>
      <TextField
        name="zipCode"
        translateKey="zipCode"
        translatePrefix="form"
        xs={4}
        md={3.5}
        rules={{
          ...ZIP_CODE_RULES,
          ...zipCodeProps?.rules
        }}
        {...zipCodeProps}
      />

      <TextField
        name="town"
        translateKey="town"
        translatePrefix="form"
        xs={8}
        md={8.5}
        {...townProps}
      />

    </FormGridRow>
  )
}

export default TownRow
