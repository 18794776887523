import TextField from './TextField'

const FreeTextField = props => {
  const { localTranslation, inputProps, ...rest } = props

  let translateKey, translatePrefix

  if (!localTranslation) {
    translateKey = 'freeText'
    translatePrefix = 'form'
  }

  return (
    <TextField
      translateKey={translateKey}
      translatePrefix={translatePrefix}
      inputProps={{
        placeholder: '...',
        multiline: true,
        minRows: 6,
        ...inputProps,
        InputLabelProps: {
          shrink: true,
          ...inputProps?.InputLabelProps
        }
      }}
      {...rest}
    />
  )
}

export default FreeTextField
