/* eslint-disable camelcase */
import { useEffect, useMemo, useState } from 'react'
import SensorDataChart from './SensorDataChart'
import { Link } from 'react-router-dom'
import { TextField, Grid, Typography, Button, Container } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/system'
import UpdateReferenceWeight from './UpdateReferenceWeight'
import { NodeInterval } from '../../../../../models/interval'
import BottomContentContainer from '../../../../common/Layout/BottomContentContainer'
import ButtonListContainer from '../../../../common/Layout/ButtonListContainer'
import { useErrorStateSnackEffect } from '../../../../../utils/hooks/stateSnackEffect'
import SettingsIcon from '../../../../common/Icon/Settings'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import SensorFields from './SensorField'
import {
  useGetIoTDataDateRangeQuery,
  useSubscribeToNotificationMutation
} from '../../../../../utils/api/hooks/iot'
import {
  useGetReferenceWeightQuery,
  useSetReferenceWeightMutation
} from '../../../../../utils/api/hooks/beehive'
import IoTMap from './IoTMap'
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm'
import AlarmDialog from './AlarmDialog'
import { Beehive, SensorType } from '../../../../../models/beehive'

const COMPONENT_TRANSLATE_PREFIX = 'components.beehive.iotTab'

type DatePickerProps = {
  label: string
  value: Date | null
  onChange: (date: Date | null) => void
  maxDate: Date | null
  minDate: Date | null
}

function DatePicker(props: DatePickerProps) {
  const { label, value, onChange, maxDate } = props

  return (
    <MobileDatePicker
      // disableFuture={true}
      label={label}
      value={value}
      maxDate={maxDate ?? undefined}
      onChange={onChange}
      renderInput={(params: any) => <TextField fullWidth {...params} />}
    />
  )
}

export type SensorChartsProps = {
  beehive: Beehive
}

function SensorCharts(props: SensorChartsProps) {
  const { beehive } = props
  const { t } = useTranslation(undefined, {
    keyPrefix: COMPONENT_TRANSLATE_PREFIX
  })
  const { t: tGlobal } = useTranslation()

  const componentInitializationDate = useMemo(() => new Date(), [])

  const [todayDateTime, setTodayDateTime] = useState<Date>(
    componentInitializationDate
  )
  const [startDateTime, setStartDateTime] = useState<Date>(
    new Date(
      componentInitializationDate.getFullYear(),
      componentInitializationDate.getMonth(),
      componentInitializationDate.getDate() - 7
    )
  )
  const [endDateTime, setEndDateTime] = useState<Date>(
    componentInitializationDate
  )

  const minStartDatePickerValue = useMemo(() => {
    const minStartDate = new Date()
    minStartDate.setDate(minStartDate.getDate() - 7)

    return minStartDate
  }, [])

  const [referenceWeightDialogOpen, setReferenceWeightDialogOpen] =
    useState(false)

  const [alarmIsOpen, setAlarmIsOpen] = useState(false)

  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const [sensorDataUpdateIntervalId, setSensorDataUpdateIntervalId] =
    useState<NodeInterval | null>(null)

  // 6 is the sensor idenftifier for the weight sensor in the database
  const weight_sensor_id = 6

  const weightData = useGetIoTDataDateRangeQuery(
    beehive.id,
    startDateTime,
    endDateTime,
    weight_sensor_id
  )

  useEffect(() => {
    weightData.refetch()
  }, [startDateTime, endDateTime])

  const setReferenceWeightMutation = useSetReferenceWeightMutation()

  // const getIotaDataQuery = useGetIotaDataQuery(beehive.id)
  // const imsi = getIotaDataQuery.data?.data.imsi as string

  const onReferenceWeightSave = (newReferenceWeight: number) => {
    setReferenceWeight({ weight: newReferenceWeight })
    setReferenceWeightMutation.mutate({
      beehiveId: beehive.id,
      weight: newReferenceWeight
    })
  }

  const addSubscriber = (thresholdValue: number, sensorID: number) => {
    useSubscribeToNotification.mutate({
      sensor_id: sensorID,
      thingId: beehive.iotId,
      thresHoldValue: thresholdValue
    })
    setAlarmIsOpen(false)
  }

  useErrorStateSnackEffect(
    setReferenceWeightMutation.isError,
    t('actions.getReferenceWeight.error')
  )

  const latestWeight = weightData.data?.data?.sort(
    (a, b) => new Date(a.time).getTime() - new Date(b.time).getTime()
  )[weightData.data?.data?.length - 1]

  const currRefWeightQuery = useGetReferenceWeightQuery(beehive.id).data?.data

  const [referenceWeight, setReferenceWeight] = useState<{
    weight: number | undefined
  }>({ weight: currRefWeightQuery?.weight })

  const useSubscribeToNotification = useSubscribeToNotificationMutation()

  useEffect(() => {
    const start = new Date()
    const end = new Date(start)
    start.setDate(start.getDate() - 7)
    start.setHours(0)
    start.setMinutes(0)

    setStartDateTime(start)

    end.setHours(23)
    end.setMinutes(59)
    setEndDateTime(end)
    setTodayDateTime(new Date(end))
  }, [])

  return (
    <>
      <UpdateReferenceWeight
        open={referenceWeightDialogOpen}
        onCancel={() => setReferenceWeightDialogOpen(false)}
        onReferenceWeightSave={onReferenceWeightSave}
        beehive={beehive}
        latestWeight={latestWeight?.value}
      />
      <AlarmDialog
        beehive={beehive}
        isOpen={alarmIsOpen}
        onCancel={() => setAlarmIsOpen(false)}
        onSave={addSubscriber}
      />

      <Box maxWidth="lg">
        <Grid
          sx={{ mb: 2, borderShadow: 'none' }}
          container
          maxWidth="lg"
          justifyContent="center"
        >
          {!sensorDataUpdateIntervalId && (
            <>
              <Grid item xs={12} sx={{ justifyContent: 'center', margin: 1 }}>
                <Typography variant="h2" marginLeft={'25%'}>
                  {t('timeIntervalTitle')}
                </Typography>
              </Grid>

              <Grid item xs={6} sm={3} md={3} sx={{ margin: 1 }}>
                <DatePicker
                  label={t('timeIntervalStartLabel')}
                  value={startDateTime}
                  onChange={(newVal) => setStartDateTime(newVal!)}
                  maxDate={endDateTime}
                  minDate={minStartDatePickerValue}
                />
              </Grid>

              <Grid item xs={6} sm={3} md={3} sx={{ margin: 1 }}>
                <DatePicker
                  label={t('timeIntervalEndLabel')}
                  value={endDateTime}
                  onChange={(newVal) => setEndDateTime(newVal!)}
                  maxDate={todayDateTime}
                  minDate={startDateTime}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Box>
      <Box>
        <Grid
          item
          columns={1}
          justifyContent={'center'}
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
        >
          {beehive.id && (
            <>
              <Grid justifyContent={'center'} columns={1}>
                <SensorDataChart
                  height={300}
                  sensorType={SensorType.WEIGHT}
                  sensorData={weightData.data?.data}
                  beehiveId={beehive.id}
                  currRefWeight={referenceWeight.weight}
                />
                <ButtonListContainer>
                  <Button
                    color="secondary"
                    variant="outlined"
                    onClick={() => setAlarmIsOpen(true)}
                    startIcon={<AccessAlarmIcon />}
                  >
                    {t('buttons.createAlarm')}
                  </Button>
                  <Button
                    color="secondary"
                    variant="outlined"
                    onClick={() => setReferenceWeightDialogOpen(true)}
                    startIcon={<SettingsIcon />}
                  >
                    {t('buttons.editReferenceWeight')}
                  </Button>
                </ButtonListContainer>
              </Grid>
              <SensorFields beehive={beehive}></SensorFields>
            </>
          )}
          {beehive !== undefined && (
            <Container maxWidth="lg">
              <IoTMap beehive={beehive}></IoTMap>
            </Container>
          )}
        </Grid>
      </Box>
      <BottomContentContainer>
        <ButtonListContainer>
          <Button
            variant="outlined"
            color="secondary"
            component={Link}
            to="./.."
          >
            {tGlobal('general.back')}
          </Button>
        </ButtonListContainer>
      </BottomContentContainer>
    </>
  )
}

export default SensorCharts
