import { FormControlLabel, Radio, Stack, Typography } from '@mui/material'

const radioSx = (theme) => ({
  display: 'none',
  '& + .MuiFormControlLabel-root': {
    m: 0
  },

  '& + .MuiFormControlLabel-label': {
    width: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.border.main,
    borderRadius: 1,
    m: 0,
    transition: 'all .2s ease-out'
  },

  '&.Mui-checked': {
    '& + .MuiFormControlLabel-label': {
      padding: 1,
      mx: -1,
      borderColor: theme.palette.primary.main,
      transition: 'all .2s ease-out'
    }
  }
})

const formControlLabelSx = {
  m: 0
}

const labelStackSx = (theme) => ({
  p: 2,
  alignItems: 'center',
  overflow: 'hidden',
  '& svg': {
    width: theme.spacing(3),
    height: theme.spacing(3)
  }
})

const OutlinedRadioLabel = (props) => {
  const { label, description, endIcon } = props

  return (
    <Stack sx={labelStackSx} direction="row" spacing={2}>
      <Stack flexGrow={1}>
        <Typography fontWeight="bold">{label}</Typography>

        <Typography>{description}</Typography>
      </Stack>

      {endIcon}
    </Stack>
  )
}

const OutlinedRadio = (props) => {
  const { value, label, description, endIcon } = props
  return (
    <FormControlLabel
      value={value}
      sx={formControlLabelSx}
      control={<Radio sx={radioSx} />}
      label={<OutlinedRadioLabel {...{ label, description, endIcon }} />}
    />
  )
}

export default OutlinedRadio
