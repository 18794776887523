import { createTheme, ThemeOptions } from '@mui/material/styles'
import { grey } from '@mui/material/colors'
import { TypographyOptions } from '@mui/material/styles/createTypography'
import { THEME_CONSTANTS } from './themeConstants'

declare module '@mui/material/styles' {
  interface TypographyVariants {
    hint: React.CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    hint: React.CSSProperties
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    p2: true
  }
}

declare module '@mui/material/styles' {
  interface Palette {
    darkContrast: Palette['primary']
    paper: Palette['primary']
    white: Palette['primary']
    border: Palette['primary']
    imagePlaceholder: Palette['primary']
  }
  interface PaletteOptions {
    darkContrast: PaletteOptions['primary']
    paper: PaletteOptions['primary']
    white: PaletteOptions['primary']
    border: PaletteOptions['primary']
    imagePlaceholder: PaletteOptions['primary']
  }

  interface zIndex {
    sideMenu: string
  }
}

declare module '@mui/material/styles' {
  interface ZIndex {
    sideMenu: string
  }
}

declare module '@mui/material' {
  interface ButtonPropsColorOverrides {
    // @tslint-ignore
    white: any
  }
}

interface ExtendedTypographyOptions extends TypographyOptions {
  p2: React.CSSProperties
  hint: React.CSSProperties
}

interface ExtendedThemeOptions extends ThemeOptions {
  zIndex: ThemeOptions['zIndex'] & {
    sideMenu: string
  }
}

const theme = createTheme({
  spacing: 11,
  typography: {
    fontFamily: 'Avenir',
    button: {
      textTransform: 'none'
    },
    body2: {
      fontSize: '1rem'
    },
    h1: {
      fontSize: '2rem'
    },
    h2: {
      fontSize: '1.25rem',
      fontWeight: 'bold'
    },
    h3: {
      fontSize: '1rem',
      fontWeight: 'bold'
    },
    p2: {
      fontSize: '1rem',
      color: '#00000099'
    }
  } as ExtendedTypographyOptions,
  palette: {
    primary: {
      main: '#FAA302'
    },
    secondary: {
      main: '#2E2E2E'
    },
    darkContrast: {
      main: '#fff'
    },
    paper: {
      main: '#F4F4F4'
    },
    white: {
      main: '#fff'
    },
    border: {
      main: grey[400]
    },
    imagePlaceholder: {
      main: grey[600]
    }
  },
  zIndex: {
    map: '5',
    sideMenu: '1009'
  }
} as ExtendedThemeOptions)

export const APP_CONTENT_MARGIN = `calc(${THEME_CONSTANTS.toolbarHeight} - 1px)`
export const APP_CONTENT_MIN_HEIGHT = `calc(100vh - ${THEME_CONSTANTS.toolbarHeight} + 1px)`

theme.typography = {
  ...theme.typography,
  hint: {
    color: theme.palette.text.secondary
  }
  // body2: {
  //   [theme.breakpoints.up('md')]: {
  //     fontSize: '1.25rem'
  //   }
  // },
  // h1: {
  //   [theme.breakpoints.up('md')]: {
  //     fontSize: '3rem',
  //     fontWeight: 'bold'
  //   }
  // },
  // h2: {
  //   [theme.breakpoints.up('md')]: {
  //     fontSize: '1.75rem',
  //     fontWeight: 'bold'
  //   }
  // },
  // h3: {
  //   [theme.breakpoints.up('md')]: {
  //     fontSize: '1.25rem',
  //     fontWeight: 'bold'
  //   }
  // }
}

theme.components = {
  MuiPagination: {
    defaultProps: {
      variant: 'outlined',
      shape: 'rounded'
    },
    styleOverrides: {
      root: {
        alignSelf: 'center'
      }
    }
  },

  MuiTab: {
    styleOverrides: {
      root: {
        '&.Mui-selected': {
          color: theme.palette.secondary.main
        }
      }
    }
  },

  MuiStack: {},

  MuiTabs: {
    styleOverrides: {
      root: {
        borderBottomWidth: '1px',
        borderBottomColor: theme.palette.border.main,
        borderBottomStyle: 'solid'
      },
      indicator: {
        backgroundColor: theme.palette.secondary.main
      }
    }
  },
  MuiDivider: {
    variants: [
      {
        props: { color: 'primary' },
        style: {
          background: theme.palette.primary.main
        }
      }
    ]
  },
  MuiButton: {
    styleOverrides: {
      root: {
        fontWeight: 'bold',
        padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
        fontSize: '0.875rem'
        // [theme.breakpoints.up('md')]: {
        //   fontSize: '1.25rem'
        // }
      }
    },
    variants: [
      {
        props: { variant: 'contained' },
        style: {
          boxShadow: 'none'
        }
      }
    ]
  },
  MuiAlert: {
    styleOverrides: {
      root: {
        padding: theme.spacing(1),
        display: 'flex',
        alignItems: 'center'
      },
      message: {
        padding: 0
      },
      icon: {
        padding: 0
      }
    }
  },
  MuiContainer: {
    defaultProps: {
      disableGutters: true
    }
  },
  MuiToolbar: {
    styleOverrides: {
      root: {
        height: THEME_CONSTANTS.toolbarHeight
      }
    }
  },
  MuiLink: {
    defaultProps: {
      color: 'secondary'
    }
  },
  MuiFormControlLabel: {
    styleOverrides: {
      root: {
        '& .MuiFormControlLabel-label': {
          fontSize: '0.75rem'
        }
      }
    }
  }
}

export default theme
