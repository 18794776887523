import { FormGridFieldCustomRenderProps } from '../../../../../../models/form'
import { MakeOptional } from '../../../../../../models/util'
import FormGridField, { FormGridFieldProps } from '../../FormGridField'
import ControllerTextField from './ControllerTextField'

export type TextFieldProps<InputProps, OutputValueType = string> = Omit<
  FormGridFieldProps<InputProps, string, OutputValueType>,
  'render'
>

export type DefaultTranslatedTextFieldProps<
  InputProps,
  OutputValueType = string
> = MakeOptional<
  FormGridFieldProps<InputProps, string, OutputValueType>,
  'name' | 'translateKey' | 'translatePrefix' | 'render'
>

function renderTextField<InputProps, OutputValueType>(
  props: FormGridFieldCustomRenderProps<InputProps, string, OutputValueType>
) {
  return <ControllerTextField {...props} />
}

function TextField<InputProps, OutputValueType = string>(
  props: TextFieldProps<InputProps, OutputValueType>
) {
  return (
    <FormGridField<InputProps, string, OutputValueType>
      render={renderTextField}
      {...props}
    />
  )
}

export default TextField
