const ORGANIZATION_RULES = {
  name: {
    required: true,
    maxLength: 60
  },
  description: {
    required: true,
    maxLength: 300
  }
}

export default ORGANIZATION_RULES
