const alarmDialog = {
  sections: {
    main: {
      header: 'Larm',
      body: 'Du håller på att aktivera larm för {{name}}. Efter detta är gjort kommer larmet att aktiveras och du kommer notifieras via epost vid ovanlig aktivitet i denna bikupa.'
    }
  }
}

export default alarmDialog
