const apiaryInfoTab = {
  info: {
    deleteConfirmation: 'Remove apiary',
    deleteInformation:
      'Are you sure you want to remove the apiary? If the apiary contains any beehives, these will also be deleted, this cannot be undone.',
    deleteSucces: 'Apiary has been removed'
  },
  button: {
    deleteApiary: 'Remove apiary',
    cancel: 'Cancel'
  },
  apiary: {
    owner: 'Owner',
    coordinates: 'Coordinates',
    town: 'Town',
    propertyDesignation: 'Property designation',
    areaOfUse: 'Area of use',
    other: 'Other'
  },
  error: {
    loadingApiary: 'An error occurred while loading the apiary',
    loadingOrganization: 'An error occurred while loading the organization'
  },
  settings: 'Settings'
}

export default apiaryInfoTab
