import {
  Button,
  Stack,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import MagnifyerIcon from '../../components/common/Icon/Magnifyer'
import ItemList from '../../components/common/ItemList'
import ItemListLinkCol from '../../components/common/ItemList/Row/ItemListLinkCol'
import TitleImage from '../../components/common/TitleImage'
import { useErrorStateSnackEffect } from '../../utils/hooks/stateSnackEffect'
import { AppRoutePaths, AppRoutePathSegment } from '../../utils/routes'
import useArrayKeySearch from '../../utils/hooks/search'
import PageBodyContainer from '../../components/common/PageBodyContainer'
import BottomContentContainer from '../../components/common/Layout/BottomContentContainer'
import ButtonListContainer from '../../components/common/Layout/ButtonListContainer'
import { Link } from 'react-router-dom'
import V from '../../enums/Path/VariablePathSegment'
import { OrganizationBasicOverview } from '../../models/organization'
import { useGetOrganizationWhereUserNotMember } from '../../utils/api/hooks/organization'

const COMPONENT_TRANSLATE_PREFIX = 'pages.organization.searchOrganization'

const OrganizationRow = ({
  id,
  name,
  description
}: OrganizationBasicOverview) => {
  return (
    <TableRow key={id}>
      <TableCell>{name}</TableCell>
      <TableCell>{description}</TableCell>
      <ItemListLinkCol
        link={AppRoutePathSegment.replaceSegments(
          AppRoutePaths.DISPLAY_ORGANIZATION_PATH,
          [
            {
              name: V.ORGANIZATION_ID,
              value: id
            }
          ]
        )}
      />
    </TableRow>
  )
}

const ORGANIZATION_FILTER_KEYS = ['name', 'description']

const SearchOrganization = () => {
  const { t: tGlobal } = useTranslation()
  const { t } = useTranslation(undefined, {
    keyPrefix: COMPONENT_TRANSLATE_PREFIX
  })

  const [term, setTerm] = useState('')

  const searchTerm = useRef<HTMLInputElement>()

  const listOrganizationsQuery = useGetOrganizationWhereUserNotMember()

  const userOrganizations = listOrganizationsQuery.data?.data.values

  const filteredList = useArrayKeySearch(
    term,
    ORGANIZATION_FILTER_KEYS,
    userOrganizations
  )

  useErrorStateSnackEffect(
    listOrganizationsQuery.error,
    t('error.loadingOrganizations')
  )

  return (
    <Stack className="pageRoot">
      <TitleImage title={t('title')} src="/images/generic-background.png" />
      <Stack className="pageContent" spacing={2}>
        <PageBodyContainer>
          <Typography>{t('search')}</Typography>

          <Typography variant="p2">{t('description')}</Typography>
        </PageBodyContainer>

        <TextField label={t('search')} inputRef={searchTerm} />

        <Button
          variant="contained"
          type="submit"
          startIcon={<MagnifyerIcon />}
          onClick={() => {
            if (typeof searchTerm.current?.value === 'string') {
              setTerm(searchTerm.current.value)
            }
          }}
        >
          {t('search')}
        </Button>

        <ItemList pending={listOrganizationsQuery.isLoading}>
          <TableHead>
            <TableRow>
              <TableCell>{t('header.name')}</TableCell>
              <TableCell>{t('header.description')}</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {filteredList &&
              filteredList.map((org: OrganizationBasicOverview) => (
                <OrganizationRow
                  key={org.id}
                  id={org.id}
                  name={org.name}
                  description={org.description}
                />
              ))}
          </TableBody>
        </ItemList>

        <BottomContentContainer>
          <ButtonListContainer>
            <Button
              component={Link}
              to="./.."
              color="secondary"
              variant="outlined"
            >
              {tGlobal('general.back')}
            </Button>
          </ButtonListContainer>
        </BottomContentContainer>
      </Stack>
    </Stack>
  )
}

export default SearchOrganization
