const displayBeehiveInspectionNote = {
  actions: {
    updateInspectionNote: {
      error: 'Ett fel uppstod när ändringarna skulle sparas',
      success: 'Ändringarna har sparats.'
    },
    loadInspectionNote: {
      error: 'Ett fel uppstod när anteckningen skulle laddas',
      success: 'Anteckningen har laddats.'
    }
  }
}

export default displayBeehiveInspectionNote
