import { useTranslation } from 'react-i18next'
import { NoteCategory } from '../../../models'

import FormGrid from '../../common/Form/FormGrid'
import FormGridRadioGroup from '../../common/Form/FormGrid/CommonRows/OutlinedRadioGroup'
import OutlinedRadio from '../../common/Form/FormGrid/CommonRows/OutlinedRadioGroup/OutlinedRadio'
import { useFormContext } from 'react-hook-form'
import { useMemo } from 'react'

export const CATEGORY_TYPES = Object.values(NoteCategory)

let COMPONENT_TRANSLATE_PREFIX = 'components.note.newNote.newNoteCategorySelect'

const NewNoteCategorySelect = (props: {
  name: string
  translatePrefix?: string
}) => {
  const { name } = props
  if (props?.translatePrefix) {
    COMPONENT_TRANSLATE_PREFIX = props.translatePrefix
  }
  const { t } = useTranslation(undefined, {
    keyPrefix: COMPONENT_TRANSLATE_PREFIX
  })
  const { watch, setValue } = useFormContext()
  const selectedType = watch(`${name}.type`)
  const selectedCategory = watch('type.category')
  useMemo(() => {
    if (selectedType === 'inspection') {
      setValue(`${name}.category`, NoteCategory.BEEHIVE)
    }
  }, [selectedType, selectedCategory])

  return (
    <FormGrid name={name} translatePrefix={COMPONENT_TRANSLATE_PREFIX}>
      <FormGridRadioGroup
        name="category"
        rules={{
          required: true
        }}
      >
        {selectedType !== 'inspection' ? (
          CATEGORY_TYPES.map((name) => (
            <OutlinedRadio
              key={name}
              value={name}
              label={t(`fields.category.options.${name}.title`)}
              description={t(`fields.category.options.${name}.description`)}
            />
          ))
        ) : (
          <OutlinedRadio
            key={selectedCategory}
            value={selectedCategory}
            label={t('fields.category.options.BEEHIVE.title')}
            description={t('fields.category.options.BEEHIVE.description')}
          />
        )}
      </FormGridRadioGroup>
    </FormGrid>
  )
}

export default NewNoteCategorySelect
