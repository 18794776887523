import TextField, { DefaultTranslatedTextFieldProps } from './TextField'

export const VALID_EMAIL_PATTERN = /\S+@\S+\.\S+/

export type NameFieldProps<InputProps> =
  DefaultTranslatedTextFieldProps<InputProps>

function NameField<InputProps> (props: NameFieldProps<InputProps>) {
  return (
    <TextField
      name="name"
      translateKey="name"
      translatePrefix="form"
      {...props}
    />
  )
}

export default NameField
