const beehiveInspectionDetails = {
  section: {
    general: 'Allmänt',
    society: 'Om samhället',
    queen: 'Drottning',
    other: 'Annat'
  },
  defaultTitle: '{{val, datetime}}',
  fields: {
    title: {
      label: 'Titel',
      errors: {
        required: ''
      }
    },

    newEggs: {
      _checkbox: {
        label: 'Nylagda ägg',
        errors: {
          required: ''
        }
      },
      value: {
        label: 'Antal ramar',
        errors: {
          required: ''
        }
      }
    },
    coveredFries: {
      _checkbox: {
        label: 'Täckt yngel',
        errors: {
          required: ''
        }
      },
      value: {
        label: 'Antal ramar',
        errors: {
          required: ''
        }
      }
    },
    filledFryRoomsWithFrames: {
      _checkbox: {
        label: 'Fyllt upp yngelrummet med ramar',
        errors: {
          required: ''
        }
      },
      value: {
        label: 'Antal',
        errors: {
          required: ''
        }
      }
    },
    cutDroneFrame: {
      label: 'Drönarram utskuren',
      errors: {
        required: ''
      }
    },
    attractsPollen: {
      label: 'Samhället drar in pollen',
      errors: {
        required: ''
      }
    },
    winterFed: {
      _checkbox: {
        label: 'Vinterfodrat',
        errors: {
          required: ''
        }
      },
      value: {
        label: 'kg / liter',
        errors: {
          required: ''
        }
      }
    },
    checkedFramesWithRemainingFodder: {
      _checkbox: {
        label: 'Kontrollerat antal ramar med foder kvar',
        errors: {
          required: ''
        }
      },
      value: {
        label: 'Antal ramar',
        errors: {
          required: ''
        }
      }
    },
    hurled: {
      _checkbox: {
        label: 'Slungat',
        errors: {
          required: ''
        }
      },
      value: {
        label: 'kg',
        errors: {
          required: ''
        }
      }
    },
    performedSpringControl: {
      _checkbox: {
        label: 'Utfört vårkontroll',
        errors: {
          required: ''
        }
      }
    },
    performedBeeHouseControl: {
      _checkbox: {
        label: 'Utfört bihusesyn/egenkontroll',
        errors: {
          required: ''
        }
      }
    },
    societyStrength: {
      _checkbox: {
        label: 'Styrka på samhället',
        errors: {
          required: ''
        }
      }
    },
    societyBehavior: {
      _checkbox: {
        label: 'Samhällets egenskap',
        errors: {
          required: ''
        }
      }
    },
    sicknessSymptom: {
      _checkbox: {
        label: 'Sjukdomssymptom',
        errors: {
          required: ''
        }
      },
      value: {
        label: '',
        errors: {
          required: ''
        }
      }
    },
    queenControl: {
      _checkbox: {
        label: 'Drottningkontroll',
        errors: {
          required: ''
        }
      }
    },
    newQueen: {
      label: 'Ny drottning tillsatt',
      errors: {
        required: ''
      }
    },
    queenMarked: {
      _checkbox: {
        label: 'Märkning av drottning',
        errors: {
          required: ''
        }
      },
      value: {
        label: 'Färg',
        errors: {
          required: ''
        },
        option: {
          blue: 'Blå',
          red: 'Röd',
          yellow: 'Gul'
        }
      }
    },
    queenWingCut: {
      label: 'Vingklippte drottningen',
      errors: {
        required: ''
      }
    },
    other: {
      _checkbox: {
        label: 'Övrigt',
        errors: {
          required: ''
        }
      }
    }
  }
}

export default beehiveInspectionDetails
