const beeHiveInfo = {
  error: {
    loadingApiary: 'An error occurred while loading the apiary'
  },
  noteTypes: {
    OTHER: 'Other',
    INSPECTION: 'Inspection'
  },

  headers: {
    name: 'Name',
    apiaryId: 'Apiary',
    beeType: 'Bee Type',
    beehiveType: 'Beehive Type',
    iotId: 'Digital Hive',
    lastSwarmed: 'Last Swarmed',
    queenColor: 'Queen Color',
    queenWingCut: 'Queen Wing Cut',
    framesAmount: 'Frames Amount',
    boxAmount: 'Box Amount',
    settings: 'Settings',
    moveHiveOrOrg: 'Moved to another Apiary/Organization?',
    harvestThisYear: 'Harvest This Year',
    otherBeehivesSameApiary: 'Other Beehives in the Apiary:',
    noData: '-',
    noNotes: 'No notes'
  },
  subtext: {
    EDIT_SETTINGS: 'Adjust the settings for the beehive',
    MOVE_HIVE:
      'Has the beehive been moved to another apiary or organization since it was installed? Click the button below'
  },
  buttons: {
    MOVE_HIVE: 'Move Beehive',
    RETURN: 'Back',
    SETTINGS: 'Settings',
    EDIT: 'Edit',
    NEW_NOTE: 'New Note'
  },
  units: {
    weight: 'kg'
  }
}

export default beeHiveInfo
