import { Grid } from '@mui/material'
import { Box } from '@mui/system'
import { useMemo } from 'react'
import { FormProvider } from 'react-hook-form'
import FormGridContext from './FormGridContext'

/**
 * Provides a grid and context for form related features, see FormGridContext for more details.
 * @param {*} props.sx {root, grid} stylings for the root and grid element
 * @param {*} props.tranlsatePrefix optional translatePrefix to use for the fields
 * @param {*} props.name optional prefix to use for contained fields
 * @param {*} props.methods optional react-use-form instance. Will be provided via FormProvider
 * @param {*} props.rules optional 'global' rules for the form which
 * @param {*} props.children content to be rendered within the grid
 */

const FormGrid = (props) => {
  const {
    children,
    translatePrefix,
    name,
    methods,
    rules,
    sx,
    item: isSubForm,
    ...rest
  } = props

  const formGridContextValue = useMemo(
    () => ({
      namePrefix: name,
      translatePrefix,
      gridRules: rules
    }),
    [name, translatePrefix]
  )

  const gridLayout = (
    <Grid container spacing={2} sx={sx?.grid} item={isSubForm} {...rest}>
      {children}
    </Grid>
  )

  const wrappedGrid = (
    <FormGridContext.Provider value={formGridContextValue}>
      {isSubForm ? gridLayout : <Box sx={sx?.root}>{gridLayout}</Box>}
    </FormGridContext.Provider>
  )

  return methods ? (
    <FormProvider {...methods}>{wrappedGrid}</FormProvider>
  ) : (
    wrappedGrid
  )
}

export default FormGrid
