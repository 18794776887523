import { TextField } from '@mui/material'
import FormGridField from '../FormGridField'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'

const DateField = (props) => {
  return (
    <FormGridField
      name="date"
      translateKey="date"
      translatePrefix="form"
      render={({
        label,
        controller: {
          field: { onChange, value },
          fieldState: { error }
        },
        inputProps,
        ...renderProps
      }) => (
        <MobileDatePicker
          label={label}
          inputFormat="yyyy-MM-dd"
          value={value}
          onChange={onChange}
          renderInput={(params) => (
            <TextField fullWidth {...params} error={!!error} />
          )}
          {...renderProps}
          {...inputProps}
        />
      )}
      {...props}
    />
  )
}

export default DateField
