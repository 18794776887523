const verifyUserEmail = {
  title: 'Verifiera konto',
  infoTitle: 'Verifiering',
  description: 'Öppna mailet som skickades till dig och ange siffrorna för att slutföra registreringen samt verifiera e-postadressen',

  actions: {
    verify: {
      success: 'Kontot har verifierats'
    },
    resendCode: {
      success: 'En ny kod har skickats'
    }
  },

  resendConfirmationMail: {
    description: 'Har du inte fått något mail?',
    link: 'Skicka igen'
  }
}

export default verifyUserEmail
