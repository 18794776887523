import { useMutation } from '@tanstack/react-query'
import { ApiError } from 'openapi-typescript-fetch'
import { useContext } from 'react'
import useIsMounted from '../../hooks/isMounted'
import AuthContext from '../AuthContext'
import { CallbackVoid } from '../util/types'
import { useApiClient } from './useApiClient'

export const USER_NOT_CONFIRMED_MESSAGE = 'User is not confirmed.'

export function useLoginMutation(onError?: (err: ApiError) => void) {
  const apiClient = useApiClient()
  const authContext = useContext(AuthContext)
  const isMounted = useIsMounted()

  return useMutation({
    mutationFn: apiClient.auth.login,
    onSuccess: (res) => {
      if (isMounted()) {
        authContext.setAuthState({
          userId: res.data.userId,
          sessionJwtToken: res.data.idToken,
          hasSeenStartupGuide: res.data.hasSeenStartupGuide
        })
      }
    },
    onError
  })
}

export function useRegisterMutation(onSuccess?: CallbackVoid) {
  const apiClient = useApiClient()

  return useMutation({
    mutationFn: apiClient.auth.register,
    onSuccess: onSuccess
  })
}

export function useVerifyAccountMutation(onSuccess?: CallbackVoid) {
  const apiClient = useApiClient()

  return useMutation({
    mutationFn: apiClient.auth.verifyAccount,
    onSuccess: onSuccess
  })
}

export function useResendAccountVerificationCodeMutation() {
  const apiClient = useApiClient()

  return useMutation({
    mutationFn: apiClient.auth.resendAccountVerificationCode
  })
}

export function useInitiateResetPasswordMutation() {
  const apiClient = useApiClient()

  return useMutation({
    mutationFn: apiClient.auth.initPasswordReset
  })
}

export function useVerifyResetPasswordMutation(onSuccess?: CallbackVoid) {
  const apiClient = useApiClient()

  return useMutation({
    mutationFn: apiClient.auth.verifyPasswordReset,
    onSuccess
  })
}

export function useSignOutMutation() {
  const apiClient = useApiClient()

  return useMutation({
    mutationFn: apiClient.auth.signOut
  })
}
