const apiariesTab = {
  action: {
    addApiaries: {
      label: 'Lägg till fler bigårdar'
    }
  },

  section: {
    notMember: {
      body: 'Gå med i organisationen för att få tillgång till dess bigårdar'
    }
  }
}

export default apiariesTab
