const BeehivePlanningForm = {
  date: 'Date',
  chooseDate: 'Choose a date',
  fields: {
    reminder: {
      _checkbox: {
        label: 'Set a reminder'
      },
      value: {
        label: ''
      }
    },
    winterFed: {
      _checkbox: {
        label: 'Winter feed'
      }
    },
    addBoxes: {
      _checkbox: {
        label: 'Add honey chambers'
      },
      value: {
        label: 'Number of honey chambers'
      }
    },
    springInspection: {
      _checkbox: {
        label: 'Spring inspection'
      }
    },
    boxInspection: {
      _checkbox: {
        label: 'Inspect honey chambers'
      }
    },
    findQueen: {
      _checkbox: {
        label: 'Look for the queen'
      }
    },
    findEggs: {
      _checkbox: {
        label: 'Look for laid eggs/brood'
      }
    },
    wingCut: {
      _checkbox: {
        label: 'Cut drone comb'
      }
    },
    newEggs: {
      _checkbox: {
        label: 'Add frames with brood'
      }
    },
    other: {
      _checkbox: {
        label: 'Other'
      }
    }
  }
}

export default BeehivePlanningForm
