import InfoGridText from '../../components/common/InfoGridText'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { Stack, Button } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, Link, useNavigate } from 'react-router-dom'
import LoadingPage from '../../components/common/LoadingPage'
import TitleImage from '../../components/common/TitleImage'
import { AppRoutePathSegment } from '../../utils/routes'
import EditIcon from '../../components/common/Icon/Edit'
import TrashCanIcon from '../../components/common/Icon/TrashCan'
import BottomContentContainer from '../../components/common/Layout/BottomContentContainer'
import DeleteConfirmationDialog from '../../components/DeleteConfirmationDialog'
import { useErrorStateSnackEffect } from '../../utils/hooks/stateSnackEffect'
import ChangeMemberRole from './ChangeMemberRole'
import {
  useOrganizationMemberQuery,
  useOrganizationMembersQuery
} from '../../utils/api/hooks/organization'
import {
  OrganizationMemberOverview,
  OrganizationMemberRoleInput
} from '../../models/organization'
import {
  useDeleteMemberMutation,
  useUpdateMemberRoleMutation
} from '../../utils/api/hooks/organizationMember'
import { useSnackbar } from 'notistack'
import { isAuthorizedMemberWithRole } from '../../utils/helpers/organizationMember'
import { useGetUserQuery } from '../../utils/api/hooks/user'
import useIsMounted from '../../utils/hooks/isMounted'

const COMPONENT_TRANSLATE_PREFIX = 'pages.organization.editOrganizationMember'

type MemberFieldConfig = {
  key: string
  getValue: (
    member?: OrganizationMemberOverview | null
  ) => string | null | undefined
}

const MEMBER_FIELDS_TO_SHOW: MemberFieldConfig[] = [
  {
    key: 'name',
    getValue: (member) => member?.firstname + ' ' + member?.surname
  },
  { key: 'email', getValue: (member) => member?.email },
  /* { key: 'phoneNumber', getValue: (member) => member?.phone }, */
  { key: 'address', getValue: (member) => member?.street }
]

const EditMemberPage = () => {
  const isMounted = useIsMounted()
  const navigate = useNavigate()
  const navigateBackToOrg = () => navigate('./../../..')
  const { enqueueSnackbar } = useSnackbar()
  const [showDeleteMemberDialog, setShowDeleteMemberDialog] = useState(false)
  const [showSelectRoleDialog, setShowSelectRoleDialog] = useState(false)

  const {
    [AppRoutePathSegment.ORGANIZATION_MEMBER_ID]: memberId,
    [AppRoutePathSegment.ORGANIZATION_ID]: organizationId
  } = useParams()

  const { t } = useTranslation(undefined, {
    keyPrefix: COMPONENT_TRANSLATE_PREFIX
  })
  const { t: tGlobal } = useTranslation()

  const orgMemberByIdQuery = useOrganizationMemberQuery(
    organizationId,
    memberId
  )
  const member = orgMemberByIdQuery.data?.data
  const orgMembersByidQuery = useOrganizationMembersQuery(organizationId)
  const members = orgMembersByidQuery.data?.data
  useErrorStateSnackEffect(orgMemberByIdQuery.isError, tGlobal('error.general'))
  useErrorStateSnackEffect(orgMemberByIdQuery.error, tGlobal('error.general'))

  const onDeleteMemberSuccess = useCallback(() => {
    enqueueSnackbar(t('actions.removeMember.fullfilled'), {
      variant: 'success'
    })
    if (isMounted()) {
      navigate('./../../..')
    }
  }, [t, enqueueSnackbar, navigate, isMounted])

  const deleteMemberQuery = useDeleteMemberMutation(onDeleteMemberSuccess)
  function deleteMember() {
    if (!memberId || !organizationId) return
    deleteMemberQuery.mutate({ id: memberId, orgId: organizationId })
  }

  useErrorStateSnackEffect(
    deleteMemberQuery.isError,
    t('actions.removeMember.error')
  )

  const onSubmitSuccess = useCallback(() => {
    enqueueSnackbar(t('actions.changeRole.fullfilled'), { variant: 'success' })
    location.reload()
  }, [enqueueSnackbar, t])

  const updateMemberRoleMutation = useUpdateMemberRoleMutation(onSubmitSuccess)

  function updateMember(newRole: OrganizationMemberRoleInput) {
    if (memberId && organizationId) {
      updateMemberRoleMutation.mutate({
        id: memberId,
        orgId: organizationId,
        role: newRole
      })
    }
  }

  // useEffect below closes the deleteconfirmation dialog through a boolean
  // and navigates the user back to the organization page.
  useEffect(() => {
    if (deleteMemberQuery.isSuccess) {
      setShowDeleteMemberDialog(false)
      navigateBackToOrg()
    }
  }, [deleteMemberQuery.isSuccess])

  // useEffect below closes the deleteconfirmation dialog through a boolean
  // and rerenders the component.
  useEffect(() => {
    if (updateMemberRoleMutation.isSuccess) {
      setShowSelectRoleDialog(false)
    }
  }, [updateMemberRoleMutation.isSuccess])

  const requestingUser = useGetUserQuery()

  const orgMembersQuery = useOrganizationMemberQuery(
    organizationId,
    requestingUser.data?.data.id
  )
  const isAdmin = isAuthorizedMemberWithRole(
    orgMembersQuery.data?.data,
    'ADMIN'
  )

  // Frontend quick fix to avoid non-admin organizations
  const actionsDisabled =
    !isAdmin || (members?.length !== undefined && members.length <= 1)

  return (
    <Stack className="pageRoot">
      <TitleImage
        src="/images/generic-background.png"
        title={
          member
            ? `${member.firstname} ${member.surname}`
            : tGlobal('general.pending')
        }
      />

      <Stack className="pageContent" spacing={2}>
        {orgMemberByIdQuery.isLoading ||
        orgMemberByIdQuery.isLoading ||
        updateMemberRoleMutation.isLoading ||
        deleteMemberQuery.isLoading ? (
          <LoadingPage />
        ) : (
          <>
            {MEMBER_FIELDS_TO_SHOW.map((field) => (
              <InfoGridText
                key={field.key}
                header={t(`header.${field.key}`)}
                body={field.getValue(member)}
              />
            ))}
            {isAdmin && (
              <>
                <InfoGridText
                  header={t('header.role')}
                  body={
                    member?.role
                      ? tGlobal(`general.memberRole.${member.role}`)
                      : t('body.roleMissing')
                  }
                />

                {member?.isApproved ? (
                  <InfoGridText
                    header={t('actions.changeRole.title')}
                    body={t('actions.changeRole.description')}
                  />
                ) : (
                  <InfoGridText
                    header={t('actions.approveMember.title')}
                    body={t('actions.approveMember.description')}
                  />
                )}

                {member?.isApproved ? (
                  <Button
                    variant="contained"
                    fullWidth
                    disabled={actionsDisabled}
                    startIcon={<EditIcon />}
                    onClick={() => setShowSelectRoleDialog(true)}
                  >
                    {t('actions.changeRole.buttonText')}
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    fullWidth
                    disabled={actionsDisabled}
                    startIcon={<CheckCircleOutlineIcon />}
                    onClick={() => setShowSelectRoleDialog(true)}
                  >
                    {t('actions.approveMember.title')}
                  </Button>
                )}

                <ChangeMemberRole
                  open={!!showSelectRoleDialog}
                  pending={updateMemberRoleMutation.isLoading}
                  setOpenSelectRoleDialog={setShowSelectRoleDialog}
                  memberRole={member?.role}
                  changeRoleFunction={updateMember}
                />

                <InfoGridText
                  header={t('actions.removeMember.title')}
                  body={t('actions.removeMember.description')}
                />

                <Button
                  variant="contained"
                  fullWidth
                  disabled={actionsDisabled}
                  startIcon={<TrashCanIcon />}
                  onClick={() => setShowDeleteMemberDialog(true)}
                >
                  {t('actions.removeMember.buttonText')}
                </Button>
              </>
            )}

            <DeleteConfirmationDialog
              open={!!showDeleteMemberDialog}
              pending={deleteMemberQuery.isLoading}
              setOpenDeleteDialog={setShowDeleteMemberDialog}
              deleteFunction={deleteMember}
            />

            <BottomContentContainer>
              <Button
                variant="outlined"
                color="secondary"
                component={Link}
                to={'./../../..'}
              >
                {tGlobal('general.back')}
              </Button>
            </BottomContentContainer>
          </>
        )}
      </Stack>
    </Stack>
  )
}

export default EditMemberPage
