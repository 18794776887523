import { useCallback } from 'react'
import EditAndNew from '../../components/beehives/BeehiveEditForm'
import { useErrorStateSnackEffect } from '../../utils/hooks/stateSnackEffect'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Stack } from '@mui/material'
import TitleImage from '../../components/common/TitleImage'
import LoadingPage from '../../components/common/LoadingPage'
import { useSnackbar } from 'notistack'
import useIsMounted from '../../utils/hooks/isMounted'
import { useCreateBeehiveMutation } from '../../utils/api/hooks/beehive'
import { isNotFoundError } from '../../utils/api/util/isNotFoundError'
import PageNotFound from '../PageNotFound'
import { BeehiveInput } from '../../models/beehive'
import { useGetApiaryQuery } from '../../utils/api/hooks/apiary'

const convertToBeehiveCreateInput = (
  organizationId: string | undefined,
  apiaryId: string,
  form: BeehiveInput
) => {
  return { organizationId, apiaryId, ...form }
}

const NewBeehive = () => {
  const initialState = null
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const isMounted = useIsMounted()
  const { organizationId, apiaryId } = useParams()

  const onCreateBeehiveSuccess = useCallback(() => {
    enqueueSnackbar(t('success.beehiveCreated'), { variant: 'success' })
    if (isMounted()) {
      navigate('./../')
    }
  }, [t, enqueueSnackbar, isMounted, navigate])

  const createBeehiveMutation = useCreateBeehiveMutation(onCreateBeehiveSuccess)

  if (isNotFoundError(createBeehiveMutation.error)) {
    return <PageNotFound />
  }
  useErrorStateSnackEffect(createBeehiveMutation.error, t('error.saveBeehive'))

  const apiaryQuery = useGetApiaryQuery(apiaryId!)
  const apiary = apiaryQuery.data?.data

  useErrorStateSnackEffect(apiaryQuery.error, t('error.loadingApiary'))

  const submit = useCallback(
    (form: BeehiveInput) => {
      createBeehiveMutation.mutate(
        convertToBeehiveCreateInput(organizationId, apiaryId!, form)
      )
    },
    [createBeehiveMutation.mutate]
  )

  return apiaryQuery.isLoading ? (
    <LoadingPage />
  ) : (
    <>
      <Stack className="pageRoot">
        <TitleImage src="/images/generic-background.png" title={apiary?.name} />
      </Stack>
      <Stack className="pageContent">
        <EditAndNew
          initialState={initialState}
          onSubmit={submit}
          pending={createBeehiveMutation.isLoading}
        />
      </Stack>
    </>
  )
}

export default NewBeehive
