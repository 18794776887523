const apiaryEditForm = {
  title: {
    create: 'Skapa Bigård',
    update: 'Ändra Bigård'
  },

  fields: {
    owner: {
      label: 'Ägare'
    },
    propertyDesignation: {
      label: 'Fastighetsbeteckning',
      errors: {
        required: 'Fastighetsbeteckning krävs!'
      }
    },
    areaOfUse: {
      label: 'Användningsområden'
    }
  },
  sections: {
    qr: {
      title: 'QR-koder',
      body: 'Generera en QR-kod för varje bikupa i bigården som inte redan har en QR-kod. Dessa gör det smidigt att skapa anteckningar och se data för den bikupan utan att behöva navigera genom hemsidan.'
    }
  },
  success: {
    updateApiary: 'Bigården har uppdaterats!'
  },
  error: {
    updateApiary: 'Ett fel inträffade när bigården skulle uppdateras!'
  },
  updateApiary: 'Ändra Bigård',
  coordinates: 'Koordinater',
  town: 'Kommun',
  propertydesignation: 'Fastighetsbeteckning',
  areaOfUse: 'Användningsområde',
  chooseApiaryLocation: 'Placera gården på en karta',
  generateQRButton: 'Generera QR-koder för hela bigården'
}

export default apiaryEditForm
