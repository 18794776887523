const infoTab = {
  organization: {
    description: 'Beskrivning',
    organizationNumber: 'Organisationsnummer',
    zipCode: 'Postnummer',
    street: 'Adress',
    town: 'Stad',
    country: 'Land'
  },
  button: {
    cancel: 'Avbryt',
    removeOrg: 'Ta bort organisation'
  },
  info: {
    deleteSuccess: 'Organisationen har tagits bort',
    deleteFailed:
      'Något gick fel, enbart administratörer kan ta bort organisationer.',
    removeOrgTitle: 'Ta bort organisation',
    removeOrgInfo:
      'Är du säker på att du vill ta bort organisationen? Alla tillhörande bigårdar och bikupor kommer att tas bort, detta kan inte ångras.'
  }
}

export default infoTab
