const viewApiary = {
  tabs: {
    beehives: 'Beehives',
    info: 'Information'
  },
  error: {
    loadingApiary: 'An error occured when loading the apiary.'
  }
}

export default viewApiary
