import { useMemo } from 'react'

function useArrayKeySearch(searchTerm, keys, list) {
  return useMemo(
    () =>
      list &&
      list.filter((item) => {
        console.log(searchTerm)
        return Object.values(keys).some((key) =>
          item[key]
            ?.toString()
            ?.toLowerCase()
            .includes(searchTerm.toLowerCase())
        )
      }),
    [searchTerm, keys, list]
  )
}

export default useArrayKeySearch
