import { useApiClient } from './useApiClient'
import { useMutation, useQuery } from '@tanstack/react-query'
import { CallbackVoid } from '../util/types'
import { useIsAuthenticated } from './useIsAuthenticated'

export const GET_USER_QUERY_KEY = 'getUser'

export function useGetUserQuery(onSuccess?: CallbackVoid) {
  const apiClient = useApiClient()
  const isAuthenticated = useIsAuthenticated()

  return useQuery({
    queryKey: [GET_USER_QUERY_KEY, isAuthenticated],
    queryFn: apiClient.user.getUser,
    enabled: !!isAuthenticated,
    onSuccess: onSuccess
  })
}

export function useUpdateUserMutation(onSuccess?: CallbackVoid) {
  const apiClient = useApiClient()

  return useMutation({
    mutationFn: apiClient.user.updateUser,
    onSuccess: onSuccess
  })
}

export function useUpdateUserHasSeenStartupGuideutation() {
  const apiClient = useApiClient()

  return useMutation({
    mutationFn: () => apiClient.user.updateHasSeenStartupGuide(null),
    retry: 4
  })
}

export function useUpdateUserAppReviewMutation(onSuccess?: CallbackVoid) {
  const apiClient = useApiClient()

  return useMutation({
    mutationFn: apiClient.user.updateUserAppReview,
    onSuccess: onSuccess,
    retry: 4
  })
}
