import FormGrid from '../../common/Form/FormGrid'
import CodeRow from '../../common/Form/FormGrid/CommonRows/CodeRow'
import PasswordField from '../../common/Form/FormGrid/CommonRows/PasswordField'
import PasswordRepeatField from '../../common/Form/FormGrid/CommonRows/PasswordRepeatField'

const PASSWORD_RESET_CODE_LENGTH = 6

const PasswordSubmit = props => {
  const {
    sx,
    ...rest
  } = props

  return (
    <FormGrid
      sx={{ px: 2, ...sx }}
      rules={{ required: true }}
      {...rest}
    >
      <CodeRow length={PASSWORD_RESET_CODE_LENGTH}/>
      <PasswordField/>
      <PasswordRepeatField/>
    </FormGrid>
  )
}

export default PasswordSubmit
