import { Button, Collapse, Grow, Stack, Typography, Card } from '@mui/material'
import { useEffect, useState } from 'react'
import { useMap } from 'react-leaflet'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/system'
import ButtonListContainer from '../../common/Layout/ButtonListContainer'

const COMPONENT_TRANSLATE_PREFIX = 'components.map.apiaryLocationSelect.overlay'
const MAP_BUTTONS_CLASS = 'leaflet'

const ApiaryMapOverlay = props => {
  const {
    onConfirm,
    onCancel,
    selectedApiaryLocation,
    apiaries,
    updateSelectedApiary
  } = props
  const { t } = useTranslation(undefined, { keyPrefix: COMPONENT_TRANSLATE_PREFIX })
  const map = useMap()
  const [menuOpen, setMenuOpen] = useState(false)

  useEffect(() => {
    if (selectedApiaryLocation) {
      goToSelectedApiary()
    }
  }, [selectedApiaryLocation?.apiary?.sortId, selectedApiaryLocation?.apiary?.id])

  const goToSelectedApiary = () => {
    map.setView(selectedApiaryLocation.location)
  }

  const selectApiary = (apiary) => {
    updateSelectedApiary(apiary)
    setMenuOpen(false)
  }

  const toggleMenuOpen = () => setMenuOpen(!menuOpen)

  return (
    <div className={MAP_BUTTONS_CLASS}>
      <Stack
        className="leaflet-control"
        sx={{ width: '100%', mt: 4 }}
      >
        <Box alignItems="center" display="flex" flexDirection="column">
          <Button
            variant="contained"
            color="white"
            sx={{
              py: 2,
              px: 8,
              boxShadow: 3,
              wordBreak: 'break-all'
            }}
            onClick={toggleMenuOpen}
            endIcon={menuOpen ? <KeyboardArrowDownIcon/> : <KeyboardArrowRightIcon/>}
          >
            <Typography fontWeight="bold">
              {selectedApiaryLocation?.apiary.name || selectedApiaryLocation?.apiary.defaultName}
            </Typography>
          </Button>

          <Collapse in={menuOpen}>
            <Grow in={menuOpen} sx={{ mt: 2 }}>
              <Stack
                spacing={2}
                justifyContent="center"
                alignItems="center"
              >
                {apiaries.map((apiary, index) => (
                  <Card
                    elevation={3}
                    sx={{ py: 1, px: 6, textAlign: 'center', width: 'fit-content' }}
                    key={index}
                    onClick={() => selectApiary(apiary)}
                  >
                    <Typography fontWeight={ (selectedApiaryLocation?.apiary === apiary) ? 'bold' : undefined }>
                      {apiary.name || apiary.defaultName}
                    </Typography>
                  </Card>
                ))}
              </Stack>
            </Grow>
          </Collapse>
        </Box>
      </Stack>

      <ButtonListContainer
        className="leaflet-control"
        sx={{ position: 'fixed', width: '100%', bottom: 0, mb: 4 }}
      >
        <Button
          variant="outlined"
          color="secondary"
          style={{ backgroundColor: '#FFFFFF' }}
          onClick={onCancel}
        >
          {t('buttons.cancel')}
        </Button>

        <Button
          variant="contained"
          color="secondary"
          onClick={onConfirm}
        >
          {t('buttons.save')}
        </Button>
      </ButtonListContainer>
    </div>
  )
}

export default ApiaryMapOverlay
