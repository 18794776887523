const beehiveEditForm = {
  title: {
    create: 'Skapa ny bikupa',
    update: 'Ändra bikupa '
  },

  section: {
    iot: {
      title: 'Nytt Digitalt Kit?',
      body: 'Har du fixat ett digitalt kit till din bikupa och installerat det? Klicka på knappen nedan och skanna QR-koden på locket för att koppla ditt nya kit till denna bikupa.'
    }
  },

  fields: {
    framesAmount: {
      label: 'Antal ramar'
    },

    boxAmount: {
      label: 'Antal lådor'
    },

    lastSwarmed: {
      label: 'Senast svärmad'
    },

    beeType: {
      label: 'Typ av bi'
    },

    beehiveType: {
      label: 'Typ av bikupa'
    },

    frameType: {
      label: 'Typ av ramar'
    },

    queenColor: {
      label: 'Drottningmärkning'
    },

    queenWingCut: {
      label: 'Drottning vingklippt'
    }
  }
}

export default beehiveEditForm
