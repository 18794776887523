
const searchOrganization = {
  title: 'Organisationer',
  search: 'Sök',
  description: 'Sök efter organisationen du letar efter',
  header: {
    name: 'Namn',
    description: 'Beskrivning'
  },
  error: {
    loadingOrganizations: 'Ett fel uppstod när organisationerna skulle laddas',
    loadingMembers: 'Ett fel uppstod när medlemmarna skulle laddas'
  }
}

export default searchOrganization
