import newNotePage from './newNotePage'
import notesLandingPage from './notesLandingPage'
import listNotes from './listNotes'
import editNote from './editNote'
import displayOtherNote from './displayOtherNote'
import displayBeehiveInspectionNote from './displayBeehiveInspectionNote'

const note = {
  notesLandingPage,
  newNotePage,
  listNotes,
  editNote,
  displayOtherNote,
  displayBeehiveInspectionNote
}

export default note
