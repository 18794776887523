import { Grid, Typography } from '@mui/material'
import { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import FormGridContext from '../../FormGridContext'

const CheckSectionTitle = props => {
  const { name, disableGutter, ...rest } = props

  const { translatePrefix } = useContext(FormGridContext)

  const { t } = useTranslation(undefined, { keyPrefix: translatePrefix })

  const typographyClass = useMemo(
    () => `check-row-section-title ${disableGutter ? 'no-gutter' : ''}`,
    [disableGutter]
  )

  return (
    <Grid item xs={12} className="checkRow" {...rest}>
      <Typography className={typographyClass} variant="h2">
          {t(`section.${name}`)}
      </Typography>
    </Grid>
  )
}

export default CheckSectionTitle
