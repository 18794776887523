const sideMenu = {
  link: {
    organisations: 'Organisationer',
    apiaries: 'Bigårdar',
    beehives: 'Bikupor',
    notes: 'Anteckningar',
    alarms: 'Notiser',
    profile: 'Min Profil',
    signIn: 'Inloggning',
    register: 'Registrering',
    logOut: 'Logga ut',
    reports: 'Rapporter',
    about: 'Om Beelab'
  },

  actions: {
    logOut: {
      pending: 'Du loggas ut...',
      fulfilled: 'Du har loggats ut'
    }
  }
}

export default sideMenu
