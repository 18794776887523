import { useTranslation } from 'react-i18next'
import ThermostatIcon from '@mui/icons-material/Thermostat'
import { Grid, Box, CircularProgress } from '@mui/material'
import OpacityIcon from '@mui/icons-material/Opacity'
import AirIcon from '@mui/icons-material/Air'
import { useGetSensorsLatestValQuery } from '../../../../../utils/api/hooks/iot'
import { useMemo } from 'react'
import { Beehive } from '../../../../../models/beehive'

const COMPONENT_TRANSLATE_PREFIX = 'components.beehive.iotTab'

type currentBeehiveProp = {
  beehive: Beehive
}

function SensorFields(props: currentBeehiveProp) {
  const { beehive } = props
  let iotId = '   '

  if (beehive.iotId !== undefined && beehive.iotId !== null) {
    iotId = beehive.iotId
  }

  const { t } = useTranslation(undefined, {
    keyPrefix: COMPONENT_TRANSLATE_PREFIX
  })

  const sensorTypes = [
    { type: t('sensorFields.lowerBeehiveTemp'), suffix: ' C', id: 1 },
    { type: t('sensorFields.outerTemp'), suffix: ' C', id: 2 },
    { type: t('sensorFields.humidity'), suffix: ' %', id: 3 },
    { type: t('sensorFields.pressure'), suffix: ' hPa', id: 4 },
    { type: t('sensorFields.upperBeehiveTemp'), suffix: ' C', id: 5 },
    { type: t('sensorFields.weight'), suffix: ' kg', id: 6 }
  ]

  const icons = [
    ThermostatIcon,
    ThermostatIcon,
    OpacityIcon,
    AirIcon,
    ThermostatIcon,
    ThermostatIcon
  ]

  const dataQuery = useGetSensorsLatestValQuery(iotId)

  const data = useMemo(() => dataQuery, [dataQuery, iotId])

  const sortedList = data.data?.data.sort((a, b) => a.sensor_id - b.sensor_id)

  return (
    <>
      {sensorTypes.map((sensorType, index) => {
        const Icon = icons[index]
        return sortedList?.find((val) => val.sensor_id === sensorType.id) ? (
          <Grid
            item
            margin={1}
            key={index}
            sx={{
              p: 0,
              borderRadius: '10px',
              border: 'none',
              borderShadow: 'none',
              boxShadow: 'none',
              backgroundColor: '#F6F7F9',
              m: 1
            }}
          >
            {data.isSuccess && sortedList ? (
              <Box
                sx={{
                  minHeight: 58,
                  p: 1,
                  m: 1
                }}
                display="flex"
                alignItems="center"
              >
                <Grid
                  container
                  wrap="nowrap"
                  direction={'row'}
                  sx={{ justifyContent: 'left', alignItems: 'center' }}
                >
                  <>
                    <Icon
                      style={{
                        height: 40,
                        width: 40
                      }}
                    ></Icon>
                    <p>{sensorTypes[index].type}</p>
                  </>
                </Grid>

                <Grid
                  container
                  direction={'row'}
                  sx={{ justifyContent: 'right', alignItems: 'center' }}
                >
                  <p>
                    {(() => {
                      const value = parseFloat(sortedList[index].value)
                      switch (sensorType.id) {
                        case 1: // Temp in bottom
                          return value.toFixed(1) // One decimal place
                        case 2: // Temp out
                          return value.toFixed(1)
                        case 5: // Temp in top
                          return value.toFixed(1)
                        case 6: // weight
                          return value.toFixed(1)
                        case 3: // Humidity
                          return Math.round(value) // Rounded to nearest integer
                        case 4: // Pressure
                          return (Math.round(value * 2) / 2).toFixed(1) // Rounded to nearest 0.5
                        default:
                          return value
                      }
                    })()}
                    {sensorTypes[index].suffix}
                  </p>
                </Grid>
              </Box>
            ) : (
              <CircularProgress sx={{ margin: 2 }} />
            )}
          </Grid>
        ) : null
      })}
    </>
  )
}

export default SensorFields
