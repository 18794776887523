const editOrganization = {
  section: {
    unauthorized: {
      body: 'Du måste vara administratör för organisationen för att kunna redigera dess information.'
    }
  },
  actions: {
    loadOrganization: {
      error: 'Ett fel inträffade när organisationen skulle hämtas'
    },
    loadCurrMember: {
      error: 'Ett fel inträffade när medlemsskapet för organisationen skulle hämtas'
    },
    updateOrganization: {
      error: 'Ett fel inträffade när ändringarna skulle sparas',
      success: 'Ändringarna har sparats.'
    }
  }
}

export default editOrganization
