import { useCallback, useMemo, useState } from 'react'
import { Stack, Typography } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import EmailSubmit from '../../components/Auth/PasswordReset/EmailSubmit'
import PasswordSubmit from '../../components/Auth/PasswordReset/PasswordSubmit'
import {
  FormStepConfiguration,
  FormStepsContainer
} from '../../components/common/Form/FormSteps'
import TitleImage from '../../components/common/TitleImage'
import {
  useErrorStateSnackEffect,
  useSuccessStateSnackEffect
} from '../../utils/hooks/stateSnackEffect'
import { AppRoutePaths } from '../../utils/routes'
import PageBodyContainer from '../../components/common/PageBodyContainer'
import { promiseForForm } from '../../utils/form/helpers'
import {
  useInitiateResetPasswordMutation,
  useVerifyResetPasswordMutation
} from '../../utils/api/hooks/auth'
import useIsMounted from '../../utils/hooks/isMounted'
import { ApiError } from 'openapi-typescript-fetch'
import { StatusCodes } from 'http-status-codes'
import { useSnackbar } from 'notistack'

const COMPONENT_TRANSLATE_PREFIX = 'pages.auth.passwordReset'

const EMAIL_SUBMIT_NAME = 'emailSubmit'
const PASSWORD_SUBMIT_NAME = 'passwordSubmit'

type PasswordResetForm = {
  [EMAIL_SUBMIT_NAME]: {
    email: string
  }
  [PASSWORD_SUBMIT_NAME]: {
    password: string
    code: string[]
  }
}

type PasswordResetHeaderProps = {
  name: string
}
const PasswordResetHeader = (props: PasswordResetHeaderProps) => {
  const { name } = props
  const { t } = useTranslation(undefined, {
    keyPrefix: `${COMPONENT_TRANSLATE_PREFIX}.stepTitle.${name}`
  })

  return (
    <PageBodyContainer>
      <Typography variant="h2">{t('title')}</Typography>
      <Typography>{t('description')}</Typography>
    </PageBodyContainer>
  )
}

const PasswordReset = () => {
  const navigate = useNavigate()
  const isMounted = useIsMounted()

  const { enqueueSnackbar } = useSnackbar()

  const { t } = useTranslation(undefined, {
    keyPrefix: COMPONENT_TRANSLATE_PREFIX
  })
  const { t: tGlobal } = useTranslation()

  const [activeStep, setActiveStep] = useState(0)

  const methods = useForm({
    mode: 'onSubmit'
  })

  const initPasswordResetMutation = useInitiateResetPasswordMutation()

  useSuccessStateSnackEffect(
    initPasswordResetMutation.isSuccess,
    t('actions.resetInitiate.success')
  )

  const initPasswordResetMutationErrorMessage = useMemo(() => {
    if (initPasswordResetMutation.error instanceof ApiError) {
      switch (initPasswordResetMutation.error.status) {
        case StatusCodes.TOO_MANY_REQUESTS:
          return t('actions.resetInitiate.error.tooManyRequests')
        case StatusCodes.BAD_REQUEST:
          return t('actions.resetInitiate.error.badRequest')
      }
    }
  }, [initPasswordResetMutation.error, t, tGlobal])

  useErrorStateSnackEffect(
    initPasswordResetMutation.isError,
    initPasswordResetMutationErrorMessage
  )

  const onVerified = useCallback(() => {
    if (isMounted()) {
      enqueueSnackbar(t('actions.resetSubmit.success'), { variant: 'success' })
      navigate(AppRoutePaths.LOGIN_PATH)
    }
  }, [isMounted, navigate, enqueueSnackbar, t])

  const verifyPasswordResetMutation = useVerifyResetPasswordMutation(onVerified)

  const verifyPasswordResetMutationErrorMessage = useMemo(() => {
    if (initPasswordResetMutation.error instanceof ApiError) {
      switch (initPasswordResetMutation.error.status) {
        case StatusCodes.UNAUTHORIZED:
          return t('actions.resetInitiate.error.unauthorized')
        case StatusCodes.BAD_REQUEST:
          return t('actions.resetInitiate.error.badRequest')
      }
    }
  }, [initPasswordResetMutation.error, t, tGlobal])

  useErrorStateSnackEffect(
    verifyPasswordResetMutation.isError,
    verifyPasswordResetMutationErrorMessage
  )

  const onSubmit = useCallback(
    (form: PasswordResetForm) => {
      const email = form[EMAIL_SUBMIT_NAME].email
      const { password, code } = form[PASSWORD_SUBMIT_NAME]

      const transformedCode = code.join('')
      return promiseForForm(
        verifyPasswordResetMutation.mutateAsync({
          email,
          verificationCode: transformedCode,
          newPassword: password
        })
      )
    },
    [verifyPasswordResetMutation.mutateAsync]
  )

  const formSteps = useMemo<FormStepConfiguration<PasswordResetForm>[]>(
    () => [
      {
        name: EMAIL_SUBMIT_NAME,
        submit: (form: PasswordResetForm) =>
          promiseForForm(
            initPasswordResetMutation.mutateAsync({
              email: form[EMAIL_SUBMIT_NAME].email
            })
          ),
        renderForm: (props) => <EmailSubmit {...props} />
      },
      {
        name: PASSWORD_SUBMIT_NAME,
        renderForm: (props) => <PasswordSubmit {...props} />
      }
    ],
    [initPasswordResetMutation.mutateAsync]
  )

  return (
    <FormProvider {...methods}>
      <Stack className="pageRoot">
        <TitleImage
          title={t('title')}
          src="/images/generic-background.png"
          stepsAmount={formSteps.length}
          activeStep={activeStep}
        />
        <Stack className="pageContent">
          <FormStepsContainer
            steps={formSteps}
            renderTitle={(props) => <PasswordResetHeader {...props} />}
            onSubmit={onSubmit}
            activeStepIndex={activeStep}
            onStepChange={setActiveStep}
            onCancel={() => navigate(AppRoutePaths.LOGIN_PATH)}
          />
        </Stack>
      </Stack>
    </FormProvider>
  )
}

export default PasswordReset
