const organizationEditForm = {
  title: 'Edit Organization',
  error: {
    organizationNumber:
      'The organization number should be in the format XXXXXXXXXX.',
    zipCode: 'The zip code should be in the format XXXXX or XXX XX.'
  },

  fields: {
    description: {
      label: 'Description'
    },

    organizationNumber: {
      label: 'Organization number'
    }
  }
}

export default organizationEditForm
