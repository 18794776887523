const ListNotes = {
  noteTypes: {
    other: 'Other Notes',
    inspection: 'Inspection Notes'
  },
  title: 'All Notes',
  header: {
    chooseCategory: 'Choose Category',
    filterNotes: 'Filter Notes',
    noteTypeNotFound: 'Note type not found'
  },
  description: {
    chooseCategory: 'Choose which category of notes to view',
    noteTypeNotFound: 'Notes could not be found for the specified note type.'
  },
  label: {
    fromDate: 'From',
    toDate: 'To',
    showNotes: 'Show Notes',
    update: 'Update'
  },
  filter: 'Create a filter',
  notes: 'Notes',
  type: 'Type',
  error: 'An error occurred while loading notes.',
  text: 'Text',
  freeText: 'Free text',
  olderNotes: 'Older notes',

  // Following are alternate translations used in NewNoteCategorySelect.js (should be renamed to
  // NoteCategorySelect.js). The translations are used to change buttons to make sense for selecting
  // categories when viewing old notes instead of creating new notes which is the original intent
  // of NewNoteCategorySelect.js

  fields: {
    category: {
      options: {
        BEEHIVE: {
          title: 'Beehive',
          description: 'Notes for all beehives you have access to'
        },
        APIARY: {
          title: 'Apiary',
          description: 'Notes for all apiaries you have access to'
        },
        ORGANIZATION: {
          title: 'Organization',
          description: 'Notes for all organizations you have access to'
        }
      },
      errors: {
        required: 'Select a category'
      }
    }
  }
}

export default ListNotes
