export const promiseForForm = <Data = any>(promise: Promise<Data>) =>
  promise
    .then(() => true)
    .catch((err) => {
      console.error(err)
      return false
    })
    .finally(() => console.log('Finished'))

// TODO: Fix typing of this function
// Move to a more type safe solution
export const getFormValue = (form: object, key: string) => {
  const val = key
    .split('.')
    .reduce((el: any, seg: string) => (el ? el[seg] : undefined), form)

  return val
}

export const createCheckBoxValueFromModelField = <T>(value: T) => ({
  _checkbox: value !== true && value !== null,
  value: value
})
