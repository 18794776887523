const beehiveReminderDetails = {
  section: {
    general: 'General'
  },
  fields: {
    title: {
      label: 'Title'
    },
    bringExtraFrames: {
      _checkbox: {
        label: 'Bring extra frames'
      },
      value: {
        label: 'Number'
      }
    },
    bringHoneyChambers: {
      _checkbox: {
        label: 'Bring honey supers'
      },
      value: {
        label: 'Number'
      }
    },
    bringChildFrames: {
      _checkbox: {
        label: 'Bring brood frames'
      },
      value: {
        label: 'Number'
      }
    },
    bringFodder: {
      _checkbox: {
        label: 'Bring feed'
      },
      value: {
        label: 'Kg'
      }
    },
    bringFodderCup: {
      label: 'Bring feed cup'
    },
    bringEquipment: {
      _checkbox: {
        label: 'Bring equipment'
      }
    },
    getNewQueen: {
      label: 'Get a new queen'
    },
    cutDroneFrames: {
      label: 'Cut drone frames'
    },
    seachForChildren: {
      label: 'Search for brood/eggs'
    },
    checkQueen: {
      label: 'Check the queen'
    },
    other: {
      _checkbox: {
        label: 'Other'
      }
    }
  }
}

export default beehiveReminderDetails
