import {
  Backdrop,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Fade,
  Grid,
  Modal,
  Typography
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { OrganizationMemberRole } from '../../../../models'
import {
  Organization,
  OrganizationMemberOverview
} from '../../../../models/organization'
import {
  useGetUserQuery,
  useUpdateUserAppReviewMutation
} from '../../../../utils/api/hooks/user'
import { organizationMemberHelpers } from '../../../../utils/helpers'
import { AppRoutePathSegment, AppRoutePaths } from '../../../../utils/routes'
import SettingsIcon from '../../../common/Icon/Settings'
import InfoGridText from '../../../common/InfoGridText'
import BottomContentContainer from '../../../common/Layout/BottomContentContainer'
import PageBodyContainer from '../../../common/PageBodyContainer'
import { useCallback, useMemo, useState } from 'react'
import { promiseForForm } from '../../../../utils/form/helpers'
import ReviewModal from '../../../common/ReviewModal'
import LoadingPage from '../../../common/LoadingPage'
import { useRemoveOrganizationMutation } from '../../../../utils/api/hooks/organization'
import { useSnackbar } from 'notistack'

const modalRootStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%'
}

const keys: (keyof Organization)[] = [
  'description',
  'organizationNumber',
  'street',
  'zipCode',
  'town',
  'country'
]

const COMPONENT_TRANSLATE_PREFIX =
  'components.organization.displayOrganization.tabs.infoTab'

type InfoTabProps = {
  organization?: Organization
  authorizedMember?: OrganizationMemberOverview
}

const InfoTab = (props: InfoTabProps) => {
  const removeOrgMutation = useRemoveOrganizationMutation(
    () => {
      enqueueSnackbar(t('info.deleteSuccess'), { variant: 'success' })
      navigate(AppRoutePaths.LIST_ORGANIZATIONS_PATH)
    },
    () => {
      enqueueSnackbar(t('info.deleteFailed'), { variant: 'error' })
    }
  )
  const [open, setOpen] = useState(false)
  const { organization, authorizedMember } = props
  const { t: tGeneral } = useTranslation()
  const { t } = useTranslation(undefined, {
    keyPrefix: COMPONENT_TRANSLATE_PREFIX
  })
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const requestingMemberQuery = useGetUserQuery()
  const requestingMember = requestingMemberQuery.data?.data

  const isAdmin = organizationMemberHelpers.isAuthorizedMemberWithRole(
    authorizedMember,
    OrganizationMemberRole.ADMIN
  )

  const updateUserAppReview = useUpdateUserAppReviewMutation()

  const onCloseReviewModal = useCallback(
    (rating: number, text: string) => {
      promiseForForm(
        updateUserAppReview.mutateAsync({
          rating,
          text
        })
      )
    },
    [updateUserAppReview.mutateAsync]
  )

  const appReviewSurvey = useMemo(
    () => (
      <ReviewModal
        onClose={onCloseReviewModal}
        userData={requestingMember}
      ></ReviewModal>
    ),
    [requestingMemberQuery.data?.data]
  )

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleRemoveOrganization = () => {
    handleClose()
    if (!organization) return
    removeOrgMutation.mutate({ orgId: organization.id })
  }

  return (
    <Container>
      <PageBodyContainer>
        <Typography variant="h2">
          {organization ? organization.name : tGeneral('general.pending')}
        </Typography>
      </PageBodyContainer>

      <Grid container spacing={2}>
        {keys &&
          keys.map((key) => (
            <InfoGridText
              key={key}
              header={t(`organization.${key}`)}
              body={organization?.[key] || tGeneral('general.pending')}
            />
          ))}
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <Box sx={modalRootStyle}>
            <Container maxWidth="xs">
              <Card sx={{ padding: 2 }}>
                <CardHeader title={t('info.removeOrgTitle')} />
                <CardContent sx={{ pt: 0 }}>
                  {t('info.removeOrgInfo')}
                </CardContent>
                <CardActions sx={{ justifyContent: 'space-evenly' }}>
                  <Button
                    onClick={handleClose}
                    variant="outlined"
                    color="secondary"
                  >
                    {t('button.cancel')}
                  </Button>
                  <Button
                    onClick={handleRemoveOrganization}
                    sx={{
                      background: '#C23F38',
                      outline: '#000000',
                      ':hover': { background: '#C23F38' },

                      color: '#FFFFFF'
                    }}
                    variant="contained"
                  >
                    {t('button.removeOrg')}
                  </Button>
                </CardActions>
              </Card>
            </Container>
          </Box>
        </Fade>
      </Modal>
      {isAdmin && requestingMemberQuery.isLoading ? (
        <LoadingPage />
      ) : (
        <>
          {appReviewSurvey}
          <BottomContentContainer>
            <Button
              component={Link}
              to={AppRoutePathSegment.EDIT}
              variant="contained"
              startIcon={<SettingsIcon />}
            >
              {tGeneral('general.settings')}
            </Button>
            <Button
              sx={{ color: '#000000', textDecoration: 'underline' }}
              onClick={handleOpen}
            >
              {t('button.removeOrg')}
            </Button>
          </BottomContentContainer>
        </>
      )}
    </Container>
  )
}

export default InfoTab
