const register = {
  title: 'Skapa konto',
  infoTitle: 'Uppgifter',
  descriptionCredentials: 'Ange dina uppgifter och välj ett lösenord.',
  descriptionMail:
    'Ett mail kommer skickas till din e-postadress med en kod för verifiering som används i nästa steg',

  registerSuccess: 'Användaren har skapats',

  actions: {
    register: {
      errors: {
        conflict: 'Användaren finns redan',
        weakPassword:
          'Lösendordet uppfyller inte lösenordspolicyn, inkludera minst en stor bokstav och ett specialtecken'
      }
    }
  },
  fields: {
    firstname: {
      label: 'Förnamn',
      errors: {
        required: 'Förnamn krävs'
      }
    },
    surname: {
      label: 'Efternamn',
      errors: {
        required: 'Förnamn krävs'
      }
    }
  }
}

export default register
