const general = {
  save: 'Spara',
  back: 'Tillbaka',
  finish: 'Slutför',
  next: 'Nästa',
  close: 'Stäng',
  email: 'Email',
  phone: 'Telefon',
  address: 'Adress',
  street: 'Adress',
  town: 'Stad',
  zipCode: 'Postnummer',
  country: 'Land',
  password: 'Lösenord',
  skip: 'Hoppa över',
  ok: 'Ok',
  verify: 'Verifiera',
  confirm: 'Bekräfta',
  createNew: 'Skapa ny',
  add: 'Lägg till',
  loading: 'Laddar...',
  noOptions: 'Alternativ saknas',
  logOutUser: 'Logga ut',
  frameTypesInBeehive: 'Ramtyper',
  undefined: 'Ej angivet',
  edit: 'Redigera',
  yes: 'Ja',
  no: 'Nej',
  update: 'Spara',
  cancel: 'Avbryt',
  name: 'Namn',
  create: 'Skapa',
  settings: 'Inställningar',
  search: 'Sök',

  pending: '...',
  missingInfo: 'Information saknas',

  error: {
    general: 'Ett fel inträffade'
  },

  memberRole: {
    ADMIN: 'Administratör',
    MAINTAINER: 'Biskötare',
    READER: 'Prenumerant'
  },

  trueFalse: {
    true: 'Ja',
    false: 'Nej'
  },

  beeType: {
    YELLOW_ITALIAN: 'Gula italienska bin',
    GRAY_KRANIUM: 'Gråa Kranierbin',
    DARK_NORDIC: 'Mörka Nordiska',
    BUCKFAST: 'Buckfastbin',
    ELGON: 'Elgonbin',
    UNKKNWON: 'Okänt'
  },

  beehiveType: {
    HIKE: 'Vandringskupa',
    STATIONARY: 'Stationär kupa',
    LAY_OFF: 'Avläggarkupa / Avläggarlådor',
    TRAY: 'Trågkupa',
    STRAW: 'Halmkupa',
    LOG: 'Stockkupa',
    HOUSE: 'Bipaviljong / Bihus',
    TREE: 'Trädkupa',
    MATING: 'Parningskupa / Drottningodlingskupa',
    OBSERVATION: 'Observationskupa / Visningskupa / Demonstrationskupa',
    TOREBODA: 'Töreboda-kupan',
    NACKA: 'Nacka-kupan',
    OSTERVALA: 'Östervåla-kupan',
    HJO: 'HJO-Kupan',
    HULT: 'Ljusabergskupan / Hultkupan',
    MULTI: 'Multikupan',
    REA_DAN: 'REA Dan',
    HUDDINGE: 'Huddinge-kupan',
    SMALAND: 'Smålandskupan',
    PARADISE: 'Paradise-kupan',
    SKALDERHUS: 'Skälderhus-kupan',
    OTHER: 'Annan'
  },

  frameType: {
    LOW_NORMAL: 'Lågnormal',
    SVEA: 'Svea',
    SVEA_HALF: 'Halv Svea',
    LS: 'LS',
    LS_HALF: 'Halv LS (HLS)',
    NORWEGIAN: 'Norsk',
    NORWEGIAN_HALF: 'Halv Norsk',
    WIESLANDER: 'Wieslander',
    DADANT: 'Dadant',
    LANGSTROTH: 'Langstroth',
    LANGSTROTH_3_4: '3/4 Langstroth (Farrar)'
  },

  beehiveSocietyStrength: {
    STRONG: 'Stark',
    OK: 'Ok',
    WEAK: 'Svag'
  },

  beehiveSocietyBehavior: {
    CALM: 'Lugna',
    OK: 'Ok',
    ANGRY: 'Arga'
  },

  beeQueenColor: {
    WHITE: 'Vit',
    YELLOW: 'Gul',
    RED: 'Röd',
    GREEN: 'Grön',
    BLUE: 'Blå'
  },

  beehiveInspectionQueenControl: {
    SEEN_EXISTING_QUEEN: 'Sett nuvarande drottning',
    SEEN_NEW_QUEEN: 'Sett ny drottning, egetbyte',
    SEEMS_QUEENLESS: 'Samhället verkar vara drottningslöst'
  },

  beehiveSickness: {
    NOSEMA: 'Nosema',
    VARROAMITE: 'Varroakvalster',
    AMERICANFRYROT: 'Amerikansk yngelröta',
    SOOT: 'Utsot',
    KUPSALWETHER: 'Kupsalbagge',
    TRAKEWETHER: 'Trakékvalster',
    TROPILAELAPWETHER: 'Tropilaelapskvalster'
  },

  beehiveWildlifeAttack: {
    BADGER: 'Grävling',
    MARTEN: 'Mård',
    BEAR: 'Björn',
    BIRD: 'Fågel',
    ANT: 'Myra'
  },

  noteCategory: {
    ORGANIZATION: 'Organisationer',
    APIARY: 'Bigårdar',
    BEEHIVE: 'Bikupor'
  }
}

export default general
