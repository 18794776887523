const data = {
  sv: {
    /* SWEDISH */
    translation: {
      time: {
        intlDateTime: '{{val, datetime}}'
      },
      overlay: {
        beelab: 'Beelab',
        sideMenuLink: {
          organisations: 'Organisationer',
          apiaries: 'Bigårdar',
          beehives: 'Bikupor',
          notes: 'Anteckningar',
          alarms: 'Alarm',
          profile: 'Profil',
          signIn: 'Inloggning',
          register: 'Registrering'
        }
      },
      success: {
        noteMessageDeleted: 'Anteckning har raderats!',
        userUpdated: 'Användaren har uppdaterats!',
        noteSaved: 'Anteckning har sparats!',
        joinOrginzation: 'Förfrågan om att gå med har skickats',
        leaveOrganization: 'Du har lämnat organisationen',
        updateApiary: 'Bigård uppdaterad!',
        deletedApiary: 'Bigård har raderats!',
        updateBeehive: 'Bikupa Uppdaterad!',
        deletedBeehive: 'Bikupan har raderats!',
        beehiveCreated: 'Bikupa Skapad!',
        beehiveUpdated: 'Bikupa updaterad!',
        userRegistration: 'Tryck på mejlet för att slutföra registreringen',
        apiaryCreated: 'Bigården har skapats',
        memberApproved: 'Förfrågan har godkänts',
        memberDeclined: 'Förfrågan har avböjts',
        accountVerification: 'Ditt konto har verifierats!'
      },
      tabs: {
        overview: 'Överblick',
        journal: 'Loggbok',
        settings: 'Inställningar',
        members: 'Medlemmar',
        requests: 'Förfrågningar',
        base: 'Grund',
        checkbox: 'Checkbox',
        other: 'Annat'
      },
      apiary: {
        createApiary: 'Skapa bigård',
        updateApiary: 'Uppdatera bigård'
      },
      general: {
        name: 'Namn',
        description: 'Beskrivning',
        organizationNumber: 'Organisationsnummer',
        streetAdress: 'Gatuadress',
        zipCode: 'Postnummer',
        city: 'Stad',
        back: 'Tillbaka',
        leave: 'Lämna',
        cancelRequest: 'Avbryt förfrågan',
        apiary: 'Bigård',
        coordinates: 'Koordinater',
        organization: 'Organisation',
        beehives: 'Bikupor',
        beehive: 'Bikupa',
        remove: 'Radera',
        removeQuestion: 'Är du säker på att du vill radera?',
        yes: 'Ja',
        no: 'Nej',
        username: 'Användarnamn',
        bio: 'Bio',
        email: 'E-post',
        website: 'Webbsida',
        title: 'Titel',
        selectDate: 'Välj datum',
        selectLanguage: 'Välj språk',
        characters: 'tecken',
        create: 'Skapa',
        join: 'Gå med',
        noApiaries: 'Inga bigårdar...',
        noOrganizations: 'Inga organisationer...',
        noBeehives: 'Inga bikupor...',
        kindOfBeehive: 'Typ av bikupa',
        frameTypesInBeehive: 'Ramtyper i kupan',
        kindOfBee: 'Typ av bi',
        queenColor: 'Färg på drottningen',
        update: 'Uppdatera',
        selectBeehive: 'Välj bikupa',
        edit: 'Redigera',
        noConnection: 'Internet saknas: Vissa funktioner kräver internet',
        save: 'Spara',
        apiaries: 'Bigårdar',
        notSelected: 'Ej vald',
        firstName: 'Förnamn',
        sureName: 'Efternamn',
        cancel: 'Avbryt',
        notes: 'Anteckningar',
        alarm: 'Alarm',
        notifications: 'Notifikationer',
        profile: 'Profil',
        loggingOut: 'Loggar ut...',
        loggingIn: 'Loggar in...',
        login: 'Inloggning',
        loginSubmit: 'Logga in',
        loginRegister: 'Registrera Konto',
        loginFromRegister: 'Tillbaka till inloggning',
        registration: 'Registrering',
        logOut: 'Logga ut',
        swedish: 'Svenska',
        english: 'Engelska',
        created: 'Skapad',
        password: 'Lösenord',
        registerAccount: 'Registrera konto',
        white: 'Vit',
        yellow: 'Gul',
        red: 'Röd',
        green: 'Grön',
        blue: 'Blå',
        language: 'Språk',
        verifyEmail: 'Verifiera email',
        verificationCode: 'Bekräftelsekod',
        submit: 'Skicka'
      },
      error: {
        general: 'Oops, något gick fel!',
        loadUser: 'Ett fel inträffade när användaren skulle hämtas!',
        nameRequired: 'Namn krävs',
        nameTooFewCharacters: 'Namn har för få tecken',
        nameTooManyCharacters: 'Namn har för många tecken',
        descriptionRequired: 'Beskrivning krävs',
        descriptionTooFewCharacters: 'Beskrivning har för få tecken',
        descriptionTooManyCharacters: 'Beskrivning har för många tecken',
        organizationNumberRequired: 'Organisationsnummer krävs',
        organizationNumberLength: 'Organisationsnummer måste ha ',
        streetAdressRequired: 'Gatuadress krävs',
        streetAdressTooFewCharacters: 'Gatuadress har för få tecken',
        streetAdressTooManyCharacters: 'Gatuadress har för många tecken',
        zipCodeRequired: 'Postnummer krävs',
        zipCodeLength: 'Postnummer måste ha ',
        cityRequired: 'Stad krävs',
        cityTooFewCharacters: 'Stad har för få tecken',
        cityTooManyCharacters: 'Stad har för många tecken',
        retriveOrginization: 'Kunde inte hämta organisationen',
        apiaryRequired: 'Bigård krävs',
        kindOfBeehiveRequired: 'Typ av bikupa krävs',
        kindOfBeeRequired: 'Typ av bi krävs',
        queenColorRequired: 'Färg på drottningen krävs',
        coordinatesRequired: 'Koordinater krävs',
        coordinatesTooFewCharacters: 'Koordinater har för få tecken',
        coordinatesTooManyCharacters: 'Koordinater har för många tecken',
        organizationRequired: 'Organisation krävs',
        beehiveAndTitleRequired: 'Bikupa och Titel krävs',
        beehiveRequired: 'Bikupa krävs',
        titleRequired: 'Titel krävs',
        joinOrginzation: 'Något gick fel när förfrågan skulle skickas',
        leaveOrganization: 'Något gick fel när organisationen skulle lämnas',
        loggingOut: 'Ett fel inträffade vid utloggning:',
        loadingApiary: 'Oops, Något gick fel med att ladda in bigården!',
        editApiary: 'Oops, Något gick fel med att redigera bigården!',
        loadingBeehives: 'Oops, något gick fel med att hitta dina bikupor!',
        loadingBeehivesByApiary: 'Oops, något gick fel med att hitta bigårdens bikupor!',
        loadingNote: 'Oops, Något gick fel med att ladda in anteckningen!',
        loadingBeehiveNotes: 'Oops, Något gick fel med att ladda in anteckningarna för bikupan!',
        login: 'Ett fel inträffade vid inloggning',
        emailFormat: 'E-post har felaktigt format',
        passwordFormat: 'Lösenord har felaktigt format',
        emailRequired: 'E-post krävs',
        emailLengthMin: 'E-post måste ha minst',
        emailLengthMax: 'E-post får max ha',
        passwordRequired: 'Lösenord krävs',
        passwordLengthMin: 'Lösenord måste ha minst',
        passwordLengthMax: 'Lösenord får max ha',
        loadUserOrganizations: 'Något gick fel när användarens organisationer skulle hämtas',
        loadingOrganizations: 'Något gick fel när organisationer skulle hämtas',
        updateApiary: 'Ett fel inträffade när bigården skulle uppdateras',
        deletedApiary: 'Något gick fel när bigården skulle raderas',
        createApiary: 'Ett fel inträffade när bigården skulle skapas',
        updateBeehive: 'Ett fel inträffade när bikupan skulle updateras',
        deleteApiary: 'Ett fel inträffade när bikupan skulle raderas',
        saveBeehive: 'Ett fel inträffade när bikupan skulle sparas',
        saveNote: 'Något gick fel när anteckningen skulle sparas',
        deleteNote: 'Ett fel inträffade när anteckningen skulle raderas',
        retrieveOrganizationMembers: 'Ett fel inträffade när medlemmarna av organisationen skulle hämtas',
        approveOrganizationMember: 'Ett fel inträffade när medlemmen skulle godkännas',
        declineOrganizationMember: 'Ett fel inträffade när medlemmen skulle avböjas'
      },
      organization: {
        myOrganizations: 'Mina Organisationer',
        allOrganizations: 'Alla Organisationer',
        createOrganization: 'Skapa Organisation',
        waitingForAnswer: 'Väntar på svar',
        creatingOrganization: 'Organisationen skapas...',
        creatingOrganizationTitle: 'Skapa organization',
        savedOrganization: 'Organisationen har sparats',
        createError: 'Ett fel inträffade när organisationen skulle skapas',
        addOrganization: 'Lägg till organisation'
      },
      beehive: {
        typeOfBeehive: 'Typ av bikupa',
        frameTypesInBeehive: 'Ramtyper i kupan',
        kindOfBee: 'Typ av bi',
        queenColor: 'Färg på drottningen',
        lastSwarmed: 'Senast svärmad',
        wingCut: 'Vingklippt drottning',
        createBeehive: 'Skapa bikupa',
        updateBeehive: 'Uppdatera bikupa'
      },
      note: {
        amountOfEggFrames: 'Antal yngelramar',
        amountOfFramesInBeehive: 'Antal ramar i kupan',
        fodder: 'Foder - kg',
        honey: 'Honung - kg',
        seenQueen: 'Sett Drottning',
        manuallyShiftingQueen: 'Manuellt eget byte av drottning',
        winterFodder: 'Vinterfodrat',
        slungat: 'Slungat',
        newTreasureBox: 'Ny skattlåda tillsatt',
        readyForWinter: 'Färdigställer inför vintern',
        foundEgg: 'Finns lagt yngel',
        pullInPollen: 'Dra in pollen',
        varroaTreatment: 'Varroa-behandlat',
        cutOutDroneFrame: 'Drönarram utskuren',
        sickness: 'Sjukdom',
        behavior: 'Beteende',
        comments: 'Noteringar',
        latestNote: 'Senaste anteckning:',
        calm: 'Lugna',
        between: 'Mellan',
        angry: 'Arga',
        numberOfEggs: 'Antal ägg'
      },
      user: {
        informationSaved: 'Användarinformation sparad!',
        noWebsite: 'Ingen webbsida'
      }
    }
  }
  /* ENGLISH */
  /*
  en: {
    translation: {
      time: {
        intlDateTime: '{{val, datetime}}'
      },
      overlay: {
        sideMenuLink: {
          organisations: 'Organizations',
          apiaries: 'Apiaries',
          beehives: 'Beehives',
          notes: 'Notes',
          alarms: 'Alarms',
          profile: 'Profile',
          signIn: 'Sign in',
          register: 'Register'
        }
      },
      success: {
        noteMessageDeleted: 'Note got deleted!',
        userUpdated: 'User has been updated!',
        noteSaved: 'Note saved!',
        joinOrginzation: 'The request to join has been sent',
        leaveOrganization: 'You have left the organization',
        updateApiary: 'Apiary updated!',
        deletedApiary: 'Apiary has been deleted!',
        updateBeehive: 'Beehive Updated!',
        deletedBeehive: 'Beehive has been deleted!',
        beehiveCreated: 'Beehive Created!',
        userRegistration: 'Open the email to complete the registration',
        apiaryCreated: 'The apiary has been created',
        memberApproved: 'Förfrågan har godkänts',
        memberDeclined: 'Förfrågan har avböjts',
        accountVerification: 'Your account has been verified!'
      },
      tabs: {
        overview: 'Overview',
        journal: 'Journal',
        settings: 'Settings',
        members: 'Members',
        requests: 'Requests',
        base: 'Base',
        checkbox: 'Checkbox',
        other: 'Other'
      },
      apiary: {
        createApiary: 'Create apiary',
        updateApiary: 'Update apiary'
      },
      general: {
        name: 'Name',
        description: 'Description',
        organizationNumber: 'Organization Number',
        streetAdress: 'Street adress',
        zipCode: 'Zip code',
        city: 'City',
        back: 'Back',
        leave: 'Leave',
        cancelRequest: 'Cancel request',
        apiary: 'Apiary',
        coordinates: 'Coordinates',
        organization: 'Organization',
        beehives: 'Beehives',
        beehive: 'Beehive',
        remove: 'Remove',
        removeQuestion: 'Are you sure you want to delete?',
        yes: 'Yes',
        no: 'No',
        username: 'Username',
        bio: 'Bio',
        email: 'Email',
        website: 'Website',
        selectLanguage: 'Select language',
        title: 'Title',
        selectDate: 'Select date',
        characters: 'characters',
        create: 'Create',
        join: 'Join',
        noApiaries: 'No apiaries...',
        noOrganizations: 'No organizations...',
        noBeehives: 'No beehives...',
        kindOfBeehive: 'Kind of beehive',
        frameTypesInBeehive: 'Frame types in beehive',
        kindOfBee: 'Kind of bee',
        queenColor: 'Color of the queen',
        update: 'Update',
        selectBeehive: 'Select beehive',
        edit: 'Edit',
        noConnection: 'Internet missing: Some features require internet',
        save: 'Save',
        apiaries: 'Apiaries',
        notSelected: 'Not selected',
        firstName: 'First name',
        sureName: 'Surname',
        cancel: 'Cancel',
        notes: 'Notes',
        alarm: 'Alarm',
        notifications: 'Notifications',
        profile: 'Profile',
        loggingOut: 'Logging out...',
        loggingIn: 'Logging in...',
        login: 'Login',
        loginSubmit: 'Sign in',
        loginRegister: 'Register account',
        loginFromRegister: 'Back to login',
        registration: 'Registration',
        logOut: 'Log out',
        swedish: 'Swedish',
        english: 'English',
        created: 'Created',
        password: 'Password',
        registerAccount: 'Register account',
        white: 'White',
        yellow: 'Yellow',
        red: 'Red',
        green: 'Green',
        blue: 'Blue',
        language: 'Language',
        verifyEmail: 'Verify email',
        verificationCode: 'Verification code',
        submit: 'Submit'
      },
      error: {
        general: 'Oops, Something went wrong!',
        loadUser: 'An error occurred when loading the user!',
        nameRequired: 'Name is required',
        nameTooFewCharacters: 'name has too few characters',
        nameTooManyCharacters: 'name has too many characters',
        descriptionRequired: 'Description is required',
        descriptionTooFewCharacters: 'Description has too few characters',
        descriptionTooManyCharacters: 'Description has too many characters',
        organizationNumberRequired: 'organization number is required',
        organizationNumberLength: 'Description requires ',
        streetAdressRequired: 'Street adress required',
        streetAdressTooFewCharacters: 'Street adress has too few characters',
        streetAdressTooManyCharacters: 'Street adress has too many characters',
        zipCodeRequired: 'Zip code required',
        zipCodeLength: 'Zip code requires ',
        cityRequired: 'City is required',
        cityTooFewCharacters: 'City has too few characters',
        cityTooManyCharacters: 'City has too many characters',
        retriveOrginization: 'Could not retrieve the organization',
        apiaryRequired: 'Apiary required',
        kindOfBeehiveRequired: 'Kind of beehive required',
        kindOfBeeRequired: 'Kind of bee required',
        queenColorRequired: 'Color of the queen required',
        coordinatesRequired: 'Coordinates is required',
        coordinatesTooFewCharacters: 'Coordinates has too few characters',
        coordinatesTooManyCharacters: 'Coordinates has too many characters',
        organizationRequired: 'Organization required',
        joinOrginzation: 'Something went wrong when sending the request',
        leaveOrganization: 'Something went wrong when leaving the organization',
        loggingOut: 'An error occurred while logging out:',
        loadingApiary: 'Oops, something went wrong loading the apiary!',
        editApiary: 'Oops, something went wrong editing the apiary!',
        loadingBeehives: 'Oops, something went wrong with finding your beehives!',
        loadingBeehivesByApiary: 'Oops, something went wrong with finding the apiaries beehives!',
        loadingNote: 'Oops, something went wrong while loading the note!',
        loadingBeehiveNotes: 'Oops, something went wrong while loading the beehive notes!',
        login: 'An error occurred while logging in',
        emailFormat: 'Email is formatted incorrectly',
        passwordFormat: 'Password is incorrectly formatted',
        emailRequired: 'Email is required',
        emailLengthMin: 'Email must have at least',
        emailLengthMax: 'E-mail may have a maximum of',
        passwordRequired: 'Password is required',
        passwordLengthMin: 'Password must have at least',
        passwordLengthMax: 'Password may have a maximum of',
        loadUserOrganizations: 'Something went wrong when retrieving the users organizations',
        loadingOrganizations: 'Something went wrong when retrieving organizations',
        updateApiary: 'An error occurred while updating the apiary',
        deletedApiary: 'Something went wrong while deleting the apiary',
        createApiary: 'An error occurred while creating the apiary',
        updateBeehive: 'An error occurred while updating the beehive',
        deleteApiary: 'An error occurred while deleting the beehive',
        saveBeehive: 'An error occurred while saving the beehive',
        saveNote: 'An error occurred while saving the note',
        deleteNote: 'An error occurred while deleting the note',
        retrieveOrganizationMembers: 'An error occured while retrieveing members of the organization',
        approveOrganizationMember: 'An error occurred while approving the member',
        declineOrganizationMember: 'An error occurred while declining the member'
      },
      organization: {
        myOrganizations: 'My Organizations',
        allOrganizations: 'All Organizations',
        createOrganization: 'Create Organization',
        waitingForAnswer: 'Waiting for answer',
        creatingOrganization: 'Creating organization...',
        creatingOrganizationTitle: 'Create organization',
        savedOrganization: 'The organization has been saved',
        createError: 'An error occurred when creating the organization',
        addOrganization: 'Add organisation'
      },
      beehive: {
        typeOfBeehive: 'Type of beehive',
        frameTypesInBeehive: 'Frame types in beehive',
        kindOfBee: 'Kind of bee',
        queenColor: 'Queen color',
        lastSwarmed: 'Last swarmed',
        wingCut: 'Wing cut queen',
        createBeehive: 'Create beehive',
        updateBeehive: 'Update beehive'
      },
      note: {
        amountOfEggFrames: 'Amount of egg frames',
        amountOfFramesInBeehive: 'Amount of frames in beehive',
        fodder: 'Fodder - kg',
        honey: 'Honey - kg',
        seenQueen: 'Seen queen',
        manuallyShiftingQueen: 'Manually shifting queen',
        winterFodder: 'Winter fodder',
        slungat: 'Slung',
        newTreasureBox: 'New treasure box',
        readyForWinter: 'Ready for winter',
        foundEgg: 'Found egg',
        pullInPollen: 'Pull in pollen',
        varroaTreatment: 'Varroa treated',
        cutOutDroneFrame: 'Cut out drone frame',
        sickness: 'Sickness',
        behavior: 'Behavior',
        comments: 'Comments',
        latestNote: 'Latest note:',
        calm: 'Calm',
        between: 'Between',
        angry: 'Angry',
        numberOfEggs: 'Number of eggs'
      },
      user: {
        informationSaved: 'Användarinformation sparad!',
        noWebsite: 'Ingen webbsida'
      }
    }
  } */
}

export default data
