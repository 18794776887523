const gettingStartedGuide = {
  title: 'Introduktion',
  getStarted: 'Kom igång',

  pwaExample: {
    heading: 'Spara på hemskärmen',
    content:
      'För enklare åtkomst och bättre upplevelse kan du spara en genväg till appen direkt på din hemskärm.'
  },
  organizations: {
    heading: 'Organisationer',
    content:
      'Organisationer samlar bigårdar och bikupor på en plats för enkelt underhåll och översikt. Skapa din egen eller gå med i en befintlig.'
  },
  digitalBeehives: {
    heading: 'Digitala bikupor',
    content:
      'Köp ett digitalt kit till din befintliga bikupa eller köp helt digitala bikupor för att kunna se viktig information om bikuporna direkt från startskärmen eller från bikupans information.'
  },
  notes: {
    heading: 'Anteckningar',
    content:
      'Skapa anteckningar för inspektioner,planering, påminnelser med mera för att enkelt hålla koll på alla bikupor och bigårdar.'
  }
}

export default gettingStartedGuide
