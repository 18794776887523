const apiaryLocationSelect = {
  yourLocation: 'Din plats',
  apiarySelect: {
    chooseApiary: 'Välj bigård',
    apiaryLabel: 'Bigård'
  },
  overlay: {
    buttons: {
      save: 'Spara',
      menu: 'Meny',
      confirm: 'Godkänn platser',
      cancel: 'Avbryt',
      chooseApiary: 'chooseApiary',
      moveApiaryToCurrCamLocation: 'Flytta hit markören',
      goToApiary: 'Gå till bigården',
      moveCameraToCurrLocation: 'Gå till nuvarande plats',
      showAllApiaries: 'Visa samtliga bigårdar',
      showOnlySelectedApiary: 'Visa endast vald bigård'
    }
  }
}

export default apiaryLocationSelect
