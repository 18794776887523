import { createContext } from 'react'
import * as apiClient from './apiClient'

export type ApiClient = typeof apiClient

export const ApiClientContext = createContext<ApiClient | undefined>(undefined)

type Props = {
  client: ApiClient
  children: JSX.Element | JSX.Element[]
}

const ApiClientProvider: React.FC<Props> = ({ client, ...props }) => {
  return <ApiClientContext.Provider value={client} {...props} />
}

export default ApiClientProvider
