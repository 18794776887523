const listNoteOverviews = {
  actions: {
    getNotes: {
      error: 'Ett fel inträffade när anteckningarna skulle hämtas'
    }
  },
  header: {
    noteTitle: 'Titel',
    noteDate: 'Datum'
  },
  data: {
    noteDate: '{{val, datetime}}'
  }
}

export default listNoteOverviews
