const userProfileEdit = {
  title: 'My Profile',

  firstTime: {
    infoTitle: 'Complete settings',
    description: 'Take a few minutes and complete all settings of your profile'
  },
  ordinary: {
    infoTitle: 'Edit details'
  },

  fields: {
    firstname: {
      label: 'First name',
      errors: {
        required: 'First name is required'
      }
    },
    surname: {
      label: 'Last name',
      errors: {
        required: 'Last name is required'
      }
    }
  },
  actions: {
    updateUser: {
      fulfilled: 'Profile has been updated'
    }
  }
}

export default userProfileEdit
