import { useState } from 'react'

export type PaginationQuery = {
  take: number
  page?: number
  skip?: number
}

export const usePagination = (
  initialQueryParameters: PaginationQuery = { take: 20 }
) => {
  const [queryParams, setQueryParameters] = useState<PaginationQuery>(
    initialQueryParameters
  )

  const setTake = (take: number) =>
    setQueryParameters((state) => ({ ...state, take }))
  const setPage = (page: number) =>
    setQueryParameters((state) => ({ ...state, page }))
  const setSkip = (skip: number) =>
    setQueryParameters((state) => ({ ...state, skip }))

  const queryKey = [queryParams.take, queryParams.page, queryParams.skip]

  return {
    queryParams,
    setTake,
    setPage,
    setSkip,
    setState: setQueryParameters,
    queryKeys: queryKey
  }
}
