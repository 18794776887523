// get divisions from https://stefangabos.github.io/world_countries/
import divisions from './subdivisions.json'

type Division = {
  name: string,
  country: string,
  code: string
}

export const COUNTRIES = (divisions as Division[]).reduce(
  (res, division) => ({
    ...res,
    [division.country]: division.country.toLowerCase()
  }),
  {}
)
