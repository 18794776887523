/**
 *
 * DEPRECATED
 *
 */
export const ORGANIZATION_FORM_NAME = 'organization'
export const APIARIES_FORM_NAME = 'apiaries'
export const BEEHIVES_FORM_NAME = 'beehives'

export type OrganizationFields = {
  name: string
  description: string
  organizationNumber: string
  street: string
  town: string
  zipCode: string
  country: string
}
export type OtherNoteData = {
  id: string
  category: string
  title: string
  text: string
}

export type ApiaryData = {
  id: string
  sortId: string
  beehivesAmount: number
  name: string
  defaultName: string
  location?: {
    lat: number
    lng: number
  } | null
}

export type ApiariesFields = {
  apiaries: ApiaryData[]
}

export type BeehiveData = {
  name: string
  apiaryId: string
  apiarySortIndex: number
  defaultName: string
}

export type BeehivesFields = {
  beehives: BeehiveData[]
  selectedApiaryIndex: number
}

export type CreateApiariesForm = {
  [APIARIES_FORM_NAME]: ApiariesFields
  [BEEHIVES_FORM_NAME]: BeehivesFields
}

export type CreateOrganizationForm = CreateApiariesForm & {
  [ORGANIZATION_FORM_NAME]: OrganizationFields
}
