const loggedInLoadingPage = {
  title: 'Welcome',
  hasOrgDescription:
    'Create a note or go to organizations to manage your beekeeping.',
  description:
    'You have not yet joined any organization. \nCreate a new one or join an existing one to access the beekeeping features of the application.',
  scanBeehive: 'Scan beehive',
  displayOrganization: 'Go to organizations',
  createOrganization: 'Create a new organization',
  joinOrganization: 'Join an organization',
  listOrganizations: 'My organizations',
  addApiaries: 'Add your own apiaries',
  downloadApp: 'Download the app',
  settings: 'Complete the settings of your profile',
  addNote: 'Create a note'
}

export default loggedInLoadingPage
