const displayOrganization = {
  title: 'Organisation',
  tabs: {
    apiaries: 'Bigårdar',
    members: 'Medlemmar',
    info: 'Information'
  },
  actions: {
    getMembers: {
      error: 'Ett fel inträffade när organisationens medlemmar skulle hämtas'
    }
  },
  success: {
    addMember: 'Förfrågan skickad'
  }
}

export default displayOrganization
