
const listOrganizations = {
  title: 'Organisationer',
  searchAllOrganizations: 'Sök bland alla organisationer',
  myOrganizations: {
    title: 'Mina organisationer',
    header: {
      name: 'Namn',
      apiariesAmount: 'Antal bigårdar'
    }
  }
}

export default listOrganizations
