import { useState, useCallback, useMemo } from 'react'
import Backdrop from '@mui/material/Backdrop'
import Modal from '@mui/material/Modal'
import Fade from '@mui/material/Fade'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Stack
} from '@mui/material'
import BeehiveQrScan from './BeehiveQrScan'

import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner'
import { useErrorStateSnackEffect } from '../../../../../../utils/hooks/stateSnackEffect'
import LoadingButtonIcon from '../../../../../common/LoadingButtonIcon'
import { useTranslation } from 'react-i18next'
import { Beehive } from '../../../../../../models/beehive'
import { useConnectIotBeehiveMutation } from '../../../../../../utils/api/hooks/beehive'
import useIsMounted from '../../../../../../utils/hooks/isMounted'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { ApiError } from 'openapi-typescript-fetch'
import { StatusCodes } from 'http-status-codes'

const COMPONENT_TRANSLATE_PREFIX = 'components.beehive.iotTab.register'

const modalRootStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%'
}
type ScanDataType = {
  iotId: string
  iotPassword: string
}

type RegisterPropType = {
  beehive: Beehive
  onRegister: any
  buttonWidth?: string
  buttonProps?: any
}

const Register = (props: RegisterPropType) => {
  const { beehive, buttonWidth, buttonProps } = props

  const { t } = useTranslation(undefined, {
    keyPrefix: COMPONENT_TRANSLATE_PREFIX
  })
  const { t: tGeneral } = useTranslation()

  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const isMounted = useIsMounted()

  const [open, setOpen] = useState(false)
  const [scanData, setScanData] = useState<ScanDataType | null>(null)
  const [scanError, setScanError] = useState<string | boolean>(false)

  const onConnectIotBeehiveSuccess = useCallback(() => {
    enqueueSnackbar(t('actions.connectIotBeehive.fulfilled'), {
      variant: 'success'
    })
    if (isMounted()) {
      navigate('./..')
    }
  }, [t, enqueueSnackbar])

  const usePostConnectIotBeehiveMutation = useConnectIotBeehiveMutation(
    onConnectIotBeehiveSuccess
  )
  const connectIotBeehiveError = usePostConnectIotBeehiveMutation.error
  const connectIotBeehivePending = usePostConnectIotBeehiveMutation.isLoading

  const connectIotBeehiveErrorMessage = useMemo(() => {
    if (connectIotBeehiveError instanceof ApiError) {
      switch (connectIotBeehiveError.status) {
        case StatusCodes.UNAUTHORIZED:
          return t('actions.connectIotBeehive.error')
        case StatusCodes.CONFLICT:
          return t('actions.connectIotBeehive.conflictError')
      }
    }
  }, [connectIotBeehiveError, t])

  useErrorStateSnackEffect(
    connectIotBeehiveError,
    connectIotBeehiveErrorMessage
  )

  const handleOpen = () => setOpen(true)
  const handleClose = () => !connectIotBeehivePending && setOpen(false)

  const onQrScan = (scanData: ScanDataType) => {
    setScanError(false)
    setScanData(scanData)
    console.log({ scanData })
  }

  const onQrScanError = (err: string) => {
    setScanError(err)
  }

  const submitScanData = () => {
    usePostConnectIotBeehiveMutation.mutate({
      beehiveId: beehive.id,
      ...scanData!
    })
  }

  return (
    <>
      <Stack alignItems="center">
        <Button
          width={buttonWidth}
          onClick={handleOpen}
          variant="contained"
          endIcon={<QrCodeScannerIcon />}
          {...buttonProps}
        >
          {t('integrateBeehive')}
        </Button>
      </Stack>

      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <Box sx={modalRootStyle}>
            <Container maxWidth="md">
              <Card>
                <CardHeader title={t('header')} />
                <CardContent sx={{ pt: 0 }}>
                  <BeehiveQrScan
                    onCancel={() => setOpen(false)}
                    onScan={onQrScan}
                    onScanError={onQrScanError}
                    useScan={!scanData}
                  />
                  <Grid container spacing={2} sx={{ pt: 2 }}>
                    <Grid item>
                      <Typography variant="h6">
                        {t(
                          `qrStatus.${
                            scanData
                              ? 'scanned'
                              : scanError
                              ? 'invalid'
                              : 'unScanned'
                          }`
                        )}
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      container
                      xs={12}
                      spacing={2}
                      justifyContent="center"
                    >
                      {scanData && (
                        <>
                          <Grid item xs={12}>
                            <Typography>
                              {t('scanDataInfo', { qrData: scanData.iotId })}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Button
                              disabled={connectIotBeehivePending}
                              variant="contained"
                              onClick={submitScanData}
                              endIcon={
                                connectIotBeehivePending && (
                                  <LoadingButtonIcon />
                                )
                              }
                            >
                              {tGeneral('general.verify')}
                            </Button>
                          </Grid>

                          <Grid item>
                            <Button
                              disabled={connectIotBeehivePending}
                              variant="contained"
                              onClick={() => setScanData(null)}
                            >
                              {t('reScan')}
                            </Button>
                          </Grid>
                        </>
                      )}

                      <Grid item>
                        <Button
                          disabled={connectIotBeehivePending}
                          onClick={() => setOpen(false)}
                          variant="contained"
                        >
                          {tGeneral('general.cancel')}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Container>
          </Box>
        </Fade>
      </Modal>
    </>
  )
}

export default Register
