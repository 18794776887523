/**
 *
 * DEPRECATED
 *
 */
import { useFormContext } from 'react-hook-form'
import ApiariesOverviewList from '../../../../apiaries/ApiaryOverviewList'
import { getFormApiariesToDisplay } from '.'

const Review = (props) => {
  const { apiariesFormName, beehivesFormName } = props

  const { watch } = useFormContext()

  const form = watch()

  const apiariesToDisplay = getFormApiariesToDisplay(
    form,
    apiariesFormName,
    beehivesFormName
  )

  return (
    <ApiariesOverviewList
      sx={{ pt: 2 }}
      showTitle={false}
      apiaries={apiariesToDisplay}
    />
  )
}

export default Review
