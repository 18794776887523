const addApiaries = {
  title: 'Lägg till bigårdar',
  stepTitle: {
    apiaries: 'Lägg till bigårdar',
    beehives: 'Justera bikuporna',
    review: 'Översikt'
  },
  actions: {
    createApiaries: {
      pending: 'Bigårdar skapas',
      fulfilled: 'Bigårdarna har skapats!',
      error: 'Ett fel inträffade när bigårdarna skulle skapas'
    }
  },
  fields: {
    apiaries: {
      errors: {
        minLength: 'Bigårdar krävs'
      }
    }
  }
}

export default addApiaries
