const login = {
  title: 'Sign In',
  infoTitle: 'Sign in',

  actions: {
    login: {
      label: 'Sign In',
      pending: 'Signing in...',
      error: {
        badCredentials: 'Incorrect username or password',
        network: 'Could not reach the service during sign in',
        general: 'An error occurred during sign in'
      }
    },

    forgotPassword: {
      label: 'Forgot Your Password?'
    }
  }
}

export default login
