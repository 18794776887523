import landingPage from './landingPage'
import login from './login'
import passwordReset from './passwordReset'
import register from './register'
import verifyUserEmail from './verifyUserEmail'

const auth = {
  register,
  verifyUserEmail,
  landingPage,
  login,
  passwordReset
}

export default auth
