import FormGrid from '../../common/Form/FormGrid'
import EmailField from '../../common/Form/FormGrid/CommonRows/EmailField'

const EmailSubmit = props => {
  const { name } = props

  return (
    <FormGrid sx={{ px: 2 }} name={name} rules={{ required: true }}>
      <EmailField/>
    </FormGrid>
  )
}

export default EmailSubmit
