import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import TextField, { TextFieldProps } from './TextField'

export type NumberTextFieldProps<InputProps> =
  Exclude<TextFieldProps<InputProps, number>, 'onChange'> &
  Partial<{
    allowFloat: boolean,
    unit: string,
    onChange?: (value: number) => number
  }>

function NumberTextField<InputProps> (props: NumberTextFieldProps<InputProps>) {
  const {
    inputProps,
    allowFloat,
    onChange,
    unit,
    ...rest
  } = props

  const { t: tUnit } = useTranslation(undefined, { keyPrefix: 'unit' })

  const computedOnChange = useCallback(
    (value: string) => {
      if (value !== '') {
        const parsedNumber = allowFloat ? +value : parseInt(value)
        return onChange ? onChange(parsedNumber) : (parsedNumber < 0 ? 0 : parsedNumber)
      }
    }
    ,
    [onChange, allowFloat]
  )

  return (
    <TextField
      onChange={computedOnChange}
      labelSuffix={unit && `(${tUnit(unit)})`}
      inputProps={{
        type: 'number',
        ...inputProps
      }}
      {...rest}
    />
  )
}

export default NumberTextField
