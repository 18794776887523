import apiFetcher from '../apiFetcher'

export const getIotData = apiFetcher
  .path('/iot-data/{beehiveId}')
  .method('get')
  .create()

export const getIotDataByDateRange = apiFetcher
  .path('/iot-data/dateRange/{beehiveId}')
  .method('get')
  .create()

export const getAllSensorsLatestVal = apiFetcher
  .path('/iot-data/allSensors/{thingId}')
  .method('get')
  .create()

export const subscribeToNotification = apiFetcher
  .path('/aws/subscribeToNotifications')
  .method('post')
  .create({ thingId: true, sensor_id: true, thresHoldValue: true })

export const getIotaData = apiFetcher
  .path('/aws/getIotaData/{beehiveId}')
  .method('get')
  .create()
