const infoTab = {
  organization: {
    description: 'Description',
    organizationNumber: 'Organization number',
    zipCode: 'Postal code',
    street: 'Street',
    town: 'Town',
    country: 'Country'
  },
  button: {
    cancel: 'Cancel',
    removeOrg: 'Remove organization'
  },
  info: {
    deleteSuccess: 'Organization has been removed',
    deleteFailed:
      'Something went wrong, only administrators can remove organizations.',
    removeOrgTitle: 'Remove organization',
    removeOrgInfo:
      'Are you sure you want to remove the organization? All associated apiaries and beehives will be removed, this cannot be undone.'
  }
}

export default infoTab
