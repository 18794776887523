/**
 *
 * DEPRECATED
 *
 */
import { Stack } from '@mui/material'
import ApiariesOverviewList from '../../../apiaries/ApiaryOverviewList'
import { getFormApiariesToDisplay } from './ApiariesOptions'
import { useFormContext } from 'react-hook-form'
import InformationList from '../../InformationList'
import { CreateOrganizationForm, ORGANIZATION_FORM_NAME } from './types'

const COMPONENT_TRANSLATE_PREFIX = 'components.forms.organizationGeneration'

const ORGANIZATION_ROWS_TRANSLATE_PREFIX = `${COMPONENT_TRANSLATE_PREFIX}.organizationOptions.fields`

const ORGANIZATION_ROWS = [
  { key: 'name', labelKey: 'form.fields.name.label' },
  ...[{ key: 'description' }, { key: 'organizationNumber' }].map((el) => ({
    ...el,
    labelKey: `${ORGANIZATION_ROWS_TRANSLATE_PREFIX}.${el.key}.label`
  })),

  ...[{ key: 'street' }, { key: 'town' }, { key: 'zipCode' }].map((el) => ({
    ...el,
    labelKey: `form.fields.${el.key}.label`
  }))
]

const Review = () => {
  const { watch } = useFormContext<CreateOrganizationForm>()

  const form = watch()
  const apiariesToDisplay = getFormApiariesToDisplay(form)

  return (
    <Stack spacing={2}>
      <InformationList
        rows={ORGANIZATION_ROWS}
        data={form[ORGANIZATION_FORM_NAME]}
      />
      {apiariesToDisplay && (
        <ApiariesOverviewList showTitle={false} apiaries={apiariesToDisplay} />
      )}
    </Stack>
  )
}

export default Review
