import { useState, useMemo, useCallback } from 'react'
import { Stack, Tab, Tabs } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useErrorStateSnackEffect } from '../../utils/hooks/stateSnackEffect'
import { useTranslation } from 'react-i18next'
import TitleImage from '../../components/common/TitleImage'
import TabPanel from '../../components/common/TabPanel'
import LoadingPage from '../../components/common/LoadingPage'
import { useAuthenticatedUserId } from '../../utils/api/hooks/useAuthenticatedUserId'
import {
  useJoinOrganization,
  useOrganizationMembersQuery,
  useOrganizationQuery
} from '../../utils/api/hooks/organization'
import VariablePathSegment from '../../enums/Path/VariablePathSegment'
import { isNotFoundError } from '../../utils/api/util/isNotFoundError'
import PageNotFound from '../PageNotFound'
import ApiariesTab from '../../components/Organization/DisplayOrganization/Tabs/ApiariesTab'
import MembersTab from '../../components/Organization/DisplayOrganization/Tabs/MembersTab'
import InfoTab from '../../components/Organization/DisplayOrganization/Tabs/InfoTab'
import {
  Organization,
  OrganizationMemberOverview
} from '../../models/organization'
import { useSnackbar } from 'notistack'
import { useUpdateUserAppReviewMutation } from '../../utils/api/hooks/user'

const COMPONENT_TRANSLATE_PREFIX = 'pages.organization.displayOrganization'

type OrganizationTabRenderProps = {
  organizationId: string
  organization?: Organization
  organizationMembers?: OrganizationMemberOverview[]
  authorizedMember?: OrganizationMemberOverview
}

type OrganizationTabConfiguration = {
  name: string
  render: (props: OrganizationTabRenderProps) => JSX.Element
}

const COMPONENT_TABS: OrganizationTabConfiguration[] = [
  {
    name: 'apiaries',
    render: ({ organization, authorizedMember }) => (
      <ApiariesTab {...{ authorizedMember, organization }} />
    )
  },
  {
    name: 'members',
    render: ({ organization, organizationMembers, authorizedMember }) => (
      <MembersTab
        {...{
          organization,
          organizationMembers,
          authorizedMember
        }}
      />
    )
  },
  {
    name: 'info',
    render: ({ organization, authorizedMember }) => (
      <InfoTab {...{ organization, authorizedMember }} />
    )
  }
]

const DisplayOrganization = () => {
  const { [VariablePathSegment.ORGANIZATION_ID]: organizationId } = useParams()

  const { t } = useTranslation(undefined, {
    keyPrefix: COMPONENT_TRANSLATE_PREFIX
  })
  const { t: tGeneral } = useTranslation()
  const [openTabIndex, setOpenTabIndex] = useState(0)

  const userId = useAuthenticatedUserId()
  const { enqueueSnackbar } = useSnackbar()

  const organizationQuery = useOrganizationQuery(organizationId)

  useErrorStateSnackEffect(organizationQuery.isError, tGeneral('general.error'))

  const organizationMembersQuery = useOrganizationMembersQuery(organizationId)

  // eslint-disable-next-line
  const userAppReviewQuery = useUpdateUserAppReviewMutation()

  useErrorStateSnackEffect(
    organizationMembersQuery.isError,
    t('actions.getMembers.error'),
    false
  )

  const authorizedMember = useMemo(
    () => organizationMembersQuery.data?.data?.find((m) => m.userId === userId),
    [organizationMembersQuery.data, userId]
  )

  const onJoinOrganizationSuccess = useCallback(() => {
    enqueueSnackbar(t('success.addMember'), {
      variant: 'success'
    })
    location.reload()
  }, [t, enqueueSnackbar])

  // eslint-disable-next-line
  const onMembershipRequestToggle = () => {
    const joinOrganizationMutation = useJoinOrganization(
      onJoinOrganizationSuccess
    )

    joinOrganizationMutation.mutateAsync({
      id: userId!
    })
    // dispatch(Actions.DataStore.syncDataStore)
  }

  const pagePending =
    organizationMembersQuery.isLoading || organizationQuery.isLoading

  const organization = organizationQuery.data?.data
  const organizationMembers = organizationMembersQuery.data?.data

  if (isNotFoundError(organizationQuery.error)) {
    return <PageNotFound />
  }

  const anyTabRenderPropUndefined = !organization

  return (
    <Stack className="pageRoot">
      <TitleImage
        src="/images/generic-background.png"
        title={organization ? organization.name : '...'}
        description={organization ? organization.description : '...'}
      />

      <Stack className="pageContent" sx={{ p: 0 }}>
        <Tabs
          value={openTabIndex}
          onChange={(evt, val) => setOpenTabIndex(val)}
          aria-label="basic tabs example"
          variant="fullWidth"
          color="secondary"
        >
          {COMPONENT_TABS.map(({ name }, index) => (
            <Tab key={index} label={t(`tabs.${name}`)} />
          ))}
        </Tabs>

        {!organizationId || pagePending ? (
          <LoadingPage />
        ) : !authorizedMember ? (
          COMPONENT_TABS.map(({ render }, index) => (
            <TabPanel key={index} index={index} value={openTabIndex}>
              {render({
                organizationId,
                organization,
                organizationMembers,
                authorizedMember
              })}
            </TabPanel>
          ))
        ) : anyTabRenderPropUndefined ? (
          <PageNotFound />
        ) : (
          COMPONENT_TABS.map(({ render }, index) => (
            <TabPanel key={index} index={index} value={openTabIndex}>
              {render({
                organizationId,
                organization,
                organizationMembers,
                authorizedMember
              })}
            </TabPanel>
          ))
        )}
      </Stack>
    </Stack>
  )
}

export default DisplayOrganization
