import sideMenu from './sideMenu'
import toolbar from './toolbar'

const overlay = {
  sideMenu,
  beelab: 'Beelab',
  toolbar
}

export default overlay
