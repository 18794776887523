import { FormControl, Grid } from '@mui/material'
import { UseFormReturn } from 'react-hook-form'
import {
  BeehiveInspectionQueenControl,
  BeehiveSickness,
  BeehiveSocietyBehavior,
  BeehiveSocietyStrength,
  BeeQueenColor
} from '../../models'
import { createCheckBoxValueFromModelField } from '../../utils/form/helpers'
import FormGrid from '../common/Form/FormGrid'
import AutocompleteField from '../common/Form/FormGrid/CommonRows/AutocompleteField'
import CheckRow from '../common/Form/FormGrid/CommonRows/CheckRow/CheckRow'
import CheckSectionTitle from '../common/Form/FormGrid/CommonRows/CheckRow/CheckSectionTitle'
import ContentCheckRow from '../common/Form/FormGrid/CommonRows/CheckRow/ContentCheckRow'
import FreeTextField from '../common/Form/FormGrid/CommonRows/FreeTextField'
import NumberTextField from '../common/Form/FormGrid/CommonRows/NumberField'
import RadioGroupField from '../common/Form/FormGrid/CommonRows/RadioField'
import TextField from '../common/Form/FormGrid/CommonRows/TextField'

const SOCIETY_BEHAVIOR_OPTIONS = Object.keys(BeehiveSocietyBehavior)
const SOCIETY_STRENGTH_OPTIONS = Object.keys(BeehiveSocietyStrength)
const QUEEN_CONTROL_OPTIONS = Object.keys(BeehiveInspectionQueenControl)
const SICKNESS_OPTIONS = Object.keys(BeehiveSickness)
const QUEEN_COLOR_OPTIONS = Object.keys(BeeQueenColor)

const COMPONENT_TRANSLATE_PREFIX =
  'components.note.newNote.newNoteDetails.beehiveInspectionDetails'

export const BEEHIVE_INSPECTION_NOTE_FIELDS = [
  'organizationId',
  'beehiveId',
  'title',
  'cutDroneFrame',
  'attractsPollen',
  'newQueen',
  'queenWingCut',
  'newEggs',
  {
    key: 'newEggs',
    transform: createCheckBoxValueFromModelField
  },
  'coveredFries',
  {
    key: 'coveredFries',
    transform: createCheckBoxValueFromModelField
  },
  'filledFryRoomsWithFrames',
  {
    key: 'filledFryRoomsWithFrames',
    transform: createCheckBoxValueFromModelField
  },
  'winterFed',
  {
    key: 'winterFed',
    transform: createCheckBoxValueFromModelField
  },
  'checkedFramesWithRemainingFodder',
  {
    key: 'checkedFramesWithRemainingFodder',
    transform: createCheckBoxValueFromModelField
  },
  'performedSpringControl',
  {
    key: 'performedSpringControl',
    transform: createCheckBoxValueFromModelField
  },
  'performedBeeHouseControl',
  {
    key: 'performedBeeHouseControl',
    transform: createCheckBoxValueFromModelField
  },
  'societyStrength',
  {
    key: 'societyStrength',
    transform: createCheckBoxValueFromModelField
  },
  'societyBehavior',
  {
    key: 'societyBehavior',
    transform: createCheckBoxValueFromModelField
  },
  'sicknessSymptom',
  {
    key: 'sicknessSymptom',
    transform: createCheckBoxValueFromModelField
  },
  'queenControl',
  {
    key: 'queenControl',
    transform: createCheckBoxValueFromModelField
  },
  'queenMarked',
  {
    key: 'queenMarked',
    transform: createCheckBoxValueFromModelField
  },
  'other',
  {
    key: 'other',
    transform: createCheckBoxValueFromModelField
  },
  'hurled',
  {
    key: 'hurled',
    transform: createCheckBoxValueFromModelField
  }
]

export type BeehiveInspectionFormType = {
  title: string
  cutDroneFrame: boolean
  attractsPollen: boolean
  newQueen: boolean
  queenWingCut: boolean
  hurled: any
  newEggs: any
  coveredFries: any
  filledFryRoomsWithFrames: any
  winterFed: any
  checkedFramesWithRemainingFodder: any
  performedSpringControl: any
  performedBeeHouseControl: any
  societyStrength: any
  societyBehavior: any
  sicknessSymptoms: any
  queenControl: any
  queenMarked: any
  other: any
}

type BeehiveInspectionNoteFormProps = {
  name?: string
  methods?: UseFormReturn<any, any>
  isForDisplayOnly?: boolean
}

const BeehiveInspectionNoteForm = (props: BeehiveInspectionNoteFormProps) => {
  const { name, methods, isForDisplayOnly } = props
  const disabled = isForDisplayOnly ?? false
  console.log({ disabled: disabled })

  return (
    <FormGrid
      name={name}
      methods={methods}
      className="overflowPageContentPadding"
      translatePrefix={COMPONENT_TRANSLATE_PREFIX}
      rules={{ required: false }}
    >
      <FormControl disabled={disabled}>
        <Grid container className="checkRow">
          {!isForDisplayOnly && (
            <TextField
              inputProps={{ p: 'insidePageContentPadding' }}
              rules={{ required: true }}
              name={'title'}
              disabled={disabled}
            />
          )}

          <CheckSectionTitle
            className="after-other-fields"
            name="general"
            disableGutter={isForDisplayOnly}
          />
        </Grid>

        <ContentCheckRow
          name="newEggs"
          renderContent={(props: any) => (
            <NumberTextField {...props} disabled={disabled} />
          )}
        />

        <ContentCheckRow
          name="coveredFries"
          renderContent={(props: any) => (
            <NumberTextField {...props} disabled={disabled} />
          )}
        />

        <CheckRow name="cutDroneFrame" />
        <CheckRow name="attractsPollen" />

        <ContentCheckRow
          name="winterFed"
          renderContent={(props: any) => (
            <NumberTextField {...props} disabled={disabled} />
          )}
        />

        <ContentCheckRow
          name="checkedFramesWithRemainingFodder"
          renderContent={(props: any) => (
            <NumberTextField allowFloat {...props} />
          )}
        />
        <ContentCheckRow
          name="hurled"
          renderContent={(props: any) => (
            <NumberTextField allowFloat {...props} />
          )}
        />

        {/* <CheckRow name="hurled" /> */}

        <ContentCheckRow
          name="performedSpringControl"
          renderContent={(props: any) => (
            <FreeTextField {...props} disabled={disabled} />
          )}
        />

        <ContentCheckRow
          name="performedBeeHouseControl"
          renderContent={(props: any) => (
            <FreeTextField {...props} disabled={disabled} />
          )}
        />

        <CheckSectionTitle name="society" />

        <ContentCheckRow
          name="societyStrength"
          alwaysOpen
          renderContent={(props: any) => (
            <RadioGroupField
              cols={3}
              hideLabel
              optionTranslatePrefix="general.beehiveSocietyStrength"
              options={SOCIETY_STRENGTH_OPTIONS}
              disabled={disabled}
              {...props}
            />
          )}
        />

        <ContentCheckRow
          name="societyBehavior"
          alwaysOpen
          renderContent={(props: any) => (
            <RadioGroupField
              cols={3}
              hideLabel
              optionTranslatePrefix="general.beehiveSocietyBehavior"
              options={SOCIETY_BEHAVIOR_OPTIONS}
              disabled={disabled}
              {...props}
            />
          )}
        />

        <ContentCheckRow
          name="sicknessSymptom"
          alwaysOpen
          renderContent={(props: any) => (
            <RadioGroupField
              cols={2}
              hideLabel
              includeOther
              optionTranslatePrefix="general.beehiveSickness"
              options={SICKNESS_OPTIONS}
              disabled={disabled}
              {...props}
            />
          )}
        />

        <CheckSectionTitle name="queen" />

        <ContentCheckRow
          name="queenControl"
          renderContent={(props: any) => (
            <RadioGroupField
              cols={2}
              hideLabel
              optionTranslatePrefix="general.beehiveInspectionQueenControl"
              options={QUEEN_CONTROL_OPTIONS}
              disabled={disabled}
              {...props}
            />
          )}
        />

        <CheckRow name="newQueen" />

        <ContentCheckRow
          name="queenMarked"
          renderContent={(props: any) => (
            <AutocompleteField
              {...props}
              disabled={disabled}
              optionTranslatePrefix="general.beeQueenColor"
              options={QUEEN_COLOR_OPTIONS}
              translateOptions={true}
            />
          )}
        />

        <CheckRow name="queenWingCut" />

        <CheckSectionTitle name="other" />

        <ContentCheckRow
          name="other"
          renderContent={(props: any) => (
            <FreeTextField {...props} disabled={disabled} />
          )}
        />
      </FormControl>
    </FormGrid>
  )
}

export default BeehiveInspectionNoteForm
