const beeHiveInfo = {
  error: {
    loadingApiary: 'Ett fel uppstod när bigården skulle laddas'
  },
  noteTypes: {
    OTHER: 'Övrigt',
    INSPECTION: 'Inspektion'
  },

  headers: {
    name: 'Namn',
    apiaryId: 'Bigård',
    beeType: 'Typ av bi',
    beehiveType: 'Typ av bikupa',
    iotId: 'Digital kupa',
    lastSwarmed: 'Senast svärmad',
    queenColor: 'Drottning',
    queenWingCut: 'Drottning vingklippt',
    framesAmount: 'Antal ramar',
    boxAmount: 'Antal lådor',
    settings: 'Inställningar',
    moveHiveOrOrg: 'Ändrat bigård eller organisation?',
    harvestThisYear: 'Skörd i år',
    otherBeehivesSameApiary: 'Bikupor i samma bigård:',
    noData: '-',
    noNotes: 'Inga anteckningar'
  },
  subtext: {
    EDIT_SETTINGS: 'Justera inställningarna för bikupan',
    MOVE_HIVE:
      'Har bikupan flyttats sedan installationen så den tillhör en annan bigård eller organisation, klicka på knappen nedan'
  },
  buttons: {
    MOVE_HIVE: 'Flytta Bikupan',
    RETURN: 'Tillbaka',
    SETTINGS: 'Inställningar',
    EDIT: 'Redigera',
    NEW_NOTE: 'Ny anteckning'
  },
  units: {
    weight: 'kg'
  }
}

export default beeHiveInfo
