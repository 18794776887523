// eslint-disable-next-line no-use-before-define
import React, { useMemo, useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import { Button, Box, Stack, Card, CardActions, Divider } from '@mui/material'
import { Link } from 'react-router-dom'
import EditIcon from '@mui/icons-material/Edit'
import { useTranslation } from 'react-i18next'
import { AppRoutePaths, AppRoutePathSegment } from '../../../../../utils/routes'
import { useErrorStateSnackEffect } from '../../../../../utils/hooks/stateSnackEffect'
import BottomContentContainer from '../../../../common/Layout/BottomContentContainer'
import Collapse from '@mui/material/Collapse'
import { styled } from '@mui/material/styles'
import CardContent from '@mui/material/CardContent'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  useGetApiaryBeehivesQuery,
  useGetBeehiveQuery
} from '../../../../../utils/api/hooks/beehive'
import { useGetBeehiveInspNotesHurledQuery } from '../../../../../utils/api/hooks/inspectionNote'
import { useGetOtherNotesQuery } from '../../../../../utils/api/hooks/otherNote'
import { NoteCategory } from '../../../../../models'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'

const COMPONENT_TRANSLATE_PREFIX = 'components.beehive.beeHiveInfo'

const ExpandMore = styled((props) => {
  const { ...other } = props
  return <IconButton {...other} />
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest
  })
}))

const BeehiveInfo = (props) => {
  const { beehiveId, apiaryId, orgId } = props
  const [sortedNotes, setSortedNotes] = useState([])

  const beehiveData = useGetBeehiveQuery(beehiveId).data?.data
  let beehive
  if (beehiveData) {
    beehive = {
      ...beehiveData,
      lastSwarmed: beehiveData.lastSwarmed
        ? new Date(beehiveData.lastSwarmed).toLocaleDateString()
        : null
    }

    // use beehiveData here
  }

  const currentDate = new Date()
  const currentYear = currentDate.getFullYear()
  const startDate = new Date(currentYear, 0, 1) // January 1st of current year
  const endDate = new Date(currentYear, 11, 31) // December 31st of current year
  const apiariesQuery = useGetApiaryBeehivesQuery(apiaryId)
  const beehives = apiariesQuery.data?.data.values

  const otherNotesQuery = useGetOtherNotesQuery({
    selectedCategory: NoteCategory.BEEHIVE,
    organizationId: orgId,
    apiaryId: apiaryId,
    beehiveId: beehive.id,
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString()
  })
  const otherNotesData = otherNotesQuery.query.data?.data

  const inspNoteQuery = useGetBeehiveInspNotesHurledQuery({
    selectedCategory: NoteCategory.BEEHIVE,
    organizationId: orgId,
    apiaryId: apiaryId,
    beehiveId: beehive.id,
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString()
  })
  const inspNotesData = inspNoteQuery.query.data?.data

  // let sortedNotes = []

  const combinedAndSortedNotes = useMemo(() => {
    if (inspNotesData && otherNotesData) {
      const combinedNotes = [
        ...otherNotesData.map((note) => ({
          ...note,
          type: 'OTHER',
          link: '/home/note/otherNote/' + note.id
        })),
        ...inspNotesData.map((note) => ({
          ...note,
          type: 'INSPECTION',
          link: '/home/note/beehive-inspection-note/' + note.id
        }))
      ]

      return combinedNotes.sort((a, b) => {
        const dateA = new Date(a.createdAt)
        const dateB = new Date(b.createdAt)
        return dateB - dateA
      })
    }

    return []
  }, [inspNotesData, otherNotesData])

  useEffect(() => {
    setSortedNotes(combinedAndSortedNotes)
  }, [combinedAndSortedNotes])

  let totalHarvest = 0
  if (inspNotesData !== undefined && inspNotesData !== null) {
    inspNotesData.forEach((item) => {
      totalHarvest += item.hurled
    })
  }

  const { t } = useTranslation(undefined, {
    keyPrefix: COMPONENT_TRANSLATE_PREFIX
  })
  const { t: tGeneral } = useTranslation(undefined, { keyPrefix: 'general' })
  const [expanded, setExpanded] = React.useState(false)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  useErrorStateSnackEffect(beehive.isError, t('error.loadingApiary'))

  const formatDate = (dateString) => {
    const date = new Date(dateString)

    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0') // Months are 0-indexed in JavaScript
    const year = date.getFullYear()

    return `${day}/${month}/${year}`
  }

  const fields = [
    {
      name: 'framesAmount',
      fixedText: beehive.framesAmount
    },
    {
      name: 'queenColor',
      enumName: 'beeQueenColor'
    },
    {
      name: 'beeType',
      enumName: 'beeType'
    },
    {
      name: 'iotId',
      fixedText: tGeneral(`${beehive?.iotId ? 'yes' : 'no'}`)
    },
    {
      name: 'name',
      fixedText: beehive?.name
    },
    {
      name: 'apiaryId',
      fixedText: beehive.APIARY_ID // apiaryQuery.isFetching
        ? tGeneral('pending')
        : beehive?.apiaryName
    },
    {
      name: 'beehiveType',
      enumName: 'beehiveType'
    },
    {
      name: 'boxAmount',
      fixedText: beehive.boxAmount
    },
    {
      name: 'lastSwarmed',
      fixedText: beehive.lastSwarmed
    },
    {
      name: 'queenWingCut',
      fixedText: tGeneral(`${beehive?.queenWingCut ? 'yes' : 'no'}`)
    }
  ]

  const fieldsFirstFour = []
  for (let i = 0; i < 4; i++) {
    const item = fields.shift()
    fieldsFirstFour.push(item)
  }
  const buttonData = []
  if (beehives !== undefined && buttonData.length === 0) {
    beehives.forEach((item) => {
      buttonData.push({
        ...item,
        link: '/home/organization/' + orgId + '/' + apiaryId + '/' + item.id
      })
    })
  }

  return (
    <>
      <Grid container justifyContent={'end'}>
        <Link
          to={AppRoutePathSegment.replaceSegments(
            AppRoutePaths.EDIT_BEEHIVE_PATH,
            [
              {
                name: AppRoutePathSegment.BEEHIVE_ID,
                value: beehiveId
              },
              {
                name: AppRoutePathSegment.APIARY_ID,
                value: apiaryId
              },
              {
                name: AppRoutePathSegment.ORGANIZATION_ID,
                value: orgId
              }
            ]
          )}
          className="link"
          variant="text"
        >
          {t('buttons.EDIT')}
        </Link>
        <EditIcon></EditIcon>
      </Grid>
      <Grid container padding={0} spacing={0} justifyContent={'space-evenly'}>
        <Grid item xs={6}>
          <Card
            sx={{
              flexGrow: true,
              borderRadius: 8,
              backgroundColor: 'rgba(211, 211, 211, 0.3)',
              boxShadow: 'none'
            }}
          >
            <CardContent>
              {fieldsFirstFour.map((field) => (
                <React.Fragment key={field.name}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    justifyContent="flex-start"
                    marginBottom={1}
                    marginTop={1}
                  >
                    <Typography variant="body2" color="text.secondary">
                      {t(`headers.${field.name}`) + ': '}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {field.fixedText ??
                        (field.enumName
                          ? beehive?.[field.name] &&
                            tGeneral(
                              `${field.enumName}.${beehive?.[field.name]}`
                            )
                          : beehive?.[field.name]) ??
                        t('headers.noData')}
                    </Typography>
                  </Stack>
                  <Divider />
                </React.Fragment>
              ))}
              <CardActions sx={{ maxHeight: '0px', paddingTop: 2 }}>
                <ExpandMore
                  expand={expanded}
                  onClick={handleExpandClick}
                  aria-expanded={expanded}
                  aria-label="show more"
                >
                  <ExpandMoreIcon />
                </ExpandMore>
              </CardActions>
            </CardContent>

            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <CardContent>
                {fields.map((field) => (
                  <React.Fragment key={field.name}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      spacing={0.5}
                      marginBottom={1}
                      marginTop={1}
                      width={'100%'}
                    >
                      <Typography variant="body2" color="text.secondary">
                        {t(`headers.${field.name}`) + ': '}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {field.fixedText ??
                          (field.enumName
                            ? beehive?.[field.name] &&
                              tGeneral(
                                `${field.enumName}.${beehive?.[field.name]}`
                              )
                            : beehive?.[field.name]) ??
                          t('headers.noData')}
                      </Typography>
                    </Stack>
                    <Divider />
                  </React.Fragment>
                ))}
              </CardContent>
            </Collapse>
          </Card>
        </Grid>
        <Grid item xs={5}>
          <Card
            sx={{
              flexGrow: true,
              borderRadius: 8,
              backgroundColor: '#d3d3d34d',
              boxShadow: 'none',
              height: '100%',
              maxHeight: '279.5px'
            }}
          >
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '90%'
              }}
            >
              <Typography variant="h2" color="text.secondary">
                {t('headers.harvestThisYear')}
              </Typography>
              <Typography variant="h1">
                {totalHarvest + t('units.weight')}
              </Typography>
              <CardActions></CardActions>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Button
        component={Link}
        to={AppRoutePaths.NEW_NOTE_PATH}
        state={beehive}
        fullWidth
        sx={{ borderRadius: 4 }}
        variant="contained"
      >
        {t('buttons.NEW_NOTE')}
      </Button>
      {/* Mapping out a scroll view with buttons where each button should lead to a beehive.
          Code below should be extracted to a component for reuse and support for images. */}
      <Typography sx={{ paddingLeft: 2 }} variant="h2">
        {t('headers.otherBeehivesSameApiary')}
      </Typography>
      <Box
        style={{
          width: '100%',
          overflowX: 'auto',
          overflowY: 'hidden',
          whiteSpace: 'nowrap',
          scrollbarWidth: 'none',
          overflow: 'auto',
          '&::WebkitScrollbar': {
            display: 'none'
          }
        }}
      >
        {buttonData.length > 0 &&
          buttonData.map((button) => (
            <Button
              component={Link}
              to={button.link}
              relative="path"
              onClick={() => inspNoteQuery.refetch()}
              variant="contained"
              key={button.id}
              sx={{
                width: '120px',
                height: '120px',
                margin: '10px',
                background: '#d3d3d34d',
                backgroundSize: 'cover',
                border: 'none',
                borderRadius: '15%',
                whiteSpace: 'normal', // or 'pre-wrap' to preserve white spaces
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
            >
              {button.name.length > 20
                ? `${button.name.substring(0, 20)}...`
                : button.name}
            </Button>
          ))}
      </Box>

      <Grid container justifyContent={'left'} paddingLeft={1}>
        <Card
          sx={{
            flexGrow: true,
            backgroundColor: '#d3d3d34d',
            width: '100%',
            borderRadius: 4,
            margin: 2
          }}
        >
          <CardContent>
            <List
              sx={{
                maxHeight: '500px',
                overflowY: 'auto',
                padding: 0
              }}
            >
              {sortedNotes !== undefined ? (
                sortedNotes.map((item, index) => (
                  <React.Fragment key={item.id}>
                    <ListItem
                      button
                      component={Link}
                      to={item.link}
                      relative="path"
                    >
                      <ListItemText
                        primary={formatDate(item.createdAt)}
                        secondary={
                          t(`noteTypes.${item.type}`) + ', ' + item.title
                        }
                      />
                    </ListItem>
                    {index !== sortedNotes.length - 1 && <Divider />}
                  </React.Fragment>
                ))
              ) : (
                <React.Fragment>
                  <ListItem>
                    <ListItemText primary={t('headers.noNotes')} />
                  </ListItem>
                </React.Fragment>
              )}
            </List>
          </CardContent>
        </Card>
      </Grid>
      <BottomContentContainer>
        <Stack direction="row" justifyContent="center">
          <Button
            variant="outlined"
            color="secondary"
            component={Link}
            to="./.."
          >
            {t('buttons.RETURN')}
          </Button>
        </Stack>
      </BottomContentContainer>
    </>
  )
}

export default BeehiveInfo
