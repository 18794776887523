const displayOtherNote = {
  actions: {
    loadOtherNote: {
      error: 'An error occurred while loading the note.'
    },
    updateOtherNote: {
      error: 'An error occurred while saving the changes.',
      success: 'Changes have been saved.'
    }
  },
  fields: {
    titles: {
      otherNote: 'Other Note'
    },
    title: 'Title',
    text: 'Text'
  },
  buttons: {
    addPicture: 'Add picture',
    save: 'Save',
    edit: 'Edit',
    abort: 'Cancel'
  }
}

export default displayOtherNote
