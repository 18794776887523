import VariablePathSegment from '../../../../enums/Path/VariablePathSegment'
import { useGetOtherNotesQuery } from '../../../../utils/api/hooks/otherNote'
import { AppRoutePaths, AppRoutePathSegment } from '../../../../utils/routes'
import { ListNoteOverviews, NoteOverview } from '../ListNoteOverviews'
import { generateNoteOverview, ListNoteTypeRenderProps } from '../util'
import { OtherNotesResultDto } from '../../../../models/notes'

const generateOtherNoteOverview = (note: OtherNotesResultDto): NoteOverview =>
  generateNoteOverview(
    note,
    AppRoutePathSegment.replaceSegments(AppRoutePaths.VIEW_OTHER_NOTE_PATH, [
      {
        name: VariablePathSegment.OTHER_NOTE_ID,
        value: note.id
      }
    ])
  )

export const ListOtherNotes = (props: ListNoteTypeRenderProps) => {
  const { filter } = props

  return (
    <ListNoteOverviews
      queryResult={useGetOtherNotesQuery({
        selectedCategory: filter!.selectedCategory,
        organizationId: filter!.organizationId,
        apiaryId: filter?.apiaryId ? filter.apiaryId : null,
        beehiveId: filter?.beehiveId ? filter?.beehiveId : null,
        startDate: filter!.startDate,
        endDate: filter!.endDate
      })}
      noteTransformFn={generateOtherNoteOverview}
    />
  )
}
