const beehiveInventoryDetails = {
  section: {
    general: 'Allmänt'
  },

  measureFields: {
    quantity: {
      available: 'Antal hemma',
      toGet: 'Antal att köpa'
    },
    amount: {
      available: 'Mängd hemma',
      toGet: 'Mängd att köpa'
    }
  },

  fields: {
    title: {
      label: 'Titel',
      errors: {
        required: 'Titel krävs'
      }
    },
    frames: {
      _checkbox: {
        label: 'Ramar'
      }
    },
    boxes: {
      _checkbox: {
        label: 'Lådor'
      }
    },
    coverboard: {
      _checkbox: {
        label: 'Täckbrädor'
      }
    },
    wax: {
      _checkbox: {
        label: 'Vax'
      }
    },
    feed: {
      _checkbox: {
        label: 'Foder'
      }
    },
    other: {
      _checkbox: {
        label: 'Övrigt'
      }
    }
  }
}

export default beehiveInventoryDetails
