import apiFetcher from '../apiFetcher'

export const getApiary = apiFetcher
  .path('/apiary/byId/{id}')
  .method('get')
  .create()

export const createApiaries = apiFetcher
  .path('/apiary/createApiaries')
  .method('post')
  .create()

export const removeApiary = apiFetcher
  .path('/apiary/{orgIg}/{id}')
  .method('delete')
  .create()

export const updateApiary = apiFetcher
  .path('/apiary/{id}')
  .method('put')
  .create()

export const getApiariesByOrganization = apiFetcher
  .path('/apiary/forOrganization/{id}')
  .method('get')
  .create()

export const getFirstIoTUnit = apiFetcher
  .path('/apiary/getFirstIotUnit/{userId}')
  .method('get')
  .create()
