import { useCallback } from 'react'
import BeehiveEditForm from '../../components/beehives/BeehiveEditForm'
import { useNavigate, useParams } from 'react-router-dom'
import { useErrorStateSnackEffect } from '../../utils/hooks/stateSnackEffect'
import { useTranslation } from 'react-i18next'
import LoadingPage from '../../components/common/LoadingPage'
import TitleImage from '../../components/common/TitleImage'
import { BeehiveInput } from '../../models/beehive'
import { Stack } from '@mui/material'
import {
  useGetBeehiveQuery,
  useUpdateBeehiveMutation
} from '../../utils/api/hooks/beehive'
import { useSnackbar } from 'notistack'
import useIsMounted from '../../utils/hooks/isMounted'

const EditBeehive = () => {
  const navigate = useNavigate()
  const isMounted = useIsMounted()
  const { t } = useTranslation()
  const { beehiveId } = useParams()
  const { enqueueSnackbar } = useSnackbar()

  const beehiveQuery = useGetBeehiveQuery(beehiveId!)
  const beehive = beehiveQuery.data?.data

  useErrorStateSnackEffect(beehiveQuery.isError, t('error.loadingBeehives'))

  const onUpdateBeehiveSuccess = useCallback(() => {
    enqueueSnackbar(t('success.updateBeehive'), {
      variant: 'success'
    })
    if (isMounted()) {
      navigate('./..')
    }
  }, [t, enqueueSnackbar])

  const updateBeehiveMutation = useUpdateBeehiveMutation(onUpdateBeehiveSuccess)
  useErrorStateSnackEffect(
    updateBeehiveMutation.isError,
    t('error.updateBeehive')
  )

  function updateBeehive(data: BeehiveInput) {
    updateBeehiveMutation.mutate({
      beehiveId: beehiveId!,
      ...data
    })
  }

  return beehiveQuery.isLoading ? (
    <LoadingPage />
  ) : (
    <>
      <Stack className="pageRoot">
        <TitleImage
          src="/images/generic-background.png"
          title={beehive?.name}
        />
      </Stack>
      <Stack className="pageContent">
        <BeehiveEditForm
          forExisting
          initialState={beehive}
          pending={updateBeehiveMutation.isLoading}
          onSubmit={(form: BeehiveInput) => updateBeehive({ ...form })}
          // onIotRegister={() => dispatch(Actions.DataStore.syncDataStore())}
        />
      </Stack>
    </>
  )
}

export default EditBeehive
