import { MouseEventHandler, useEffect, useState } from 'react'
import { FieldValues, SubmitHandler, useFormContext } from 'react-hook-form'

import { Button, Stack, Typography } from '@mui/material'
import LoadingPage from '../../LoadingPage'
import { useTranslation } from 'react-i18next'
import useIsMounted from '../../../../utils/hooks/isMounted'
import LoadingButtonIcon from '../../LoadingButtonIcon'
import PageBodyContainer from '../../PageBodyContainer'
import ErrorsList from '../ErrorsList'
import BottomButtonsContainer from '../../Layout/BottomButtonsContainer'
import { Box } from '@mui/system'

export type FormStepRenderFunctionProps = {
  name: string
}
export type FormStepRenderFunction = (
  args: FormStepRenderFunctionProps
) => JSX.Element

export type FormStepRenderTitleFunctionProps = {
  name: string
  activeStepIndex: number
}
export type FormStepRenderTitleFunction = (
  args: FormStepRenderTitleFunctionProps
) => JSX.Element

export type FormStepValidationRules = {
  field: string
  rules: {
    type: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    condition: (field: any, value: any) => boolean
  }[]
}

export type FormStepConfiguration<
  FormFields extends FieldValues = FieldValues
> = {
  name: string
  title?: string
  description?: string
  renderForm: FormStepRenderFunction
  renderTitle?: () => JSX.Element
  submit?: (form: FormFields) => boolean | Promise<boolean>
  validationRules?: FormStepValidationRules[]
  validationTranslatePrefix?: string
}

export type FormStepsProps<FormFields extends FieldValues = FieldValues> = {
  activeStepIndex: number
  onStepChange: (stepIndex: number) => void
  onCancel?: () => void
  hideSubmitButton?: boolean
  renderTitle?: FormStepRenderTitleFunction
  onSubmit?: SubmitHandler<FormFields>
  steps: FormStepConfiguration<FormFields>[]
  className?: string
}

/**
 * Component for wrapping a set of forms as a stepper.
 * @param {*} props.sx style to apply to the root Stack element
 * @param {*} props.activeStepIndex form step index to render
 * @param {*} props.onSubmit function to invoke when submitting the entire form
 * @param {*} props.onStepChange callback which is invoked when the user navigates forward or backward in the form
 * @param {*} props.onCancel callback which is invoked when the user cancels the form
 * @param {*} props.renderTitle optional function to 'globally' handle the title render logic for the form, may still be overridden by steps rendertitle
 * @param {*} props.steps array of objects for
 * @example
 * {
 *   name: <STEP_NAME>,
 *   renderForm: props => <<RENDER_FORM_COMPONENT> {...props}/>,
 *   renderTitle: () => <<RENDER_TITLE_COMPONENT>/>,
 *   validationRules: [
 *     {
 *       field: <FIELD_NAME>,
 *       rules: [
 *         {
 *           type: 'minLength',
 *           value: 0,
 *           condition: (<FIELD_NAME>, value) => <FIELD_NAME>.length > value
 *         }
 *       ]
 *     }
 *   ],
 *   submit: form => <SUBSTEP_SUBMIT_FN>(form)
 *   validationTranslatePrefix: <TRANSLATE_PREFIX_FOR_VALIDATION_ERRORS>
 * },
 */
export function FormStepsContainer<FormFields extends FieldValues>(
  props: FormStepsProps<FormFields>
) {
  const {
    activeStepIndex = 0,
    onSubmit,
    onStepChange,
    onCancel,
    hideSubmitButton,
    renderTitle,
    steps,
    ...rest
  } = props

  const { t } = useTranslation()

  const {
    clearErrors,
    handleSubmit,
    getValues,
    trigger,
    setError,
    /* errors, */
    formState
  } = useFormContext<FormFields>()

  const { isSubmitting } = formState

  const totalSteps = steps?.length
  const activeStep = steps[activeStepIndex]
  const isLastStep = activeStepIndex === totalSteps - 1

  const isMounted = useIsMounted()

  const [stepChangePending, setStepChangePending] = useState(false)

  useEffect(() => {
    onStepChange && onStepChange(activeStepIndex)
  }, [activeStepIndex])

  const goToNextStep = async (
    evt: Parameters<MouseEventHandler<HTMLButtonElement>>[0]
  ) => {
    clearErrors()

    const form = getValues()

    const activeStepForm = form[activeStep?.name]

    const validationErrors =
      activeStep.validationRules &&
      activeStep.validationRules
        .map(({ field, rules }) =>
          rules.map((rule) => {
            if (!rule.condition(activeStepForm[field], rule.value)) {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              setError(`${activeStep.name}.${field}` as any, {
                type: rule.type,
                message: t(
                  `${activeStep.validationTranslatePrefix}.fields.${field}.errors.${rule.type}`,
                  rule.value
                )
              })
              return rule
            }
            return false
          })
        )
        .flat()
        .filter((el) => el)

    const isValid = await trigger()

    if (!isMounted() || !isValid || validationErrors?.length) return

    let canContinue = true

    if (activeStep.submit) {
      try {
        setStepChangePending(true)
        canContinue = await activeStep.submit(form)
      } catch (e) {
        canContinue = false
      } finally {
        if (isMounted()) {
          setStepChangePending(false)
        }
      }
    }

    if (!isMounted()) {
      return
    }

    if (!canContinue) {
      evt.preventDefault()
      return
    }

    if (!isLastStep && activeStepIndex < totalSteps) {
      onStepChange(activeStepIndex + 1)
      evt.preventDefault()
    }
  }

  const goBack = () => {
    if (activeStepIndex > 0) {
      clearErrors()
      onStepChange(activeStepIndex - 1)
    }
  }

  return (
    <Stack
      spacing={2}
      flexGrow={1}
      component="form"
      onSubmit={onSubmit && handleSubmit((...args) => onSubmit(...args))}
      {...rest}
    >
      {isSubmitting ? (
        <LoadingPage />
      ) : (
        <>
          {activeStep.renderTitle ? (
            activeStep.renderTitle()
          ) : renderTitle ? (
            renderTitle({ activeStepIndex, name: activeStep.name })
          ) : (
            <PageBodyContainer>
              <Typography variant="h2">{activeStep.title}</Typography>
              {activeStep.description && (
                <Typography>{activeStep.description}</Typography>
              )}
            </PageBodyContainer>
          )}
          {activeStep.renderForm &&
            activeStep.renderForm({ name: activeStep.name })}

          <ErrorsList formState={formState} name={activeStep.name} />

          <Box sx={{ flexGrow: 1 }} />

          <BottomButtonsContainer>
            <Button
              fullWidth
              color="secondary"
              onClick={() => onCancel && onCancel()}
            >
              {t('general.cancel')}
            </Button>
            <Button
              fullWidth
              variant="outlined"
              color="secondary"
              sx={{ mr: 2 }}
              onClick={goBack}
              disabled={activeStepIndex <= 0}
            >
              {t('general.back')}
            </Button>

            {!hideSubmitButton && (
              <Button
                fullWidth
                disabled={stepChangePending}
                variant="contained"
                color="secondary"
                type={isLastStep ? 'submit' : 'button'}
                onClick={goToNextStep}
              >
                {t(isLastStep ? 'general.finish' : 'general.next')}
                {stepChangePending && <LoadingButtonIcon />}
              </Button>
            )}
          </BottomButtonsContainer>
        </>
      )}
    </Stack>
  )
}
