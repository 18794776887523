import { Button, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  useDeclineOrganizationMember,
  useJoinOrganization
} from '../../../utils/api/hooks/organization'
import { useErrorStateSnackEffect } from '../../../utils/hooks/stateSnackEffect'
import PlusIcon from '../../common/Icon/Plus'
import ButtonListContainer from '../../common/Layout/ButtonListContainer'
import LoadingButtonIcon from '../../common/LoadingButtonIcon'
import PageBodyContainer from '../../common/PageBodyContainer'
import { OrganizationMemberOverview } from '../../../models/organization'
import { useAuthenticatedUserId } from '../../../utils/api/hooks/useAuthenticatedUserId'
import useIsMounted from '../../../utils/hooks/isMounted'
import { useNavigate } from 'react-router-dom'
const COMPONENT_TRANSLATE_PREFIX =
  'components.organization.unauthorized.unauthorizedByMembership'

type UnauthorizedByMembershipProps = {
  organizationId?: string
  member?: OrganizationMemberOverview
  notMemberBody: string
}

const UnauthorizedByMembership = (props: UnauthorizedByMembershipProps) => {
  const { organizationId, member, notMemberBody } = props
  const { enqueueSnackbar } = useSnackbar()
  const userId = useAuthenticatedUserId()
  const isMounted = useIsMounted()
  const navigate = useNavigate()

  const { t } = useTranslation(undefined, {
    keyPrefix: COMPONENT_TRANSLATE_PREFIX
  })

  const onJoinOrganizationSuccess = useCallback(() => {
    enqueueSnackbar(t('action.createRequest.fulfilled'), {
      variant: 'success'
    })
    if (isMounted()) {
      navigate('./../')
    }
  }, [t, enqueueSnackbar])

  const joinOrganizationMutation = useJoinOrganization(
    onJoinOrganizationSuccess
  )

  useErrorStateSnackEffect(
    joinOrganizationMutation.error,
    t('action.createRequest.error')
  )

  const onDeclineOrganizationMemberSuccess = useCallback(() => {
    enqueueSnackbar(t('action.cancelRequest.fulfilled'), {
      variant: 'success'
    })
    if (isMounted()) {
      navigate('./../')
    }
  }, [t, enqueueSnackbar])

  const declineOrganizationMemberMutation = useDeclineOrganizationMember(
    onDeclineOrganizationMemberSuccess
  )

  useErrorStateSnackEffect(
    declineOrganizationMemberMutation.error,
    t('action.cancelRequest.error')
  )

  const isPendingMember = !!member && !member.isApproved

  const sectionPrefix = useMemo(
    () => `section.${isPendingMember ? 'pendingMember' : 'notMember'}`,
    [member]
  )

  const updateMembership = () => {
    member
      ? declineOrganizationMemberMutation.mutateAsync({
          id: organizationId!,
          userId: userId!
        })
      : joinOrganizationMutation.mutateAsync({
          id: organizationId!
        })
  }

  const updateMembershipPending =
    joinOrganizationMutation.isLoading ||
    declineOrganizationMemberMutation.isLoading

  return (
    <>
      <PageBodyContainer>
        <Typography variant="h2">{t(`${sectionPrefix}.title`)}</Typography>

        <Typography>
          {isPendingMember ? t(`${sectionPrefix}.body`) : notMemberBody}
        </Typography>
      </PageBodyContainer>

      <ButtonListContainer>
        <Button
          variant="contained"
          startIcon={!updateMembershipPending && <PlusIcon />}
          disabled={updateMembershipPending}
          onClick={updateMembership}
        >
          {updateMembershipPending ? (
            <LoadingButtonIcon />
          ) : (
            t(
              `action.${
                isPendingMember ? 'cancelRequest' : 'createRequest'
              }.label`
            )
          )}
        </Button>
      </ButtonListContainer>
    </>
  )
}

export default UnauthorizedByMembership
