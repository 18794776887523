import {
  LineChart,
  Line,
  YAxis,
  Tooltip,
  CartesianGrid,
  XAxis,
  ResponsiveContainer,
  ReferenceLine
} from 'recharts'
import { useTranslation } from 'react-i18next'
import { Card, Typography } from '@mui/material'
import { useGetReferenceWeightQuery } from '../../../../../utils/api/hooks/beehive'

const COMPONENT_TRANSLATE_PREFIX = 'components.beehive.sensorDataChart'

const SensorDataChart = (props) => {
  const { sensorType, sensorData, height, beehiveId, currRefWeight } = props
  const refWeight = useGetReferenceWeightQuery(beehiveId).data?.data

  const { t } = useTranslation(undefined, {
    keyPrefix: COMPONENT_TRANSLATE_PREFIX
  })

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' }
    return new Date(dateString).toLocaleDateString(undefined, options)
  }

  const formatDateDayMonth = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' }
    return new Date(dateString).toLocaleDateString(undefined, options)
  }

  return (
    <Card
      sx={{
        p: 0,
        borderRadius: '10px',
        border: 'none',
        borderShadow: 'none',
        boxShadow: 'none',
        backgroundColor: '#F6F7F9',
        m: 2
      }}
    >
      <Typography
        style={{ border: 'none', borderShadow: 'none' }}
        variant="h6"
        textAlign="center"
      >
        {t(`sensorType.${sensorType}`)}
      </Typography>

      <ResponsiveContainer
        height={height}
        sx={{ p: 0, border: 'none', borderShadow: 'none' }}
        style={{ border: 'none', borderShadow: 'none' }}
      >
        {sensorData !== undefined && sensorData?.length > 0 ? (
          <LineChart
            data={sensorData} // sensorData
            width={'100%'}
            Height={25}
            margin={{ top: 5, right: 30, left: 0, bottom: 5 }}
          >
            <XAxis
              dataKey="time"
              minTickGap={50}
              interval="preserveEnd"
              tickFormatter={(tick) => formatDateDayMonth(tick)} // {formatDateDayMonth}
            />
            <ReferenceLine y={currRefWeight || refWeight} strokeWidth={2} />
            <YAxis y dataKey="value" domain={['auto', 'auto']} />
            <Tooltip labelFormatter={formatDate} />
            <CartesianGrid stroke="#f5f5f5" strokeDasharray="5 5" />
            <Line
              type="monotone"
              dataKey="value"
              stroke="#ff7300"
              yAxisId={0}
              // dot={false}
            />
          </LineChart>
        ) : (
          <Typography textAlign={'center'} sx={{ paddingTop: 2 }}>
            {t('error.noData')}
          </Typography>
        )}
      </ResponsiveContainer>
    </Card>
  )
}

export default SensorDataChart
