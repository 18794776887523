const apiaryEditForm = {
  title: {
    create: 'Create Apiary',
    update: 'Update Apiary'
  },

  fields: {
    owner: {
      label: 'Owner'
    },
    propertyDesignation: {
      label: 'Property Designation',
      errors: {
        required: 'Property designation is required!'
      }
    },
    areaOfUse: {
      label: 'Area of Use'
    }
  },
  sections: {
    qr: {
      title: 'QR Codes',
      body: "Generate a QR code for each beehive in the apiary that doesn't already have one. These make it easy to create notes and view data for that beehive without having to navigate through the website."
    }
  },
  success: {
    updateApiary: 'Apiary has been updated!'
  },
  error: {
    updateApiary: 'An error occurred while updating the apiary!'
  },
  updateApiary: 'Update Apiary',
  coordinates: 'Coordinates',
  town: 'Town',
  propertydesignation: 'Property Designation',
  areaOfUse: 'Area of Use',
  chooseApiaryLocation: 'Choose Apiary Location on a Map',
  generateQRButton: 'Generate QR Codes for the whole Apiary'
}

export default apiaryEditForm
