// eslint-disable-next-line no-use-before-define
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { BottomNavigation, BottomNavigationAction } from '@mui/material'
import MuiAlert, { AlertProps } from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'
import { useTranslation } from 'react-i18next'
import HexagonOutlinedIcon from '@mui/icons-material/HexagonOutlined'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined'
import TimelineRoundedIcon from '@mui/icons-material/TimelineRounded'
import { AppRoutePathSegment, AppRoutePaths } from '../../utils/routes'
import { useGetFirstIotUnit } from '../../utils/api/hooks/apiary'
import VariablePathSegment from '../../enums/Path/VariablePathSegment'

const COMPONENT_TRANSLATE_PREFIX = 'components.navigation.BottomNavigation'

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

const BottomNav = () => {
  let userId: string | undefined

  const authStateString = localStorage.getItem('api-auth-state') ?? ''
  try {
    const authState = JSON.parse(authStateString)
    userId = authState.userId
  } catch (error) {
    console.error('Error parsing auth state:', error)
  }

  const hasIoT = useGetFirstIotUnit(userId).data?.data
  const location = useLocation()
  const { t } = useTranslation(undefined, {
    keyPrefix: COMPONENT_TRANSLATE_PREFIX
  })
  const [open, setOpen] = React.useState(false)

  const handleClick = () => {
    setOpen(true)
  }

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }
  return (
    <BottomNavigation
      sx={{ backgroundColor: '#F5F5F5' }}
      value={location.pathname}
      showLabels
    >
      <BottomNavigationAction
        label={t('Home')}
        value="/"
        component={Link}
        to="/"
        icon={<HomeOutlinedIcon></HomeOutlinedIcon>}
      />
      <BottomNavigationAction
        label={t('organization')}
        value="/about"
        component={Link}
        to={AppRoutePaths.LIST_ORGANIZATIONS_PATH}
        icon={<HexagonOutlinedIcon></HexagonOutlinedIcon>}
      />
      <BottomNavigationAction
        label={t('notes')}
        value="/contact"
        component={Link}
        to={AppRoutePaths.NEW_NOTE_PATH}
        icon={<NoteAltOutlinedIcon></NoteAltOutlinedIcon>}
      ></BottomNavigationAction>
      {hasIoT?.hasIot ? (
        <BottomNavigationAction
          label={t('IoT')}
          value="/contact"
          component={Link}
          to={AppRoutePathSegment.replaceSegments(
            AppRoutePaths.DISPLAY_BEEHIVE_PATH,
            [
              {
                name: VariablePathSegment.BEEHIVE_ID,
                value: hasIoT.iotUnit.id
              },
              {
                name: VariablePathSegment.APIARY_ID,
                value: hasIoT.iotUnit.apiaryId
              },
              {
                name: VariablePathSegment.ORGANIZATION_ID,
                value: hasIoT.iotUnit.organitazionId
              }
            ]
          )}
          icon={<TimelineRoundedIcon></TimelineRoundedIcon>}
        ></BottomNavigationAction>
      ) : (
        <BottomNavigationAction
          label="IoT"
          value="/contact"
          onClick={handleClick}
          icon={<TimelineRoundedIcon></TimelineRoundedIcon>}
        ></BottomNavigationAction>
      )}
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="warning" sx={{ width: '100%' }}>
          {t('errorNoIoT')}
        </Alert>
      </Snackbar>
    </BottomNavigation>
  )
}

export { BottomNav }
