const beehiveInspectionNoteEditForm = {
  section: {
    general: 'Allmänt',
    society: 'Om samhället',
    queen: 'Drottning',
    other: 'Annat'
  },
  actions: {
    updateNote: {
      label: 'Redigera'
    },
    updatingNote: {
      label: 'Redigerar...'
    },
    saveNote: {
      label: 'Spara'
    }
  },
  defaultTitle: '{{val, datetime}}',
  fields: {
    title: {
      label: 'Titel'
    },

    newEggs: {
      _checkbox: {
        label: 'Nylagda ägg'
      },
      value: {
        label: 'Mängd ramar'
      }
    },
    coveredFries: {
      _checkbox: {
        label: 'Täckt yngel'
      },
      value: {
        label: 'Antal ramar'
      }
    },
    filledFryRoomsWithFrames: {
      _checkbox: {
        label: 'Fyllt upp yngelrummet med ramar'
      },
      value: {
        label: 'Antal'
      }
    },
    cutDroneFrame: {
      label: 'Drönarram utskuren'
    },
    attractsPollen: {
      label: 'Samhället drar in pollen'
    },
    winterFed: {
      _checkbox: {
        label: 'Vinterfodrat'
      },
      value: {
        label: 'kg / liter'
      }
    },
    checkedFramesWithRemainingFodder: {
      _checkbox: {
        label: 'Kontrollerat antal ramar med foder kvar'
      },
      value: {
        label: 'Antal ramar'
      }
    },
    hurled: {
      label: 'Slungat'
    },
    performedSpringControl: {
      _checkbox: {
        label: 'Utfört vårkontroll'
      }
    },
    performedBeeHouseControl: {
      _checkbox: {
        label: 'Utfört bihusesyn/egenkontroll'
      }
    },
    societyStrength: {
      _checkbox: {
        label: 'Styrka på samhället'
      }
    },
    societyBehavior: {
      _checkbox: {
        label: 'Samhällets egenskap'
      }
    },
    sicknessSymptom: {
      _checkbox: {
        label: 'Sjukdomssymptom'
      },
      value: {
        label: ''
      }
    },
    queenControl: {
      _checkbox: {
        label: 'Drottningkontroll'
      }
    },
    newQueen: {
      label: 'Ny drottning tillsatt'
    },
    queenMarked: {
      _checkbox: {
        label: 'Märkning av drottning'
      },
      value: {
        label: 'Färg',
        option: {
          blue: 'Blå',
          red: 'Röd',
          yellow: 'Gul'
        }
      }
    },
    queenWingCut: {
      label: 'Vingklippte drottningen'
    },
    other: {
      _checkbox: {
        label: 'Övrigt'
      }
    }
  }
}

export default beehiveInspectionNoteEditForm
