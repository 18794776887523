const about = {
  title: 'Om oss',
  contactButton: 'Kontakta oss',
  contactTitle: 'Kontakt',

  sections: {
    background: {
      title: 'Bakgrund',
      sections: {
        intro: 'BeeLab Technology Sweden AB är ett nystartat svenskt företag med sitt säte i Kalmar. Vi är ett gäng entusiaster som bryr oss om bin och vill arbeta för att utveckla biodlingen. I teamet finns erfarna back-end specialister, teknikutvecklare, UX/UI kompetens, professionell hantverkskunskap och naturligtvis bi-expertis! Företagets VD är Mikael Ekström med lång erfarenhet av biodling.',
        details: 'BeeLab är ett företag grundat ämnat att tillföra en serie produkter ämmade att underlätta modern biodling. Utöver att erbjuda två modeller av toppmoderna och lättanvändliga kupor så erbjuds även BeeLab Appen, där biodlare kan övervaka sina kupor i realtid samt få historisk data över exempelvis vikt, luftfuktighet och temperatur. Appen bidrar även med lösningar för att dokumentera sin biodling. För att tillhandahålla denna data finns även BeeLab Gateway – ett sensorkit som placeras i kupan för att hämta information.'
      }
    },
    vision: {
      title: 'Vision',
      sections: {
        default: 'Bidöden är svår och det krävs avsevärt mer forskning, data och arbete för att verkligen förstå vad som ligger till grund till den katastrof som just nu drappar bin och bisamhällen. Denna katastrof är global och drabbar alla biodlare, stora som små. Vi på BeeLab anser det vara vår högsta prioritet att hitta grunden till bidöden så att lösningar kan finnas omgående. BeeLabs tjänster kan vara mycket viktiga för att påbörja ett sådant initiativ, då dessa bidrar till långsiktig global hållbarhet och biologisk mångfald – för med bina dör planeten!'
      }
    },
    strategy: {
      title: 'Strategi',
      sections: {
        default: ' Vi på BeeLab bryr oss om bin och brinner för att utveckla teknik och miljöer som underlättar för både biodlare och bin. I Beelabs team finns allt ifrån biexperter, snickeri-specialister till tekniknördar. Denna unika kombination av kunskap och erfarenhet skapar en dynamik som leverarar resultat. Som en följd av genuint intresse har vi just den rätta insikten som krävs för att förstå marknad, produktion och vad som funkar bäst – genom hands on eperience!'
      }
    },
    history: {
      title: 'Historia',
      sections: {
        hsb: {
          title: 'Hur BeeLab Projektet Grundades',
          default: ' I Göteborg finns sedan flera år tillbaka ett projekt kallat HSB Living Lab. Där har man skapat ett lägenhetshus som samtidigt fungerar som en forskningsplats med sensorer och annan utrustning för att  hitta långsiktiga och smarta lösningar. BeeLab kom till som ett 3 årigt projekt mellan Tieto Evry och HSB Living Labs för att skapa en bikupa med samma funktion som lägenheterna i Göteborg – att övervaka vässentlig data och finna nya lösningar till biodlingens problem.'
        },
        beelab: {
          title: 'BeeLab Technology Sweden AB',
          default: ' grundades sedan 2020 med syfte att bygga vidare på BeeLab projektet som endast pågår mellan 2019-2022. Genom detta företag har vi fortsatt utveckla kuporna som byggdes under projekt-eran men även skapat nya kupor för att tillgodose efterfrågan som finns. Våra uppkopplade kupor står nu runt om i Sverige blandat annat vid Tieto Evry Kalmar, HSB Living Labs i Göteborg och vid Biodlarnas kansli i Skänninge.'
        }
      }
    },
    GDPR: {
      title: 'GDPR',
      informationText: 'I enlighet med artikel 6.1.b i EU:s dataskyddsförordning lagrar vi de personuppgifter som du fyllt i när du registrerar dig för att använda tjänsten. \nUtöver dessa uppgifter samlar vi inte in någon ytterligare data om dig. Vi använder inga cookies eller dylikt för att spåra dig, våra servrar står i Sverige och vi förmedlar inte dina uppgifter vidare till tredje part. \n\nPersonuppgiftsansvarig är Mikael Ekström \n\nEpost:  mikael.ekstrom@beelab.se'
    }
  }
}

export default about
