const login = {
  title: 'Inloggning',
  infoTitle: 'Logga in',

  actions: {
    login: {
      label: 'Logga in',
      pending: 'Loggar in...',
      error: {
        badCredentials: 'Felaktigt användarnamn eller lösenord',
        network: 'Kunde inte kontakta tjänsten vid inloggning',
        general: 'Ett fel inträffade vid inloggning'
      }
    },

    forgotPassword: {
      label: 'Har du glömt ditt lösenord?'
    }
  }
}

export default login
