import { MutableRefObject, useCallback, useEffect } from 'react'
import { formHelpers } from '../../../../utils/form'
import { replaceContentCheckRowValues } from '../../../common/Form/FormGrid/CommonRows/CheckRow/ContentCheckRow'
import BeehiveInspectionNoteForm from '../../BeehiveInspectionNoteForm'
import { useCreateBeehiveInspectionNoteMutation } from '../../../../utils/api/hooks/inspectionNote'
import { useSnackbar } from 'notistack'
import useIsMounted from '../../../../utils/hooks/isMounted'
import { useNavigate } from 'react-router-dom'
import { AppRoutePaths } from '../../../../utils/routes'
import { BeehiveInspectionNoteBaseDto } from '../../../../models/notes'
import { useTranslation } from 'react-i18next'

type BeehiveInspectionNoteProps = {
  forExisting?: boolean
  entitySelectName: string
  onSubmitRef: MutableRefObject<any>
  name: string
}
const COMPONENT_TRANSLATE_PREFIX = 'pages.note.newNotePage'

const COMPONENT_CREATE_FUNCTION =
  (name: string, entitySelectName: string, mutation: any) => (form: object) => {
    const inspectionValues = formHelpers.getFormValue(form, name)
    const entitySelectValues = formHelpers.getFormValue(form, entitySelectName)

    const values = replaceContentCheckRowValues(inspectionValues) as Omit<
      BeehiveInspectionNoteBaseDto,
      'beehiveId' | 'organizationId'
    >

    return mutation.mutate({
      ...values,
      organizationId: entitySelectValues.organization.id,
      beehiveId: entitySelectValues.beehive.id
    })
  }

// const COMPONENT_UPDATE_FUNCTION = (name : string, entitySelectName: string) => () => { throw new Error('UPDATE BeehiveInspectionNote not implemented') }

const BeehiveInspectionDetails = (props: BeehiveInspectionNoteProps) => {
  const { entitySelectName, forExisting, onSubmitRef, name } = props

  const { t } = useTranslation(undefined, {
    keyPrefix: `${COMPONENT_TRANSLATE_PREFIX}`
  })

  const { enqueueSnackbar } = useSnackbar()
  const isMounted = useIsMounted()
  const navigate = useNavigate()

  const onCreateBeehiveInspectionNoteSuccess = useCallback(() => {
    enqueueSnackbar(t('actions.submit.fulfilled'), { variant: 'success' })
    navigate(AppRoutePaths.NOTES_PATH)
  }, [enqueueSnackbar, isMounted, navigate])

  const createBeehiveInspectionNoteMutation =
    useCreateBeehiveInspectionNoteMutation(onCreateBeehiveInspectionNoteSuccess)

  useEffect(() => {
    if (forExisting) {
      // onSubmitRef.current = COMPONENT_UPDATE_FUNCTION(name, entitySelectName)
    } else {
      onSubmitRef.current = COMPONENT_CREATE_FUNCTION(
        name,
        entitySelectName,
        createBeehiveInspectionNoteMutation
      )
    }
  }, [name, forExisting, onSubmitRef])

  return <BeehiveInspectionNoteForm name={name} />
}

export default BeehiveInspectionDetails
