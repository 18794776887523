import apiariesTab from './apiariesTab'
import membersTab from './membersTab'
import infoTab from './infoTab'

const tabs = {
  apiariesTab,
  membersTab,
  infoTab
}

export default tabs
