import apiFetcher from '../apiFetcher'

export const createOrganization = apiFetcher
  .path('/organization/createWithEntities')
  .method('post')
  .create()

export const getUserOrganizations = apiFetcher
  .path('/organization/forUser')
  .method('get')
  .create()

export const getOrganizationsWhereUserNotMember = apiFetcher
  .path('/organization/getAll')
  .method('get')
  .create()

export const getOrganizationById = apiFetcher
  .path('/organization/byId/{id}')
  .method('get')
  .create()

export const getOrganizationMembers = apiFetcher
  .path('/organization/members/forOrganization/{id}')
  .method('get')
  .create()

export const getOrganizationMemberById = apiFetcher
  .path('/organization/member/forOrganization/{id}/{memberId}')
  .method('get')
  .create()

export const approveOrganizationMember = apiFetcher
  .path('/organization/accept/member/{id}/{userId}')
  .method('put')
  .create()

export const declineOrganizationMember = apiFetcher
  .path('/organization/decline/member/{id}/{userId}')
  .method('delete')
  .create()

export const leaveOrganization = apiFetcher
  .path('/organization/leave/{id}')
  .method('delete')
  .create()

export const updateOrganization = apiFetcher
  .path('/organization/update/{orgId}')
  .method('put')
  .create()

export const joinOrganization = apiFetcher
  .path('/organization/add/member/{id}')
  .method('put')
  .create()

export const removeOrganization = apiFetcher
  .path('/organization/{orgId}')
  .method('delete')
  .create()
