const editOrganizationMember = {
  actions: {
    approveMember: {
      title: 'Approve member',
      description: 'Approve a member and assign a role in the organization.'
    },
    changeRole: {
      title: 'Change role',
      description:
        'Roles determine what a member can do within the organization.',
      buttonText: 'Change role',
      fulfilled: 'Role updated',
      error: 'Role could not be updated',
      adminDescription:
        'An administrator can edit, add, and remove information about the organization and its members',
      maintainerDescription:
        'A maintainer can view, edit, and add information about the organization and its members.',
      readerDescription:
        'A reader can only view information about the organization and its members.'
    },
    removeMember: {
      title: 'Remove member',
      description:
        'Removing the member will also remove all information the member has added to the organization. This action cannot be undone.',
      buttonText: 'Remove',
      fulfilled: 'Member removed',
      error: 'Removal failed.'
    },
    getMember: {
      error: 'An error occurred while fetching the member'
    },
    getAuthMember: {
      error: 'An error occurred while fetching your role for the organization'
    }
  },
  header: {
    name: 'Name',
    role: 'Role',
    email: 'Email',
    phoneNumber: 'Phone number',
    address: 'Address'
  },
  body: {
    roleMissing: 'Unconfirmed'
  }
}

export default editOrganizationMember
