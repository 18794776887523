import { Checkbox, Stack, Typography } from '@mui/material'
import FormGridField from '../../FormGridField'

const CheckRow = props => {
  const {
    hideCheckbox,
    disableGutter,
    ...rest
  } = props

  return (
    <FormGridField
      className={disableGutter ? '' : 'checkRow'}
      defaultValue={!!hideCheckbox}
      render={
        (
          {
            controller: {
              field: {
                onChange,
                value
              }
            },
            label
          }
        ) =>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography fontWeight={(hideCheckbox || value) ? 'bold' : undefined}>
              {label}
            </Typography>

            {!hideCheckbox &&
              <Checkbox
                className='main-checkbox'
                color="secondary"
                checked={!!value}
                onChange={(e) => {
                  onChange(e.target.checked)
                }
                }
              />
            }
          </Stack>
      }
      {...rest}
    />
  )
}

export default CheckRow
