import React from 'react'
import { FormGridRules } from '../../../../models/form'

type FormGridContextValue = {
  namePrefix: string,
  translatePrefix: string,
  gridRules: FormGridRules | null
}

const FormGridContext = React.createContext<FormGridContextValue>({
  namePrefix: '',
  translatePrefix: '',
  gridRules: null
})

export default FormGridContext
